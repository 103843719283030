import React, { createContext, useContext, useEffect, useMemo } from "react";
import { createSearchParams, Outlet, Route, Routes, useNavigate, useParams } from "react-router-dom";

import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import clsx from "clsx";

import { PageContent } from "./Layout";
import EventEmitter from "eventemitter3";

import useTabs from "../utils/tabs";

const noop = () => null;
const defaultCtx = {
    refreshList: noop,
    showDetail: noop,
    hideDetail: noop,
    showOverview: noop,
    showMaster: noop,
};

const eventEmitter = new EventEmitter();

const REFRESH_MASTER_EVENT = "REFRESH_MASTER";
const REFRESH_DETAIL_EVENT = "REFRESH_DETAIL";
const REFRESH_OVERVIEW_EVENT = "REFRESH_OVERVIEW";

export const useMasterRefresh = (fn, dependencies) => {
    useEffect(() => {
        eventEmitter.on(REFRESH_MASTER_EVENT, fn);
        return () => eventEmitter.off(REFRESH_MASTER_EVENT, fn);
    }, dependencies || []);
};

export const useDetailRefresh = (fn, dependencies) => {
    useEffect(() => {
        eventEmitter.on(REFRESH_DETAIL_EVENT, fn);
        return () => eventEmitter.off(REFRESH_DETAIL_EVENT, fn);
    }, dependencies || []);
};

export const useOverviewRefresh = (fn, dependencies) => {
    useEffect(() => {
        eventEmitter.on(REFRESH_OVERVIEW_EVENT, fn);
        return () => eventEmitter.off(REFRESH_OVERVIEW_EVENT, fn);
    }, dependencies || []);
};

export const MasterDetailContext = createContext(defaultCtx);

export const useMasterDetail = () => {
    const ctx = useContext(MasterDetailContext);
    return ctx;
};

const createStyles = makeStyles((theme) => ({
    root: {
        flex: 1,
        display: "flex",
        flexDirection: "row",
        alignItems: "stretch",
        height: "100%",
        overflow: "hidden",
    },
    master: {
        flex: "0 0 360px",
        width: 360,
        [theme.breakpoints.down("md")]: {
            width: "100%",
            flex: "1 1 auto",
        },
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
    },
    detail: {
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
    },
    content: {
        flex: "1 1 auto",
        maxHeight: "100%",
        overflowY: "auto",
        overflowX: "hidden",
    },
    detailContent: {
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
    },
    detailBackground: {
        backgroundColor: theme.palette.background.paper,
    },
    detailBorder: {
        borderLeft: "1px solid rgba(0,0,0,0.12)",
        [theme.breakpoints.down("md")]: {
            border: "none",
        },
    },
    empty: {
        flex: "1 1 auto",
        padding: theme.spacing(8),
        display: "flex",
        alignItems: "center",
        alignContent: "center",
        justifyContent: "center",
    },
}));

const MasterWrapper = ({ classes }) => {
    return (
        <div className={classes.master}>
            <Outlet />
        </div>
    );
};

const Wrapper = ({ content, classes, hasBackground }) => {
    return <div className={clsx(classes.detailContent, hasBackground ? classes.detailBackground : classes.detailBorder)}>{content}</div>;
};

const Empty = ({ classes, text }) => {
    return <div className={classes.empty}>{text || ""}</div>;
};

export const useMasterDetailParams = () => {
    let { ...params } = useParams();
    let tabName = params["*"];
    if (!!tabName) {
        delete params["*"];
        params.tabName = tabName;
    }
    return params;
};

export const useMasterDetailTabs = (tabNames) => {
    return useTabs(tabNames, "tab", {});
};

export const MasterDetail = ({
    masterElement,
    detailElement,
    overviewElement,
    addElement,
    addPath = "add",
    detailHasBackground = true,
    overviewHasBackground = false,
}) => {
    const classes = createStyles();
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down("md"));
    const navigate = useNavigate();
    const params = useMasterDetailParams();

    const showAdd = (path) => {
        let parts = (addPath || "").split("/") || [];

        if (!!path) {
            (path.split("/") || []).forEach((s) => parts.push(s));
        }

        navigate(parts.filter((s) => !!s).join("/"));
    };

    const showDetail = useMemo(
        () => (id, params) => {
            let obj = { pathname: `${id}` };
            if (!!params) {
                obj.search = `?${createSearchParams(params)}`;
            }
            navigate(obj);
        },
        [navigate, params]
    );

    const hideDetail = () => {
        navigate("");
    };

    const showOverview = () => {
        navigate("overview");
    };

    const refreshMaster = useMemo(
        () => () => {
            eventEmitter.emit(REFRESH_MASTER_EVENT, {});
        },
        []
    );

    const refreshDetail = useMemo(
        () => () => {
            eventEmitter.emit(REFRESH_DETAIL_EVENT);
        },
        []
    );

    const refreshOverview = useMemo(
        () => () => {
            eventEmitter.emit(REFRESH_OVERVIEW_EVENT);
        },
        []
    );

    return (
        <MasterDetailContext.Provider value={{ mobile, showDetail, hideDetail, showOverview, showAdd, refreshMaster, refreshDetail, refreshOverview }}>
            <div className={classes.root}>
                <Routes>
                    <Route element={<MasterWrapper classes={classes} />}>
                        <Route path={mobile ? "/" : "/*"} element={masterElement} />
                    </Route>
                </Routes>
                <div className={classes.detail}>
                    <Routes>
                        {!mobile && !!overviewElement && (
                            <Route path="" element={<Wrapper content={overviewElement} classes={classes} hasBackground={overviewHasBackground} />} />
                        )}
                        <Route path="overview" element={<Wrapper content={overviewElement} classes={classes} hasBackground={overviewHasBackground} />} />
                        {!!addElement && (
                            <Route path="add/*" element={<Wrapper content={addElement} classes={classes} hasBackground={detailHasBackground} />} />
                        )}
                        <Route path=":detailId/*" element={<Wrapper content={detailElement} classes={classes} hasBackground={detailHasBackground} />} />
                        {!mobile && <Route path="*" element={<Empty classes={classes} text="Vyberte záznam k zobrazení" />} />}
                    </Routes>
                </div>
            </div>
        </MasterDetailContext.Provider>
    );
};

export const MasterDetailContent = ({ children, ...props }) => {
    return <PageContent {...props}>{children}</PageContent>;
};

export const MasterContent = ({ children }) => {
    const classes = createStyles();
    return <div className={classes.content}>{children}</div>;
};
