export const avatarString = (s, len) => {
    if (!s) {
        return "";
    }

    if (isNaN(len) || len < 2) {
        len = 2;
    }

    return s.substring(0, Math.min(len, s.length));
};

export const unaccent = (s, shouldLowercase, shouldTrim) => {
    s = (s || "").normalize("NFD").replace(/\p{Diacritic}/gu, "");
    if (shouldTrim) {
        s = s.trim();
    }
    if (shouldLowercase) {
        s = s.toLowerCase();
    }
    return s;
};
