import React from "react";

import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "./Link";
import Grid from "@mui/material/Grid";
import HomeIcon from "@mui/icons-material/Home";
import Toolbar from "@mui/material/Toolbar";
import {makeStyles} from "@mui/styles";
import {LoadingIndicator, useLoadingIndicator} from "../utils/loading";
import Avatar from "@mui/material/Avatar";
import PersonIcon from "@mui/icons-material/Person";

import {useForm} from "../utils/form";
import {FieldErrors, FormErrors} from "../utils/errors";

const useLayoutClasses = makeStyles((theme) => ({
    root: {
        display: "flex",
        flex: 1,
    },
    left: {
        flex: "1 1 30%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        //backgroundColor: "#FAFAFA",
        backgroundColor: theme.palette.primary.light,
        [theme.breakpoints.down("md")]: {
            display: "none",
        },
    },
    right: {
        flex: "1 1 70%",
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        backgroundColor: theme.palette.background.paper,
    },
    rightContent: {
        flex: "1 1 auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    content: {
        maxWidth: 800,
        width: "100%",
    },
    homeButton: {
        position: "absolute",
        right: 0,
        top: 0,
    },
    toolbar: {
        justifyContent: "space-between",
    },
}));

export const SecurityLayout = ({ banner, children, onAction, actionIcon, actionTitle, withLoadingIndicator }) => {
    if (!actionIcon) {
        actionIcon = <HomeIcon />;
    }
    const classes = useLayoutClasses();
    return (
        <>
            <div className={classes.root}>
                <div className={classes.left}>{banner}</div>
                <div className={classes.right}>
                    {!!withLoadingIndicator ? <LoadingIndicator color="secondary" fixed /> : null}
                    <Toolbar className={classes.toolbar}>
                        <div />
                        <IconButton onClick={onAction} title={actionTitle || ""}>
                            {actionIcon}
                        </IconButton>
                    </Toolbar>
                    <div className={classes.rightContent}>
                        <Box p={4} className={classes.content}>
                            {children}
                        </Box>
                    </div>
                </div>
            </div>
        </>
    );
};

const useButtonBarClasses = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
}));

const ButtonBar = ({ children }) => {
    const classes = useButtonBarClasses();
    return <Box className={classes.root}>{children}</Box>;
};

const useHeaderClasses = makeStyles((theme) => ({
    root: {
        fontSize: "1rem",
        lineHeight: 1.5,
        color: "rgb(100, 110, 115)",
        textTransform: "uppercase",
        fontWeight: 500,
    },
}));

const Header = ({ children }) => {
    const classes = useHeaderClasses();
    return (
        <Typography variant="body1" className={classes.root} component="div">
            {children}
        </Typography>
    );
};

const useLinkClasses = makeStyles((theme) => ({
    root: {
        textDecoration: "underline",
        color: theme.palette.text.disabled,
    },
}));

const CustomLink = ({ disabled, children, onClick, ...props }) => {
    const classes = useLinkClasses();
    if (!!disabled) {
        return (
            <Typography color="textDisabled" component="span" className={classes.root}>
                {children}
            </Typography>
        );
    }
    return (
        <Link
            {...props}
            onClick={
                onClick
                    ? (e) => {
                          e.preventDefault();
                          onClick();
                      }
                    : null
            }
        >
            {children}
        </Link>
    );
};

export const Login = ({ onSignup, onResetPassword, loginAction }) => {
    const { isLoading } = useLoadingIndicator();
    const { values, handleChange, errors, setErrors, handleSubmit } = useForm({
        onSubmit: loginAction,
        parseErrors: (e) => {
            if (!!e.offline) {
                return {
                    "": "Nelze se připojit na server",
                };
            }
            if (e.statusCode == 403) {
                return {
                    username: "Nesprávný email nebo heslo",
                    password: "Nesprávný email nebo heslo",
                };
            }

            //should not happen
            if (e.statusCode == 400 && e.payload) {
                return e.paylaod;
            }

            return {
                "": "Neočekávaná chyba",
            };
        },
    });

    return (
        <>
            <Box mb={3}>
                <Header>přihlášení</Header>
                <Typography variant="h4" component="div" gutterBottom>
                    Vítejte zpět
                </Typography>
                <Typography variant="subtitle1" component="div" color="textSecondary">
                    Přihlašte se a začněte používat svůj účet
                </Typography>
                <LoadingIndicator />
                <FormErrors errors={errors} />
            </Box>
            <form onSubmit={handleSubmit}>
                <Grid spacing={2} container>
                    <Grid item xs={12}>
                        <TextField
                            label="Email"
                            name="username"
                            fullWidth
                            margin="normal"
                            required
                            value={values.username || ""}
                            onChange={handleChange}
                            error={!!errors.username}
                            disabled={isLoading}
                            autoFocus
                        />
                        <FieldErrors errors={errors} field="username" />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography align="right" component="div">
                            <CustomLink href="/resetPassword" disabled={isLoading} onClick={onResetPassword}>
                                Zapomněli jste heslo?
                            </CustomLink>
                        </Typography>
                        <TextField
                            label="Heslo"
                            name="password"
                            fullWidth
                            margin="normal"
                            type="password"
                            required
                            value={values.password || ""}
                            onChange={handleChange}
                            error={!!errors.password}
                            disabled={isLoading}
                        />
                        <FieldErrors errors={errors} field="password" />
                    </Grid>
                </Grid>
                <ButtonBar>
                    <Box>
                        {onSignup ? (
                            <>
                                Ještě nemáte svůj účet?{" "}
                                <CustomLink href="/signup" disabled={isLoading} onClick={onSignup}>
                                    Vytvořte si jej zde
                                </CustomLink>
                            </>
                        ) : null}
                    </Box>
                    <Button variant="contained" size="large" type="submit" disabled={isLoading}>
                        Přihlásit
                    </Button>
                </ButtonBar>
            </form>
        </>
    );
};

export const ResetPassword = ({ onLogin, resetPasswordAction }) => {
    const { values, handleChange, errors, setErrors, handleSubmit } = useForm({
        onSubmit: resetPasswordAction,
        parseErrors: (e) => {
            return {
                "": "Neočekávaná chyba",
            };
        },
    });

    return (
        <>
            <Box mb={3}>
                <Header>obnovení hesla</Header>
                <Typography variant="h4" component="div" gutterBottom>
                    Zapomněli jste heslo?
                </Typography>
                <Typography variant="body1" component="div" color="textSecondary">
                    Zadejte svou emailovou adresu a my vám zašleme instrukce pro obnovení hesla
                </Typography>
                <LoadingIndicator />
            </Box>
            <form onSubmit={handleSubmit}>
                <Grid spacing={2} container>
                    <Grid item xs={12}>
                        <TextField
                            label="Email"
                            name="email"
                            fullWidth
                            margin="normal"
                            required
                            value={values.email || ""}
                            onChange={handleChange}
                            error={!!errors.email}
                            helperText={errors.email}
                            autoFocus
                        />
                    </Grid>
                </Grid>
                <ButtonBar>
                    <Button variant="outlined" size="large" onClick={onLogin}>
                        Zpět na přihlášení
                    </Button>
                    <Button variant="contained" size="large" type="submit">
                        Pokračovat
                    </Button>
                </ButtonBar>
            </form>
        </>
    );
};

export const NewPassword = ({ onLogin, onResetPassword, newPasswordAction }) => {
    const { values, handleChange, errors, setErrors, handleSubmit } = useForm({
        onSubmit: newPasswordAction,
        parseErrors: () => {
            return {
                "": "Neočekávaná chyba",
            };
        },
    });

    return (
        <>
            <Box mb={3}>
                <Header>obnovení hesla</Header>
                <Typography variant="h4" component="div">
                    Nastavení hesla
                </Typography>
                <Typography variant="body1" component="div" color="textSecondary">
                    Vyplňte následující formulář a pokračujte ve využívání služby
                </Typography>
                <LoadingIndicator />
            </Box>
            <form onSubmit={handleSubmit}>
                <Grid spacing={2} container>
                    <Grid item xs={12}>
                        <TextField
                            label="Heslo"
                            name="password"
                            fullWidth
                            margin="normal"
                            required
                            type="password"
                            value={values.password || ""}
                            onChange={handleChange}
                            error={!!errors.password}
                            helperText={errors.password}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Heslo znova"
                            name="passwordCheck"
                            fullWidth
                            margin="normal"
                            required
                            type="password"
                            value={values.passwordCheck || ""}
                            onChange={handleChange}
                            error={!!errors.passwordCheck}
                            helperText={errors.passwordCheck}
                        />
                    </Grid>
                </Grid>

                <ButtonBar>
                    <Box />
                    <Button variant="contained" size="large" type="submit">
                        Změnit heslo
                    </Button>
                </ButtonBar>
            </form>
        </>
    );
};

export const Signup = ({ onLogin, signupAction }) => {
    const { values, handleChange, errors, setErrors, handleSubmit } = useForm({
        onSubmit: signupAction,
    });

    return (
        <>
            <Box mb={3}>
                <Header>registrace</Header>
                <Typography variant="h4" component="div">
                    Vytvořte si nový účet
                </Typography>
                <Typography variant="body1" component="div" color="textSecondary">
                    Vyplňte následující formulář a začněte využívat službu
                </Typography>
                <LoadingIndicator />
            </Box>
            <form onSubmit={handleSubmit}>
                <Grid spacing={2} container>
                    <Grid item xs={12}>
                        <TextField
                            label="Email"
                            name="email"
                            fullWidth
                            margin="normal"
                            required
                            value={values.email || ""}
                            onChange={handleChange}
                            error={!!errors.email}
                            helperText={errors.email}
                            autoFocus
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Heslo"
                            name="password"
                            fullWidth
                            margin="normal"
                            required
                            type="password"
                            value={values.password || ""}
                            onChange={handleChange}
                            error={!!errors.password}
                            helperText={errors.password}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Heslo znova"
                            name="passwordCheck"
                            fullWidth
                            margin="normal"
                            required
                            type="password"
                            value={values.passwordCheck || ""}
                            onChange={handleChange}
                            error={!!errors.passwordCheck}
                            helperText={errors.passwordCheck}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel control={<Checkbox />} label="Souhlasím s Podmínkami služby a Zásadami ochrany soukromí" />
                    </Grid>
                </Grid>

                <ButtonBar>
                    <Box>
                        Již máte svůj účet?{" "}
                        <CustomLink href="/login" onClick={onLogin}>
                            Přihlašte se
                        </CustomLink>
                    </Box>
                    <Button variant="contained" size="large" type="submit">
                        Registrovat
                    </Button>
                </ButtonBar>
            </form>
        </>
    );
};

export const UserInfo = ({ user, logout }) => {
    if (!user) {
        user = {};
    }

    /*
                secondaryAction={
                    <IconButton edge="end" aria-label="delete">
                        <LogoutIcon />
                    </IconButton>
                }    */
    return (
        <Box p={1} display="flex">
            <Avatar>
                <PersonIcon />
            </Avatar>
            <Box px={2}>
                <Box>
                    <Typography variant="subtitle2" component="div">
                        {user.name}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" gutterBottom component="div">
                        {user.owner ? "správce" : "uživatel"}
                    </Typography>
                    <Link
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            logout && logout();
                        }}
                        href="/logout"
                    >
                        Odhlásit
                    </Link>
                </Box>
            </Box>
        </Box>
    );
};

const parseErrors = (e) => {
    if (!!e.offline) {
        return {
            "": "Nelze se připojit na server",
        };
    }
    if (e.statusCode == 403) {
        return {
            username: "Nesprávný email nebo heslo",
            password: "Nesprávný email nebo heslo",
        };
    }

    //should not happen
    if (e.statusCode == 400 && e.payload) {
        return e.paylaod;
    }

    return {
        "": "Neočekávaná chyba",
    };
};
