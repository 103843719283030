import React from "react";

import MuiDatePicker from "@mui/lab/DatePicker";
import MuiTimePicker from "@mui/lab/TimePicker";

import TextField from "@mui/material/TextField";

import moment from "moment";
import {useSettings} from "../utils/settings";

/*
                <DesktopDatePicker label="Date desktop" inputFormat="MM/dd/yyyy" renderInput={(params) => <TextField {...params} />} />
                <MobileDatePicker label="Date mobile" inputFormat="MM/dd/yyyy" renderInput={(params) => <TextField {...params} />} />
*/

const handleChange = (onChange) => (val) => {};

//TODO

export const DatePicker = ({ label, value, clearable, clearText, inputFormat, disabled, onChange, mask, ...props }) => {
    return (
        <MuiDatePicker
            label={label}
            inputFormat={inputFormat || "DD.MM.yyyy"}
            mask={mask || "__.__.____"}
            value={value || null}
            onChange={(date) => {
                !!onChange && onChange(date ? date.format("YYYY-MM-DD") : null);
            }}
            clearable={clearable}
            clearText={clearText}
            disabled={disabled}
            renderInput={(params) => <TextField {...props} {...params} />}
        />
    );
};

export const TimePicker = ({ label, value, clearable, clearText, inputFormat, disabled, onChange, ...props }) => {
    return (
        <MuiTimePicker
            label={label}
            inputFormat={inputFormat || "HH:mm"}
            value={value || null}
            onChange={onChange || console.log}
            clearable={clearable}
            clearText={clearText}
            ampm={false}
            disabled={disabled}
            renderInput={(params) => <TextField {...props} {...params} />}
        />
    );
};

export const formatDate = (val, format = "DD.MM.yyyy") => {
    if (!val) {
        return "";
    }
    return moment(val).format(format);
};

export const DateValue = ({ value, sourceFormat = "YYYY-MM-DD" }) => {
    const settings = useSettings();
    return <>{formatDate(value, settings.dateFormat, sourceFormat)}</>;
};
