import exactMath from "exact-math";

const num = (a, fixed = 0) => {
    if (!a || isNaN(a)) {
        a = 0;
    }
    return exactMath.round(a, -1 * fixed || 0);
};

const multiply = (a, b, fixed) => {
    return num(exactMath.mul(num(a, fixed), num(b, fixed)), fixed);
};

const divide = (a, b, fixed) => {
    b = num(b, fixed);
    if (b == 0) {
        return undefined;
    }

    return num(exactMath.div(num(a, fixed), num(b, fixed)), fixed);
};

const plus = (a, b, fixed) => {
    return num(exactMath.add(num(a, fixed), num(b, fixed)), fixed);
};

const minus = (a, b, fixed) => {
    return num(exactMath.sub(num(a, fixed), num(b, fixed)), fixed);
};

export default {
    num,
    multiply,
    divide,
    plus,
    minus,
};
