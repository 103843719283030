import React, {useMemo, useState} from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

import CloseIcon from "@mui/icons-material/Close";

import {useTheme} from "@mui/material/styles";
import {makeStyles} from "@mui/styles";
import classnames from "classnames";

import {LoadingIndicator} from "../utils/loading";

const createClasses = makeStyles((theme) => ({
    modalRoot: {
        zIndex: 100,
        opacity: 0.975,
        "& > .MuiPaper-root": {
            width: 384,
            maxWidth: "100%",
            display: "flex",
            flexDirection: "column",
        },
    },
    modalSizeBig: {
        "& > .MuiPaper-root": {
            width: 500,
            maxWidth: "100%",
        },
    },
    modalBody: {
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
    },
    modalContent: {
        flex: "1 1 auto",
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
    },
    modalButtons: {
        flex: "0 0 auto",
        marginTop: 16,
        marginBottom: 16,
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        gap: 8,
    },
    fullSizeButton: {
        display: "block",
        marginBottom: 8,
    },
}));

/*

const buttonData = {
  type: "icon|normal",
  main: true|false,
  loadable: true|false,
  closeAfterClick: true|false,
};

*/

const Modal = ({ title, size, variant, children, buttons, fullSizeButtons, open, onClose, disabled, ...props }) => {
    const theme = useTheme();
    const classes = createClasses();

    return (
        <Drawer anchor="right" open={open} onClose={onClose} className={classnames(classes.modalRoot, { [classes.modalSizeBig]: size === "big" })}>
            <>
                <LoadingIndicator color="primary" />
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                    <DialogTitle>{title}</DialogTitle>
                    <IconButton onClick={onClose} aria-label="close" disabled={disabled}>
                        <CloseIcon />
                    </IconButton>
                </Stack>
            </>
            <div className={classes.modalBody}>
                <div className={classes.modalContent}>{children}</div>
                <Box px={2}>
                    {Array.isArray(buttons) && (
                        <div className={classes.modalButtons}>
                            {buttons.map((button, key) => (
                                <Button
                                    key={key}
                                    type="button"
                                    startIcon={button.icon || null}
                                    onClick={button.onClick || onClose}
                                    disabled={disabled || button.disabled}
                                    color="primary"
                                    variant={button.main ? "contained" : undefined}
                                >
                                    {button.title}
                                </Button>
                            ))}
                        </div>
                    )}
                    {Array.isArray(fullSizeButtons) &&
                        fullSizeButtons.map((button, key) => (
                            <Button
                                key={key}
                                className={classes.fullSizeButton}
                                type="button"
                                startIcon={button.icon || null}
                                onClick={button.onClick || onClose}
                                disabled={disabled || button.disabled}
                                color="primary"
                                variant="outlined"
                            >
                                {button.title}
                            </Button>
                        ))}
                </Box>
            </div>
        </Drawer>
    );
};

export default Modal;

export const useModal = () => {
    const [state, setState] = useState({
        open: false,
        data: {},
    });

    const showModal = useMemo(
        () => (data) => {
            setState({ open: true, data: data || {} });
        },
        [state]
    );

    const closeModal = useMemo(
        () => () => {
            setState({ open: false, data: {} });
        },
        [state]
    );

    const setModalData = useMemo(
        () => (data) => {
            setState({ ...state, data: data || {} });
        },
        [state]
    );

    return { showModal, closeModal, data: state.data || {}, modalState: state, open: state.open };
};
