import React from "react";
import {makeStyles} from "@mui/styles";
import Box from "@mui/material/Box";

/*
xs, extra-small: 0px
sm, small: 600px
md, medium: 900px
lg, large: 1200px
xl, extra-large: 1536px
*/

const useBreakpointsStyles = makeStyles((theme) => ({
    root: {
        position: "fixed",
        backgroundColor: "#FFFFFFAA",
        bottom: 0,
        right: 16,
        zIndex: 1000,
    },
}));

const breakpoints = ["xs", "sm", "md", "lg", "xl"];

export const Breakpoints = () => {
    const classes = useBreakpointsStyles();
    return (
        <div className={classes.root}>
            {breakpoints.map((bp) => {
                let display = {};
                breakpoints.forEach((b) => (display[b] = b == bp ? "block" : "none"));
                return (
                    <Box key={bp} sx={{ display }}>
                        <b>{bp}</b>
                    </Box>
                );
            })}
        </div>
    );
};
