import React, {useState} from "react";

import {makeStyles} from "@mui/styles";

import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Menu from "@mui/material/Menu";
import Link from "./Link";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const createClasses = makeStyles({
    dropDownRoot: {
        display: "inline-flex",
        alignItems: "center",
    },
});

export default ({ title, items, variant, onSelect, disabled, children, renderButton }) => {
    const classes = createClasses();
    const [state, setState] = useState({ anchorEl: null });

    const handleClick = (event) => {
        setState({ ...state, anchorEl: event.currentTarget });
        event.stopPropagation();
        event.preventDefault();
    };

    const handleClose = (type) => {
        setState({ ...state, anchorEl: null });
        if (!!type && !!onSelect) {
            onSelect(type);
        }
    };

    return (
        <>
            {typeof renderButton === "function" ? (
                renderButton(handleClick, disabled)
            ) : (
                <Link onClick={handleClick} component="button" underline="none" className={classes.dropDownRoot} disabled={disabled}>
                    {title} {React.isValidElement(children) ? children : <ArrowDropDownIcon color="inherit" />}
                </Link>
            )}
            <Menu
                anchorEl={state.anchorEl}
                open={!!state.anchorEl && !disabled}
                onClose={() => handleClose()}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        "&:before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            left: 14,
                            width: 10,
                            height: 10,
                            bgcolor: "background.paper",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: "left", vertical: "top" }}
                anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
            >
                <MenuList>
                    {Array.isArray(items) &&
                        items.map((item, key) => {
                            return (
                                <MenuItem key={item.id || key} onClick={() => handleClose(item)}>
                                    {item.name}
                                </MenuItem>
                            );
                        })}
                </MenuList>
            </Menu>
        </>
    );
};
