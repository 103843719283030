import {useContext} from "react";

import {SecurityContext} from "./security";

export const useSettings = () => {
    const ctx = useContext(SecurityContext);
    return (ctx.user && ctx.user.payload) || {};
};

export const useChangeSettings = () => {
    const ctx = useContext(SecurityContext);
    return (ctx && ctx.updatePayload) || (() => console.warn("No security context available"));
};
