import React, {createContext, useContext, useState} from "react";
import Button from "@mui/material//Button";
import Dialog from "@mui/material//Dialog";
import DialogActions from "@mui/material//DialogActions";
import DialogContent from "@mui/material//DialogContent";
import DialogContentText from "@mui/material//DialogContentText";
import DialogTitle from "@mui/material//DialogTitle";

const AlertContext = createContext({});

export const useAlert = () => {
    const ctx = useContext(AlertContext) || {};
    return (ctx && ctx.showAlert) || (() => console.warn("Function showAlert is used outside of AlertContainer"));
};

export const AlertContainer = ({ children }) => {
    const [state, setState] = useState({});
    const [open, setOpen] = useState(false);

    const showAlert = (title, content, buttons) => {
        setState({ title, content, buttons });
        setOpen(true);
    };

    const hideAlert = () => {
        setOpen(false);
        setState({});
    };

    const onButtonPressed = (action) => async (e) => {
        e.stopPropagation();

        if (typeof action === "function") {
            try {
                await action();
                hideAlert();
            } catch (e) {
                hideAlert();
                throw e;
            }
        } else {
            hideAlert();
        }
    };

    return (
        <AlertContext.Provider value={{ showAlert }}>
            {children}

            <Dialog open={!!open} onClose={hideAlert} fullWidth maxWidth="sm">
                <DialogTitle>{state.title || ""}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{state.content}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    {!Array.isArray(state.buttons)
                        ? null
                        : state.buttons.map((button, key) => (
                              <Button key={key} type="button" onClick={onButtonPressed(button.onPress)}>
                                  {button.text}
                              </Button>
                          ))}
                </DialogActions>
            </Dialog>
        </AlertContext.Provider>
    );
};
