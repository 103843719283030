import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export default ({ children, label, value, secondaryValue, my, big, showFor = true, align = "left" }) => {
    if (!showFor) {
        return null;
    }

    let content = null;
    if (children) {
        content = children;
    } else {
        content = (
            <Typography variant={big ? "h5" : "body1"} component="span">
                {value || "\u00a0"}
            </Typography>
        );
        if (secondaryValue) {
            content = (
                <>
                    {content}
                    {" / "}
                    <Typography variant={big ? "h6" : "body2"} color="textSecondary" component="span">
                        {secondaryValue || "\u00a0"}
                    </Typography>
                </>
            );
        }
    }

    return (
        <Box my={2 || my} sx={{ textAlign: align || "left" }}>
            <Typography component="div" color="textSecondary" gutterBottom={true} variant="caption">
                {label}
            </Typography>
            {content}
        </Box>
    );
};
