import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

const fontSize = 15; // 13.5;

const theme = {
    palette: {
        primary: {
            //main: "#6A1B9A",
            //main: "#1976d2",
            main: "#4433aa",
            //main: "#0083a0",
        },

        secondary: {
            //main: "#00695C",
            //main: "#ff9800",
            main: "#2faf6e",
            //main: "#f79239",
        },

        grey: {
            main: "#F7F7F7",
        },

        text: {
            color: "#232323",
        },
        background: {
            paper: "#FFFFFF",
            default: "#EEEEEE",
        },
        type: "light",
        contrastThreshold: 3,
    },

    typography: {
        fontSize,
        //fontFamily: '"Delivery Normal", "Source Hans Sans CN Regular", "Source Hans Sans CN Regular", Myanmar, "Helvetica Neue", Helvetica, Arial, sans-serif;',
    },
    overrides: {
        MuiInputBase: {
            root: {
                color: "#323232",
                fontSize,
                //fontWeight: 400,
                /*fontFamily:
                    '"Delivery Normal", "Source Hans Sans CN Regular", "Source Hans Sans CN Regular", Myanmar, "Helvetica Neue", Helvetica, Arial, sans-serif;',
                    */
            },
            shrink: { color: "#323232" /*fontWeight: 700*/ },
        },
        MuiInputLabel: {
            root: {
                color: "#848484",
                fontSize,
                //fontWeight: 400,
            },
            shrink: { color: "#323232" /*fontWeight: 700*/ },
        },
        MuiSelect: {
            root: {
                /*
                fontFamily:
                    '"Delivery Normal", "Source Hans Sans CN Regular", "Source Hans Sans CN Regular", Myanmar, "Helvetica Neue", Helvetica, Arial, sans-serif;',
                    */
            },
        },
        MuiPaper: {
            root: {
                //border: "none", //'1px solid #ccc',
            },
        },

        MuiDrawer: {
            paperAnchorLeft: {
                boxShadow: "0px 8px 10px -5px rgba(0,0,0,0.2), 0px 16px 24px 2px rgba(0,0,0,0.14), 0px 6px 30px 5px rgba(0,0,0,0.12);",
            },
        },

        MuiAppBar: {
            root: {
                border: "none",
            },
        },

        MuiStepper: {
            root: {
                border: "none",
            },
        },
        /*
        MuiLinearProgress: {
            root: {
                position: "absolute",
                width: "100%",
            }
        }
        */
        MuiAccordion: {
            root: {
                "&$expanded": {
                    margin: 0,
                },
            },
        },
        /*
        MuiListItem: {
            root: {
                "&:selected": {
                    backgroundColor: "red",
                    "&:hover": {
                        backgroundColor: "orange",
                    },
                },
            },
            button: {
                "&:hover": {
                    backgroundColor: "yellow",
                },
                "&:selected": {
                    backgroundColor: "red",
                    "&:hover": {
                        backgroundColor: "orange",
                    },
                },
            },
        },
        */
    },
    shape: {
        //borderRadius: 0
    },
    zIndex: {
        modal: 1051,
    },

    props: {
        MuiTextField: {
            variant: "outlined",
        },
        MuiPaper: {
            variant: "outlined",
            elevation: 1,
        },
        MuiFormControlBase: {
            variant: "outlined",
        },
    },
};

export default theme;
