import React, {useContext, useEffect, useMemo, useState} from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import FormHelperText from "@mui/material/FormHelperText";

import {createTheme, emphasize, ThemeProvider, useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import TimerIcon from "@mui/icons-material/Timer";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import LockIcon from "@mui/icons-material/Lock";
import PeopleIcon from "@mui/icons-material/People";
import FolderIcon from "@mui/icons-material/Folder";

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import TextField from "@mui/material/TextField";

import Tooltip from "@mui/material/Tooltip";

import {DayCalendar, DayCalendarAction, MonthCalendar, MonthDay, WeekCalendar} from "../Calendar";
import {DatePicker, TimePicker} from "../DateFields";
import Modal from "../Modal";

import {ProjectSelect} from "../projects";
import {EmployeeSelect} from "../employees";

import {LoadingIndicatorContainer, useLoadingIndicator} from "../../utils/loading";
import {useForm} from "../../utils/form";
import backend, {download, prepareMultipartRequest} from "../../utils/backend";
import {calculatePeriod, formatPeriod, getDefaultPeriodDate, nextPeriodType, parsePeriod, usePeriod} from "../period";
import {DonutChart} from "../Charts";
import {AttachmentChips, AttachmentContainer, AttachmentContext, AttachmentSectionField} from "../Attachments";
import {TagChips, TagsField, TagsSectionField} from "../Tags";
import {useAlert} from "../Alert";
import moment from "moment";
import {InlineFilter, useInlineFilter} from "../InlineFilter";
import {formatNum, space} from "../NumberFields";
import {useSettings} from "../../utils/settings";

import {QRCodeSVG} from "qrcode.react";

//taken from https://mui.com/components/tooltips/#arrow-tooltips
const TooltipBody = React.forwardRef(function TooltipBody(props, ref) {
    return (
        <div {...props} ref={ref}>
            Bin
        </div>
    );
});

const TimesheetTooltip = ({ data, children }) => {
    if (!data) {
        return <>{children}</>;
    }
    return (
        <Tooltip arrow title="test">
            {children}
        </Tooltip>
    );
};

export const MonthDayTimesheetItems = ({ value, day, dayNumber, onClick, onAction, color, disabled, readOnly }) => {
    let c = null;
    if (!!disabled) {
        color = "#6A6A6A";
    }
    if (value) {
        c = value.percentage < 0 ? emphasize("#ff8100", 0.75 * Math.abs(value.percentage)) : emphasize(color, 0.75 * (1 - value.percentage));
    }

    return (
        <MonthDay
            key={day}
            day={day}
            dayNumber={dayNumber}
            onClick={!!disabled ? null : onClick}
            color={c}
            onAction={disabled || readOnly ? null : onAction}
            actionIcon={<AddIcon fontSize="small" />}
            actionTitle="Přidat položku výkazu"
            readOnly={readOnly}
        />
    );
};

//TODO dropzone on timesheet items

export const DayTimesheetItems = ({
    items,
    totalHours = 0,
    color = "#CECECE",
    showProject,
    showEmployee,
    onClick,
    onEdit,
    onDelete,
    onAdd,
    readOnly,
    disabled,
    downloadAttachmentUrl,
    onDeleteAttachment,
}) => {
    const theme = useTheme();

    if (!Array.isArray(items)) {
        return (
            <Box py={0.5}>
                {!readOnly && <DayCalendarAction icon={<AddIcon size="large" />} onClick={onAdd} disabled={disabled} title="Přidat položku výkazu" />}
            </Box>
        );
    }

    const cursor = onClick ? "pointer" : "default";
    const max = items.reduce((max, current) => (current.hours >= max.hours ? current : max), { hours: 0 });

    if (!!disabled) {
        color = "#6A6A6A";
    }

    return (
        <Box pt={0.5} mb={2}>
            {items.map((item, key) => {
                let backgroundColor = color;
                if (totalHours > 0) {
                    backgroundColor = emphasize(color, 0.75 * (1 - item.hours / max.hours));
                }

                const textColor = theme.palette.getContrastText(backgroundColor);

                return (
                    <Box key={key} sx={{ backgroundColor, color: textColor, mb: 0.5, p: 1 }} onClick={onClick}>
                        <Box sx={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", cursor }}>
                            {!!showProject && (
                                <Typography variant="subtitle1" component="div" sx={{ fontWeight: 600 }}>
                                    {!!item.projectReadOnly && <LockIcon fontSize="small" />} {item.projectName}
                                </Typography>
                            )}
                            {!!showEmployee && (
                                <Typography variant="subtitle1" component="div" sx={{ fontWeight: 600 }}>
                                    {item.employeeName}
                                </Typography>
                            )}
                            <Typography variant="body2" component="div" gutterBottom>
                                {item.hours} hod
                            </Typography>
                            {(item.mode == "TIME" && (
                                <Typography variant="caption" component="div" gutterBottom>
                                    {item.startTime} - {item.endTime}
                                </Typography>
                            )) ||
                                null}
                            <Typography variant="caption" component="div" gutterBottom>
                                {item.description}
                            </Typography>

                            {(!!(item.attachments && item.attachments.length) || !!(item.tags && item.tags.length)) && (
                                <ThemeProvider
                                    theme={(theme) =>
                                        createTheme({
                                            ...theme,
                                            palette: {
                                                ...theme.palette,
                                                primary: {
                                                    main: textColor,
                                                },
                                            },
                                        })
                                    }
                                >
                                    {item.attachments && !!item.attachments.length && (
                                        <Box my={2}>
                                            <AttachmentChips
                                                value={item.attachments}
                                                disabled={disabled}
                                                size="small"
                                                url={downloadAttachmentUrl && downloadAttachmentUrl(item)}
                                                onDelete={onDeleteAttachment && onDeleteAttachment(item)}
                                                readOnly={readOnly}
                                            />
                                        </Box>
                                    )}
                                    {item.tags && !!item.tags.length && (
                                        <Box my={2}>
                                            <TagChips value={item.tags} disabled={disabled} size="small" readOnly={readOnly} variant="outlined" />
                                        </Box>
                                    )}
                                </ThemeProvider>
                            )}
                            <Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="center">
                                {!!onEdit && !readOnly && !item.projectReadOnly && (
                                    <IconButton
                                        size="small"
                                        sx={{ color: textColor }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            onEdit(item);
                                        }}
                                        title="Upravit"
                                        disabled={disabled}
                                    >
                                        <EditIcon fontSize="inherit" />
                                    </IconButton>
                                )}
                                {!!onDelete && !readOnly && !item.projectReadOnly && (
                                    <IconButton
                                        size="small"
                                        sx={{ color: textColor }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            onDelete(item);
                                        }}
                                        title="Smazat"
                                        disabled={disabled}
                                    >
                                        <DeleteIcon fontSize="inherit" />
                                    </IconButton>
                                )}
                            </Stack>
                        </Box>
                    </Box>
                );
            })}
            {!readOnly && <DayCalendarAction icon={<AddIcon size="large" />} onClick={onAdd} disabled={disabled} title="Přidat položku výkazu" />}
        </Box>
    );
};

const TimesheetFormFields = ({ form, showProject, showEmployee, disabled, readOnly, downloadAttachmentUrl }) => {
    const { values, handleChange, setValues } = form || {};
    const onAddAttachment = useContext(AttachmentContext);

    return (
        <>
            <Box mb={4}>
                <Box px={2} mb={4}>
                    <DatePicker
                        fullWidth
                        label="Datum"
                        margin="normal"
                        clearable
                        clearText="Vymazat"
                        value={values.date}
                        onChange={(date) => setValues({ ...values, date })}
                        disabled={disabled}
                    />
                    {!!showProject && (
                        <ProjectSelect
                            value={!!values.projectId ? { id: values.projectId, name: values.projectName } : null}
                            onChange={(val) => setValues({ ...values, projectId: val ? val.id : null, projectName: val ? val.name : null })}
                            label="Projekt"
                            margin="normal"
                            disabled={disabled}
                        />
                    )}
                    {!!showEmployee && (
                        <EmployeeSelect
                            value={!!values.employeeId ? { id: values.employeeId, name: values.employeeName } : null}
                            onChange={(val) => setValues({ ...values, employeeId: val ? val.id : null, employeeName: val ? val.name : null })}
                            label="Zaměstnanec"
                            margin="normal"
                            disabled={disabled}
                        />
                    )}

                    <TextField
                        value={values.description || ""}
                        onChange={handleChange}
                        name="description"
                        fullWidth
                        label="Popis"
                        margin="normal"
                        multiline
                        rows={4}
                        disabled={disabled}
                    />

                    <ToggleButtonGroup
                        value={values.mode || "HOURS"}
                        exclusive
                        onChange={(e, mode) => setValues({ ...values, mode })}
                        name="mode"
                        margin="normal"
                        disabled={disabled}
                    >
                        <ToggleButton value="HOURS">
                            <TimerIcon />
                        </ToggleButton>
                        <ToggleButton value="TIME">
                            <AccessTimeIcon />
                        </ToggleButton>
                    </ToggleButtonGroup>

                    {values.mode === "TIME" ? (
                        <Stack direction="row" spacing={4}>
                            <div>
                                <TimePicker
                                    fullWidth
                                    label="Čas od"
                                    margin="normal"
                                    value={values.startTime ? moment(values.startTime, "HH:mm") : null}
                                    onChange={(time) => setValues({ ...values, startTime: time ? time.format("HH:mm") : null })}
                                    disabled={disabled}
                                />
                            </div>
                            <div>
                                <TimePicker
                                    fullWidth
                                    label="Čas do"
                                    margin="normal"
                                    value={values.endTime ? moment(values.endTime, "HH:mm") : null}
                                    onChange={(time) => setValues({ ...values, endTime: time ? time.format("HH:mm") : null })}
                                    disabled={disabled}
                                />
                            </div>
                        </Stack>
                    ) : (
                        <TextField
                            value={values.hours || ""}
                            onChange={handleChange}
                            name="hours"
                            fullWidth
                            label="Počet hodin"
                            margin="normal"
                            disabled={disabled}
                        />
                    )}
                </Box>

                <AttachmentSectionField
                    value={values.attachments}
                    onChange={(attachments) => setValues({ ...values, attachments })}
                    disabled={disabled}
                    readOnly={readOnly}
                    onAddClick={onAddAttachment}
                />

                <TagsSectionField value={values.tags} onChange={(tags) => setValues({ ...values, tags })} disabled={disabled} readOnly={readOnly} />
            </Box>
        </>
    );
};

export const TimesheetModal = ({ ...props }) => {
    return (
        <LoadingIndicatorContainer>
            <TimesheetModalWrapped {...props} />
        </LoadingIndicatorContainer>
    );
};

export const TimesheetModalWrapped = ({ open, onClose, onSaveItem, disabled, data, showEmployee, showProject, projectId, employeeId }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const { startLoading, stopLoading, isLoading } = useLoadingIndicator();

    const onSubmit = async (data, submitHints) => {
        startLoading();
        try {
            await onSaveItem(data, submitHints);
            stopLoading();
            if (!submitHints.keepOpen) {
                onClose && onClose();
            }
        } catch (e) {
            stopLoading();
            alert("error 2");
        }
    };
    const form = useForm({ onSubmit });
    const { values, handleChange, errors, setErrors, handleSubmit, setValues } = form;
    const handleFormSubmit = (keepOpen) => (e) => handleSubmit(e, { keepOpen });

    useEffect(() => {
        setValues(data || {});
    }, [data]);

    const downloadAttachmentUrl = useMemo(() => {
        if (projectId) {
            return (attachment) => `/api/projects/${projectId}/timesheets/${values.id}/attachments/${attachment.id}`;
        }
        if (employeeId) {
            return (attachment) => `/api/employees/${employeeId}/timesheets/${values.id}/attachments/${attachment.id}`;
        }

        return () => null;
    }, [projectId, employeeId, values]);

    const dis = disabled || isLoading;

    /*
                                <Button color="inherit" type="button" startIcon={<SaveIcon />} onClick={handleSubmit} disabled={dis}>
                                    Uložit
                                </Button>
*/
    return (
        <Modal
            open={open}
            onClose={onClose}
            title="Položka výkazu"
            buttons={[{ title: "Storno" }, { title: "Uložit", main: true, onClick: handleFormSubmit(false) }]}
            fullSizeButtons={[{ title: "Uložit a přidat další", main: true, onClick: handleFormSubmit(true), disabled: isLoading }]}
        >
            {/*<Dialog open={open} onClose={onClose} fullScreen={fullScreen} maxWidth="lg" fullWidth>*/}
            <AttachmentContainer value={values.attachments} onChange={(attachments) => setValues({ ...values, attachments })}>
                <TimesheetFormFields
                    form={form}
                    showEmployee={showEmployee}
                    showProject={showProject}
                    disabled={dis}
                    downloadAttachmentUrl={downloadAttachmentUrl}
                />
            </AttachmentContainer>
        </Modal>
    );
};

export const useTimesheetModal = () => {
    const [state, setState] = useState({
        open: false,
        data: {},
    });

    const showTimesheetModal = useMemo(
        () => (data) => {
            setState({ open: true, data: data || {} });
        },
        [state]
    );

    const closeTimesheetModal = useMemo(
        () => () => {
            setState({ open: false, data: {} });
        },
        [state]
    );

    const setTimesheetModalData = useMemo(
        () => (data) => {
            setState({ ...state, data: data || {} });
        },
        [state]
    );

    return { showTimesheetModal, closeTimesheetModal, modalState: state };
};

export const TimesheetCalendar = ({
    data,
    disabled,
    readOnly,
    showTimesheetModal,
    showProject,
    showEmployee,
    onEdit,
    onDelete,
    onDeleteAttachment,
    downloadAttachmentUrl,
}) => {
    const [period, setPeriod] = usePeriod();
    const theme = useTheme();
    const calendarColor = theme.palette.secondary.main;

    if (!data) {
        data = {};
    }

    return (
        <>
            {period && (period.type == "all" || period.type == "quarter" || period.type == "year") && (
                <Box py={2} sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
                    {data &&
                        Object.keys(data)
                            .sort()
                            .map((d) => {
                                let item = data[d];
                                let color = disabled ? "#6A6A6A" : theme.palette.secondary.main;
                                color = emphasize(color, 0.75 * (1 - item.percentage));
                                let pdate = parsePeriod(d);

                                return (
                                    <Box
                                        key={d}
                                        sx={{
                                            backgroundColor: color,
                                            color: theme.palette.getContrastText(color),
                                            mb: 0.5,
                                            p: 1,
                                            cursor: "pointer",
                                            flex: "1 1",
                                        }}
                                        onClick={() => setPeriod(calculatePeriod(nextPeriodType(period.type), pdate.day))}
                                    >
                                        <Box sx={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>
                                            <Typography variant="subtitle1" component="div" sx={{ fontWeight: 600 }}>
                                                {formatPeriod(calculatePeriod(nextPeriodType(period.type), pdate.day))}
                                            </Typography>
                                            <Typography variant="body2" component="div">
                                                {item.totalHours} hod
                                            </Typography>
                                            <Stack />
                                        </Box>
                                    </Box>
                                );
                            })}
                </Box>
            )}
            {period && period.type == "month" && (
                <MonthCalendar
                    month={period.month}
                    year={period.year}
                    renderDay={(day) => (
                        <MonthDayTimesheetItems
                            day={day.date}
                            dayNumber={day.dayNumber}
                            value={data[`day;${day.date}`]}
                            onClick={(d) => setPeriod(calculatePeriod("day", d))}
                            color={calendarColor}
                            disabled={disabled}
                            readOnly={readOnly}
                            onAction={(day) =>
                                showTimesheetModal &&
                                showTimesheetModal({
                                    date: day,
                                })
                            }
                        />
                    )}
                />
            )}
            {period && period.type == "week" && (
                <WeekCalendar
                    month={period.month}
                    year={period.year}
                    week={period.week}
                    onClick={(d) => setPeriod(calculatePeriod("day", d))}
                    onAction={(day) =>
                        showTimesheetModal &&
                        showTimesheetModal({
                            date: day,
                        })
                    }
                    actionIcon={<AddIcon fontSize="small" />}
                    actionTitle="Přidat položku výkazu"
                    renderDay={(day) => {
                        const value = data[`day;${day}`] || {};

                        return (
                            <DayTimesheetItems
                                items={value.items}
                                totalHours={value.totalHours}
                                color={calendarColor}
                                showProject={showProject}
                                showEmployee={showEmployee}
                                onClick={() => setPeriod(calculatePeriod("day", day))}
                                onEdit={onEdit}
                                onDelete={onDelete}
                                disabled={disabled}
                                readOnly={readOnly}
                                downloadAttachmentUrl={downloadAttachmentUrl}
                                onDeleteAttachment={onDeleteAttachment}
                                onAdd={() =>
                                    showTimesheetModal &&
                                    showTimesheetModal({
                                        date: day,
                                    })
                                }
                            />
                        );
                    }}
                />
            )}
            {period && period.type == "day" && (
                <DayCalendar
                    date={period.date}
                    renderDay={(day) => {
                        const value = data[`day;${day}`] || {};
                        return (
                            <DayTimesheetItems
                                items={value.items}
                                totalHours={value.totalHours}
                                color={calendarColor}
                                showProject={showProject}
                                showEmployee={showEmployee}
                                onEdit={onEdit}
                                onDelete={onDelete}
                                disabled={disabled}
                                readOnly={readOnly}
                                downloadAttachmentUrl={downloadAttachmentUrl}
                                onDeleteAttachment={onDeleteAttachment}
                                onAdd={() =>
                                    showTimesheetModal &&
                                    showTimesheetModal({
                                        date: day,
                                    })
                                }
                            />
                        );
                    }}
                />
            )}
        </>
    );
};

export const TimesheetChart = ({ data, disabled }) => {
    const theme = useTheme();
    const settings = useSettings();
    let chartValues = [];
    let chartLegend = [];
    if (data && data.data && data.data.length && !!data.data[0]) {
        chartValues = data.data[0].map((d) => d.value);
        chartLegend = data.data[0].map((d) => `${d.name || "OSTATNÍ"} ${d.value || 0}h`);
    }

    return (
        <DonutChart
            data={[chartValues]}
            labels={chartLegend}
            colors={disabled ? ["#6A6A6A"] : [theme.palette.primary.main, theme.palette.secondary.main]}
            centerTitle="Celkem hodin"
            centerValue={`${formatNum((data && data.total) || 0, settings.decimalDigits, settings.decimalDelimiter, settings.thousandDelimiter)}${space}h`}
            disabled={disabled}
        ></DonutChart>
    );
};

export const TimesheetAggregations = ({}) => {};

export const TimesheetsTab = ({ employeeId, projectId, periodRef, allowAll, disabled, readOnly }) => {
    const [period, setPeriod] = periodRef;
    const { startLoading, stopLoading, isLoading } = useLoadingIndicator();
    const { showTimesheetModal, closeTimesheetModal, modalState } = useTimesheetModal();

    const [state, setState] = useState({
        employeeId,
        projectId,
        filter: {},
        showFilter: false,
        calendarData: {},
        chartData: {},
        aggregationTab: "date",
        aggregationData: {},
        axes: [],
    });

    const showAlert = useAlert();

    const mode = !!employeeId ? "employee" : "project";

    const fetchEmployeeData = async (employeeId, from, to, periodType, attachments, keepData, filter) => {
        startLoading();
        if (!keepData) {
            setState({ ...state, calendarData: {}, chartData: {}, aggregationData: {}, axes: [], selectedAxes: [] });
        }

        stopLoading();
    };

    const fetchProjectData = async (projectId, from, to, periodType, attachments, keepData, filter) => {
        startLoading();
        stopLoading();
    };

    const fetchAggregationData = async (axes) => {
        let aggregationData = {};
        setState({ ...state, axes, aggregationData });
        startLoading();
        try {
            if (!!employeeId) {
                aggregationData = await fetchEmployeeAggregation(
                    employeeId,
                    period.start,
                    period.end,
                    state.filter,
                    axes.filter((a) => !!a.selected).map((a) => a.id)
                );
            }
            if (!!projectId) {
                aggregationData = await fetchProjectAggregation(
                    projectId,
                    period.start,
                    period.end,
                    state.filter,
                    axes.filter((a) => !!a.selected).map((a) => a.id)
                );
            }
            setState({ ...state, axes, aggregationData });
        } catch (e) {
            alert(e);
            console.error(e);
        }
        stopLoading();
    };

    const saveItem = useMemo(
        () => async (data, submitHints) => {
            if (employeeId) {
                await saveEmployeeTimesheet(employeeId, data);
                if (period) {
                    fetchEmployeeData(employeeId, period.start, period.end, period.type == "week" || period.type == "day", period.type, true, state.filter);
                }
            } else if (projectId) {
                await saveProjectTimesheet(projectId, data);
                if (period) {
                    fetchProjectData(projectId, period.start, period.end, period.type == "week" || period.type == "day", period.type, true, state.filter);
                }
            }
            if (submitHints && submitHints.keepOpen) {
                //keep open for the same day
                openTimesheetModal({ date: data.date });
            }
        },
        [employeeId, projectId, period, mode]
    );

    const openTimesheetModal = useMemo(
        () => (data) => {
            if (!!data) {
                return showTimesheetModal(data);
            }

            //prepare default data
            data = {
                date: getDefaultPeriodDate(periodRef),
            };

            return showTimesheetModal(data);
        },
        [periodRef]
    );

    const deleteItem = useMemo(
        () => (item) => {
            const deleteTimesheet = async () => {
                if (!!employeeId) {
                    await deleteEmployeeTimesheet(employeeId, item.id);
                    if (period) {
                        fetchEmployeeData(employeeId, period.start, period.end, period.type, true, state.filter);
                    }
                } else if (!!projectId) {
                    await deleteProjectTimesheet(projectId, item.id);
                    if (period) {
                        fetchProjectData(projectId, period.start, period.end, period.type, true, state.filter);
                    }
                }
            };
            showAlert("Smazat položku", `Opravdu chcete smazat položku výkazu ${employeeId ? item.projectName : item.employeeName} (${item.hours} h)?`, [
                { text: "Ne" },
                { text: "Ano", onPress: deleteTimesheet },
            ]);
        },
        [employeeId, projectId, period]
    );

    const downloadAttachmentUrl = useMemo(() => {
        if (projectId) {
            return (item) => (attachment) => `/api/projects/${projectId}/timesheets/${item.id}/attachments/${attachment.id}`;
        }
        if (employeeId) {
            return (item) => (attachment) => `/api/employees/${employeeId}/timesheets/${item.id}/attachments/${attachment.id}`;
        }

        return () => null;
    }, [projectId, employeeId]);

    const deleteAttachment = useMemo(() => {
        if (projectId) {
            return (item) => async (attachment) => {
                startLoading();
                try {
                    await deleteProjectTimesheetAttachment(projectId, item.id, attachment.id);
                    if (period) {
                        fetchProjectData(projectId, period.start, period.end, period.type, true, true, state.filter);
                    }
                    stopLoading();
                } catch (e) {
                    stopLoading();
                    alert(e);
                }
            };
        }
        if (employeeId) {
            return (item) => async (attachment) => {
                startLoading();
                try {
                    await deleteEmployeeTimesheetAttachment(employeeId, item.id, attachment.id);
                    if (period) {
                        fetchEmployeeData(employeeId, period.start, period.end, period.type, true, true, state.filter);
                    }
                    stopLoading();
                } catch (e) {
                    stopLoading();
                    alert(e);
                }
            };
        }

        return () => null;
    }, [projectId, employeeId, period]);

    useEffect(() => {
        if (employeeId && period) {
            fetchEmployeeData(employeeId, period.start, period.end, period.type, period.type == "week" || period.type == "day", false, state.filter);
            //
        }
        if (projectId && period) {
            fetchProjectData(projectId, period.start, period.end, period.type, period.type == "week" || period.type == "day", false, state.filter);
            //
        }
    }, [employeeId, projectId, period, state.filter]);

    const theme = useTheme();
    const calendarColor = theme.palette.secondary.main;

    let chartValues = [];
    let chartLegend = [];
    if (state.chartData && state.chartData.data && state.chartData.data.length && !!state.chartData.data[0]) {
        chartValues = state.chartData.data[0].map((d) => d.value);
        chartLegend = state.chartData.data[0].map((d) => `${d.name || "OSTATNÍ"} ${d.value || 0}h`);
    }

    const showProject = !!employeeId;
    const showEmployee = !!projectId;
    const isDisabled = isLoading || disabled;

    return <></>;
};

export const TimesheetFilter = ({ disabled, filter, onFilterChange, showEmployee, showProject }) => {
    const { values, handleFilterChange } = useInlineFilter(filter, onFilterChange);

    return (
        <InlineFilter>
            {!!showProject && (
                <div>
                    <ProjectSelect
                        value={values.projects || []}
                        onChange={(projects) => handleFilterChange("projects", projects)}
                        name="tags"
                        fullWidth
                        label="Projekty"
                        margin="normal"
                        disabled={disabled}
                        multiple
                    />
                </div>
            )}
            {!!showEmployee && (
                <div>
                    <EmployeeSelect
                        value={values.employees || []}
                        onChange={(employees) => handleFilterChange("employees", employees)}
                        name="tags"
                        fullWidth
                        label="Zaměstnanci"
                        margin="normal"
                        disabled={disabled}
                        multiple
                    />
                </div>
            )}
            <div>
                <TagsField
                    value={values.tags}
                    onChange={(tags) => handleFilterChange("tags", tags)}
                    name="tags"
                    fullWidth
                    label="Tagy"
                    margin="normal"
                    disabled={disabled}
                />
            </div>
        </InlineFilter>
    );
};

export const employeeIdAxis = { id: "employeeId", name: "Zaměstnanci", icon: <PeopleIcon /> };
export const projectIdAxis = { id: "projectId", name: "Projekty", icon: <FolderIcon /> };
export const employeeItemsAxis = { id: "employeeItems", name: "Položky výkazu" };
export const projectItemsAxis = { id: "projectItems", name: "Položky výkazu" };

//TODO use this

const fetchTimesheetTabData = (period, detailId, showEmployee) => async () => {
    return await Promise.allSettled([]);
};

const initialTimesheetTabState = {
    calendarData: {},
    chartData: {},
};

const TimesheetTemplateFormFields = ({ form, showProject, disabled, readOnly }) => {
    const { values, handleChange, setValues } = form || {};
    const onAddAttachment = useContext(AttachmentContext);
    const { id, name, ...qrCodeValues } = values || {};

    return (
        <>
            <Box mb={4}>
                <Box px={2} mb={4}>
                    <TextField
                        value={values.name || ""}
                        onChange={handleChange}
                        name="name"
                        fullWidth
                        label="Název kódu"
                        margin="normal"
                        disabled={disabled}
                        placeholder="Název kódu pro uložení"
                        required
                    />

                    <TextField
                        value={values.instructions || ""}
                        onChange={handleChange}
                        name="instructions"
                        fullWidth
                        label="Instrukce pro použití kódu"
                        margin="normal"
                        multiline
                        rows={4}
                        disabled={disabled}
                    />

                    <Box my={4}>
                        {/*<ProjectSelect
                        value={!!values.projectId ? { id: values.projectId, name: values.projectName } : null}
                        onChange={(val) => setValues({ ...values, projectId: val ? val.id : null, projectName: val ? val.name : null })}
                        label="Projekt"
                        margin="normal"
                        disabled={disabled}
                    />*/}

                        <FormHelperText>
                            Následující hodnoty budou obsaženy ve vygenerovaném QR kódu a budou se předvyplňovat při jeho naskenování
                        </FormHelperText>

                        <TextField
                            value={values.description || ""}
                            onChange={handleChange}
                            name="description"
                            fullWidth
                            label="Popis"
                            margin="normal"
                            multiline
                            rows={4}
                            disabled={disabled}
                        />

                        <ToggleButtonGroup
                            value={values.mode}
                            exclusive
                            onChange={(e, mode) => setValues({ ...values, mode })}
                            name="mode"
                            margin="normal"
                            disabled={disabled}
                        >
                            <ToggleButton value="HOURS">
                                <TimerIcon />
                            </ToggleButton>
                            <ToggleButton value="TIME">
                                <AccessTimeIcon />
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Box>

                    <Box my={4} style={{ textAlign: "center" }}>
                        <QRCodeSVG value={JSON.stringify({ type: "timesheetItem", ...(qrCodeValues || {}) })} level="L" />
                        {/*<FormHelperText>Vyzkoušejte prosím tento kód v mobilní aplikaci</FormHelperText>*/}
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export const TimesheetTemplateModal = ({ ...props }) => {
    return (
        <LoadingIndicatorContainer>
            <TimesheetTemplateModalWrapped {...props} />
        </LoadingIndicatorContainer>
    );
};

const TimesheetTemplateModalWrapped = ({ open, onClose, onSaveItem, disabled, data }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const { startLoading, stopLoading, isLoading } = useLoadingIndicator();

    const onSubmit = async (data, submitHints) => {
        startLoading();
        try {
            if (!!onSaveItem) {
                await onSaveItem(data, submitHints);
            }
            stopLoading();
            if (!(submitHints && submitHints.keepOpen)) {
                onClose && onClose();
            }
        } catch (e) {
            stopLoading();
            alert("error 2");
        }
    };
    const form = useForm({ onSubmit });
    const { values, handleChange, errors, setErrors, handleSubmit, setValues } = form;
    const handleFormSubmit = (keepOpen) => (e) => handleSubmit(e, { keepOpen });

    useEffect(() => {
        setValues(data || {});
    }, [data]);

    return (
        <Modal
            open={open}
            onClose={onClose}
            title="QR kód pro vykazování"
            buttons={[{ title: "Storno" }, { title: "Uložit", main: true, onClick: handleFormSubmit(false) }]}
        >
            {/*<Dialog open={open} onClose={onClose} fullScreen={fullScreen} maxWidth="lg" fullWidth>*/}
            <TimesheetTemplateFormFields form={form} disabled={disabled || isLoading} />
        </Modal>
    );
};

const populateFilterParams = (filter) => {
    let params = {};
    if (filter) {
        if (Array.isArray(filter.projects) && filter.projects.length) {
            params.projectIds = filter.projects.map((p) => p.id);
        }
        if (Array.isArray(filter.employees) && filter.employees.length) {
            params.employeeIds = filter.employees.map((p) => p.id);
        }
        if (Array.isArray(filter.tags) && filter.tags.length) {
            params.tags = [...filter.tags];
        }
    }
    return params;
};

export const fetchEmployeeTimesheetCalendar = async (employeeId, from, to, periodType, attachments, filter) => {
    let response = await backend(`/api/employees/${employeeId}/timesheets/calendar`, "GET", {
        params: { ...populateFilterParams(filter), from, to, periodType, attachments },
    });
    return response.body;
};

export const fetchEmployeeTimesheetOverview = async (employeeId, from, to, filter) => {
    let response = await backend(`/api/employees/${employeeId}/timesheets/overview`, "GET", { params: { ...populateFilterParams(filter), from, to } });
    return response.body;
};

export const fetchEmployeeTimesheetChart = async (employeeId, from, to, type, filter) => {
    let response = await backend(`/api/employees/${employeeId}/timesheets/chart`, "GET", { params: { ...populateFilterParams(filter), from, to, type } });
    return response.body;
};

export const fetchEmployeeTimesheetItems = async (employeeId, from, to, paging, filter) => {
    let response = await backend(`/api/employees/${employeeId}/timesheets`, "GET", { params: { ...populateFilterParams(filter), from, to } });
    return response.body;
};

export const fetchProjectTimesheetCalendar = async (projectId, from, to, periodType, attachments, filter) => {
    let response = await backend(`/api/projects/${projectId}/timesheets/calendar`, "GET", {
        params: { ...populateFilterParams(filter), from, to, periodType, attachments },
    });
    return response.body;
};

export const fetchProjectTimesheetChart = async (projectId, from, to, type, filter) => {
    let response = await backend(`/api/projects/${projectId}/timesheets/chart`, "GET", { params: { ...populateFilterParams(filter), from, to, type } });
    return response.body;
};

export const fetchProjectTimesheetItems = async (projectId, from, to, paging, filter) => {
    let response = await backend(`/api/projects/${projectId}/timesheets`, "GET", { params: { ...populateFilterParams(filter), from, to } });
    return response.body;
};

export const saveProjectTimesheet = async (projectId, timesheetItem) => {
    let formData = prepareMultipartRequest(timesheetItem);
    let response = await backend(
        `/api/projects/${projectId}/timesheets` + (timesheetItem.id ? "/" + timesheetItem.id : ""),
        timesheetItem.id ? "PUT" : "POST",
        {
            body: formData,
            upload: true,
        }
    );
    return response.body;
};

export const saveEmployeeTimesheet = async (employeeId, timesheetItem) => {
    let formData = prepareMultipartRequest(timesheetItem);
    let response = await backend(
        `/api/employees/${employeeId}/timesheets` + (timesheetItem.id ? "/" + timesheetItem.id : ""),
        timesheetItem.id ? "PUT" : "POST",
        {
            body: formData,
            upload: true,
        }
    );
    return response.body;
};

export const deleteProjectTimesheet = async (projectId, itemId) => {
    await backend(`/api/projects/${projectId}/timesheets/${itemId}`, "DELETE");
};

export const deleteEmployeeTimesheet = async (projectId, itemId) => {
    await backend(`/api/employees/${projectId}/timesheets/${itemId}`, "DELETE");
};

export const deleteProjectTimesheetAttachment = async (projectId, itemId, attachmentId) => {
    await backend(`/api/projects/${projectId}/timesheets/${itemId}/attachments/${attachmentId}`, "DELETE");
};

export const deleteEmployeeTimesheetAttachment = async (projectId, itemId, attachmentId) => {
    await backend(`/api/employees/${projectId}/timesheets/${itemId}/attachments/${attachmentId}`, "DELETE");
};

export const fetchEmployeesAggregation = async (from, to, filter, axes) => {
    let response = await backend(`/api/employees/timesheets/aggregation`, "GET", { params: { ...populateFilterParams(filter), from, to, axes } });
    return response.body;
};

export const fetchEmployeeAggregation = async (employeeId, from, to, filter, axes) => {
    let response = await backend(`/api/employees/${employeeId}/timesheets/aggregation`, "GET", { params: { ...populateFilterParams(filter), from, to, axes } });
    return response.body;
};

export const fetchProjectsAggregation = async (from, to, filter, axes) => {
    let response = await backend(`/api/projects/timesheets/aggregation`, "GET", { params: { ...populateFilterParams(filter), from, to, axes } });
    return response.body;
};

export const fetchProjectAggregation = async (projectId, from, to, filter, axes) => {
    let response = await backend(`/api/projects/${projectId}/timesheets/aggregation`, "GET", { params: { ...populateFilterParams(filter), from, to, axes } });
    return response.body;
};

export const fetchClientAggregation = async (projectId, from, to, filter, axes) => {
    let response = await backend(`/api/counterparties/${projectId}/timesheets/aggregation`, "GET", {
        params: { ...populateFilterParams(filter), from, to, axes },
    });
    return response.body;
};

export const downloadEmployeesExcel = async (from, to, filter) => {
    return download(`/api/employees/timesheets/excel`, { params: { from, to, ...populateFilterParams(filter) } });
};

export const downloadEmployeeExcel = async (employeeId, from, to, filter) => {
    return download(`/api/employees/${employeeId}/timesheets/excel`, { params: { from, to, ...populateFilterParams(filter) } });
};

export const downloadProjectsExcel = async (from, to, filter) => {
    return download(`/api/projects/timesheets/excel`, { params: { from, to, ...populateFilterParams(filter) } });
};

export const downloadProjectExcel = async (projectId, from, to, filter) => {
    return download(`/api/projects/${projectId}/timesheets/excel`, { params: { from, to, ...populateFilterParams(filter) } });
};

export const fetchProjectTimesheetItemTemplates = async (projectId, paging, filter) => {
    let response = await backend(`/api/projects/${projectId}/timesheets/templates`, "GET", { params: { ...(paging || {}) } });
    return response.body;
};

export const saveProjectTimesheetItemTemplate = async (projectId, timesheetItem) => {
    let response = await backend(
        `/api/projects/${projectId}/timesheets/templates` + (timesheetItem.id ? "/" + timesheetItem.id : ""),
        timesheetItem.id ? "PUT" : "POST",
        {
            body: timesheetItem,
        }
    );
    return response.body;
};

export const deleteProjectTimesheetItemTemplate = async (projectId, itemId) => {
    await backend(`/api/projects/${projectId}/timesheets/templates/${itemId}`, "DELETE");
};
