import React, {useEffect, useState} from "react";

import Collapse from "@mui/material/Collapse";
import {makeStyles} from "@mui/styles";

import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";

import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";

import {DetailRow} from "./Containers";

//import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {SortableContainer, SortableElement} from "react-sortable-hoc";
import {arrayMoveImmutable} from "array-move";

const useAggregationsStyles = makeStyles((theme) => ({
    aggregationHeaderRoot: {
        display: "flex",
        border: "1px solid #EAEAEA",
        borderRadius: 8,
        padding: 16,
        marginBottom: 16,
    },
    aggregationRowRoot: {
        margin: 0,
        paddingTop: 8,
        paddingBottom: 8,
    },
    aggregationRow: {
        border: "1px solid #EAEAEA",
        borderRadius: 8,
        margin: 0,
    },
    aggregationRowHeader: {
        display: "flex",
        backgroundColor: "#FFFFFF",
    },
    aggregationRowHeaderButton: {
        display: "flex",
        flex: "0 0 auto",
        alignItems: "center",
        justifyContent: "center",
    },
    aggregationRowHeaderValues: {
        display: "flex",
        flex: "1 1 auto",
        rowGap: 8,
        justifyContent: "space-between",
        padding: 8,
    },
    aggregationRowExpanded: {
        position: "relative",
        paddingLeft: 32,
        paddingTop: 24,
        paddingBottom: 24,
        backgroundColor: "#F4F4F4",
        display: "flex",
    },
    aggregationRowExpandedChart: {
        flex: "0 0 auto",
        width: 250,
        alignSelf: "flex-start",
    },
    aggregationRowExpandedDivider: {
        flex: "0 0 auto",
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
    aggregationRowExpandedContent: {
        flex: "1 1 auto",
    },
}));

const defaultFormatHeader = (base, classes = {}) => (
    <div className={classes.aggregationRowHeaderValues}>
        <div>{base.name || base.key || "N/A"}</div>
        <pre>{JSON.stringify(base, null, 4)}</pre>
        <div>
            {base.value} {base.unit}
        </div>
    </div>
);

const Aggregation = ({ aggregation, path, level = 0, openState, onChangeOpenState, classes, disabled, formatHeader, renderChart, count, index, isLeaf }) => {
    const { children, ...base } = aggregation || {};
    if (!formatHeader) {
        formatHeader = defaultFormatHeader;
    }

    const hasChildren = Array.isArray(children) && !!children.length;
    let hasOpenChildren = false;
    if (hasChildren) {
        for (let i = 0; i < children.length; i++) {
            if (!!openState[`${path}.${children[i].key}`]) {
                hasOpenChildren = true;
                break;
            }
        }
    }

    return (
        <DetailRow
            open={openState[path]}
            setOpen={(open) => onChangeOpenState({ ...openState, [path]: open })}
            renderDetail={
                hasChildren
                    ? () => (
                          <div className={classes.aggregationRowExpanded}>
                              {!!renderChart && (
                                  <Collapse in={!hasOpenChildren} orientation="horizontal" unmountOnExit>
                                      <div className={classes.aggregationRowExpandedChart}>{renderChart(aggregation)}</div>
                                      <div className={classes.aggregationRowExpandedDivider}>
                                          <Divider orientation="vertical" variant="middle" flexItem />
                                      </div>
                                  </Collapse>
                              )}
                              <div className={classes.aggregationRowExpandedContent}>
                                  {children.map((ch, idx) => (
                                      <Aggregation
                                          key={ch.key}
                                          aggregation={ch}
                                          level={level + 1}
                                          path={`${path}.${ch.key}`}
                                          classes={classes}
                                          openState={openState}
                                          onChangeOpenState={onChangeOpenState}
                                          disabled={disabled}
                                          renderChart={renderChart}
                                          formatHeader={formatHeader}
                                          index={idx}
                                          count={children.length}
                                          isLeaf={!hasOpenChildren}
                                      />
                                  ))}
                              </div>
                          </div>
                      )
                    : null
            }
            disabled={disabled}
        >
            {formatHeader(base, classes, index, count, isLeaf)}
        </DetailRow>
    );
};

const Aggregations = ({ data, disabled, formatHeader, renderChart, parent, ...props }) => {
    const classes = useAggregationsStyles();
    const [open, setOpen] = useState({});

    useEffect(() => setOpen({}), [data]);

    let items = [];
    if (!!data) {
        if (Array.isArray(data)) {
            items = data;
        } else {
            if (data.key) {
                items = [data];
            } else if (data.children) {
                items = data.children;
            }
        }
    }

    return (
        <>
            {items.map((a, idx) => (
                <Aggregation
                    key={a.key || idx}
                    level={0}
                    aggregation={a}
                    path={a.key || idx}
                    classes={classes}
                    openState={open}
                    onChangeOpenState={setOpen}
                    disabled={disabled}
                    renderChart={renderChart}
                    formatHeader={formatHeader}
                />
            ))}
        </>
    );
};

export default Aggregations;

const SortableItem = SortableElement(({ value, color, onClick, disabled }) => {
    let chipColor = undefined;
    if (value.selected) {
        if (value.fixed) {
            chipColor = color;
        } else {
            chipColor = color == "primary" ? "secondary" : "primary";
        }
    }

    let clickHandler = onClick; //value.fixed ? onClick : undefined;

    return (
        <Chip
            color={chipColor}
            key={value.id}
            label={value.name || value.id}
            icon={value.icon || null}
            onClick={clickHandler}
            deleteIcon={value.selected ? <CloseIcon /> : <AddIcon />}
            onDelete={clickHandler}
            size="small"
            disabled={disabled}
        />
    );
});

const SortableList = SortableContainer(({ items, color, onClick, disabled }) => {
    return (
        <Stack spacing={1} direction="row">
            {items.map((value, index) => (
                <SortableItem
                    key={`item-${value.id}`}
                    index={index}
                    value={value}
                    color={color}
                    onClick={onClick && onClick(index)}
                    disabled={!!disabled || !!value.fixed}
                />
            ))}
        </Stack>
    );
});

export const AxesSelector = ({ value, onChange, disabled, color = "primary" }) => {
    const onSortEnd = ({ oldIndex, newIndex }) => {
        if (oldIndex !== newIndex) {
            onChange && onChange([...arrayMoveImmutable(value, oldIndex, newIndex)]);
        }
    };

    const onClick = (index) => (e) => {
        e.stopPropagation();
        if (!!onChange) {
            let val = { ...value[index] };
            val.selected = !val.selected;
            value[index] = val;
            onChange([...value]);
        }
    };

    return <SortableList items={value} onSortEnd={onSortEnd} color={color} axis="x" onClick={onClick} pressDelay={250} disabled={disabled} />;
};
