//dependencies - react-dropzone, file-saver
import React, {createContext, useCallback} from "react";
import Button from "@mui/material/Button";
import {makeStyles} from "@mui/styles";
import Chip from "@mui/material/Chip";
import InputLabel from "@mui/material/InputLabel";

import AttachFileIcon from "@mui/icons-material/AttachFile";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import AddIcon from "@mui/icons-material/Add";
import FileSaver from "file-saver";

import {Section} from "./Containers";

import {useLoadingIndicator} from "../utils/loading";
import {useAlert} from "./Alert";
import {useDropzone} from "react-dropzone";
import {useEditableList} from "../utils/lists";
import {download} from "../utils/backend";
import clsx from "clsx";

const noop = (e) => {
    e.preventDefault();
    e.stopPropagation();
};

const useStyles = makeStyles((theme) => ({
    attachmentsRoot: {
        position: "relative",
        minHeight: theme.spacing(8),
        borderStyle: "dashed",
        padding: theme.spacing(1),
        borderWidth: 1,
        borderColor: "#ACACAC",
        backgroundColor: "#EAEAEA",
    },
    chips: {
        position: "relative",
        minHeight: theme.spacing(1),
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    info: {
        padding: theme.spacing(1),
        textAlign: "center",
    },
    avatar: {
        //backgroundColor: theme.palette.primary.main
    },
    overlay: {
        position: "absolute",
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "#FAFAFA97",
        margin: 0,
        border: "dashed 1px #ABABAB",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1300,
    },
    root: {
        position: "relative",
        flex: "1 1 auto",
    },
    dropzone: {
        position: "absolute",
        width: "100%",
        height: "100%",
        left: 0,
        top: 0,
        zIndex: 10,
    },
    dropzoneActive: {
        boxShadow: "inset 0px 0px 30px -10px #CECECECC",
        zIndex: 100,
        position: "relative",
    },
    input: {
        display: "none",
    },
}));
export const AttachmentsField = ({ title, value, onChange, disabled, readOnly, url, objectType, variant }) => {
    const classes = useStyles();
    const { saveRow, deleteRow } = useEditableList(value, onChange);

    const onDrop = useCallback(
        (acceptedFiles) => {
            if (Array.isArray(acceptedFiles)) {
                acceptedFiles.forEach((a) => {
                    saveRow(a, undefined, true);
                });
            } else {
                saveRow(acceptedFiles, undefined, true);
            }
        },
        [value]
    );
    const onDelete = useCallback(
        (row, index) => {
            deleteRow(index);
        },
        [value]
    );
    const { getRootProps, getInputProps, isDragActive, open } = useDropzone({ onDrop, noClick: true, noKeyboard: true });

    const downloadAttachment = (row) => () => {
        if (typeof url !== "function") {
            return;
        }

        if (!row.id) {
            const fr = new FileReader();
            fr.readAsDataURL(row);

            const blob = new Blob([row], { type: row.type });
            FileSaver.saveAs(blob, row.name);
            return;
        }

        download(url(row));
    };

    return (
        <div {...getRootProps({ className: "dropzone", style: isDragActive ? { boxShadow: "0px 0px 5px 5px #CECECECC" } : null })}>
            <input {...getInputProps()} />
            {!!title && <InputLabel shrink>{title}</InputLabel>}
            <div className={classes.attachmentsRoot}>
                <div className={classes.chips}>
                    <AttachmentChips value={value} disabled={disabled} readOnly={readOnly} url={url} onDelete={onDelete} variant={variant} />
                </div>
                <div className={classes.info}>
                    <Button variant="text" color="primary" component="span" size="small" startIcon={<CloudUploadIcon />} onClick={open} disabled={disabled}>
                        Přidat soubor
                    </Button>
                </div>
            </div>
        </div>
    );
};

export function formatFileSize(fileSizeInBytes) {
    var i = -1;
    var byteUnits = [" kB", " MB", " GB", " TB", "PB", "EB", "ZB", "YB"];
    do {
        fileSizeInBytes = fileSizeInBytes / 1024;
        i++;
    } while (fileSizeInBytes > 1024 && i < byteUnits.length);

    return Math.max(fileSizeInBytes, 1).toFixed(1) + byteUnits[i];
}

const useChipStyles = makeStyles((theme) => ({
    attachmentChips: {
        display: "flex",
        flexWrap: "wrap",
        columnGap: 8,
        rowGap: 8,

        "& > .MuiChip-root": {
            maxWidth: "100%",
        },
    },
    attachmentChipsCentered: {
        justifyContent: "center",
    },
}));

export const AttachmentChips = ({ value, url, spacing = 2, disabled, onDelete, readOnly, size, variant, center }) => {
    const classes = useChipStyles();
    const showAlert = useAlert();
    const { startLoading, stopLoading } = useLoadingIndicator();

    const handleDownload = (attachment, url) => {
        if (!url) {
            return null;
        }
        return async (e) => {
            e.stopPropagation();
            startLoading();
            try {
                if (attachment.id) {
                    await download(url(attachment));
                    return;
                }

                const fr = new FileReader();
                fr.readAsDataURL(attachment);

                const blob = new Blob([attachment], { type: attachment.type });
                FileSaver.saveAs(blob, attachment.name);
            } catch (e) {
                console.error(e);
                alert("error");
            } finally {
                stopLoading();
            }
        };
    };

    const handleDelete =
        !readOnly && onDelete
            ? (attachment, key) => () => {
                  showAlert("Smazat přílohu", `Chcete smazat přílohu ${attachment.name || attachment.path} ?`, [
                      { text: "Ne" },
                      {
                          text: "Ano",
                          onPress: () => {
                              onDelete(attachment, key);
                          },
                      },
                  ]);
              }
            : () => null;

    return (
        <div className={clsx(classes.attachmentChips, { [classes.attachmentChipsCentered]: !!center })}>
            {value &&
                Array.isArray(value) &&
                !!value.length &&
                value.map((a, key) => (
                    <Chip
                        key={a.id || `attachment-${key}`}
                        label={`${a.name} (${formatFileSize(a.size)})`}
                        onClick={handleDownload(a, url)}
                        icon={<AttachFileIcon />}
                        disabled={disabled}
                        variant={variant}
                        color="primary"
                        onDelete={handleDelete(a, key)}
                        size={size || "normal"}
                        title={`${a.name} (${formatFileSize(a.size)})`}
                    />
                ))}
        </div>
    );
};

export const AttachmentSectionField = ({ title, hint, readOnly, value, onChange, disabled, url, onAddClick }) => {
    const { saveRow, deleteRow } = useEditableList(value, onChange);

    const onDelete = useCallback(
        (row, index) => {
            deleteRow(index);
        },
        [value]
    );

    return (
        <Section
            title={title || "Přílohy"}
            items={value || []}
            subtitleFn={(a) => `${a.name} (${formatFileSize(a.size)})`}
            px={2}
            actions={
                readOnly
                    ? null
                    : [
                          {
                              title: hint || "Nahrát přílohu",
                              icon: <AddIcon />,
                              onClick: onAddClick,
                          },
                      ]
            }
        >
            <AttachmentChips value={value} onChange={onChange} size="small" disabled={disabled} url={url} onDelete={onDelete} />
        </Section>
    );
};

export const AttachmentContext = createContext(undefined);

export const AttachmentContainer = ({ value, onChange, disabled, children }) => {
    const classes = useStyles();
    const { saveRow } = useEditableList(value, onChange);

    const onDrop = useCallback(
        (acceptedFiles) => {
            if (Array.isArray(acceptedFiles)) {
                acceptedFiles.forEach((a) => {
                    saveRow(a, undefined, true);
                });
            } else {
                saveRow(acceptedFiles, undefined, true);
            }
        },
        [value]
    );

    const { getRootProps, getInputProps, isDragActive, open } = useDropzone({ onDrop, noClick: true, noKeyboard: true });

    return (
        <div
            {...getRootProps({
                className: classes.root,
            })}
        >
            <input {...getInputProps()} />

            <AttachmentContext.Provider value={open}>{children}</AttachmentContext.Provider>
            {!!isDragActive && <div className={classes.overlay}>Nahrát soubory</div>}
        </div>
    );
};
