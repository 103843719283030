import React, { useEffect, useMemo, useState, useCallback } from "react";

import { useNavigate } from "react-router-dom";

import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DashboardIcon from "@mui/icons-material/Dashboard";
import FilterListIcon from "@mui/icons-material/FilterList";

import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Pagination from "@mui/material/Pagination";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import MuiTable from "@mui/material/Table";
import MuiTableBody from "@mui/material/TableBody";
import MuiTableCell from "@mui/material/TableCell";
import MuiTableContainer from "@mui/material/TableContainer";
import MuiTableHead from "@mui/material/TableHead";
import MuiTableRow from "@mui/material/TableRow";

import {
    MasterContent,
    MasterDetail,
    MasterDetailContent,
    useMasterDetail,
    useMasterDetailParams,
    useMasterDetailTabs,
    useMasterRefresh,
    useDetailRefresh,
    useOverviewRefresh,
} from "../components/MasterDetail";
import { DetailRow, DetailRowMain, DetailRowValue, FlexItem, FlexRow } from "../components/Containers";
import { LayoutWithInfo, LayoutWithInfoInfo, LayoutWithInfoMain, Page, PageTitle, Panel } from "../components/Layout";
import {
    CounterpartyBasicInfo,
    CounterpartyList,
    CounterpartyListFilter,
    CounterpartyModal,
    fetchBillings,
    fetchBillingsCounterpartiesChart,
    fetchBillingsCounterpartiesData,
    fetchClientBillingsSummary,
    fetchClientBillingsSummaryTrends,
    fetchClientTransactionsSummaryTrends,
    fetchClientTransactionsTypes,
    fetchCounterparties,
    fetchCounterparty,
    fetchCounterpartyProjects,
    fetchCounterpartyProjectsActive,
    fetchCounterpartyProjectsBillings,
    fetchCounterpartyTimesheetCalendar,
    fetchCounterpartyTimesheetChart,
    fetchCounterpartyTimesheetItems,
    fetchCounterpartyTransactions,
    fetchOverviewActiveProjectsChart,
    fetchOverviewActiveProjectsData,
    fetchOverviewTimesheetsChart,
    fetchOverviewTimesheetsData,
    fetchTransactionCounterpartiesChart,
    fetchTransactionCounterpartiesData,
    fetchTransactionCounterpartiesStatuses,
    fetchCounterpartiesTransactionStatuses,
} from "../components/counterparties";
import {
    deleteProjectTimesheet,
    deleteProjectTimesheetAttachment,
    fetchClientAggregation,
    projectIdAxis,
    saveProjectTimesheet,
    TimesheetCalendar,
    TimesheetChart,
    useTimesheetModal,
} from "../components/timesheets";

import { ProjectModal, ProjectsList } from "../components/projects";

import {
    AddTransactionButton,
    AddTransactionButtonWithTypes,
    TransactionModal,
    TransactionsList,
    TransactionStatusesChart,
    useTransactionTypes,
} from "../components/transactions";
import { prepareProjectBillingTransaction, ProjectBillingModal, ProjectBillingsList } from "../components/billing";

import { useForm } from "../utils/form";
import { useLoadableList } from "../utils/lists";
import { LoadingIndicator, LoadingIndicatorContainer, useLoadingIndicator } from "../utils/loading";
import Period, { formatPeriod, formatPeriodValue, getDefaultPeriodDate, nextPeriodType, periodAxes, periodInterval, usePeriod } from "../components/period";
import { CurrencyValue, formatCurrency, formatNum, NumberValue } from "../components/NumberFields";
import { useModal } from "../components/Modal";
import Value from "../components/Value";
import { useAlert } from "../components/Alert";

import { chartColor, ChartLabelDot, ChartLabels, DonutChart } from "../components/Charts";
import { useSettings } from "../utils/settings";
import { WidgetFixed, WidgetFlexible, WidgetRoot, WidgetTitle } from "../components/Widgets";
import TrendsBarChart, { updatePeriodOnClick } from "../components/TrendsBarChart";
import { DateValue } from "../components/DateFields";
import Aggregations, { AxesSelector } from "../components/Aggregations";
import moment from "moment";
import EmptySection, { EmptySectionAction } from "../components/EmptySection";

const CounterpartiesMaster = ({ menuItems, showFilter, setShowFilter, filterRef }) => {
    const { showDetail, showOverview, showAdd, mobile, refreshMaster } = useMasterDetail();
    const filterForm = useForm();
    const loadableList = useLoadableList();
    const params = useMasterDetailParams();
    const selectedId = params.tabName;

    const { startLoading, stopLoading, isLoading } = useLoadingIndicator();

    const handleFilterChange = (filter) => {
        fetchList(filter);
    };

    const fetchList = async (filter, paging) => {
        startLoading();
        try {
            const data = await fetchCounterparties(filter, paging);
            loadableList.acceptData(data);
        } catch (e) {
            console.error(e);
        } finally {
            stopLoading();
        }
    };

    useEffect(() => {
        //fetch data
        fetchList(filterForm.values);
    }, []);

    useMasterRefresh(() => fetchList(filterForm.valuesRef.current));

    return (
        <>
            <Collapse in={showFilter}>
                <Box p={2} pb={0}>
                    <CounterpartyListFilter onFilterChange={handleFilterChange} form={filterForm} />
                </Box>
            </Collapse>
            <CounterpartyList
                disabled={isLoading}
                data={loadableList.data}
                hasMore={loadableList.size < loadableList.totalElements - 1}
                onShowMoreClick={() => fetchList(filterForm.values, loadableList.nextPageParams)}
                onItemClick={({ id }) => showDetail(id)}
                selectedId={params.tabName}
            />
        </>
    );
};

const CounterpartyMasterContainer = ({ menuItems }) => {
    const { showDetail, showOverview, showAdd, mobile, refreshMaster } = useMasterDetail();
    const [showFilter, setShowFilter] = useState();
    const modal = useModal();

    //remember context menu items
    const masterMenuItems = useMemo(
        () => [
            {
                icon: <AddIcon />,
                title: "Přidat protistranu",
                alwaysVisible: true,
                onClick: () => modal.showModal({ name: "default" }),
            },
            {
                icon: <FilterListIcon />,
                title: "Filtrovat",
                alwaysVisible: true,
                onClick: () => setShowFilter(!showFilter),
                badgeVariant: "dot",
                badgeContent: !showFilter,
            },
            { icon: <DashboardIcon />, title: "Přehled", onClick: showOverview },
        ],
        [showFilter]
    );

    const onDataSaved = useCallback(
        (values) => {
            showDetail(values.id);
            refreshMaster();
        },
        [refreshMaster, showDetail]
    );

    return (
        <Page>
            <LoadingIndicatorContainer>
                <LoadingIndicator hidden="smDown" />

                <PageTitle title="Protistrany" menuItems={mobile ? [...masterMenuItems, ...menuItems] : masterMenuItems} />
                <LoadingIndicator hidden="smUp" />
                <MasterContent>
                    <CounterpartiesMaster menuItems={menuItems} showFilter={showFilter} setShowFilter={setShowFilter} />
                </MasterContent>
            </LoadingIndicatorContainer>
            <CounterpartyModal open={modal.open} onClose={modal.closeModal} onDataSaved={onDataSaved} title="Nová protistrana" />
        </Page>
    );
};

const ProjectsTab = ({ data, settings }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { hideDetail, refreshMaster, refreshDetail } = useMasterDetail();
    const { detailId } = useMasterDetailParams();
    const periodRef = usePeriod();
    const [period, setPeriod] = periodRef;
    const { startLoading, stopLoading, isLoading } = useLoadingIndicator();
    const [state, setState] = useState({ projects: {}, active: {}, billings: [], includeReadOnly: false, activeOnly: false });

    const projectModal = useModal();

    const fetchData = useCallback(async () => {
        startLoading();
        let projects = {},
            active = {},
            billings = [];

        try {
            let result = await Promise.allSettled([
                fetchCounterpartyProjects(detailId, state.includeReadOnly, state.activeOnly),
                fetchCounterpartyProjectsActive(detailId, period.day, period.type),
                fetchCounterpartyProjectsBillings(detailId),
            ]);

            let dataResult = result[0];
            if (dataResult.status === "fulfilled") {
                projects = dataResult.value;
            }
            dataResult = result[1];
            if (dataResult.status === "fulfilled") {
                active = dataResult.value;
            }
            dataResult = result[2];
            if (dataResult.status === "fulfilled") {
                billings = dataResult.value;
            }
        } catch (e) {
            console.error(e);
            alert(e);
        } finally {
            setState({ ...state, projects, active, billings });
            stopLoading();
        }
    }, [detailId, period, state.includeReadOnly, state.activeOnly]);

    useEffect(() => {
        if (detailId) {
            fetchData();
        }
    }, [fetchData]);

    useDetailRefresh(fetchData, [fetchData]);

    return (
        <>
            <Box mb={2}>
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                    <Period disabled={isLoading} year quarter month week day />

                    <Stack direction="row" alignItems="center">
                        <IconButton
                            disabled={!!isLoading}
                            onClick={() => {
                                projectModal.showModal({
                                    clientId: data.id,
                                    clientName: data.name,
                                });
                            }}
                        >
                            <AddIcon />
                        </IconButton>
                    </Stack>
                </Stack>
            </Box>

            <Box mb={4}>
                <Grid container rowSpacing={2} columnSpacing={4}>
                    <Grid item xs={12} lg={6}>
                        <Panel fullHeight>
                            <WidgetTitle>Počet projektů</WidgetTitle>
                            <WidgetRoot>
                                <WidgetFixed>
                                    <Value label="Celkem projektů" value={formatNum(state.active && state.active.totalCount, 0)} />
                                    <Value label="Aktivních projektů" value={formatNum(state.active && state.active.activeCount, 0)} big />
                                </WidgetFixed>
                                <WidgetFlexible style={{ height: 180 }}>
                                    <TrendsBarChart
                                        data={state.active && state.active.activeTrend}
                                        period={period}
                                        disabled={isLoading}
                                        formatValue={(v) => `${formatNum(v.value, 0)}`}
                                        onClick={updatePeriodOnClick(setPeriod)}
                                    />
                                </WidgetFlexible>
                            </WidgetRoot>
                        </Panel>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Panel fullHeight>
                            <WidgetTitle>Zbývá vyúčtovat</WidgetTitle>
                            <WidgetRoot>
                                <WidgetFlexible style={{ flex: "1 1 40%" }}>
                                    <ChartLabels
                                        values={state.billings || []}
                                        color={isLoading ? "#6A6A6A" : theme.palette.primary.main}
                                        renderValue={(nv) =>
                                            `${formatNum(nv.value, settings.decimalDigits, settings.decimalDelimiter, settings.thousandDelimiter)}%`
                                        }
                                    />
                                </WidgetFlexible>
                                <WidgetFixed>
                                    <DonutChart
                                        data={[(state.billings || []).map((nv) => nv.value)]}
                                        colors={isLoading ? ["#6A6A6A"] : [theme.palette.primary.main]}
                                        disabled={isLoading}
                                        size={140}
                                    />
                                </WidgetFixed>
                            </WidgetRoot>
                        </Panel>
                    </Grid>
                </Grid>
            </Box>

            <Box my={2}>
                <Stack direction="row" alignItems="center" spacing={2}>
                    <FormGroup sx={{ px: 1 }}>
                        <FormControlLabel
                            control={
                                <Switch
                                    color="secondary"
                                    checked={state.includeReadOnly || false}
                                    onChange={(e) => setState({ ...state, includeReadOnly: e.target.checked })}
                                    name="includeReadOnly"
                                    value={true}
                                    disabled={!!isLoading}
                                    size="small"
                                />
                            }
                            label={<Typography variant="caption">Včetně uzavřených</Typography>}
                        />
                    </FormGroup>
                </Stack>
            </Box>

            <Box mb={4}>
                <ProjectsList
                    clientId={detailId}
                    period={period}
                    data={(state.projects && state.projects.content) || []}
                    disabled={isLoading}
                    onProjectDetailClick={(detailId) => navigate(`/projects/${detailId}`)}
                />
                {!!state.projects && state.projects.totalPages > 1 && (
                    <Pagination
                        count={state.projects.totalPages}
                        siblingCount={1}
                        boundaryCount={1}
                        onChange={async (e, page) => {
                            startLoading();
                            try {
                                let projects = await fetchCounterpartyProjects(detailId, state.includeReadOnly, state.activeOnly, { page: page - 1, size: 5 });
                                setState({ ...state, projects });
                            } catch (e) {
                                console.error(e);
                                alert("error");
                            }
                            stopLoading();
                        }}
                    />
                )}
            </Box>
            <ProjectModal
                open={projectModal.open}
                onClose={projectModal.closeModal}
                data={projectModal.data}
                onDataSaved={refreshDetail}
                title="Nový projekt"
                size="normal"
                hideClient
            />
        </>
    );
};

const BillingsTab = ({ data, readOnly, projectName, projectId, transactionModal }) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const settings = useSettings();
    const { hideDetail, refreshMaster, refreshDetail } = useMasterDetail();
    const { detailId } = useMasterDetailParams();
    const periodRef = usePeriod();
    const [period, setPeriod] = periodRef;
    const { startLoading, stopLoading, isLoading } = useLoadingIndicator();
    const [state, setState] = useState({ billings: {}, summary: {}, trends: {}, types: [] });
    const billingModal = useModal();

    const fetchData = useCallback(async () => {
        startLoading();
        try {
            let result = await Promise.allSettled([
                fetchBillings(detailId, period.start, period.end),
                fetchClientBillingsSummary(detailId, period.start, period.end),
                fetchClientBillingsSummaryTrends(detailId, period.day, period.type),
            ]);

            let billings = {},
                summary = {},
                trends = {};

            let dataResult = result[0];
            if (dataResult.status === "fulfilled") {
                billings = dataResult.value;
            }
            dataResult = result[1];
            if (dataResult.status === "fulfilled") {
                summary = dataResult.value;
            }
            dataResult = result[2];
            if (dataResult.status === "fulfilled") {
                trends = dataResult.value;
            }
            setState({ billings, summary, trends });
        } catch (e) {
            alert("error");
            console.error(e);
        }
        stopLoading();
    }, [detailId, period]);

    useEffect(() => {
        if (detailId) {
            fetchData();
        }
    }, [fetchData]);
    useDetailRefresh(fetchData, [fetchData]);

    return (
        <>
            <Box mb={2}>
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                    <Period disabled={isLoading} year quarter month week day />

                    <Stack direction="row" alignItems="center">
                        {/*<AddTransactionButton
                                    disabled={!!isLoading}
                                    onTypeSelected={(type) => {
                                        let transaction = {
                                            ...type,
                                            typeId: type.id,
                                            typeName: type.name,
                                            name: "",
                                            id: null,
                                            currencyCode: settings.defaultCurrencyCode,
                                        };
                                        if (!!data) {
                                            transaction.counterpartyId = data.id;
                                            transaction.counterpartyName = data.name;
                                            transaction.counterpartyIdNumber = data.idNumber;
                                            transaction.counterpartyTaxIdNumber = data.taxIdNumber;
                                        }
                                        transactionModal.showModal(transaction);
                                    }}
                                    hasProjectReference={true}
                                />*/}
                    </Stack>
                </Stack>
            </Box>

            <Box mb={4}>
                <Grid container rowSpacing={2} columnSpacing={4}>
                    <Grid item xs={12} lg={6}>
                        <Panel fullHeight>
                            <WidgetRoot>
                                <WidgetFixed>
                                    <Value
                                        label="Zisk celkem"
                                        value={
                                            <CurrencyValue value={(state.trends && state.trends.total) || 0} currencyCode={state.trends && state.trends.unit} />
                                        }
                                    />
                                    <Value
                                        label="Zisk za období"
                                        value={
                                            <CurrencyValue value={(state.trends && state.trends.value) || 0} currencyCode={state.trends && state.trends.unit} />
                                        }
                                        big
                                    />
                                </WidgetFixed>
                                <WidgetFlexible style={{ height: 180 }}>
                                    <TrendsBarChart
                                        data={state.trends.data}
                                        period={period}
                                        disabled={isLoading}
                                        formatValue={(v) =>
                                            `${formatCurrency(
                                                v.value,
                                                settings.defaultCurrencyCode,
                                                settings.currencies,
                                                settings.decimalDigits,
                                                settings.decimalDelimiter,
                                                settings.thousandDelimiter
                                            )}`
                                        }
                                        onClick={updatePeriodOnClick(setPeriod)}
                                    />
                                </WidgetFlexible>
                            </WidgetRoot>
                        </Panel>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Panel fullHeight>
                            <WidgetRoot>
                                <WidgetFlexible style={{ flex: "1 1 40%" }}>
                                    <Box mb={2}>
                                        <Typography variant="caption" component="div" color="textSecondary">
                                            Počet hodin vyúčtováno/nevyúčtováno
                                        </Typography>
                                        <ChartLabelDot
                                            value={<NumberValue value={(state.summary && state.summary.billedHours) || 0} unit="h" />}
                                            color={chartColor(theme.palette.primary.main, 2, 0)}
                                        />
                                        {" / "}
                                        <ChartLabelDot
                                            value={<NumberValue value={(state.summary && state.summary.unbilledHours) || 0} unit="h" />}
                                            color={chartColor(theme.palette.primary.main, 2, 1)}
                                        />
                                    </Box>
                                    <Box mb={2}>
                                        <Typography variant="caption" component="div" color="textSecondary">
                                            Počet transakcí vyúčtovaných/nevyúčtovaných
                                        </Typography>

                                        <ChartLabelDot
                                            value={<NumberValue value={(state.summary && state.summary.billedTransactionsCount) || 0} />}
                                            color={chartColor(theme.palette.secondary.main, 2, 0)}
                                        />
                                        {" / "}
                                        <ChartLabelDot
                                            value={<NumberValue value={(state.summary && state.summary.unbilledTransactionsCount) || 0} />}
                                            color={chartColor(theme.palette.secondary.main, 2, 1)}
                                        />
                                    </Box>
                                </WidgetFlexible>
                                <WidgetFixed>
                                    <DonutChart
                                        data={[
                                            [state.summary.billedHours || 0, state.summary.unbilledHours || 0],
                                            [state.summary.billedTransactionsCount || 0, state.summary.unbilledTransactionsCount || 0],
                                        ]}
                                        colors={isLoading ? ["#6A6A6A"] : [theme.palette.primary.main, theme.palette.secondary.main]}
                                        disabled={isLoading}
                                        size={140}
                                    />
                                </WidgetFixed>
                            </WidgetRoot>
                        </Panel>
                    </Grid>
                </Grid>
            </Box>

            <Box mb={4}>
                <ProjectBillingsList
                    billings={state.billings}
                    disabled={isLoading}
                    onEdit={billingModal.showModal}
                    onTransactionCreated={refreshDetail}
                    onTransactionClick={(id) => navigate(`/transactions/${id}`)}
                    onTransactionTypeSelected={
                        transactionModal
                            ? async (type, billing) => {
                                  startLoading();
                                  try {
                                      let data = await prepareProjectBillingTransaction(detailId, billing.id, type.id);
                                      transactionModal.showModal(data);
                                  } catch (e) {
                                      alert("error");
                                      console.error(e);
                                  }
                                  stopLoading();
                              }
                            : null
                    }
                />

                {!!state.billings && state.billings.totalPages > 1 && (
                    <Pagination
                        count={state.billings.totalPages}
                        siblingCount={1}
                        boundaryCount={1}
                        onChange={async (e, page) => {
                            startLoading();
                            try {
                                let billings = await fetchBillings(detailId, period.start, period.end, { page: page - 1, size: 5 });
                                setState({ ...state, billings });
                            } catch (e) {
                                console.error(e);
                                alert("error");
                            }
                            stopLoading();
                        }}
                    />
                )}
            </Box>

            <ProjectBillingModal
                open={billingModal.open}
                onClose={billingModal.closeModal}
                data={billingModal.data}
                onDataSaved={refreshDetail}
                projectId={detailId}
                projectName={data ? `${data.name} - ${formatPeriod(period)}` : undefined}
                from={period.start}
                to={period.end}
                periodType={period.type}
                period={period}
            />
        </>
    );
};

const createOverviewClasses = makeStyles((theme) => ({
    dashboardTopRow: {
        display: "grid",
        gridTemplateColumns: "2fr 3fr 3fr",
        gap: theme.spacing(4),
        [theme.breakpoints.down("lg")]: {
            gridTemplateColumns: "1fr",
        },
    },
    dashboardSecondRow: {
        display: "grid",
        gridTemplateColumns: "7fr 3fr",
        gap: theme.spacing(4),
        [theme.breakpoints.down("xl")]: {
            gridTemplateColumns: "1fr",
        },
    },
    dashboardSecondRowCharts: {
        display: "grid",
        gridTemplateColumns: "1fr",
        gap: theme.spacing(2),
        overflow: "hidden",
        [theme.breakpoints.down("xl")]: {
            gridTemplateColumns: "1fr 1fr",
        },
        [theme.breakpoints.down("lg")]: {
            gridTemplateColumns: "1fr",
        },
    },
    smallChart: {
        marginTop: theme.spacing(2),
        display: "grid",
        gridTemplateColumns: "2fr 3fr",
        gap: theme.spacing(1),
        alignItems: "stretch",
        overflow: "hidden",
        "& > *": {
            overflow: "hidden",
        },
    },
}));

const TransactionsTab = ({ data, detailId, settings, onDataSaved }) => {
    const theme = useTheme();
    const classes = createOverviewClasses();
    const { hideDetail, refreshMaster, refreshDetail } = useMasterDetail();
    //const { detailId } = useMasterDetailParams();
    const periodRef = usePeriod();
    const [period, setPeriod] = periodRef;
    const { startLoading, stopLoading, isLoading } = useLoadingIndicator();
    const transactionModal = useModal();
    const [state, setState] = useState({ summary: {}, transactions: {}, trends: {}, types: [], statuses: {}, showAll: true });
    const types = useTransactionTypes({ hasCounterparty: true });

    const fetchData = useCallback(async () => {
        startLoading();
        let showAll = state.showAll,
            transactions = {},
            summary = {},
            trends = {},
            types = [],
            statuses = {};

        try {
            let result = await Promise.allSettled([
                fetchCounterpartyTransactions(detailId, showAll ? null : period.start, showAll ? null : period.end),
                fetchClientTransactionsTypes(detailId, period.start, period.end),
                fetchClientTransactionsSummaryTrends(detailId, period.day, period.type),
                fetchTransactionCounterpartiesStatuses(detailId, showAll ? null : period.start, showAll ? null : period.end, moment().format("YYYY-MM-DD")),
            ]);

            let dataResult = result[0];
            if (dataResult.status === "fulfilled") {
                transactions = dataResult.value;
            }

            dataResult = result[1];
            if (dataResult.status === "fulfilled") {
                types = dataResult.value;
            }
            dataResult = result[2];
            if (dataResult.status === "fulfilled") {
                trends = dataResult.value;
            }
            dataResult = result[3];
            if (dataResult.status === "fulfilled") {
                statuses = dataResult.value;
            }
        } catch (e) {
            console.error(e);
            alert(e);
        } finally {
            stopLoading();
            setState({ transactions, types, trends, summary, statuses, showAll });
        }
    }, [detailId, period, state.showAll]);

    useEffect(() => {
        if (detailId) {
            fetchData();
        }
    }, [fetchData]);
    useDetailRefresh(fetchData, [fetchData]);

    const addTransaction = useCallback(
        (type) => {
            let subject = {};
            if (type.hasSubjectInfo) {
                subject.subjectName = settings.name;
                subject.subjectIdNumber = settings.idNumber;
                subject.subjectTaxIdNumber = settings.taxIdNumber;
                subject.subjectAddress = settings.address;
            }

            let transaction = {
                ...type,
                typeId: type.id,
                typeName: type.name,
                name: "",
                ...subject,

                id: null,
                currencyCode: settings.defaultCurrencyCode,
                date: moment().format("YYYY-MM-DD"),
            };
            if (!!data) {
                transaction.counterpartyId = data.id;
                transaction.counterpartyName = data.name;
                transaction.counterpartyIdNumber = data.idNumber;
                transaction.counterpartyTaxIdNumber = data.taxIdNumber;
            }
            transactionModal.showModal(transaction);
        },
        [settings, data, settings]
    );

    return (
        <>
            <Box mb={2}>
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                    <Stack direction="row" alignItems="center"></Stack>
                </Stack>
            </Box>

            <Box mb={4}>
                <Panel fullHeight>
                    <Box mb={2}>
                        <Period
                            disabled={isLoading}
                            year
                            quarter
                            month
                            week
                            day
                            actions={[
                                {
                                    render: () => {
                                        return (
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        size="small"
                                                        color="secondary"
                                                        checked={!!state.showAll}
                                                        onChange={(e) => {
                                                            setState({ ...state, showAll: e.target.checked });
                                                            e.stopPropagation();
                                                        }}
                                                        disabled={isLoading}
                                                    />
                                                }
                                                label={<Typography variant="caption">Zobrazit vše</Typography>}
                                            />
                                        );
                                    },
                                },
                                {
                                    alwaysVisible: true,
                                    render: () => <AddTransactionButtonWithTypes disabled={!!isLoading} onTypeSelected={addTransaction} types={types} />,
                                },
                            ]}
                        />
                    </Box>
                    <div className={classes.dashboardTopRow}>
                        <div>
                            <Value
                                label="Celkem za období"
                                value={<CurrencyValue value={(state.trends && state.trends.value) || 0} currencyCode={state.trends && state.trends.unit} />}
                            />
                            <Value
                                label="Celkem"
                                value={<CurrencyValue value={(state.trends && state.trends.total) || 0} currencyCode={state.trends && state.trends.unit} />}
                                big
                            />
                        </div>
                        <div>
                            <TrendsBarChart
                                data={state.trends.data}
                                period={period}
                                disabled={isLoading}
                                formatValue={(v) =>
                                    `${formatCurrency(
                                        v.value,
                                        settings.defaultCurrencyCode,
                                        settings.currencies,
                                        settings.decimalDigits,
                                        settings.decimalDelimiter,
                                        settings.thousandDelimiter
                                    )}`
                                }
                                onClick={updatePeriodOnClick(setPeriod)}
                            />
                        </div>
                        <div>
                            <TransactionStatusesChart data={state.statuses} disabled={isLoading} />
                        </div>
                    </div>
                </Panel>
            </Box>

            <Box mb={2}>
                {state.transactions && state.transactions.content && state.transactions.content.length ? (
                    <TransactionsList
                        data={(state.transactions && state.transactions.content) || []}
                        onEdit={transactionModal.showModal}
                        buttons={[]}
                        withDetail
                        showProject
                    />
                ) : (
                    <EmptySection
                        loading={isLoading}
                        actions={(types || []).map((type) => ({
                            alwaysVisible: true,
                            render: () => <EmptySectionAction title={type.name} onClick={() => addTransaction(type)} icon={<AddIcon />} />,
                        }))}
                    />
                )}

                {!!state.transactions && state.transactions.totalPages > 1 && (
                    <Pagination
                        count={state.transactions.totalPages}
                        siblingCount={1}
                        boundaryCount={1}
                        onChange={async (e, page) => {
                            startLoading();
                            try {
                                let transactions = await fetchCounterpartyTransactions(
                                    detailId,
                                    state.showAll ? null : period.start,
                                    state.showAll ? null : period.end,
                                    {
                                        page: page - 1,
                                        size: 5,
                                    }
                                );
                                setState({ ...state, transactions });
                            } catch (e) {
                                console.error(e);
                                alert("error");
                            }
                            stopLoading();
                        }}
                    />
                )}
            </Box>

            <TransactionModal data={transactionModal.data} open={transactionModal.open} onDataSaved={refreshDetail} onClose={transactionModal.closeModal} />
        </>
    );
};

//TODO rewrite properly
const timesheetsTabState = { timesheetCalendar: {}, timesheetChart: {} };
const TimesheetsTab = () => {
    const { hideDetail, refreshMaster } = useMasterDetail();
    const { detailId, refreshDetail } = useMasterDetailParams();
    const theme = useTheme();
    const filter = null;

    const periodRef = usePeriod();
    const [period, setPeriod] = periodRef;
    const { startLoading, stopLoading, isLoading } = useLoadingIndicator();

    const [state, setState] = useState(timesheetsTabState);
    const { showTimesheetModal, closeTimesheetModal, modalState } = useTimesheetModal();
    const showAlert = useAlert();
    const [tab, setTab] = useState("items");
    const [axes, setAxes] = useState([]);

    const fetchData = useCallback(async () => {
        let timesheetCalendar = {},
            timesheetChart = {};
        let interval = periodInterval(period);
        startLoading();
        try {
            let result = await Promise.allSettled([
                fetchCounterpartyTimesheetCalendar(
                    detailId,
                    interval.from,
                    interval.to,
                    period.type == "month" ? "day" : nextPeriodType(period.type),
                    false,
                    filter
                ),
                fetchCounterpartyTimesheetChart(detailId, interval.from, interval.to, undefined, filter),
            ]);

            let dataResult = result[0];
            if (dataResult.status === "fulfilled") {
                timesheetCalendar = dataResult.value;
            }
            dataResult = result[1];
            if (dataResult.status === "fulfilled") {
                timesheetChart = dataResult.value;
            }
        } catch (e) {
            alert(e);
            console.error(e);
        } finally {
            setState({ timesheetCalendar, timesheetChart });
            stopLoading();
        }
    }, [detailId, period]);

    const openTimesheetModal = useCallback(
        (data) => {
            if (!!data) {
                return showTimesheetModal(data);
            }

            //prepare default data
            data = {
                date: getDefaultPeriodDate(periodRef),
            };

            return showTimesheetModal(data);
        },
        [showTimesheetModal]
    );

    const saveItem = useCallback(
        async (data, submitHints) => {
            await saveProjectTimesheet(detailId, data);
            refreshDetail();
            if (submitHints && submitHints.keepOpen) {
                openTimesheetModal({ date: data.date });
            }
        },
        [openTimesheetModal]
    );

    const deleteItem = useCallback((item) => {
        const deleteTimesheet = async () => {
            await deleteProjectTimesheet(detailId, item.id);
            refreshDetail();
        };
        showAlert("Smazat položku", `Opravdu chcete smazat položku výkazu ${item.projectName} (${item.hours} h)?`, [
            { text: "Ne" },
            { text: "Ano", onPress: deleteTimesheet },
        ]);
    }, []);

    useEffect(() => {
        if (detailId) {
            fetchData();
        } else {
            setState(timesheetsTabState);
        }
    }, [fetchData]);

    useEffect(() => {
        setAxes(periodAxes(period, projectIdAxis));
    }, [period.type]);

    useDetailRefresh(fetchData, [fetchData]);

    const tabs = (
        <Tabs value={tab} onChange={(e, tab) => setTab(tab)} disabled={isLoading} size="small">
            <Tab label="Položky výkazu" value="items" disabled={isLoading} />
            <Tab label="Přehled" value="overview" disabled={isLoading} />
        </Tabs>
    );

    return (
        <>
            <Box mb={2}>
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                    <Period disabled={isLoading} year quarter month week day />
                </Stack>
            </Box>
            <Box mb={4}>
                <Panel>
                    <Box mb={4}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} xl={7}>
                                {/*
                                                    disabled,
                                                    readOnly,
                                                    showTimesheetModal,
                                                    showProject,
                                                    onEdit,
                                                    onDelete,
                                                    onDeleteAttachment,
                                                    downloadAttachmentUrl,
                                                    */}
                                <TimesheetCalendar
                                    data={state.timesheetCalendar}
                                    disabled={isLoading}
                                    showEmployee
                                    showTimesheetModal={showTimesheetModal}
                                    onEdit={showTimesheetModal}
                                    onDelete={deleteItem}
                                    onDeleteAttachment={(item) => async (attachment) => {
                                        startLoading();
                                        try {
                                            await deleteProjectTimesheetAttachment(detailId, item.id, attachment.id);
                                            refreshDetail();
                                            stopLoading();
                                        } catch (e) {
                                            stopLoading();
                                            alert(e);
                                        }
                                    }}
                                    downloadAttachmentUrl={(item) => (attachment) =>
                                        `/api/employees/${detailId}/timesheets/${item.id}/attachments/${attachment.id}`}
                                />
                            </Grid>
                            <Grid item xs={12} xl={5}>
                                <Box px={8} mb={4}>
                                    <TimesheetChart data={state.timesheetChart} disabled={isLoading} />
                                    <ChartLabels
                                        values={
                                            (Array.isArray(state.timesheetChart && state.timesheetChart.data) &&
                                                state.timesheetChart.data.length &&
                                                state.timesheetChart.data[0]) ||
                                            []
                                        }
                                        color={isLoading ? "#6A6A6A" : theme.palette.primary.main}
                                        renderValue={(nv) => <NumberValue value={nv.value} unit="h" />}
                                        emptyValueLabel="Ostatní"
                                        variant="row"
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Panel>
            </Box>
            <Box my={3}>
                {tab === "items" && <TimesheetItemsTab tabs={tabs} detailId={detailId} period={period} disabled={isLoading} />}
                {tab === "overview" && <AggregationsTab tabs={tabs} detailId={detailId} disabled={isLoading} axes={axes} setAxes={setAxes} />}
            </Box>
        </>
    );
};

const TimesheetItemsTab = ({ detailId, tabs, period, ...props }) => {
    const [data, setData] = useState({});
    const { startLoading, stopLoading, isLoading } = useLoadingIndicator();

    const fetchData = useCallback(async () => {
        startLoading();
        let data = {};
        try {
            data = await fetchCounterpartyTimesheetItems(detailId, period.start, period.end);
        } catch (e) {
            alert("error");
            console.error(e);
        } finally {
            setData(data);
            stopLoading();
        }
    }, [detailId, period]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    useDetailRefresh(fetchData, [fetchData]);

    return (
        <>
            <Box mb={4}>
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                    {tabs}
                    <Stack direction="row" alignItems="center">
                        {/*!readOnly && (
                            <IconButton disabled={isLoading} onClick={() => openTimesheetModal()} title="Přidat položku výkazu">
                                <AddIcon />
                            </IconButton>
                        )*/}
                    </Stack>
                </Stack>
            </Box>
            <Box mb={4}>
                <MuiTableContainer component={Paper}>
                    <MuiTable size="small">
                        <MuiTableHead>
                            <MuiTableRow>
                                <MuiTableCell>Datum</MuiTableCell>
                                <MuiTableCell>Projekt</MuiTableCell>
                                <MuiTableCell align="right">Počet hodin</MuiTableCell>
                            </MuiTableRow>
                        </MuiTableHead>
                        <MuiTableBody>
                            {!!data &&
                                Array.isArray(data.content) &&
                                data.content.map((row) => (
                                    <MuiTableRow key={row.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                        <MuiTableCell>
                                            <DateValue value={row.date} />
                                        </MuiTableCell>
                                        <MuiTableCell>
                                            {row.projectName}
                                            {!!row.description && (
                                                <Typography component="div" variant="caption" color="textSecondary">
                                                    {row.description}
                                                </Typography>
                                            )}
                                        </MuiTableCell>
                                        <MuiTableCell align="right">
                                            <NumberValue value={row.hours} unit="h" />
                                        </MuiTableCell>
                                    </MuiTableRow>
                                ))}
                        </MuiTableBody>
                    </MuiTable>
                </MuiTableContainer>
                {!!data && data.totalPages > 1 && (
                    <Box my={1}>
                        <Pagination
                            count={data.totalPages}
                            page={1 + data.number}
                            siblingCount={1}
                            boundaryCount={1}
                            onChange={async (e, page) => {
                                startLoading();
                                try {
                                    let data = await fetchCounterpartyTimesheetItems(detailId, period.start, period.end, null, {
                                        page: page - 1,
                                        size: 10,
                                    });
                                    setData(data);
                                } catch (e) {
                                    console.error(e);
                                    alert("error");
                                }
                                stopLoading();
                            }}
                        />
                    </Box>
                )}
            </Box>
        </>
    );
};

const AggregationsTab = ({ detailId, axes, setAxes, tabs, ...props }) => {
    const theme = useTheme();
    const readOnly = false;
    const { hideDetail, refreshMaster } = useMasterDetail();
    const { startLoading, stopLoading, isLoading } = useLoadingIndicator();
    const periodRef = usePeriod();
    const [period] = periodRef;

    const [aggregationData, setAggregationData] = useState({});

    const showAlert = useAlert();

    const fetchData = useCallback(async () => {
        if (!axes || !axes.length) {
            return;
        }

        let aggregationData = {};
        setAggregationData({});
        let interval = periodInterval(period);
        startLoading();
        try {
            aggregationData = await fetchClientAggregation(
                detailId,
                interval.from,
                interval.to,
                null,
                axes.filter((a) => !!a.selected).map((a) => a.id)
            );

            setAggregationData(aggregationData);
        } catch (e) {
            alert(e);
            console.error(e);
        }
        stopLoading();
    }, [period, axes]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    useDetailRefresh(fetchData, [fetchData]);

    return (
        <>
            <Box mb={4}>
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                    {tabs}
                    <Stack direction="row" alignItems="center">
                        {/*!readOnly && (
                            <IconButton disabled={isLoading} onClick={() => openTimesheetModal()} title="Přidat položku výkazu">
                                <AddIcon />
                            </IconButton>
                        )*/}
                        <AxesSelector value={axes} onChange={(axes) => setAxes(axes)} disabled={isLoading} />
                    </Stack>
                </Stack>
            </Box>

            <Aggregations
                data={aggregationData}
                disabled={isLoading}
                renderChart={(val) => (
                    <DonutChart
                        data={[(val.children || []).map((ch) => ch.value)]}
                        colors={isLoading ? ["#6A6A6A"] : [theme.palette.primary.main, theme.palette.secondary.main]}
                        disabled={isLoading}
                        height={250}
                        thickness={20}
                    />
                )}
                formatHeader={(base, classes, idx, count, isLeaf) => (
                    <FlexRow>
                        <FlexItem relativeWidth={3}>
                            {!!isLeaf && (
                                <div
                                    style={{
                                        display: "inline-block",
                                        width: 16,
                                        height: 16,
                                        backgroundColor: chartColor(theme.palette.primary.main, count, idx),
                                        marginLeft: 4,
                                        marginRight: 8,
                                    }}
                                ></div>
                            )}
                            <Typography variant="body1">{base.type == "period" ? formatPeriodValue(base.key) : base.name || base.key || "N/A"}</Typography>
                        </FlexItem>
                        <FlexItem right>
                            <Typography variant="body1">
                                <b>
                                    <NumberValue value={base.value} unit={base.unit} />
                                </b>
                            </Typography>
                        </FlexItem>
                    </FlexRow>
                )}
            />
        </>
    );
};

const CounterpartyDetailContainer = ({ menuItems }) => {
    const settings = useSettings();
    const { hideDetail, refreshMaster } = useMasterDetail();
    const { detailId } = useMasterDetailParams();
    const [data, setData] = useState({});
    const supplierOnly = false;
    const { tab, setTab, tabs } = useMasterDetailTabs({
        financials: settings.transactionsSupport,
        projects: settings.projectsSupport && !supplierOnly,
        billings: settings.billingsSupport && !supplierOnly,
        timesheets: settings.timesheetsSupport && !supplierOnly,
    });
    const { startLoading, stopLoading, isLoading } = useLoadingIndicator();
    const modal = useModal();
    const theme = useTheme();

    const fetchData = useCallback(async () => {
        startLoading();
        try {
            let data = await fetchCounterparty(detailId);
            setData(data);
        } catch (e) {
            alert("error");
            console.error(e);
        }
        stopLoading();
    }, [detailId]);

    const onDataSaved = useCallback(
        async (data) => {
            setData(data);
            refreshMaster();
        },
        [refreshMaster]
    );

    const detailMenuItems = useMemo(() => {
        const items = [];

        return [
            {
                alwaysVisible: false,
                title: "Upravit",
                icon: <EditIcon />,
                onClick: () => modal.showModal(data),
            },
            /*
            {
                icon: <RefreshIcon />,
                title: "Obnovit data",
                onClick: () => loadCounterparty(detailId),
            },
            */
            ...menuItems,
        ];
    }, [detailId, data]);

    useEffect(() => {
        if (!!detailId) {
            fetchData();
        }
    }, [fetchData]);

    useDetailRefresh(fetchData, [fetchData]);

    return (
        <Page>
            <MasterDetailContent
                pageTitle={data.name || "Protistrana"}
                menuItems={detailMenuItems}
                onBack={hideDetail}
                disabled={isLoading}
                pageTitleChildren={
                    !!tabs._ && (
                        <Tabs value={tab} onChange={(e, tab) => setTab(tab)} disabled={isLoading}>
                            {!!tabs.financials && <Tab label="Finance" value="financials" disabled={isLoading} />}
                            {!!tabs.billings && <Tab label="Vyúčtování" value="billings" disabled={isLoading} />}
                            {!!tabs.projects && <Tab label="Projekty" value="projects" disabled={isLoading} />}
                            {!!tabs.timesheets && <Tab label="Výkazy" value="timesheets" disabled={isLoading} />}
                        </Tabs>
                    )
                }
                showPageTitle
                showLoadingIndicator
            >
                <LayoutWithInfo>
                    <LayoutWithInfoMain>
                        {tab == "financials" && <TransactionsTab detailId={detailId} data={data} settings={settings} />}
                        {tab == "billings" && <BillingsTab detailId={detailId} data={data} settings={settings} />}
                        {tab == "projects" && <ProjectsTab detailId={detailId} data={data} settings={settings} />}
                        {tab == "timesheets" && <TimesheetsTab detailId={detailId} data={data} settings={settings} />}
                    </LayoutWithInfoMain>
                    <LayoutWithInfoInfo>
                        <CounterpartyBasicInfo data={data} />
                    </LayoutWithInfoInfo>
                </LayoutWithInfo>
            </MasterDetailContent>
            <CounterpartyModal open={modal.open} onClose={modal.closeModal} onDataSaved={onDataSaved} data={data} title="Editace protistrany" />
        </Page>
    );
};

const createFinancialsStyles = makeStyles((theme) => ({
    root: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        columnGap: theme.spacing(4),
        rowGap: theme.spacing(2),

        alignItems: "stretch",
        justifyItems: "stretch",

        overflow: "hidden",
        [theme.breakpoints.down("xl")]: {
            gridTemplateColumns: "1fr",
        },

        "& > *": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
    },
    legend: {},
    chart: {},
}));
const overviewTransactionsState = { data: {}, chart: [], chartLabels: [] };
const OverviewTransactionsTab = ({ settings, color, showDetail }) => {
    const classes = createFinancialsStyles();
    const theme = useTheme();
    const [period, setPeriod] = usePeriod();
    const { startLoading, stopLoading, isLoading } = useLoadingIndicator();
    const [state, setState] = useState(overviewTransactionsState);
    const [showAll, setShowAll] = useState(true);
    const types = useTransactionTypes({ hasCounterparty: true });
    const transactionModal = useModal();
    const { refreshDetail } = useMasterDetail();

    const fetchData = useCallback(async () => {
        startLoading();
        try {
            let chart = [],
                chartLabels = [],
                data = {},
                dataResult = undefined;

            let paging = { page: (state.data && state.data.number) || 0, size: 10 };
            let start = period.start,
                end = period.end;

            if (showAll) {
                let m = moment(period.date);
                start = m.startOf("year").format("YYYY-MM-DD");
                end = m.endOf("year").format("YYYY-MM-DD");
            }

            const result = await Promise.allSettled([fetchTransactionCounterpartiesChart(start, end), fetchTransactionCounterpartiesData(start, end, paging)]);
            dataResult = result[0];
            if (dataResult.status === "fulfilled") {
                chartLabels = dataResult.value;
                chart = (chartLabels || []).map((nv) => Math.abs(nv.value));
            }
            dataResult = result[1];
            if (dataResult.status === "fulfilled") {
                data = dataResult.value;
            }

            setState({ chart, chartLabels, data });
        } catch (e) {
            alert(e);
            console.error(e);
        } finally {
            stopLoading();
        }
    }, [period, showAll]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    useDetailRefresh(fetchData, [fetchData]);

    const addTransaction = useCallback(
        (type) => {
            let subject = {};
            if (type.hasSubjectInfo) {
                subject.subjectName = settings.name;
                subject.subjectIdNumber = settings.idNumber;
                subject.subjectTaxIdNumber = settings.taxIdNumber;
                subject.subjectAddress = settings.address;
            }

            let transaction = {
                ...type,
                typeId: type.id,
                typeName: type.name,
                name: "",
                ...subject,

                id: null,
                currencyCode: settings.defaultCurrencyCode,
                date: moment().format("YYYY-MM-DD"),
            };
            transactionModal.showModal(transaction);
        },
        [settings, transactionModal]
    );

    return (
        <>
            <Box mb={4}>
                <Panel fullHeight>
                    <Box mb={2}>
                        <Period
                            disabled={isLoading}
                            year
                            quarter
                            month
                            week
                            day
                            actions={[
                                {
                                    render: () => (
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    size="small"
                                                    color="secondary"
                                                    checked={!!showAll}
                                                    onChange={(e) => {
                                                        setShowAll(e.target.checked);
                                                        e.stopPropagation();
                                                    }}
                                                    disabled={isLoading}
                                                />
                                            }
                                            label={<Typography variant="caption">Souhrn za rok</Typography>}
                                        />
                                    ),
                                },
                                {
                                    alwaysVisible: true,
                                    render: () => <AddTransactionButtonWithTypes disabled={!!isLoading} onTypeSelected={addTransaction} types={types} />,
                                },
                            ]}
                        />
                    </Box>
                    <Box mb={4}>
                        <div className={classes.root}>
                            <div className={classes.legend}>
                                <ChartLabels
                                    values={state.chartLabels || []}
                                    color={isLoading ? "#6A6A6A" : theme.palette.primary.dark}
                                    emptyValueLabel="Ostatní"
                                    renderValue={(nv) => <CurrencyValue value={nv.value} currencyCode={settings.defaultCurrencyCode} />}
                                    onClick={(nv) => {
                                        showDetail && showDetail(nv, { tab: "financials" });
                                    }}
                                    sx={{ xs: { textAlign: "center" }, lg: { textAlign: "left" } }}
                                />
                            </div>
                            <div className={classes.chart}>
                                <DonutChart
                                    data={[state.chart || []]}
                                    colors={isLoading ? ["#6A6A6A"] : [theme.palette.primary.light]}
                                    disabled={isLoading}
                                    size={270}
                                />
                            </div>
                        </div>
                    </Box>
                </Panel>
            </Box>
            <Box mb={4}>
                {!!state.data && Array.isArray(state.data.content) && state.data.content.length ? (
                    state.data.content.map((row) => (
                        <DetailRow key={row.id} onClick={() => showDetail && showDetail(row, { tab: "transactions" })}>
                            <DetailRowMain slim>
                                <Typography variant="body1" component="div">
                                    {row.name}
                                </Typography>
                            </DetailRowMain>
                            <DetailRowValue slim>
                                <Typography variant="body1" component="div" align="right">
                                    <CurrencyValue currencyCode={row.unit} value={row.value} />
                                </Typography>
                            </DetailRowValue>
                        </DetailRow>
                    ))
                ) : (
                    <EmptySection
                        loading={isLoading}
                        actions={(types || []).map((type) => ({
                            alwaysVisible: true,
                            render: () => <EmptySectionAction title={type.name} onClick={() => addTransaction(type)} icon={<AddIcon />} />,
                        }))}
                    />
                )}

                {!!state.data && state.data.totalPages > 1 && (
                    <Box my={1}>
                        <Pagination
                            count={state.data.totalPages}
                            page={1 + state.data.number}
                            siblingCount={1}
                            boundaryCount={1}
                            onChange={async (e, page) => {
                                startLoading();
                                try {
                                    let data = await fetchTransactionCounterpartiesData(period.start, period.end, {
                                        page: page - 1,
                                        size: 10,
                                    });
                                    setState({ ...state, data });
                                } catch (e) {
                                    console.error(e);
                                    alert("error");
                                }
                                stopLoading();
                            }}
                        />
                    </Box>
                )}
            </Box>

            <TransactionModal data={transactionModal.data} open={transactionModal.open} onDataSaved={refreshDetail} onClose={transactionModal.closeModal} />
        </>
    );
};

//TODO classes

const overviewProjectsState = { data: {}, chart: [], chartLabels: [], periods: [] };
const OverviewProjectsTab = ({ settings, color, showDetail }) => {
    const [period, setPeriod] = usePeriod();
    const { startLoading, stopLoading, isLoading } = useLoadingIndicator();
    const [state, setState] = useState(overviewProjectsState);

    useEffect(() => {
        const fetchState = async () => {
            startLoading();
            try {
                let chart = [],
                    chartLabels = [],
                    data = {},
                    dataResult = undefined;

                let paging = { page: (state.data && state.data.number) || 0, size: 10 };

                const result = await Promise.allSettled([
                    fetchOverviewActiveProjectsChart(period.start, period.end),
                    fetchOverviewActiveProjectsData(period.start, period.end, paging),
                ]);
                dataResult = result[0];
                if (dataResult.status === "fulfilled") {
                    chartLabels = dataResult.value;
                    chart = chartLabels || [];
                }
                dataResult = result[1];
                if (dataResult.status === "fulfilled") {
                    data = dataResult.value;
                }

                setState({ ...state, chart, chartLabels, data });
            } catch (e) {
                alert(e);
                console.error(e);
            } finally {
                stopLoading();
            }
        };

        fetchState();
    }, [period /*, state.filter*/]);

    return (
        <>
            <Box mb={4}>
                <Panel fullHeight>
                    <Box mb={2}>
                        <Period
                            disabled={isLoading}
                            year
                            quarter
                            month
                            week
                            day
                            actions={
                                [
                                    /*
                                {
                                    icon: <FileDownloadIcon />,
                                    title: "Exportovat do excelu",
                                    onClick: async () => {
                                        startLoading();
                                        try {
                                            await downloadEmployeesExcel(period.start, period.end, state.filter);
                                        } catch (e) {
                                            alert("error");
                                        }
                                        stopLoading();
                                    },
                                },
                            */
                                ]
                            }
                        />
                    </Box>

                    <Box mb={4}>
                        {/* TODO */}

                        <Grid container spacing={4}>
                            <Grid item xs={12} xl={6}>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12} lg={6}>
                                        <DonutChart
                                            data={[(state.chart || []).map((i) => i.value)]}
                                            colors={isLoading ? ["#6A6A6A"] : [color]}
                                            disabled={isLoading}
                                            size={270}
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                        <ChartLabels
                                            values={state.chartLabels || []}
                                            color={isLoading ? "#6A6A6A" : color}
                                            emptyValueLabel="Ostatní"
                                            renderValue={(nv) => nv.value}
                                            onClick={(nv) => {
                                                showDetail && showDetail(nv, { tab: "projects" });
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Panel>
            </Box>
            <Box mb={4}>
                {!!state.data && Array.isArray(state.data.content) && state.data.content.length ? (
                    state.data.content.map((row) => (
                        <DetailRow key={row.id} onClick={() => showDetail && showDetail(row, { tab: "projects" })}>
                            <DetailRowMain slim>
                                <Typography variant="body1" component="div">
                                    {row.name}
                                </Typography>
                            </DetailRowMain>
                            <DetailRowValue slim>
                                <Typography variant="body1" component="div" align="right">
                                    {row.value || 0}
                                </Typography>
                            </DetailRowValue>
                        </DetailRow>
                    ))
                ) : (
                    <EmptySection loading={isLoading} />
                )}

                {!!state.data && state.data.totalPages > 1 && (
                    <Box my={1}>
                        <Pagination
                            count={state.data.totalPages}
                            page={1 + state.data.number}
                            siblingCount={1}
                            boundaryCount={1}
                            onChange={async (e, page) => {
                                startLoading();
                                try {
                                    let data = await fetchOverviewActiveProjectsData(period.start, period.end, {
                                        page: page - 1,
                                        size: 10,
                                    });
                                    setState({ ...state, data });
                                } catch (e) {
                                    console.error(e);
                                    alert("error");
                                }
                                stopLoading();
                            }}
                        />
                    </Box>
                )}
            </Box>
        </>
    );
};

const overviewTimesheetsState = { data: {}, chart: [], chartLabels: [], periods: [] };
const OverviewTimesheetsTab = ({ settings, color, showDetail }) => {
    const [period, setPeriod] = usePeriod();
    const { startLoading, stopLoading, isLoading } = useLoadingIndicator();
    const [state, setState] = useState(overviewProjectsState);

    useEffect(() => {
        const fetchState = async () => {
            startLoading();
            try {
                let chart = [],
                    chartLabels = [],
                    data = {},
                    dataResult = undefined;

                let paging = { page: (state.data && state.data.number) || 0, size: 10 };

                const result = await Promise.allSettled([
                    fetchOverviewTimesheetsChart(period.start, period.end),
                    fetchOverviewTimesheetsData(period.start, period.end, paging),
                ]);
                dataResult = result[0];
                if (dataResult.status === "fulfilled") {
                    chartLabels = dataResult.value;
                    chart = chartLabels || [];
                }
                dataResult = result[1];
                if (dataResult.status === "fulfilled") {
                    data = dataResult.value;
                }

                setState({ ...state, chart, chartLabels, data });
            } catch (e) {
                alert(e);
                console.error(e);
            } finally {
                stopLoading();
            }
        };

        fetchState();
    }, [period /*, state.filter*/]);

    return (
        <>
            <Box mb={4}>
                <Panel fullHeight>
                    <Box mb={2}>
                        <Period
                            disabled={isLoading}
                            year
                            quarter
                            month
                            week
                            day
                            actions={
                                [
                                    /*
                                {
                                    icon: <FileDownloadIcon />,
                                    title: "Exportovat do excelu",
                                    onClick: async () => {
                                        startLoading();
                                        try {
                                            await downloadEmployeesExcel(period.start, period.end, state.filter);
                                        } catch (e) {
                                            alert("error");
                                        }
                                        stopLoading();
                                    },
                                },
                            */
                                ]
                            }
                        />
                    </Box>

                    <Box mb={4}>
                        {/* TODO */}

                        <Grid container spacing={4}>
                            <Grid item xs={12} xl={6}>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12} lg={6}>
                                        <DonutChart
                                            data={[(state.chart || []).map((i) => i.value)]}
                                            colors={isLoading ? ["#6A6A6A"] : [color]}
                                            disabled={isLoading}
                                            size={270}
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                        <ChartLabels
                                            values={state.chartLabels || []}
                                            color={isLoading ? "#6A6A6A" : color}
                                            emptyValueLabel="Ostatní"
                                            renderValue={(nv) => <NumberValue value={nv.value || 0} unit="h" />}
                                            onClick={(nv) => {
                                                showDetail && showDetail(nv, { tab: "timesheets" });
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Panel>
            </Box>
            <Box mb={4}>
                {!!state.data && Array.isArray(state.data.content) && state.data.content.length ? (
                    state.data.content.map((row) => (
                        <DetailRow key={row.id} onClick={() => showDetail && showDetail(row, { tab: "timesheets" })}>
                            <DetailRowMain slim>
                                <Typography variant="body1" component="div">
                                    {row.name}
                                </Typography>
                            </DetailRowMain>
                            <DetailRowValue slim>
                                <Typography variant="body1" component="div" align="right">
                                    <NumberValue value={row.value || 0} unit="h" />
                                </Typography>
                            </DetailRowValue>
                        </DetailRow>
                    ))
                ) : (
                    <EmptySection loading={isLoading} />
                )}

                {!!state.data && state.data.totalPages > 1 && (
                    <Box my={1}>
                        <Pagination
                            count={state.data.totalPages}
                            page={1 + state.data.number}
                            siblingCount={1}
                            boundaryCount={1}
                            onChange={async (e, page) => {
                                startLoading();
                                try {
                                    let data = await fetchOverviewTimesheetsData(period.start, period.end, {
                                        page: page - 1,
                                        size: 10,
                                    });
                                    setState({ ...state, data });
                                } catch (e) {
                                    console.error(e);
                                    alert("error");
                                }
                                stopLoading();
                            }}
                        />
                    </Box>
                )}
            </Box>
        </>
    );
};
const overviewBillingsState = { data: {}, chart: [], chartLabels: [], periods: [] };
const OverviewBillingsTab = ({ settings, color, showDetail }) => {
    const [period, setPeriod] = usePeriod();
    const { startLoading, stopLoading, isLoading } = useLoadingIndicator();
    const [state, setState] = useState(overviewTransactionsState);

    useEffect(() => {
        const fetchState = async () => {
            startLoading();
            try {
                let chart = [],
                    chartLabels = [],
                    data = {},
                    hoursPeriods = {},
                    dataResult = undefined;

                let paging = { page: (state.data && state.data.number) || 0, size: 10 };

                const result = await Promise.allSettled([
                    fetchBillingsCounterpartiesChart(period.start, period.end),
                    fetchBillingsCounterpartiesData(period.start, period.end, paging),
                ]);
                dataResult = result[0];
                if (dataResult.status === "fulfilled") {
                    chartLabels = dataResult.value;
                    chart = (chartLabels || []).map((nv) => ({ ...nv, value: Math.abs(nv.value) }));
                }
                dataResult = result[1];
                if (dataResult.status === "fulfilled") {
                    data = dataResult.value;
                }
                /*
            dataResult = result[2];
            if (dataResult.status === "fulfilled") {
                hoursPeriods = dataResult.value;
            }
            */
                setState({ ...state, chart, chartLabels, data, hoursPeriods });
            } catch (e) {
                alert(e);
                console.error(e);
            } finally {
                stopLoading();
            }
        };

        fetchState();
    }, [period /*, state.filter*/]);

    return (
        <>
            <Box mb={4}>
                <Panel fullHeight>
                    <Box mb={4}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} xl={6}>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12} lg={6}>
                                        <DonutChart
                                            data={[(state.chart || []).map((i) => i.value)]}
                                            colors={isLoading ? ["#6A6A6A"] : [color]}
                                            disabled={isLoading}
                                            size={270}
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                        <ChartLabels
                                            values={state.chartLabels || []}
                                            color={isLoading ? "#6A6A6A" : color}
                                            emptyValueLabel="Ostatní"
                                            renderValue={(nv) => <CurrencyValue value={nv.value} currencyCode={settings.defaultCurrencyCode} />}
                                            onClick={(nv) => {
                                                showDetail && showDetail(nv, { tab: "billings" });
                                            }}
                                            variant="row"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} xl={6}>
                                <TrendsBarChart
                                    data={state.hoursPeriods}
                                    period={period}
                                    disabled={isLoading}
                                    formatValue={(v) =>
                                        `${formatCurrency(
                                            v.value,
                                            settings.defaultCurrencyCode, //TODO fixme!!!
                                            settings.currencies,
                                            settings.decimalDigits,
                                            settings.decimalDelimiter,
                                            settings.thousandDelimiter
                                        )}`
                                    }
                                    onClick={updatePeriodOnClick(setPeriod)}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Panel>
            </Box>
            <Box mb={4}>
                <MuiTableContainer component={Paper}>
                    <MuiTable size="small">
                        <MuiTableHead>
                            <MuiTableRow>
                                <MuiTableCell>Název</MuiTableCell>
                                <MuiTableCell align="right">Zisk</MuiTableCell>
                            </MuiTableRow>
                        </MuiTableHead>
                        <MuiTableBody>
                            {!!state.data &&
                                Array.isArray(state.data.content) &&
                                state.data.content.map((row) => (
                                    <MuiTableRow key={row.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                        <MuiTableCell onClick={() => showDetail && showDetail(row, { tab: "billings" })}>{row.name}</MuiTableCell>
                                        <MuiTableCell align="right">
                                            <CurrencyValue value={row.value || 0} currencyCode={row.unit} />
                                        </MuiTableCell>
                                    </MuiTableRow>
                                ))}
                        </MuiTableBody>
                    </MuiTable>
                </MuiTableContainer>
                {!!state.data && state.data.totalPages > 1 && (
                    <Box my={1}>
                        <Pagination
                            count={state.data.totalPages}
                            page={1 + state.data.number}
                            siblingCount={1}
                            boundaryCount={1}
                            onChange={async (e, page) => {
                                startLoading();
                                try {
                                    let data = await fetchBillingsCounterpartiesData(period.start, period.end, {
                                        page: page - 1,
                                        size: 10,
                                    });
                                    setState({ ...state, data });
                                } catch (e) {
                                    console.error(e);
                                    alert("error");
                                }
                                stopLoading();
                            }}
                        />
                    </Box>
                )}
            </Box>
        </>
    );
};

const CounterpartiesOverview = ({ menuItems }) => {
    const theme = useTheme();
    const settings = useSettings();
    const { hideDetail, refreshMaster, refreshDetail, refreshOverview, mobile, showDetail } = useMasterDetail();
    const { isLoading } = useLoadingIndicator();
    const periodRef = usePeriod();
    const [period, setPeriod] = periodRef;
    const modal = useModal();
    const { tab, setTab, tabs } = useMasterDetailTabs({
        financials: settings.transactionsSupport,
        projects: settings.projectsSupport,
        timesheets: settings.timesheetsSupport,
        billings: settings.billingsSupport,
    });

    const masterMenuItems = useMemo(
        () => [
            {
                icon: <AddIcon />,
                title: "Přidat protistranu",
                alwaysVisible: true,
                onClick: () => modal.showModal({}),
            },
            ...menuItems,
        ],
        [menuItems]
    );

    const onDetailClick = (nv, params) => showDetail(nv.id, params);

    return (
        <>
            <MasterDetailContent
                showPageTitle
                showLoadingIndicator
                pageTitle="Protistrany"
                menuItems={masterMenuItems}
                onBack={(mobile && hideDetail) || null}
                pageTitleChildren={
                    !!tabs._ && (
                        <Tabs value={tab} onChange={(e, tab) => setTab(tab)} disabled={isLoading}>
                            {!!tabs.financials && <Tab label="Finance" value="financials" disabled={isLoading} />}
                            {!!tabs.projects && <Tab label="Projekty" value="projects" disabled={isLoading} />}
                            {!!tabs.timesheets && <Tab label="Výkazy" value="timesheets" disabled={isLoading} />}
                        </Tabs>
                    )
                }
            >
                <Box mb={4}>
                    {tab === "financials" && <OverviewTransactionsTab settings={settings} color={theme.palette.primary.dark} showDetail={onDetailClick} />}
                    {tab === "projects" && <OverviewProjectsTab settings={settings} color={theme.palette.primary.dark} showDetail={onDetailClick} />}
                    {tab === "timesheets" && <OverviewTimesheetsTab settings={settings} color={theme.palette.primary.dark} showDetail={onDetailClick} />}
                </Box>
                <CounterpartyModal
                    open={modal.open}
                    onClose={modal.closeModal}
                    onDataSaved={() => {
                        refreshMaster();
                        refreshDetail();
                        refreshOverview();
                    }}
                    data={{}}
                    title="Nová protistrana"
                />
            </MasterDetailContent>
        </>
    );
};

const CounterpartiesMasterDetail = ({ menuItems }) => {
    return (
        <MasterDetail
            masterElement={<CounterpartyMasterContainer menuItems={menuItems} />}
            detailElement={<CounterpartyDetailContainer menuItems={menuItems} />}
            overviewElement={<CounterpartiesOverview menuItems={menuItems} />}
            titleMenuItems={menuItems}
            detailHasBackground={false}
        />
    );
};

export default CounterpartiesMasterDetail;
