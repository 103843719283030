import React, { useContext, useEffect, useMemo } from "react";

import Autocomplete from "../Autocomplete";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import { debounce } from "lodash";

import { AttachmentChips, AttachmentContainer, AttachmentContext, AttachmentSectionField } from "../Attachments";
import { TagChips, TagsSectionField } from "../Tags";
import backend, { prepareMultipartRequest } from "../../utils/backend";
import { useForm } from "../../utils/form";
import { useLoadingIndicator } from "../../utils/loading";
import Modal from "../Modal";
import { ListContainer, ListRow } from "../Layout";

const avatarString = (s) => {
    return s && s.length && s.substring(0, Math.min(2, s.length)).toUpperCase();
};

//components

export const CounterpartySelect = ({ value, onChange, fullWidth = true, ...props }) => {
    //autoSelect
    return (
        <Autocomplete
            fullWidth={fullWidth}
            {...props}
            fetchOptions={fetchCounterpartyOptions}
            getOptionLabel={(option) => option.name}
            value={value || null}
            onChange={(e, val) => {
                if (typeof val === "string") {
                    val = { name: val };
                }
                onChange && onChange(val);
            }}
            onBlur={(e) => {
                if (!value || !value.id) {
                    onChange && onChange(e.target.value ? { name: e.target.value } : null);
                }
            }}
            freeSolo
            clearable
        />
    );
};

export const CounterpartyListFilter = ({ disabled, onFilterChange, form, ...props }) => {
    const { values = {}, setValues } = form || {};

    const fetch = useMemo(
        () =>
            debounce(
                (filter) => {
                    try {
                        onFilterChange && onFilterChange(filter);
                    } catch (e) {
                        console.error(e);
                    }
                },
                1000,
                { leading: false, trailing: true }
            ),
        []
    );

    const handleChange = (values) => {
        setValues(values);
        fetch(values);
    };

    return (
        <div>
            <TextField
                value={values.filter || ""}
                onChange={(e) => handleChange({ ...values, filter: e.target.value })}
                size="small"
                fullWidth
                margin="normal"
            />
        </div>
    );
};

export const CounterpartyList = ({ disabled, data, onItemClick, hasMore, onShowMoreClick, selectedId }) => {
    return (
        <>
            <ListContainer>
                {data.map((p) => (
                    <ListRow
                        key={p.id}
                        avatar={p.name}
                        mainTitle={p.name}
                        onClick={() => onItemClick && onItemClick(p)}
                        selected={selectedId == p.id}
                        disabled={disabled}
                    />
                ))}
            </ListContainer>
            {!!hasMore && (
                <Box sx={{ p: 2 }}>
                    <Button onClick={onShowMoreClick} disabled={disabled}>
                        načíst další...
                    </Button>
                </Box>
            )}
        </>
    );
};

export const CounterpartyForm = ({ task, editMode = true }) => {
    return <div></div>;
};

export const CounterpartyFormFields = ({ employee, form, editMode = true, disabled, readOnly }) => {
    const onAddAttachment = useContext(AttachmentContext);
    const { values = {}, handleChange, setValues, errors } = form || {};

    return (
        <>
            <Box mb={4} px={2}>
                <TextField
                    fullWidth
                    label="Název"
                    margin="normal"
                    value={values.name || ""}
                    onChange={handleChange}
                    name="name"
                    disabled={disabled}
                    required
                    error={!!errors.name}
                    helperText={errors.name}
                />

                <Stack direction="row" spacing={4}>
                    <div style={{ flex: "1 1 auto" }}>
                        <TextField
                            fullWidth
                            label="IČ"
                            margin="normal"
                            name="idNumber"
                            value={values.idNumber || ""}
                            onChange={handleChange}
                            disabled={disabled}
                            error={!!errors.idNumber}
                            helperText={errors.idNumber}
                        />
                    </div>
                    <div style={{ flex: "1 1 auto" }}>
                        <TextField
                            fullWidth
                            label="DIČ"
                            margin="normal"
                            name="taxIdNumber"
                            value={values.taxIdNumber || ""}
                            onChange={handleChange}
                            disabled={disabled}
                            error={!!errors.taxIdNumber}
                            helperText={errors.taxIdNumber}
                        />
                    </div>
                </Stack>
                <TextField
                    fullWidth
                    label="Adresa"
                    margin="normal"
                    name="address"
                    value={values.address || ""}
                    onChange={handleChange}
                    disabled={disabled}
                    multiline
                    rows={4}
                    error={!!errors.address}
                    helperText={errors.address}
                />
            </Box>
            <Box mb={4} px={2}>
                <FormGroup>
                    <FormControlLabel
                        disabled={disabled}
                        control={<Switch name="supplierOnly" checked={!!values.supplierOnly} onChange={handleChange} color="secondary" />}
                        label="Pouze dodavatel"
                    />
                    <FormHelperText>
                        Pokud je zvolena možnost "Pouze dodavatel", nejsou pro danou protistranu evidovány projekty, výkazy, ani vyúčtování (netýká se již
                        uložených dat)
                    </FormHelperText>
                </FormGroup>
            </Box>
            <Box mb={4}>
                <AttachmentSectionField
                    value={values.attachments}
                    onChange={(attachments) => setValues({ ...values, attachments })}
                    disabled={disabled}
                    readOnly={readOnly}
                    onAddClick={onAddAttachment}
                />

                <TagsSectionField value={values.tags} onChange={(tags) => setValues({ ...values, tags })} disabled={disabled} readOnly={readOnly} />
            </Box>
        </>
    );
};

export const CounterpartyModal = ({ title, data, open, onClose, onDataSaved, ...props }) => {
    const { startLoading, stopLoading, isLoading } = useLoadingIndicator();
    const onSubmit = useMemo(
        () => async (values) => {
            startLoading();
            try {
                values = await saveCounterparty(values);
                onClose && onClose();
                onDataSaved && onDataSaved(values);
            } catch (e) {
                alert(e);
                console.error(e);
            } finally {
                stopLoading();
            }
        },
        [onClose, onDataSaved]
    );

    const form = useForm({ onSubmit });

    const { values, handleChange, errors, setErrors, handleSubmit, setValues, valuesRef } = form;

    useEffect(() => setValues(data || {}), [data]);

    return (
        <Modal
            open={open}
            onClose={onClose}
            title={title}
            size="big"
            buttons={[{ title: "Storno" }, { title: "Uložit", main: true, onClick: handleSubmit, disabled: isLoading }]}
        >
            <AttachmentContainer value={values.attachments} onChange={(attachments) => setValues({ ...values, attachments })}>
                <CounterpartyFormFields form={form} disabled={isLoading} />
            </AttachmentContainer>
        </Modal>
    );
};

export const CounterpartyBasicInfo = ({ data, disabled, readOnly }) => {
    return (
        <div style={{ textAlign: "center" }}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Avatar sx={{ width: 160, height: 160, mb: 5, fontSize: 72 }} alt={data.name}>
                    {avatarString(data.name)}
                </Avatar>
            </div>
            <Typography variant="h6" component="div">
                {data.name}
            </Typography>
            <Typography variant="body1" component="div" sx={{ whiteSpace: "pre" }}>
                {data.address}
            </Typography>
            {!!data.idNumber && (
                <Typography variant="body1" component="div">
                    IČ: {data.idNumber}
                </Typography>
            )}
            {!!data.taxIdNumber && (
                <Typography variant="body1" component="div">
                    DIČ: {data.taxIdNumber}
                </Typography>
            )}

            {!!data.tags && !!data.tags.length && (
                <Box py={4}>
                    <TagChips value={data.tags} disabled={disabled} readOnly={readOnly} color="secondary" />
                </Box>
            )}

            {!!data.attachments && !!data.attachments.length && (
                <Box py={4}>
                    <AttachmentChips
                        value={data.attachments}
                        disabled={disabled}
                        readOnly={readOnly}
                        color="secondary"
                        url={(a) => `/api/counterparties/${data.id}/attachments/${a.id}`}
                        center
                    />
                </Box>
            )}
        </div>
    );
};

const populateFilterParams = () => ({});

//actions TODO
export const fetchCounterparty = async (id) => {
    let response = await backend(`/api/counterparties/${id}`);
    return response.body;
};

export const fetchCounterparties = async (filter, paging) => {
    let response = await backend("/api/counterparties", "GET", { params: { ...(filter || {}), ...(paging || {}) } });
    return response.body; // { ...emptyPage, content: [...mockedCounterparties] };
};

export const fetchCounterpartyProjects = async (id, includeReadOnly, activeOnly, paging) => {
    let response = await backend(`/api/counterparties/${id}/projects`, "GET", { params: { includeReadOnly, activeOnly, ...(paging || {}) } });
    return response.body;
};

export const fetchCounterpartyProject = async (id, projectId, from, to) => {
    let response = await backend(`/api/counterparties/${id}/projects/${projectId}`, "GET", { params: { from, to } });
    return response.body;
};

export const fetchCounterpartyProjectsActive = async (id, date, periodType) => {
    let response = await backend(`/api/counterparties/${id}/projects/active`, "GET", { params: { date, periodType } });
    return response.body;
};

export const fetchCounterpartyProjectsBillings = async (id) => {
    let response = await backend(`/api/counterparties/${id}/projects/billings`, "GET");
    return response.body;
};

export const fetchCounterpartiesProjectsBillings = async (id) => {
    let response = await backend(`/api/counterparties/projects/billings`, "GET");
    return response.body;
};

export const fetchCounterpartyTransactions = async (id, from, to, paging) => {
    let response = await backend(`/api/counterparties/${id}/transactions`, "GET", { params: { from, to, ...(paging || {}) } });
    return response.body;
};

export const saveCounterparty = async (counterparty) => {
    let formData = prepareMultipartRequest(counterparty);
    let response = await backend(`/api/counterparties` + (counterparty.id ? "/" + counterparty.id : ""), counterparty.id ? "PUT" : "POST", {
        body: formData,
        upload: true,
    });
    return response.body;
};

function wait(delay = 0) {
    return new Promise((resolve) => {
        setTimeout(resolve, delay);
    });
}

//actions TODO
export const fetchCounterpartyOptions = async (filter, counterpartyId) => {
    let response = await backend("/api/counterparties", "GET", { params: { filter: filter || "" }, paging: { size: 100 } });
    return response.body.content; // { ...emptyPage, content: [...mockedEmployees] };
};

export const fetchTransactionsSummary = async (cptyId, from, to) => {
    let response = await backend(`/api/counterparties/${cptyId}/transactions/summary`, "GET", { params: { from, to } });
    return response.body;
};

export const fetchBillings = async (cptyId, from, to, paging) => {
    let response = await backend(`/api/counterparties/${cptyId}/billings`, "GET", { params: { from, to, ...(paging || {}) } });
    return response.body;
};

export const fetchBillingsSummary = async (cptyId, from, to) => {
    let response = await backend(`/api/counterparties/${cptyId}/billings/summary`, "GET", { params: { from, to } });
    return response.body;
};

export const fetchClientsBillingSummary = async (from, to) => {
    let response = await backend("/api/counterparties/summary/billings", "GET", { params: { from, to } });
    return response.body; // { ...emptyPage, content: [...mockedProjects] };
};

export const fetchClientsTransactionsSummary = async (from, to) => {
    let response = await backend("/api/counterparties/summary/transactions", "GET", { params: { from, to } });
    return response.body; // { ...emptyPage, content: [...mockedProjects] };
};

export const fetchClientsTimesheetsSummary = async (from, to) => {
    let response = await backend("/api/counterparties/summary/timesheets", "GET", { params: { from, to } });
    return response.body; // { ...emptyPage, content: [...mockedProjects] };
};

export const fetchClientBillingsSummary = async (counterpartyId, from, to) => {
    let response = await backend(`/api/counterparties/${counterpartyId}/billings/summary`, "GET", { params: { from, to } });
    return response.body; // { ...emptyPage, content: [...mockedProjects] };
};

export const fetchClientBillingsSummaryTrends = async (counterpartyId, date, periodType) => {
    let response = await backend(`/api/counterparties/${counterpartyId}/billings/trends`, "GET", { params: { date, periodType } });
    return response.body; // { ...emptyPage, content: [...mockedProjects] };
};

export const fetchClientTransactionsSummary = async (counterpartyId, from, to) => {
    let response = await backend(`/api/counterparties/${counterpartyId}/transactions/summary`, "GET", { params: { from, to } });
    return response.body; // { ...emptyPage, content: [...mockedProjects] };
};

export const fetchClientTransactionsSummaryTrends = async (counterpartyId, date, periodType) => {
    let response = await backend(`/api/counterparties/${counterpartyId}/transactions/trends`, "GET", { params: { date, periodType } });
    return response.body; // { ...emptyPage, content: [...mockedProjects] };
};

export const fetchClientTransactionsTypes = async (counterpartyId, from, to) => {
    let response = await backend(`/api/counterparties/${counterpartyId}/transactions/types`, "GET", { params: { from, to } });
    return response.body; // { ...emptyPage, content: [...mockedProjects] };
};

export const fetchCounterpartyTimesheetCalendar = async (counterpartyId, from, to, periodType, attachments, filter) => {
    let response = await backend(`/api/counterparties/${counterpartyId}/timesheets/calendar`, "GET", {
        params: { ...populateFilterParams(filter), from, to, periodType, attachments },
    });
    return response.body;
};

export const fetchCounterpartyTimesheetItems = async (counterpartyId, from, to, filter, paging) => {
    let response = await backend(`/api/counterparties/${counterpartyId}/timesheets/items`, "GET", {
        params: { ...populateFilterParams(filter), from, to, ...(paging || {}) },
    });
    return response.body;
};

export const fetchCounterpartyTimesheetChart = async (counterpartyId, from, to, type, filter) => {
    let response = await backend(`/api/counterparties/${counterpartyId}/timesheets/chart`, "GET", {
        params: { ...populateFilterParams(filter), from, to, type },
    });
    return response.body;
};

export const fetchTransactionCounterpartiesChart = async (from, to) => {
    let response = await backend(`/api/counterparties/overview/transactions/chart`, "GET", { params: { from, to } });
    return response.body;
};

export const fetchTransactionCounterpartiesData = async (from, to, paging) => {
    let response = await backend(`/api/counterparties/overview/transactions`, "GET", { params: { from, to, ...(paging || {}) } });
    return response.body;
};

export const fetchTransactionCounterpartiesStatuses = async (counterpartyId, from, to, date) => {
    let response = await backend(`/api/counterparties/${counterpartyId}/transactions/statuses`, "GET", { params: { from, to, date } });
    return response.body;
};

export const fetchBillingsCounterpartiesChart = async (from, to) => {
    let response = await backend(`/api/counterparties/overview/billings/chart`, "GET", { params: { from, to } });
    return response.body;
};

export const fetchBillingsCounterpartiesData = async (from, to, paging) => {
    let response = await backend(`/api/counterparties/overview/billings`, "GET", { params: { from, to, ...(paging || {}) } });
    return response.body;
};

export const fetchOverviewActiveProjectsChart = async (from, to) => {
    let response = await backend(`/api/counterparties/overview/projects/chart`, "GET", { params: { from, to } });
    return response.body;
};

export const fetchOverviewActiveProjectsData = async (from, to, paging) => {
    let response = await backend(`/api/counterparties/overview/projects`, "GET", { params: { from, to, ...(paging || {}) } });
    return response.body;
};

export const fetchOverviewTimesheetsChart = async (from, to) => {
    let response = await backend(`/api/counterparties/overview/timesheets/chart`, "GET", { params: { from, to } });
    return response.body;
};

export const fetchOverviewTimesheetsData = async (from, to, paging) => {
    let response = await backend(`/api/counterparties/overview/timesheets`, "GET", { params: { from, to, ...(paging || {}) } });
    return response.body;
};

export const fetchCounterpartiesTransactionStatuses = async (from, to, date) => {
    let response = await backend(`/api/counterparties/overview/transactions/statuses`, "GET", { params: { from, to, date } });
    return response.body;
};
