import React, {useContext, useEffect, useMemo} from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import LockIcon from "@mui/icons-material/Lock";

import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";
import Autocomplete from "../Autocomplete";

//TODO implement edit and new forms properly
import {AttachmentChips, AttachmentContainer, AttachmentContext, AttachmentSectionField} from "../Attachments";
import {TagChips, TagsSectionField} from "../Tags";
import {Section} from "../Containers";

import {EmployeeBillingTransactionFields, EmployeeBillingTypeFields} from "../billing";

import {debounce} from "lodash";
import backend, {prepareMultipartRequest} from "../../utils/backend";
import {avatarString} from "../../utils/string";

import {useForm} from "../../utils/form";
import {useLoadingIndicator} from "../../utils/loading";
import Modal from "../Modal";

export const EmployeeSelect = ({ value, onChange, fullWidth = true, ...props }) => {
    return (
        <Autocomplete
            fullWidth={fullWidth}
            {...props}
            fetchOptions={fetchEmployeesOptions}
            getOptionLabel={(option) => option.name}
            value={value || null}
            onChange={(e, val) => onChange && onChange(val)}
        />
    );
};

export const EmployeeListFilter = ({ disabled, onFilterChange, form, ...props }) => {
    const { values = {}, setValues } = form || {};
    const fetch = useMemo(
        () =>
            debounce(
                (filter) => {
                    try {
                        onFilterChange && onFilterChange(filter);
                    } catch (e) {
                        console.error(e);
                    }
                },
                1000,
                { leading: false, trailing: true }
            ),
        []
    );

    const handleChange = (values) => {
        setValues(values);
        fetch(values);
    };

    return (
        <div>
            <FormGroup sx={{ px: 1 }}>
                <FormControlLabel
                    control={
                        <Switch
                            color="secondary"
                            checked={values.includeReadOnly || false}
                            onChange={(e) => handleChange({ ...values, includeReadOnly: e.target.checked })}
                            name="includeReadOnly"
                            value={true}
                            disabled={disabled}
                            size="small"
                        />
                    }
                    label={<Typography variant="caption">Včetně archivovaných</Typography>}
                />
            </FormGroup>
            <TextField
                value={values.filter || ""}
                onChange={(e) => handleChange({ ...values, filter: e.target.value })}
                size="small"
                fullWidth
                margin="normal"
            />
        </div>
    );
};

export const EmployeeList = ({ disabled, hasMore, onShowMoreClick, data, onItemClick, selectedId }) => {
    return (
        <>
            <List>
                {data &&
                    data.map((item) => (
                        <ListItem
                            key={item.id}
                            button
                            divider
                            onClick={() => onItemClick && onItemClick(item)}
                            selected={selectedId == item.id}
                            disabled={disabled}
                        >
                            <ListItemAvatar>
                                <Avatar alt={item.name}>
                                    {item.readOnly ? (
                                        <LockIcon />
                                    ) : (
                                        item.name && item.name.length && item.name.substring(0, Math.min(2, item.name.length)).toUpperCase()
                                    )}
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={item.name} />
                        </ListItem>
                    ))}
            </List>
            {!!hasMore && (
                <Box sx={{ p: 2 }}>
                    <Button onClick={onShowMoreClick} disabled={disabled}>
                        načíst další...
                    </Button>
                </Box>
            )}
        </>
    );
};

export const EmployeeFormFields = ({ employee, form, editMode = true, disabled, readOnly }) => {
    const { values = {}, handleChange, setValues, errors } = form || {};
    const onAddAttachment = useContext(AttachmentContext);

    return (
        <>
            <Box mb={4} px={2}>
                <TextField
                    fullWidth
                    label="Jméno"
                    margin="normal"
                    name="firstName"
                    value={values.firstName || ""}
                    onChange={handleChange}
                    disabled={disabled}
                    required
                    error={!!errors.firstName}
                    helperText={errors.firstName}
                />
                <TextField
                    fullWidth
                    label="Příjmení"
                    margin="normal"
                    name="lastName"
                    value={values.lastName || ""}
                    onChange={handleChange}
                    disabled={disabled}
                    required
                    error={!!errors.lastName}
                    helperText={errors.lastName}
                />
            </Box>
            <Box mb={4}>
                {/*
                <SwitchSection
                    title="Přístup do systému"
                    px={2}
                    open={values.accountEnabled || false}
                    setOpen={(accountEnabled) => setValues({ ...values, accountEnabled })}
                    disabled={disabled}
                >
                    <TextField
                        fullWidth
                        label="E-mail"
                        margin="normal"
                        disabled={!!disabled || !values.accountEnabled}
                        name="email"
                        value={values.email || ""}
                        onChange={handleChange}
                        required={!!values.accountEnabled}
                        error={!!errors.email}
                        helperText={errors.email}
                    />
                    <PasswordField
                        fullWidth
                        label="Heslo"
                        margin="normal"
                        disabled={!!disabled || !values.accountEnabled}
                        name="password"
                        value={values.password || ""}
                        onChange={handleChange}
                        required={!!values.accountEnabled && !values.id}
                        error={!!errors.password}
                        helperText={errors.password}
                    />
                </SwitchSection>
                */}
                <Section title="Účtování" px={2}>
                    <EmployeeBillingTypeFields currencyCode="CZK" form={form} itemsBillingTypeKey="defaultBillingType" />
                    <EmployeeBillingTransactionFields currencyCode="CZK" form={form} />
                </Section>

                <AttachmentSectionField
                    value={values.attachments}
                    onChange={(attachments) => setValues({ ...values, attachments })}
                    disabled={disabled}
                    readOnly={readOnly}
                    onAddClick={onAddAttachment}
                />

                <TagsSectionField value={values.tags} onChange={(tags) => setValues({ ...values, tags })} disabled={disabled} readOnly={readOnly} />
            </Box>
        </>
    );
};

export const EmployeeModal = ({ data, onClose, onDataSaved, ...props }) => {
    const { startLoading, stopLoading, isLoading } = useLoadingIndicator();
    const onSubmit = useMemo(
        () => async (values) => {
            startLoading();
            try {
                const name = [];
                if (values.lastName) {
                    name.push(values.lastName);
                }
                if (values.firstName) {
                    name.push(values.firstName);
                }
                values.name = name.join(" ");

                values = await saveEmployee(values);
                onClose && onClose();
                onDataSaved && onDataSaved(values);
            } catch (e) {
                alert(e);
                console.error(e);
            } finally {
                stopLoading();
            }
        },
        [onClose]
    );

    const form = useForm({ onSubmit });
    const { values, handleChange, errors, setErrors, handleSubmit, setValues } = form;

    useEffect(() => setValues(data || {}), [data]);

    return (
        <Modal
            {...props}
            onClose={onClose}
            size="big"
            buttons={[{ title: "Storno" }, { title: "Uložit", main: true, onClick: handleSubmit, disabled: isLoading }]}
        >
            <AttachmentContainer value={values.attachments} onChange={(attachments) => setValues({ ...values, attachments })}>
                <EmployeeFormFields form={form} disabled={isLoading} />
            </AttachmentContainer>
        </Modal>
    );
};

export const EmployeeBasicInfo = ({ data, disabled, readOnly }) => {
    return (
        <div style={{ textAlign: "center" }}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Avatar sx={{ width: 160, height: 160, mb: 5, fontSize: 72 }} alt={data.name}>
                    {avatarString(data.name)}
                </Avatar>
            </div>
            <Typography variant="h6" component="div">
                {data.name}
            </Typography>
            <Typography variant="body1" component="div" sx={{ whiteSpace: "pre" }}>
                {data.address}
            </Typography>
            {!!data.idNumber && (
                <Typography variant="body1" component="div">
                    IČ: {data.idNumber}
                </Typography>
            )}
            {!!data.taxIdNumber && (
                <Typography variant="body1" component="div">
                    DIČ: {data.taxIdNumber}
                </Typography>
            )}

            <TagChips value={data.tags} disabled={disabled} readOnly={readOnly} color="secondary" />
            <AttachmentChips value={data.attachments} disabled={disabled} readOnly={readOnly} color="secondary" />
        </div>
    );
};

//actions TODO
export const fetchEmployee = async (id) => {
    let response = await backend(`/api/employees/${id}`);
    return response.body;
};

export const fetchEmployees = async (filter, paging) => {
    let response = await backend("/api/employees", "GET", { params: { ...(filter || {}), ...(paging || {}) } });
    return response.body; // { ...emptyPage, content: [...mockedEmployees] };
};

export const fetchEmployeesOptions = async (filter) => {
    let response = await backend("/api/employees", "GET", { params: { filter: filter || "" }, paging: { size: 100 } });
    return response.body.content; // { ...emptyPage, content: [...mockedEmployees] };
};

export const saveEmployee = async (employee) => {
    let formData = prepareMultipartRequest(employee);
    let response = await backend(`/api/employees` + (employee.id ? "/" + employee.id : ""), employee.id ? "PUT" : "POST", {
        body: formData,
        upload: true,
    });
    return response.body;
};

export const lockEmployee = async (employeeId, lock) => {
    await backend(`/api/employees/${employeeId}/${lock ? "lock" : "unlock"}`, "POST");
};

export const fetchEmployeeTimesheetItems = async (employeeId, from, to, paging) => {
    let response = await backend(`/api/employees/${employeeId}/timesheets`, "GET", { params: { from, to, ...(paging || {}) } });
    return response.body;
};

export const fetchTimesheetEmployees = async (from, to) => {
    let response = await backend(`/api/employees/timesheets/employees`, "GET", { params: { from, to } });
    return response.body;
};

export const fetchTimesheetEmployeesChart = async (from, to) => {
    let response = await backend(`/api/employees/overview/timesheets/chart`, "GET", { params: { from, to } });
    return response.body;
};

export const fetchTimesheetEmployeesData = async (from, to, paging) => {
    let response = await backend(`/api/employees/overview/timesheets`, "GET", { params: { from, to, ...(paging || {}) } });
    return response.body;
};

export const fetchTimesheetPeriodSummary = async (date, type) => {
    let response = await backend(`/api/employees/overview/timesheets/periods`, "GET", { params: { date, type } });
    return response.body;
};

export const fetchTimesheetBillingSummary = async (from, to) => {
    let response = await backend(`/api/employees/timesheets/summary/billings`, "GET", { params: { from, to } });
    return response.body;
};

export const fetchBillingEmployeesChart = async (from, to) => {
    let response = await backend(`/api/employees/overview/billings/chart`, "GET", { params: { from, to } });
    return response.body;
};

export const fetchBillingEmployeesData = async (from, to, paging) => {
    let response = await backend(`/api/employees/overview/billings`, "GET", { params: { from, to, ...(paging || {}) } });
    return response.body;
};

export const fetchBillingPeriodSummary = async (date, type) => {
    let response = await backend(`/api/employees/overview/billings/periods`, "GET", { params: { date, type } });
    return response.body;
};

//MOCK - FIXME
const emptyPage = {
    content: [],
    totalElements: 0,
    totalPages: 0,
    number: 0,
};
