import React from "react";

import {useTheme} from "@mui/material/styles";
import {makeStyles} from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import Box from "@mui/material/Box";
import MoreIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import ListItemIcon from "@mui/material/ListItemIcon";

const useActionStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
}));

const ActionsSmall = ({ items, classes, disabled }) => {
    const visible = [];
    const aggregated = {
        icon: <MoreIcon />,
        badgeContent: 0,
        items: [],
    };

    items.forEach((item) => {
        if (!!item.alwaysVisible) {
            visible.push(item);
        } else {
            aggregated.badgeContent += item.badgeContent || 0;
            aggregated.items.push(item);
        }
    });

    return (
        <Box className={classes.menuItems}>
            {visible.map((item, idx) => (
                <PageTitleMenuItem item={item} key={idx} isCompact disabled={disabled} />
            ))}
            {!!aggregated.items.length && <PageTitleMenuItem item={aggregated} isCompact disabled={disabled} />}
        </Box>
    );
};

const Actions = ({ items, disabled, breakpoint = "sm" }) => {
    const classes = useActionStyles();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down(breakpoint || "sm"));

    if (!Array.isArray(items)) {
        return null;
    }

    if (isSmall) {
        return <ActionsSmall items={items} disabled={disabled} classes={classes} />;
    }

    return (
        <>
            <Box className={classes.root}>{!!items && items.map((item, idx) => <PageTitleMenuItem item={item} key={idx} disabled={disabled} />)}</Box>
        </>
    );
};

export default Actions;

const PageTitleMenuItem = ({ item, isCompact, id, disabled }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const hasMenu = typeof item.renderContent === "function" || (item.items && item.items.length);

    const handleClick = (e) => {
        if (hasMenu) {
            setAnchorEl(e.currentTarget);
        }
        item.onClick && item.onClick();
    };

    const handleClose = () => setAnchorEl(null);

    return (
        <>
            {item.render ? (
                item.render({ isCompact, id, disabled, item })
            ) : (
                <IconButton color="inherit" onClick={handleClick} title={item.title} disabled={disabled} size={isCompact ? "small" : "medium"}>
                    {!!item.badgeContent && !isNaN(item.badgeContent) ? (
                        <Badge badgeContent={item.badgeContent * 1} color="error" variant={item.badgeVariant || undefined}>
                            {item.icon}
                        </Badge>
                    ) : (
                        item.icon
                    )}
                </IconButton>
            )}
            {hasMenu && (
                <Menu
                    anchorEl={anchorEl}
                    open={!!anchorEl && !disabled}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            overflow: "visible",
                            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                            mt: 1.5,
                            "&:before": {
                                content: '""',
                                display: "block",
                                position: "absolute",
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: "background.paper",
                                transform: "translateY(-50%) rotate(45deg)",
                                zIndex: 0,
                            },
                        },
                    }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                    {!!item.renderContent && (
                        <MenuItem divider={item.items && item.item.length}>{item.renderContent({ isCompact, id, disabled, item })}</MenuItem>
                    )}
                    {!!item.items &&
                        item.items.map((i, key) =>
                            !!i.render ? (
                                <MenuItem key={key} divider={key < item.items.length - 1} disabled={disabled}>
                                    {i.render({ isCompact, id, disabled, item })}
                                </MenuItem>
                            ) : (
                                <MenuItem key={key} onClick={i.onClick} disabled={disabled}>
                                    <ListItemIcon>
                                        {!!i.badgeContent ? (
                                            <Badge badgeContent={i.badgeContent} color="error" variant={i.badgeVariant || undefined}>
                                                {i.icon}
                                            </Badge>
                                        ) : (
                                            i.icon
                                        )}
                                    </ListItemIcon>
                                    {i.title}
                                </MenuItem>
                            )
                        )}
                </Menu>
            )}
        </>
    );
};
