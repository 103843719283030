import React from "react";

import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import {makeStyles} from "@mui/styles";

const useLinkClasses = makeStyles((theme) => ({
    root: {
        textDecoration: "underline",
        color: theme.palette.text.disabled,
    },
}));

const CustomLink = ({ variant, disabled, children, onClick, target, ...props }) => {
    const classes = useLinkClasses();
    if (!!disabled || !onClick) {
        return (
            <Typography color="textDisabled" component="span" className={classes.root}>
                {children}
            </Typography>
        );
    }
    return (
        <Link
            style={{ cursor: "pointer" }}
            variant={variant || "inherit"}
            href={target || "#"}
            {...props}
            onClick={(e) => {
                e.preventDefault();
                onClick && onClick(e);
            }}
        >
            {children}
        </Link>
    );
};

export default CustomLink;
