import React, {useContext, useEffect, useMemo, useState} from "react";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import Autocomplete from "../Autocomplete";
import LockIcon from "@mui/icons-material/Lock";

import backend, {download, prepareMultipartRequest} from "../../utils/backend";
import {debounce} from "lodash";
import {useForm} from "../../utils/form";
import {useSettings} from "../../utils/settings";

import {CounterpartySelect, fetchCounterpartyProject} from "../counterparties";
import {AttachmentChips, AttachmentContainer, AttachmentContext, AttachmentSectionField} from "../Attachments";
import {TagChips, TagsSectionField} from "../Tags";

import {LoadingIndicator, LoadingIndicatorContainer, useLoadingIndicator} from "../../utils/loading";
import Modal from "../Modal";
import {DetailRow, FlexItem, FlexRow, SwitchSection} from "../Containers";
import {useAlert} from "../Alert";
import {formatCurrency, formatNum} from "../NumberFields";
import Link from "../Link";
import Value from "../Value";
import {DatePicker} from "../DateFields";
import {ListContainer, ListRow} from "../Layout";
import {parseErrors} from "../../applicationErrors";
import {FieldErrors} from "../../utils/errors";

const avatarString = (s) => {
    return s && s.length && s.substring(0, Math.min(2, s.length)).toUpperCase();
};

/*
    isOptionEqualToValue={(option, value) => option.title === value.title}
    getOptionLabel={(option) => option.title}
*/

function sleep(delay = 0) {
    return new Promise((resolve) => {
        setTimeout(resolve, delay);
    });
}

export const ProjectSelect = ({ value, onChange, fullWidth = true, multiple, employeeId, clientId, ...props }) => {
    return (
        <Autocomplete
            fullWidth={fullWidth}
            {...props}
            fetchOptions={(filter) => fetchProjectsOptions(filter, employeeId, clientId)}
            getOptionLabel={(option) => option.name}
            value={value || null}
            onChange={(e, val) => onChange && onChange(val)}
            multiple={!!multiple}
        />
    );
};

export const ProjectListFilter = ({ disabled, onFilterChange, form, ...props }) => {
    const { values = {}, setValues } = form || {};

    const fetch = useMemo(
        () =>
            debounce(
                (filter) => {
                    try {
                        onFilterChange && onFilterChange(filter);
                    } catch (e) {
                        console.error(e);
                    }
                },
                1000,
                { leading: false, trailing: true }
            ),
        []
    );

    const handleChange = (values) => {
        setValues(values);
        fetch(values);
    };

    return (
        <div>
            <FormGroup sx={{ px: 1 }}>
                <FormControlLabel
                    control={
                        <Switch
                            color="secondary"
                            checked={values.includeReadOnly || false}
                            onChange={(e) => handleChange({ ...values, includeReadOnly: e.target.checked })}
                            name="includeReadOnly"
                            value={true}
                            disabled={disabled}
                            size="small"
                        />
                    }
                    label={<Typography variant="caption">Včetně uzavřených</Typography>}
                />
            </FormGroup>
            <TextField
                value={values.filter || ""}
                onChange={(e) => handleChange({ ...values, filter: e.target.value })}
                size="small"
                fullWidth
                margin="normal"
            />
        </div>
    );
};

export const ProjectList = ({ disabled, data, onItemClick, hasMore, onShowMoreClick, selectedId }) => {
    return (
        <>
            <ListContainer>
                {data.map((p) => (
                    <ListRow
                        key={p.id}
                        avatar={p.name}
                        icon={p.readOnly ? <LockIcon /> : null}
                        mainTitle={p.name}
                        description={p.clientName}
                        onClick={() => onItemClick && onItemClick(p)}
                        selected={selectedId == p.id}
                        disabled={disabled}
                    />
                ))}
            </ListContainer>
            {!!hasMore && (
                <Box sx={{ p: 2 }}>
                    <Button onClick={onShowMoreClick} disabled={disabled}>
                        načíst další...
                    </Button>
                </Box>
            )}
        </>
    );
};

export const ProjectForm = ({ task, editMode = true }) => {
    return <div></div>;
};

export const ProjectFormFields = ({ employee, form, editMode = true, disabled, readOnly, hideClient }) => {
    const { values = {}, handleChange, setValues, errors } = form || {};
    const onAddAttachment = useContext(AttachmentContext);

    return (
        <>
            <Box mb={4} px={2}>
                <TextField
                    fullWidth
                    label="Název"
                    margin="normal"
                    value={values.name || ""}
                    onChange={handleChange}
                    name="name"
                    disabled={disabled}
                    required
                    error={!!errors.name}
                />
                <FieldErrors errors={errors} field="name" />
                {!!hideClient ? null : (
                    <>
                        <CounterpartySelect
                            label="Klient"
                            margin="normal"
                            disabled={disabled}
                            fullWidth
                            value={{ id: values.clientId, name: values.clientName || "" }}
                            onChange={(cpty) => {
                                let vals = { ...values };
                                if (cpty) {
                                    vals.clientId = cpty.id;
                                    vals.clientName = cpty.name;
                                } else {
                                    delete vals.clientId;
                                    delete vals.clientName;
                                }
                                setValues(vals);
                            }}
                            error={!!errors.client}
                        />
                        <FieldErrors errors={errors} field="client" />
                    </>
                )}
            </Box>
            <Box mb={4}>
                <SwitchSection
                    title="Uzavřít automaticky"
                    px={2}
                    open={values.autoReadOnly || false}
                    setOpen={(autoReadOnly) => setValues({ ...values, autoReadOnly })}
                    disabled={disabled}
                >
                    <FormHelperText>
                        Projekt bude automaticky uzavřen mimo stanovené období. Toto nastavení se zruší, bude-li projekt uzavřen/otevřen manuálně
                    </FormHelperText>

                    <DatePicker
                        fullWidth
                        label="Datum od"
                        margin="normal"
                        clearable
                        clearText="Vymazat"
                        value={values.dateFrom}
                        onChange={(dateFrom) => setValues({ ...values, dateFrom })}
                        disabled={disabled}
                        error={!!errors.dateFrom}
                        helperText={errors.dateFrom}
                    />
                    <DatePicker
                        fullWidth
                        label="Datum do"
                        margin="normal"
                        clearable
                        clearText="Vymazat"
                        value={values.dateTo}
                        onChange={(dateTo) => setValues({ ...values, dateTo })}
                        disabled={disabled}
                        error={!!errors.dateTo}
                        helperText={errors.dateTo}
                    />
                </SwitchSection>

                <AttachmentSectionField
                    value={values.attachments}
                    onChange={(attachments) => setValues({ ...values, attachments })}
                    disabled={disabled}
                    readOnly={readOnly}
                    onAddClick={onAddAttachment}
                />

                <TagsSectionField value={values.tags} onChange={(tags) => setValues({ ...values, tags })} disabled={disabled} readOnly={readOnly} />
            </Box>
        </>
    );
};

export const ProjectModal = ({ title, data, open, onClose, onDataSaved, size = "big", hideClient, ...props }) => {
    const { startLoading, stopLoading, isLoading } = useLoadingIndicator();

    const onSubmit = useMemo(
        () => async (values) => {
            values = await saveProject(values);
            onClose && onClose();
            onDataSaved && onDataSaved(values);
        },
        [onClose, onDataSaved]
    );

    const form = useForm({ onSubmit, parseErrors });

    const { values, handleChange, errors, setErrors, handleSubmit, setValues, valuesRef } = form;

    useEffect(() => setValues(data || {}), [data]);

    return (
        <Modal
            open={open}
            onClose={onClose}
            title={title}
            size={size}
            buttons={[{ title: "Storno" }, { title: "Uložit", main: true, onClick: handleSubmit, disabled: isLoading }]}
        >
            <AttachmentContainer value={values.attachments} onChange={(attachments) => setValues({ ...values, attachments })}>
                <ProjectFormFields form={form} disabled={isLoading} hideClient={hideClient} />
            </AttachmentContainer>
        </Modal>
    );
};

const activeValue = (val, yes, no) => {
    let activeValue = null;
    if (val === true) {
        activeValue = yes;
    } else if (val === false) {
        activeValue = no;
    }
    return activeValue;
};

const ProjectListRowDetail = ({ clientId, projectId, period, onProjectDetailClick, settings = {}, attachmentUrl }) => {
    const [data, setData] = useState({});
    const { isLoading, startLoading, stopLoading } = useLoadingIndicator();

    const loadData = async () => {
        startLoading();
        let data = {};
        try {
            data = await fetchCounterpartyProject(clientId, projectId, period && period.start, period && period.end);
        } catch (e) {
            console.error(e);
            alert("error");
        } finally {
            stopLoading();
            setData(data);
        }
    };

    useEffect(() => loadData(), [projectId, period]);

    return (
        <>
            <LoadingIndicator color="primary" />
            <Box p={2}>
                <Box mb={2}>
                    {!!data.description && (
                        <Box mb={4}>
                            <Value label="Popis projektu" value={data.description} />
                        </Box>
                    )}
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={6} md={12} lg={6} xl={4}>
                            <Value label="Uzavřený projekt" value={activeValue(data.readOnly, "Ano", "Ne")} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={12} lg={6} xl={4}>
                            <Value label="Aktivní projekt (v období)" value={activeValue(data.active, "Ano", "Ne")} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={12} lg={6} xl={4}>
                            <Value
                                label="Zbývá vyúčtovat"
                                value={`${formatNum(
                                    data.unbilledPercentage || 0,
                                    settings.decimalDigits,
                                    settings.decimalDelimiter,
                                    settings.thousandDelimiter
                                )}%`}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={12} lg={6} xl={4}>
                            <Value
                                label="Odpracováno hodin za období/celkem"
                                value={`${formatNum(data.hours || 0, settings.decimalDigits, settings.decimalDelimiter, settings.thousandDelimiter)}h`}
                                secondaryValue={`${formatNum(
                                    data.hoursTotal || 0,
                                    settings.decimalDigits,
                                    settings.decimalDelimiter,
                                    settings.thousandDelimiter
                                )}h`}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={12} lg={6} xl={4}>
                            <Value
                                label="Zisk za období/celkem"
                                value={formatCurrency(
                                    data.profit || 0,
                                    data.currencyCode,
                                    settings.currencies,
                                    settings.decimalDigits,
                                    settings.decimalDelimiter,
                                    settings.thousandDelimiter
                                )}
                                secondaryValue={formatCurrency(
                                    data.profitTotal || 0,
                                    data.currencyCode,
                                    settings.currencies,
                                    settings.decimalDigits,
                                    settings.decimalDelimiter,
                                    settings.thousandDelimiter
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={12} lg={6} xl={4}>
                            <Value
                                label="Příjmy a výdaje za období/celkem"
                                value={formatCurrency(
                                    data.balance || 0,
                                    data.currencyCode,
                                    settings.currencies,
                                    settings.decimalDigits,
                                    settings.decimalDelimiter,
                                    settings.thousandDelimiter
                                )}
                                secondaryValue={formatCurrency(
                                    data.balanceTotal || 0,
                                    data.currencyCode,
                                    settings.currencies,
                                    settings.decimalDigits,
                                    settings.decimalDelimiter,
                                    settings.thousandDelimiter
                                )}
                            />
                        </Grid>
                    </Grid>
                </Box>
                {/*<pre>{JSON.stringify(data, null, 4)}</pre>*/}{" "}
                <AttachmentChips
                    value={data.attachments}
                    disabled={isLoading}
                    url={(a) => `/api/counterparties/${clientId}/projects/${data.id}/attachments/${a.id}`}
                    size="small"
                />
                {!!onProjectDetailClick && (
                    <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                        <Button
                            color="secondary"
                            variant="contained"
                            size="small"
                            onClick={() => onProjectDetailClick(data.id)}
                            disabled={isLoading || !data.id}
                        >
                            Detail
                        </Button>
                    </Stack>
                )}
            </Box>
        </>
    );
};

export const ProjectsList = ({
    title,
    buttons,
    data,
    disabled,
    readOnly,
    onAdd,
    onEdit,
    onDelete,
    withDetail,
    onProjectDetailClick,
    clientId,
    period,
    attachmentUrl,
}) => {
    const showAlert = useAlert();
    const [openState, setOpen] = useState({});
    const settings = useSettings();

    useEffect(() => {
        setOpen({});
    }, [clientId]);

    return (
        <div>
            {(!!title || !!buttons || !!onAdd) && (
                <Box mb={2}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                        <div>{title}</div>
                        {!readOnly && !!onAdd && (
                            <div>
                                <Stack direction="row" alignItems="center" spacing={2}>
                                    {buttons}
                                </Stack>
                            </div>
                        )}
                    </Stack>
                </Box>
            )}
            <div>
                {Array.isArray(data) &&
                    data.map((t) => {
                        return (
                            <DetailRow
                                key={t.id}
                                open={openState[t.id]}
                                setOpen={(open) => setOpen({ ...openState, [t.id]: open })}
                                noDetailPadding
                                renderDetail={() => (
                                    <LoadingIndicatorContainer>
                                        <ProjectListRowDetail
                                            clientId={clientId}
                                            projectId={t.id}
                                            period={period}
                                            onProjectDetailClick={onProjectDetailClick}
                                            settings={settings}
                                        />
                                    </LoadingIndicatorContainer>
                                )}
                            >
                                <FlexRow>
                                    <FlexItem relativeWidth={3}>
                                        <Typography variant="body1" color={t.readOnly ? "textSecondary" : "text"}>
                                            {t.name}
                                        </Typography>
                                        {!!t.readOnly ? <LockIcon sx={{ fontSize: 16, px: 0.5, color: "text.secondary" }} color="inherit" /> : null}
                                    </FlexItem>
                                    {/*<FlexItem right>
                                        <Typography variant="body1">
                                            <b></b>
                                        </Typography>
                                        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
                                            {!!onEdit && (
                                                <IconButton onClick={(e) => onEdit(t)} disabled={!!disabled} size="small">
                                                    <EditIcon />
                                                </IconButton>
                                            )}
                                            {!!onDelete && (
                                                <IconButton
                                                    onClick={() =>
                                                        showAlert("Smazat projekt", `Chcete smazat projekt ${t.name || t.description} ?`, [
                                                            { text: "Ne" },
                                                            {
                                                                text: "Ano",
                                                                onPress: () => {
                                                                    onDelete(t);
                                                                },
                                                            },
                                                        ])
                                                    }
                                                    disabled={!!disabled}
                                                    size="small"
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            )}
                                        </Stack>
                                    </FlexItem>*/}
                                </FlexRow>
                            </DetailRow>
                        );
                    })}
            </div>
        </div>
    );
};

export const ProjectBasicInfo = ({ data, disabled, readOnly, onClientClick }) => {
    return (
        <div style={{ textAlign: "center" }}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Avatar sx={{ width: 160, height: 160, mb: 5, fontSize: 72 }} alt={data.name}>
                    {avatarString(data.name)}
                </Avatar>
            </div>
            <Typography variant="h6" component="div">
                {data.name}
            </Typography>
            {!!data.clientName && (
                <Typography variant="body1" component="div">
                    <Link onClick={onClientClick ? () => onClientClick(data.clientId) : null}>{data.clientName}</Link>
                </Typography>
            )}

            {!!data.tags && !!data.tags.length && (
                <Box py={4}>
                    <TagChips value={data.tags} disabled={disabled} readOnly={readOnly} color="secondary" />
                </Box>
            )}

            {!!data.attachments && !!data.attachments.length && (
                <Box py={4}>
                    <AttachmentChips
                        value={data.attachments}
                        disabled={disabled}
                        readOnly={readOnly}
                        color="secondary"
                        url={(a) => `/api/projects/${data.id}/attachments/${a.id}`}
                        center
                    />
                </Box>
            )}
        </div>
    );
};

//actions TODO
export const fetchProject = async (id) => {
    let response = await backend(`/api/projects/${id}`);
    return response.body;
};

export const fetchProjects = async (filter, paging) => {
    let response = await backend("/api/projects", "GET", { params: { ...(filter || {}), ...(paging || {}) } });
    return response.body; // { ...emptyPage, content: [...mockedProjects] };
};

export const fetchProjectsBillingSummary = async (from, to) => {
    let response = await backend("/api/projects/summary/billings", "GET", { params: { from, to } });
    return response.body; // { ...emptyPage, content: [...mockedProjects] };
};

export const fetchProjectsTransactionSummary = async (from, to) => {
    let response = await backend("/api/projects/summary/transactions", "GET", { params: { from, to } });
    return response.body; // { ...emptyPage, content: [...mockedProjects] };
};

export const fetchProjectsTimesheetsSummary = async (from, to) => {
    let response = await backend("/api/projects/summary/timesheets", "GET", { params: { from, to } });
    return response.body; // { ...emptyPage, content: [...mockedProjects] };
};

export const fetchProjectsOptions = async (filter, employeeId, clientId) => {
    let response = await backend("/api/projects", "GET", { params: { filter: filter || "", employeeId, clientId }, paging: { size: 100 } });
    return response.body.content;
};

export const lockProject = async (projectId, lock) => {
    await backend(`/api/projects/${projectId}/${lock ? "lock" : "unlock"}`, "POST");
};

export const fetchProjectTimesheetItems = async (projectId, from, to, paging) => {
    let response = await backend(`/api/projects/${projectId}/timesheets`, "GET", { params: { from, to, ...(paging || {}) } });
    return response.body;
};

export const saveProject = async (project) => {
    let formData = prepareMultipartRequest(project);
    let response = await backend(`/api/projects` + (project.id ? "/" + project.id : ""), project.id ? "PUT" : "POST", {
        body: formData,
        upload: true,
    });
    return response.body;
};

export const fetchProjectTransactions = async (id, from, to, paging) => {
    let response = await backend(`/api/projects/${id}/transactions`, "GET", { params: { from, to, ...(paging || {}) } });
    return response.body;
};

export const fetchTransactionsSummary = async (projectId, from, to) => {
    let response = await backend(`/api/projects/${projectId}/transactions/summary`, "GET", { params: { from, to } });
    return response.body;
};

export const fetchTransactionsTrends = async (projectId, date, periodType) => {
    let response = await backend(`/api/projects/${projectId}/transactions/trends`, "GET", { params: { date, periodType } });
    return response.body;
};

export const fetchTransactionsTypes = async (projectId, from, to) => {
    let response = await backend(`/api/projects/${projectId}/transactions/types`, "GET", { params: { from, to } });
    return response.body;
};

export const fetchTransactionsSummaryFiscal = async (projectId, date) => {
    let response = await backend(`/api/projects/${projectId}/transactions/summary/fiscal`, "GET", { params: { date } });
    return response.body;
};

export const saveProjectBilling = async (projectId, billing) => {
    let response = await backend(`/api/projects/${projectId}/billings` + (billing.id ? "/" + billing.id : ""), billing.id ? "PUT" : "POST", {
        body: billing,
    });
    return response.body;
};

export const fetchProjectDocumentsSummary = async (projectId) => {
    let response = await backend(`/api/projects/${projectId}/documents/summary`);
    return response.body;
};

export const fetchProjectOverviewFinancialsData = async (from, to, paging) => {
    let response = await backend(`/api/projects/overview/financials`, "GET", { params: { from, to, ...(paging || {}) } });
    return response.body;
};

export const fetchProjectOverviewFinancialsChart = async (from, to, paging) => {
    let response = await backend(`/api/projects/overview/financials/chart`, "GET", { params: { from, to } });
    return response.body;
};

export const fetchProjectOverviewFinancialsChartProfit = async (from, to, paging) => {
    let response = await backend(`/api/projects/overview/financials/chart/profit`, "GET", { params: { from, to } });
    return response.body;
};

export const fetchProjectOverviewTimesheetsData = async (from, to, paging) => {
    let response = await backend(`/api/projects/overview/timesheets`, "GET", { params: { from, to, ...(paging || {}) } });
    return response.body;
};

export const fetchProjectOverviewTimesheetsChart = async (from, to, paging) => {
    let response = await backend(`/api/projects/overview/timesheets/chart`, "GET", { params: { from, to } });
    return response.body;
};

export const fetchProjectTransactionStatuses = async (projectId, from, to, date) => {
    let response = await backend(`/api/projects/${projectId}/transactions/statuses`, "GET", { params: { from, to, date } });
    return response.body;
};

export const fetchProjectTimesheetItemTemplatesPdf = async (projectId) => {
    await download(`/api/projects/${projectId}/timesheets/templates/pdf`);
};

function wait(delay = 0) {
    return new Promise((resolve) => {
        setTimeout(resolve, delay);
    });
}
