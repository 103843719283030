import React, {useEffect, useMemo, useState} from "react";

import {
    discardDocument,
    DocumentBasicInfo,
    DocumentList,
    DocumentListFilter,
    DocumentModal,
    fetchDocument,
    fetchDocuments,
    fetchDocumentsSummary,
    prepareDocumentTransactionData,
    ProcessDocumentModal,
} from "../components/documents";

import {useNavigate} from "react-router-dom";

import {useTheme} from "@mui/material/styles";

import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DashboardIcon from "@mui/icons-material/Dashboard";
import FilterListIcon from "@mui/icons-material/FilterList";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

import {
    MasterContent,
    MasterDetail,
    MasterDetailContent,
    useMasterDetail,
    useMasterDetailParams,
    useMasterDetailTabs,
    useMasterRefresh,
} from "../components/MasterDetail";
import {Page, PageTitle, Panel} from "../components/Layout";

import {useForm} from "../utils/form";
import {useLoadableList} from "../utils/lists";
import {LoadingIndicator, LoadingIndicatorContainer, useLoadingIndicator} from "../utils/loading";
import Link from "../components/Link";
import {CurrencyValue, formatCurrency} from "../components/NumberFields";
import {formatDate} from "../components/DateFields";

import {useModal} from "../components/Modal";

import Value from "../components/Value";
import {useAlert} from "../components/Alert";

import {ChartLabels, DonutChart} from "../components/Charts";
import {useSettings} from "../utils/settings";
import {usePeriod} from "../components/period";

const DocumentsMaster = ({ menuItems, showFilter, setShowFilter, filterRef }) => {
    const { showDetail, showOverview, showAdd, mobile, refreshMaster } = useMasterDetail();
    const filterForm = useForm({});
    const loadableList = useLoadableList();
    const params = useMasterDetailParams();
    const selectedId = params.tabName;

    const { startLoading, stopLoading, isLoading } = useLoadingIndicator();

    const handleFilterChange = (filter) => {
        fetchList(filter);
    };

    const fetchList = async (filter, paging) => {
        startLoading();
        try {
            const data = await fetchDocuments(filter, paging);
            loadableList.acceptData(data);
        } catch (e) {
            console.error(e);
        } finally {
            stopLoading();
        }
    };

    useEffect(() => {
        //fetch data
        fetchList(filterForm.values);
    }, []);

    useMasterRefresh(() => fetchList(filterForm.valuesRef.current));

    return (
        <>
            <Collapse in={showFilter}>
                <Box p={2} pb={0}>
                    <DocumentListFilter onFilterChange={handleFilterChange} form={filterForm} />
                </Box>
            </Collapse>
            <DocumentList
                disabled={isLoading}
                data={loadableList.data}
                hasMore={loadableList.size < loadableList.totalElements - 1}
                onShowMoreClick={() => fetchList(filterForm.values, loadableList.nextPageParams)}
                onItemClick={({ id }) => showDetail(id)}
                selectedId={selectedId}
            />
        </>
    );
};

const DocumentMasterContainer = ({ menuItems }) => {
    const { showDetail, showOverview, showAdd, mobile, refreshMaster } = useMasterDetail();
    const [showFilter, setShowFilter] = useState();
    const modal = useModal();

    //remember context menu items
    const masterMenuItems = useMemo(
        () => [
            {
                icon: <AddIcon />,
                title: "Přidat dokument",
                alwaysVisible: true,
                onClick: () => modal.showModal({}),
            },
            {
                icon: <FilterListIcon />,
                title: "Filtrovat",
                alwaysVisible: true,
                onClick: () => setShowFilter(!showFilter),
                badgeVariant: "dot",
                badgeContent: !showFilter,
            },
            { icon: <DashboardIcon />, title: "Přehled", onClick: showOverview },
        ],
        [showFilter]
    );

    const onDataSaved = useMemo(
        () => (values) => {
            showDetail(values.id);
            refreshMaster();
        },
        [refreshMaster, showDetail]
    );

    return (
        <Page>
            <LoadingIndicatorContainer>
                <LoadingIndicator hidden="smDown" />

                <PageTitle title="Dokumenty" menuItems={mobile ? [...masterMenuItems, ...menuItems] : masterMenuItems} />
                <LoadingIndicator hidden="smUp" />
                <MasterContent>
                    <DocumentsMaster menuItems={menuItems} showFilter={showFilter} setShowFilter={setShowFilter} />
                </MasterContent>
            </LoadingIndicatorContainer>
            <DocumentModal open={modal.open} onClose={modal.closeModal} onDataSaved={onDataSaved} title="Nový dokument" />
        </Page>
    );
};

const DocumentDetailContainer = ({ menuItems }) => {
    const settings = useSettings();
    const { hideDetail, refreshMaster } = useMasterDetail();
    const { detailId } = useMasterDetailParams();
    const [tab, setTab] = useMasterDetailTabs({ projects: true, billings: true, transactions: true, timesheets: true });
    const { startLoading, stopLoading, isLoading } = useLoadingIndicator();
    const modal = useModal();
    const [data, setData] = useState({});
    const periodRef = usePeriod();
    const [period, setPeriod] = periodRef;
    const theme = useTheme();
    const processModal = useModal();
    const showAlert = useAlert();
    const navigate = useNavigate();

    const loadDocument = async (detailId) => {
        startLoading();
        try {
            let data = await fetchDocument(detailId);
            setData(data);
        } catch (e) {
            alert("error");
            console.error(e);
        }
        stopLoading();
    };

    const onDataSaved = async (data) => {
        loadDocument(detailId);
        refreshMaster();
    };

    const detailMenuItems = useMemo(() => {
        const items = [];

        return [
            {
                alwaysVisible: false,
                title: "Upravit",
                icon: <EditIcon />,
                onClick: () => modal.showModal(prepareDocumentTransactionData(data)),
            },
            /*
            {
                icon: <RefreshIcon />,
                title: "Obnovit data",
                onClick: () => loadDocument(detailId),
            },
            */
            ...menuItems,
        ];
    }, [detailId, data]);

    useEffect(() => {
        if (!!detailId) {
            loadDocument(detailId);
        }
    }, [detailId, period]);

    return (
        <Page>
            <LoadingIndicator hidden="smDown" />
            <PageTitle title={data.name || "Protistrana"} menuItems={detailMenuItems} onBack={hideDetail} disabled={isLoading}></PageTitle>
            <LoadingIndicator hidden="smUp" />
            <MasterDetailContent>
                <Box px={2}>
                    <Grid container rowSpacing={3} columnSpacing={4}>
                        <Grid item sm={12} md={9}>
                            <Grid container spacing={4}>
                                <Grid item xs={12} md={6}>
                                    <Panel fullHeight>
                                        <Box mb={6}>
                                            <Value label="Název" value={data.name} />
                                            <Value label="Popis" value={data.description} />
                                        </Box>
                                        <Grid container columnSpacing={4}>
                                            <Grid item xs={6}>
                                                {!!data.employeeId && <Value label="Zadal" value={data.employeeName} />}
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Value label="Datum" value={formatDate(data.date, settings.dateFormat)} />
                                            </Grid>
                                        </Grid>
                                    </Panel>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Panel fullHeight>
                                        {!!data.projectId && (
                                            <Value label="Projekt">
                                                <Link onClick={() => navigate(`/projects/${data.projectId}`)}>{data.projectName}</Link>
                                            </Value>
                                        )}
                                        {!!data.accountingTransactionId && (
                                            <Value label="Transakce">
                                                <Link onClick={console.log}>
                                                    {data.accountingTransactionName} ({data.typeName})
                                                </Link>
                                            </Value>
                                        )}

                                        <Grid container columnSpacing={4}>
                                            <Grid item xs={6}>
                                                <Value label="Zpracováno" value={data.processed ? "Ano" : "Ne"} />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Value label="Datum zpracování" value={formatDate(data.processedDate, settings.dateFormat)} />
                                            </Grid>
                                        </Grid>

                                        <Value
                                            label="Celkem"
                                            align="right"
                                            value={formatCurrency(
                                                data.total,
                                                data.currencyCode,
                                                settings.currencies,
                                                settings.decimalDigits,
                                                settings.decimalDelimiter,
                                                settings.thousandDelimiter
                                            )}
                                            big
                                        />

                                        {!data.processed && (
                                            <Stack direction="row" spacing={4}>
                                                <Button
                                                    onClick={() =>
                                                        showAlert(
                                                            "Zahodit dokument",
                                                            'Doklad bude označen jako "zpracovaný", takže se přestane zobrazovat mezi dokumenty ke zpracování, ale nebude vytvořena žádná účetní transkace. Chcete pokračovat?',
                                                            [
                                                                {
                                                                    text: "Ano",
                                                                    onPress: async () => {
                                                                        startLoading();
                                                                        try {
                                                                            await discardDocument(detailId);
                                                                            onDataSaved();
                                                                        } catch (e) {
                                                                            console.error(e);
                                                                            alert("error");
                                                                        }
                                                                        stopLoading();
                                                                    },
                                                                },
                                                                { text: "Ne" },
                                                            ]
                                                        )
                                                    }
                                                >
                                                    Zahodit dokument
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    onClick={() => {
                                                        processModal.showModal(prepareDocumentTransactionData(data));
                                                    }}
                                                >
                                                    Zpracovat dokument
                                                </Button>
                                            </Stack>
                                        )}
                                    </Panel>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item sm={12} md={3}>
                            <DocumentBasicInfo data={data} />
                        </Grid>
                    </Grid>
                </Box>
            </MasterDetailContent>
            <DocumentModal open={modal.open} onClose={modal.closeModal} data={modal.data} onDataSaved={onDataSaved} title="Editace dokumentu" />
            <ProcessDocumentModal open={processModal.open} onClose={processModal.closeModal} data={processModal.data} onDataSaved={onDataSaved} />
        </Page>
    );
};

const DocumentsOverview = ({ menuItems }) => {
    const theme = useTheme();
    const { hideDetail, refreshMaster, mobile, showDetail } = useMasterDetail();
    const [summary, setSummary] = useState({});
    const periodRef = usePeriod();
    const [period, setPeriod] = periodRef;
    const { startLoading, stopLoading, isLoading } = useLoadingIndicator();
    const modal = useModal();

    const masterMenuItems = useMemo(
        () => [
            {
                icon: <AddIcon />,
                title: "Přidat dokument",
                alwaysVisible: true,
                onClick: () => modal.showModal({}),
            },

            {
                icon: <FilterListIcon />,
                title: "Filtrovat",
                alwaysVisible: true,
                onClick: console.log,
            },
            ...menuItems,
        ],
        [menuItems]
    );

    const fetchData = async () => {
        startLoading();
        let summary = {};
        try {
            let result = await Promise.allSettled([fetchDocumentsSummary()]);
            let dataResult = result[0];
            if (dataResult.status === "fulfilled") {
                summary = dataResult.value;
            }
        } catch (e) {
            alert("error");
            console.error(e);
        } finally {
            stopLoading();
            setSummary(summary);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const onDetailClick = (nv) => showDetail(nv.id);

    return (
        <>
            <LoadingIndicator hidden="smDown" />
            <PageTitle title="Přehled" menuItems={masterMenuItems} onBack={(mobile && hideDetail) || null} />
            <LoadingIndicator hidden="smUp" />
            <MasterDetailContent>
                <Box px={2} pb={4}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} lg={6}>
                            <Panel fullHeight>
                                <Typography variant="h6" gutterBottom>
                                    Počet nezpracovaných dokumentů
                                </Typography>
                                <Grid container spacing={4}>
                                    <Grid item xs={12} md={3}>
                                        <ChartLabels
                                            values={(summary.uprocessedProjectsCount && summary.uprocessedProjectsCount.values) || []}
                                            color={isLoading ? "#6A6A6A" : theme.palette.primary.main}
                                            renderValue={(nv) => nv.value}
                                            onClick={onDetailClick}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <DonutChart
                                            data={[
                                                ((summary.uprocessedProjectsCount && summary.uprocessedProjectsCount.values) || []).map((i) =>
                                                    Math.abs(i.value)
                                                ),
                                            ]}
                                            colors={isLoading ? ["#6A6A6A"] : [theme.palette.primary.main]}
                                            disabled={isLoading}
                                            height={230}
                                            thickness={8}
                                        />
                                    </Grid>
                                </Grid>
                            </Panel>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Panel fullHeight>
                                <Typography variant="h6" gutterBottom>
                                    Objem nezpracovaných dokumentů
                                </Typography>
                                <Grid container spacing={4}>
                                    <Grid item xs={12} md={3}>
                                        <ChartLabels
                                            values={(summary.uprocessedProjectsAmount && summary.uprocessedProjectsAmount.values) || []}
                                            color={isLoading ? "#6A6A6A" : theme.palette.secondary.main}
                                            renderValue={(nv) => <CurrencyValue value={nv.value} currencyCode={summary.currencyCode} />}
                                            onClick={onDetailClick}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <DonutChart
                                            data={[
                                                ((summary.uprocessedProjectsAmount && summary.uprocessedProjectsAmount.values) || []).map((i) =>
                                                    Math.abs(i.value)
                                                ),
                                            ]}
                                            colors={isLoading ? ["#6A6A6A"] : [theme.palette.secondary.main]}
                                            disabled={isLoading}
                                            height={230}
                                            thickness={8}
                                        />
                                    </Grid>
                                </Grid>
                            </Panel>
                        </Grid>
                    </Grid>
                </Box>
                <DocumentModal
                    open={modal.open}
                    onClose={modal.closeModal}
                    onDataSaved={() => {
                        fetchData();
                        refreshMaster();
                    }}
                    data={{}}
                    title="Nový dokument"
                />
            </MasterDetailContent>
        </>
    );
};

const DocumentsMasterDetail = ({ menuItems }) => {
    return (
        <MasterDetail
            masterElement={<DocumentMasterContainer menuItems={menuItems} />}
            detailElement={<DocumentDetailContainer menuItems={menuItems} />}
            overviewElement={<DocumentsOverview menuItems={menuItems} />}
            titleMenuItems={menuItems}
            detailHasBackground={false}
        />
    );
};

export default DocumentsMasterDetail;
