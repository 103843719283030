import React from "react";

import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import CalculateIcon from "@mui/icons-material/Calculate";
import IconButton from "@mui/material/IconButton";

import {useSettings} from "../utils/settings";

export const space = String.fromCharCode(160);

//TODO
export const formatNum = (n, decimalDigits, decimalDelimiter, groupSeparator) => {
    if (isNaN(n)) {
        return n;
    }

    let c = isNaN(decimalDigits) ? 2 : decimalDigits,
        d = decimalDelimiter || ",",
        t = groupSeparator || space,
        s = n < 0 ? "-" : "",
        i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c)))),
        j = i.length > 3 ? i.length % 3 : 0;
    //TODO optimize?
    return (
        s +
        (j ? i.substr(0, j) + t : "") +
        i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
        (c
            ? d +
              Math.abs(n - i)
                  .toFixed(c)
                  .slice(2)
            : "")
    );
};

export const NumberField = ({ unit, digits = 2, value, onChange, ...props }) => {
    let inputProps = {};
    if (!!unit) {
        inputProps.endAdornment = <InputAdornment position="end">{unit}</InputAdornment>;
    }

    return <TextField {...props} value={value || ""} onChange={onChange} inputProps={{ style: { textAlign: "right" } }} />;
};

export const CurrencyField = ({ currencyCode, digits = 2, onCalculate, calculateHint, ...props }) => {
    const settings = useSettings();
    let inputProps = { style: { textAlign: "right" } };
    let currency = (settings.currencies && settings.currencies[currencyCode]) || { symbol: currencyCode, decimalDigits: 2, symbolPosition: "left" };

    if (currency.symbolPosition == "left") {
        inputProps.startAdornment = <InputAdornment position="start">{currency.symbol}</InputAdornment>;
    }

    if (currency.symbolPosition != "left" || !!onCalculate) {
        inputProps.endAdornment = (
            <InputAdornment position="end">
                {currency.symbolPosition != "left" ? currency.symbol : null}
                {!!onCalculate ? (
                    <IconButton onClick={onCalculate} edge="end" title={calculateHint}>
                        <CalculateIcon />
                    </IconButton>
                ) : null}
            </InputAdornment>
        );
    }

    return <NumberField {...props} InputProps={inputProps} inputProps={{ style: { textAlign: "right" } }} />;
};

export const NumberValue = ({ value, unit, digits = 2 }) => {
    const settings = useSettings();
    if (!unit) {
        return <>{formatNum(value, digits, settings.decimalDelimiter, settings.thousandDelimiter)}</>;
    }
    return <>{`${formatNum(value, digits, settings.decimalDelimiter, settings.thousandDelimiter)}${space}${unit}`}</>;
};

export const formatCurrency = (value, currencyCode, currencySettings, decimalDigits = 2, decimalDelimiter = ",", groupSeparator = space) => {
    let currency = (currencySettings && currencySettings[currencyCode]) || { symbol: currencyCode, decimalDigits: 2, symbolPosition: "left" };

    let num = formatNum(value, decimalDigits, decimalDelimiter);
    if (!currency.symbol) {
        return num;
    }

    if (currency.symbolPosition == "left") {
        return `${currency.symbol}${space}${num}`;
    }

    return `${num}${space}${currency.symbol}`;
};

export const CurrencyValue = ({ value, currencyCode, perUnit, emptyValue = "", unit }) => {
    const settings = useSettings();
    let val =
        (value || value === 0) && !isNaN(value) ? formatNum(value, settings.decimalDigits, settings.decimalDelimiter, settings.thousandDelimiter) : emptyValue;

    if (!val) {
        return <></>;
    }

    let s = formatCurrency(value, currencyCode, settings.currencies, settings.decimalDigits, settings.decimalDelimiter, settings.thousandDelimiter);
    if (!!unit) {
        s = `${s}/${unit}`;
    }

    return <>{s}</>;
};

/*
//init default??

if (!global.formatCtx) {
    global.formatCtx = {};
}

const space = String.fromCharCode(160);
export const empty = "\u00a0";

const defaultCtx = {
    locale: "en",

    dateFormat: "DD.MM.YYYY",
    timeFormat: "HH:mm",

    thousandDelimiter: space,
    decimalDelimiter: ",",
    decimalDigits: 2,

    currencySymbolLeft: false
};
global.i18n = defaultCtx;

const space = String.fromCharCode(160);
export const empty = "\u00a0";

export const formatNum = (n, decimalDigits, decimalDelimiter, groupSeparator) => {
    if (isNaN(n)) {
        return "0";
    }

    let c = decimalDigits || 2,
        d = decimalDelimiter || ",",
        t = groupSeparator || space,
        s = n < 0 ? "-" : "",
        i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c)))),
        j = i.length > 3 ? i.length % 3 : 0;
    //TODO optimize?
    return (
        s +
        (j ? i.substr(0, j) + t : "") +
        i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
        (c
            ? d +
              Math.abs(n - i)
                  .toFixed(c)
                  .slice(2)
            : "")
    );
};

export const formatCurrency = (n, currency) => {
    if (!currency) {
        return formatAmount(n);
    }

    return formatAmount(n, currency.symbol, currency.symbolPosition, currency.decimalDigits);
};

export const formatAmount = (n, symbol = "", symbolPosition = "left", decimalDigits = 2, decimalDelimiter, groupSeparator) => {
    let s = "";
    if (symbolPosition == "right") {
        return `${formatNum(n || 0, decimalDigits, decimalDelimiter, groupSeparator)}${space}${symbol}`;
    }

    return symbol + space + formatNum(n, decimalDigits, decimalDelimiter, groupSeparator);
};

export const formatDate = (d, format, sourceFormat) => {
    return !d ? "" : moment(d, sourceFormat || "YYYY-MM-DD").format(format || "DD.MM.YYYY");
};

export const dateToString = (v, format) => {
    return v ? moment(v).format(format || "YYYY-MM-DD") : null;
};

export const parseNum = (n) => {
    if (n && isNaN(n) && n.length) {
        return n.replaceAll(",", ".").replaceAll(/\s/g, "");
    }
    return n;
};

export function formatFileSize(fileSizeInBytes) {
    var i = -1;
    var byteUnits = [" kB", " MB", " GB", " TB", "PB", "EB", "ZB", "YB"];
    do {
        fileSizeInBytes = fileSizeInBytes / 1024;
        i++;
    } while (fileSizeInBytes > 1024);

    return Math.max(fileSizeInBytes, 1).toFixed(1) + byteUnits[i];
}

export const parseTime = (v) => {
    return v ? moment(v, "HH:mm") : null;
};

export const formatTime = (v) => {
    return v ? moment(v).format("HH:mm") : null;
};

const timeRegexp = /[^\d]+/g;

export const normalizeTime = (val) => {
    if (!val) {
        return val;
    }

    let s = val.replace(/\s/, "");
    let groups = s.split(timeRegexp);
    if (!groups.length) {
        return val;
    }

    if (isNaN(groups[0])) {
        return val;
    }

    let hours = lpad(groups[0], 2, "0").substring(0, 2);
    let minutes = "00";
    if (groups.length > 1) {
        if (isNaN(groups[1])) {
            return val;
        }
        minutes = lpad(groups[1], 2, "0").substring(0, 2);
    }

    return `${hours}:${minutes}`;
};

function lpad(n, width, z) {
    z = z || "0";
    n = n + "";
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}
*/
