import React from "react";

import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import Actions from "./Actions";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";

const useEmptySectionStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: theme.spacing(8),
    },
    content: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        color: "#707070",
    },
    action: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        transition: "all .1s",
        background: "none",
        cursor: "pointer",
        border: "none",
        color: "#707070",
        "&:not([disabled]):hover": {
            color: "#242424",
        },
    },
}));

const EmptySection = ({
    mainIcon = <ChatBubbleOutlineIcon fontSize="inherit" />,
    mainTitle = "Prázdný seznam",
    subTitle = "Nemáme zde co zobrazit. Chcete něco přidat?",
    actions,
    size,
    loading,
    disabled,
}) => {
    const classes = useEmptySectionStyles();

    return (
        <div className={classes.root}>
            {!!loading ? (
                <CircularProgress />
            ) : (
                <div className={clsx({ [classes.content]: true })}>
                    <Typography variant="h1" component="div">
                        {mainIcon}
                    </Typography>
                    <Typography variant="h3" component="div" gutterBottom>
                        {mainTitle}
                    </Typography>
                    <Typography variant="h6" component="div" gutterBottom>
                        {subTitle}
                    </Typography>
                    {Array.isArray(actions) && <Actions items={actions} disabled={disabled} />}
                </div>
            )}
        </div>
    );
};

export default EmptySection;

export const EmptySectionAction = ({ title, icon, onClick }) => {
    const classes = useEmptySectionStyles();
    return (
        <button className={classes.action} type="button" onClick={onClick}>
            <div className={classes.icon}>{icon}</div>
            <div className={classes.actionTitle}>{title}</div>
        </button>
    );
};
