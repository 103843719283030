import React, { useContext, useEffect, useMemo, useState } from "react";

import backend, { download, prepareMultipartRequest } from "../../utils/backend";

import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

import Typography from "@mui/material/Typography";
import ListItemText from "@mui/material/ListItemText";
import Button from "@mui/material/Button";
import Link from "../Link";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import Collapse from "@mui/material/Collapse";

import MuiTable from "@mui/material/Table";
import MuiTableBody from "@mui/material/TableBody";
import MuiTableCell from "@mui/material/TableCell";
import MuiTableContainer from "@mui/material/TableContainer";
import MuiTableHead from "@mui/material/TableHead";
import MuiTableRow from "@mui/material/TableRow";

import AddIcon from "@mui/icons-material/Add";
import LockIcon from "@mui/icons-material/Lock";
import CallMadeIcon from "@mui/icons-material/CallMade";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import PrintIcon from "@mui/icons-material/Print";

import Paper from "@mui/material/Paper";

import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Pagination from "@mui/material/Pagination";

import SwitchField from "../SwitchField";
import Modal from "../Modal";
import ExpandButton from "../ExpandButton";

import { debounce } from "lodash";
import { useForm } from "../../utils/form";
import { useEditableList } from "../../utils/lists";
import { LoadingIndicator, LoadingIndicatorContainer, useLoadingIndicator } from "../../utils/loading";

import { useAlert } from "../Alert";
import { DatePicker, formatDate } from "../DateFields";
import { AttachmentChips, AttachmentContainer, AttachmentContext, AttachmentSectionField } from "../Attachments";
import { TagsSectionField } from "../Tags";
import { CounterpartySelect } from "../counterparties";
import DropDown from "../DropDown";
import { DetailRow, DetailRowMain, DetailRowValue, Section, TableContainer, TableRowContainer, TableRowMain, TableRowValue } from "../Containers";
import { CurrencyField, CurrencyValue, formatCurrency, NumberField, NumberValue } from "../NumberFields";
import Value from "../Value";

import { QRCodeSVG } from "qrcode.react";
import numbers from "../../utils/numbers";
import { ProjectSelect } from "../projects";
import { useSettings } from "../../utils/settings";
import { ChartLabels, DonutChart, ReactECharts } from "../Charts";
import { formatPeriodValue } from "../period";

import { ListContainer, ListRow, Page, PageTitle, Panel } from "../Layout";
import { MasterDetailContent, useMasterDetail } from "../MasterDetail";
import TrendsBarChart, { updatePeriodOnClick } from "../TrendsBarChart";
import { WidgetFlexible, WidgetRoot } from "../Widgets";
import { parseErrors } from "../../applicationErrors";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

const makeTransactionsSelectorStyles = makeStyles((theme) => ({
    transactionsRoot: {
        display: "flex",
        flexDirection: "column",
    },
    filterContainer: {
        flex: "0 0",
    },
    listContainer: {
        flex: "1 1 auto",
        border: "1px solid #CECECE",
        height: "100%",
        maxHeight: 500,
        minHeight: 250,
        overflowX: "hidden",
        overflowY: "auto",
        marginBottom: theme.spacing(2),
    },
}));

export const TransactionListFilter = ({ disabled, onFilterChange, form, ...props }) => {
    const { values = {}, setValues } = form || {};

    const fetch = useMemo(
        () =>
            debounce(
                (filter) => {
                    try {
                        onFilterChange && onFilterChange(filter);
                    } catch (e) {
                        console.error(e);
                    }
                },
                1000,
                { leading: false, trailing: true }
            ),
        []
    );

    const handleChange = (values) => {
        setValues(values);
        fetch(values);
    };

    return (
        <>
            <TextField
                value={values.filter || ""}
                onChange={(e) => handleChange({ ...values, filter: e.target.value })}
                size="small"
                fullWidth
                margin="normal"
            />
        </>
    );
};

export const TransactionList = ({ disabled, data, onItemClick, hasMore, onShowMoreClick, selectedId }) => {
    const settings = useSettings();

    return (
        <>
            <ListContainer>
                {data.map((data) => (
                    <ListRow
                        key={data.id}
                        avatar={data.direction === "CREDIT" ? "Odchozí platba" : "Příchozí platba"}
                        icon={data.readOnly ? <LockIcon /> : data.direction === "CREDIT" ? <ArrowDownwardIcon /> : <CallMadeIcon />}
                        upperTitle={data.typeName}
                        mainTitle={data.name}
                        value={formatCurrency(
                            data.total * (data.direction === "CREDIT" ? -1 : 1),
                            data.currencyCode,
                            settings.currencies,
                            settings.decimalDigits,
                            settings.decimalDelimiter,
                            settings.thousandDelimiter
                        )}
                        description={data.counterpartyName}
                        date={formatDate(data.date, settings.dateFormat)}
                        selected={selectedId == data.id}
                        onClick={() => !!onItemClick && onItemClick(data)}
                    />
                ))}
            </ListContainer>
            {!!hasMore && (
                <Box sx={{ p: 2 }}>
                    <Button onClick={onShowMoreClick} disabled={disabled}>
                        načíst další...
                    </Button>
                </Box>
            )}
        </>
    );
};

export const createTransactionTypeFeatures = (transactionsTaxSupport) => {
    let features = {
        hasProjectReference: "Reference na projekt",
        hasSubjectInfo: "Dodavatel",
        hasCpty: "Protistrana",
        hasItems: "Položky",
        hasIdentificator: "Identifikátor",
        hasDueDate: "Datum splatnosti",
        hasStatus: "Stav",
        affectsTaxBasis: "Ovlivňuje základ daně",
        hasPaymentInstructions: "Instrukce pro platbu",
        printable: "Export do PDF",
    };
    if (!!transactionsTaxSupport) {
        features.hasTax = "Daň na transakci";
    }
    return features;
};

const TransactionSwitch = ({ fieldKey, fieldLabel, handleChange, values, disabled }) => (
    <>
        <Divider />
        <Box px={3} my={1}>
            <SwitchField title={fieldLabel} value={values[fieldKey]} onChange={handleChange} name={fieldKey} disabled={disabled} />
        </Box>
    </>
);

export const TransactionTypeFormFields = ({ form, disabled, readOnly }) => {
    const { values = {}, handleChange, setValues, errors } = form || {};
    const settings = useSettings();
    let switchValues = useMemo(() => createTransactionTypeFeatures(settings.transactionsTaxSupport));
    return (
        <>
            <Box mb={4} px={3}>
                <TextField
                    fullWidth
                    label="Název"
                    margin="normal"
                    name="name"
                    value={values.name || ""}
                    onChange={handleChange}
                    disabled={disabled}
                    required
                    error={!!errors.name}
                    helperText={errors.name}
                />
                <TextField
                    fullWidth
                    label="Popis"
                    margin="normal"
                    name="description"
                    value={values.description || ""}
                    onChange={handleChange}
                    disabled={disabled}
                    error={!!errors.description}
                    helperText={errors.description}
                    multiline
                    rows={3}
                />
            </Box>
            <Box mb={4} px={3}>
                <ToggleButtonGroup
                    color="secondary"
                    value={values.direction}
                    exclusive
                    onChange={(e, direction) => setValues({ ...values, direction })}
                    disabled={disabled}
                >
                    <ToggleButton value="DEBIT" disabled={disabled}>
                        Příchozí platba
                    </ToggleButton>
                    <ToggleButton value="CREDIT" disabled={disabled}>
                        Odchozí platba
                    </ToggleButton>
                </ToggleButtonGroup>
            </Box>
            <Box mb={4}>
                {Object.keys(switchValues).map((fieldKey) => (
                    <TransactionSwitch
                        key={fieldKey}
                        fieldKey={fieldKey}
                        fieldLabel={switchValues[fieldKey] || ""}
                        values={values}
                        handleChange={handleChange}
                        disabled={disabled}
                    />
                ))}
                {!!values.hasPaymentInstructions ? (
                    <PaymentMethodsFormField value={values.paymentMethods} onChange={(paymentMethods) => setValues({ ...values, paymentMethods })} />
                ) : (
                    <Divider />
                )}
            </Box>
        </>
    );
};

export const TransactionTypeModal = ({ data, open, onClose, onDataSaved, ...props }) => {
    const { startLoading, stopLoading, isLoading } = useLoadingIndicator();
    const onSubmit = async (values) => {
        values = await saveTransactionType(values);
        onClose && onClose();
        onDataSaved && onDataSaved(values);
    };

    const form = useForm({ onSubmit, parseErrors });

    const { values, handleChange, errors, setErrors, handleSubmit, setValues, valuesRef } = form;

    useEffect(() => setValues(data || {}, true), [data]);

    return (
        <Modal
            open={open}
            onClose={onClose}
            title="Typ transakce"
            size="big"
            buttons={[{ title: "Storno" }, { title: "Uložit", main: true, onClick: handleSubmit, disabled: isLoading }]}
        >
            <TransactionTypeFormFields form={form} disabled={isLoading} />
        </Modal>
    );
};

const SubjectFields = ({ id, form, disabled, readOnly }) => {
    const { values = {}, handleChange, setValues } = form || {};
    return (
        <div>
            <TextField
                fullWidth
                label="Název"
                margin="normal"
                name="subjectName"
                value={values.subjectName || ""}
                onChange={handleChange}
                disabled={disabled}
                size="small"
            />
            <Stack direction="row" spacing={4}>
                <div>
                    <TextField
                        fullWidth
                        label="IČ"
                        margin="normal"
                        name="subjectIdNumber"
                        value={values.subjectIdNumber || ""}
                        onChange={handleChange}
                        disabled={disabled}
                        size="small"
                    />
                </div>
                <div>
                    <TextField
                        fullWidth
                        label="DIČ"
                        margin="normal"
                        name="subjectTaxIdNumber"
                        value={values.subjectTaxIdNumber || ""}
                        onChange={handleChange}
                        disabled={disabled}
                        size="small"
                    />
                </div>
            </Stack>
            <TextField
                fullWidth
                label="Adresa"
                margin="normal"
                name="subjectAddress"
                value={values.subjectAddress || ""}
                onChange={handleChange}
                disabled={disabled}
                multiline
                rows={4}
                size="small"
            />
        </div>
    );
};

export const TransactionFormFields = ({ form, disabled, readOnly, currencyCode, clientId }) => {
    const settings = useSettings();
    const { values = {}, handleChange, setValues, errors } = form || {};
    const hasType = !!values.typeId;
    const onAddAttachment = useContext(AttachmentContext);

    return (
        <>
            <Box px={2}>
                <Box mb={4}>
                    <TextField
                        fullWidth
                        label="Název"
                        margin="normal"
                        name="name"
                        value={values.name || ""}
                        onChange={handleChange}
                        disabled={disabled}
                        required
                        error={!!errors.name}
                        helperText={errors.name}
                    />
                    {!!values.hasIdentificator && (
                        <TextField
                            fullWidth
                            label="Identifikátor transakce"
                            margin="normal"
                            name="transactionId"
                            value={values.transactionId || ""}
                            onChange={handleChange}
                            disabled={disabled}
                            required
                            error={!!errors.transactionId}
                            helperText={errors.transactionId}
                        />
                    )}
                </Box>
                <Box mb={4}>
                    <DatePicker
                        fullWidth
                        label="Datum"
                        margin="normal"
                        name="date"
                        value={values.date || ""}
                        onChange={(val) => setValues({ ...values, date: val })}
                        disabled={disabled}
                        required
                        error={!!errors.date}
                        helperText={errors.date}
                    />
                    {!!values.hasDueDate && (
                        <DatePicker
                            fullWidth
                            label="Datum splatnosti"
                            margin="normal"
                            name="due"
                            value={values.due || ""}
                            onChange={(val) => setValues({ ...values, due: val })}
                            disabled={disabled}
                            required
                            error={!!errors.due}
                            helperText={errors.due}
                        />
                    )}
                    {/** TODO move somewhere? **/}
                    <TextField
                        fullWidth
                        label="Účetní období"
                        margin="normal"
                        name="fiscalYear"
                        value={values.fiscalYear || ""}
                        onChange={handleChange}
                        disabled={disabled}
                    />
                </Box>
            </Box>

            {!!values.hasProjectReference && (
                <Section title="Projekt" subtitle={values.projectName} px={2}>
                    {/*<SubjectFields id={values.id} form={form} disabled={disabled} readOnly={readOnly} />*/}
                    <ProjectSelect
                        size="small"
                        value={!!values.projectId ? { id: values.projectId, name: values.projectName } : null}
                        onChange={(val) => setValues({ ...values, projectId: val ? val.id : null, projectName: val ? val.name : null })}
                        label="Projekt"
                        margin="normal"
                        disabled={disabled}
                        clientId={clientId}
                    />
                </Section>
            )}

            {!!values.hasSubjectInfo && (
                <Section title="Dodavatel" subtitle={values.subjectName} px={2}>
                    <SubjectFields id={values.id} form={form} disabled={disabled} readOnly={readOnly} />
                </Section>
            )}

            {!!values.hasCpty && (
                <Section title="Protistrana" subtitle={values.counterpartyName} px={2}>
                    <CounterpartySelect
                        label="Protistrana"
                        margin="normal"
                        disabled={disabled}
                        fullWidth
                        value={{ id: values.counterpartyId, name: values.counterpartyName || "" }}
                        onChange={(cpty, val) => {
                            let vals = { ...values };
                            console.log(cpty, val);
                            if (cpty) {
                                vals.counterpartyId = cpty.id;
                                vals.counterpartyName = cpty.name;
                                vals.counterpartyIdNumber = cpty.idNumber;
                                vals.counterpartyTaxIdNumber = cpty.taxIdNumber;
                                vals.counterpartyAddress = cpty.address;
                            } else {
                                delete vals.counterpartyId;
                                delete vals.counterpartyName;
                                delete vals.counterpartyIdNumber;
                                delete vals.counterpartyTaxIdNumber;
                                delete vals.counterpartyAddress;
                            }
                            setValues(vals);
                        }}
                        size="small"
                    />
                    <Stack direction="row" spacing={4}>
                        <div>
                            <TextField
                                fullWidth
                                label="IČ"
                                margin="normal"
                                name="counterpartyIdNumber"
                                value={values.counterpartyIdNumber || ""}
                                onChange={handleChange}
                                disabled={disabled}
                                size="small"
                            />
                        </div>
                        <div>
                            <TextField
                                fullWidth
                                label="DIČ"
                                margin="normal"
                                name="counterpartyTaxIdNumber"
                                value={values.counterpartyTaxIdNumber || ""}
                                onChange={handleChange}
                                disabled={disabled}
                                size="small"
                            />
                        </div>
                    </Stack>
                    <TextField
                        fullWidth
                        label="Adresa"
                        margin="normal"
                        name="counterpartyAddress"
                        value={values.counterpartyAddress || ""}
                        onChange={handleChange}
                        disabled={disabled}
                        multiline
                        rows={4}
                        size="small"
                    />
                </Section>
            )}

            {!!values.hasItems ? (
                <TransactionItemsField
                    value={values.items}
                    onChange={(items) => setValues({ ...values, items })}
                    disabled={disabled}
                    readOnly={readOnly}
                    currencyCode={values.currencyCode}
                    hasTax={!!values.hasTax}
                />
            ) : (
                <Box px={2} my={4}>
                    <CurrencyField
                        fullWidth
                        label="Celkem"
                        margin="normal"
                        name="total"
                        value={values.total || ""}
                        onChange={handleChange}
                        disabled={disabled}
                        currencyCode={currencyCode}
                        required
                        error={!!errors.transactionId}
                        helperText={errors.transactionId}
                    />
                    {!!settings.transactionsTaxSupport && !!values.hasTax ? (
                        <>
                            <TaxItemsField
                                baseAmount={values.total}
                                value={values.taxItems}
                                onChange={(taxItems) => setValues({ ...values, taxItems })}
                                disabled={disabled}
                                currencyCode={currencyCode}
                            />
                            <CurrencyField
                                fullWidth
                                label="Celkem k zaplacení"
                                margin="normal"
                                name="totalPayable"
                                value={values.totalPayable || ""}
                                onChange={handleChange}
                                disabled={disabled}
                                currencyCode={currencyCode}
                                onCalculate={() => {
                                    let totalPayable = values.total;
                                    if (Array.isArray(values.taxItems)) {
                                        for (let i = 0; i < values.taxItems.length; i++) {
                                            totalPayable = numbers.plus(values.taxItems[i].total, totalPayable, 2);
                                        }
                                    }
                                    setValues({ ...values, totalPayable });
                                }}
                                calculateHint="Spočítat celkovou částku"
                                required
                                error={!!errors.totalPayable}
                                helperText={errors.totalPayable}
                            />
                        </>
                    ) : null}
                </Box>
            )}

            {!!values.hasPaymentInstructions && (
                <PaymentMethodsFormField
                    value={values.paymentMethods}
                    onChange={(paymentMethods) => setValues({ ...values, paymentMethods })}
                    disabled={disabled}
                    readOnly={readOnly}
                />
            )}

            <AttachmentSectionField
                value={values.attachments}
                onChange={(attachments) => setValues({ ...values, attachments })}
                disabled={disabled}
                readOnly={readOnly}
                onAddClick={onAddAttachment}
            />

            <TagsSectionField value={values.tags} onChange={(tags) => setValues({ ...values, tags })} disabled={disabled} readOnly={readOnly} />
        </>
    );
};

export const TransactionModal = ({ data, open, onClose, onDataSaved, clientId, ...props }) => {
    const { isLoading } = useLoadingIndicator();
    const onSubmit = useMemo(
        () => async (values, opts) => {
            values = await saveTransaction(values);
            onClose && onClose();
            onDataSaved && onDataSaved(values);
        },
        [onClose, onDataSaved]
    );

    const form = useForm({ onSubmit });

    const { values, handleChange, errors, setErrors, handleSubmit, setValues, valuesRef } = form;

    useEffect(() => setValues(data || {}, true), [data]);

    return (
        <Modal
            open={open}
            onClose={onClose}
            title={values.typeName || "Transakce"}
            size="big"
            buttons={[{ title: "Storno" }, { title: "Uložit", main: true, onClick: handleSubmit, disabled: isLoading }]}
        >
            <AttachmentContainer value={values.attachments} onChange={(attachments) => setValues({ ...values, attachments })}>
                <TransactionFormFields form={form} disabled={isLoading} currencyCode={values.currencyCode} clientId={clientId} />
            </AttachmentContainer>
        </Modal>
    );
};

const TransactionsListRow = ({ t, disabled, settings, onTransactionExport, showCounterparty, showProject }) => {
    let data = t || {};

    const [itemsOpen, setItemsOpen] = useState(false);

    return (
        <>
            <Box mb={2}>
                <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
                    {!!data.printable && !!onTransactionExport && (
                        <IconButton onClick={() => onTransactionExport(data.id)} disabled={!!disabled} size="small" title="Exportovat transakci">
                            <PrintIcon />
                        </IconButton>
                    )}
                </Stack>

                {!!data.description && (
                    <Box mb={4}>
                        <Value label="Popis projektu" value={data.description} />
                    </Box>
                )}
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={6} md={12} lg={6} xl={4}>
                        <Value label="Typ" value={`${data.typeName} (${data.direction})`} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={12} lg={6} xl={4}>
                        <Value label="Datum" value={formatDate(data.date, settings.dateFormat)} />
                    </Grid>
                    {!!data.hasDueDate && (
                        <Grid item xs={12} sm={6} md={12} lg={6} xl={4}>
                            <Value label="Datum splatnosti" value={formatDate(data.due, settings.dateFormat)} />
                        </Grid>
                    )}
                    {!!data.hasStatus && (
                        <Grid item xs={12} sm={6} md={12} lg={6} xl={4}>
                            <Value label="Stav" value={data.status} />
                        </Grid>
                    )}
                    {!!data.hasIdentificator && (
                        <Grid item xs={12} sm={6} md={12} lg={6} xl={4}>
                            <Value label="Identifikátor transakce" value={data.transactionId} />
                        </Grid>
                    )}
                    <Grid item xs={12} sm={6} md={12} lg={6} xl={4}>
                        <Value label="Zúčtovací období" value={data.fiscalYear} />
                    </Grid>
                    {!!showCounterparty && !!data.hasCpty && !!data.counterpartyId && (
                        <Grid item xs={12} sm={6} md={12} lg={6} xl={4}>
                            <Value label="Protistrana" value={data.counterpartyName} />
                        </Grid>
                    )}
                    {!!showProject && !!data.hasProjectReference && !!data.projectId && (
                        <Grid item xs={12} sm={6} md={12} lg={6} xl={4}>
                            <Value label="Projekt" value={data.projectName} />
                        </Grid>
                    )}
                </Grid>
                {!!data.hasItems && Array.isArray(data.items) && !!data.items.length && (
                    <Box my={4}>
                        <Typography variant="subtitle2" gutterBottom>
                            <ExpandButton open={!!itemsOpen} onOpenStateChange={() => setItemsOpen(!itemsOpen)} />
                            Položky
                        </Typography>
                        <Collapse in={!!itemsOpen}>
                            <Box my={2}>
                                <MuiTableContainer component={Paper}>
                                    <MuiTable size="small">
                                        <MuiTableHead>
                                            <MuiTableRow>
                                                <MuiTableCell align="center">Datum</MuiTableCell>
                                                <MuiTableCell>Projekt</MuiTableCell>
                                                <MuiTableCell align="right">Počet hodin</MuiTableCell>
                                            </MuiTableRow>
                                        </MuiTableHead>
                                        <MuiTableBody>
                                            {data.items.map((row) => (
                                                <MuiTableRow key={row.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                                    <MuiTableCell align="center"></MuiTableCell>
                                                    <MuiTableCell>{row.projectName}</MuiTableCell>
                                                    <MuiTableCell align="right">
                                                        <NumberValue value={row.hours} unit="h" />
                                                    </MuiTableCell>
                                                </MuiTableRow>
                                            ))}
                                        </MuiTableBody>
                                    </MuiTable>
                                </MuiTableContainer>
                            </Box>
                        </Collapse>
                    </Box>
                )}

                <AttachmentChips value={data.attachments} disabled={disabled} url={(a) => `/api/transactions/${data.id}/attachments/${a.id}`} />
            </Box>

            {/*<pre>{JSON.stringify(data, null, 4)}</pre>*/}
        </>
    );
};

export const TransactionsList = ({
    title,
    buttons,
    data,
    currencyCode,
    disabled,
    readOnly,
    onAdd,
    onEdit,
    onDelete,
    withDetail,
    addTransactionTypeFilter,
    showCounterparty,
    showProject,
    projectId,
    clientId,
}) => {
    const showAlert = useAlert();
    const [openState, setOpen] = useState({});
    const settings = useSettings();
    const { startLoading, stopLoading } = useLoadingIndicator();

    useEffect(() => {
        setOpen({});
    }, [projectId, clientId]);

    return (
        <div>
            <div>
                {Array.isArray(data) &&
                    data.map((t, key) => {
                        return (
                            <DetailRow
                                key={`${key}-${t.id}`}
                                open={openState[t.id]}
                                setOpen={(open) => setOpen({ ...openState, [t.id]: open })}
                                renderDetail={() => (
                                    <LoadingIndicatorContainer>
                                        <TransactionsListRow
                                            id={t.id}
                                            t={t}
                                            settings={settings}
                                            showCounterparty={showCounterparty}
                                            showProject={showProject}
                                            onTransactionExport={async (id) => {
                                                try {
                                                    startLoading();
                                                    await fetchTransactionPdf(id);
                                                } catch (e) {
                                                    alert("error");
                                                    console.error(e);
                                                } finally {
                                                    stopLoading();
                                                }
                                            }}
                                        />
                                    </LoadingIndicatorContainer>
                                )}
                            >
                                <DetailRowMain slim>
                                    <Typography variant="body1" component="div">
                                        {t.name}
                                    </Typography>
                                    <Typography variant="caption" color="textSecondary" component="div">
                                        {t.typeName} {formatDate(t.date, settings.dateFormat)}
                                    </Typography>
                                </DetailRowMain>
                                <DetailRowValue>
                                    <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
                                        <Typography variant="body1" component="span">
                                            <CurrencyValue
                                                currencyCode={t.currencyCode}
                                                value={numbers.multiply(t.total, t.direction == "CREDIT" ? -1 : 1, 2)}
                                            />
                                        </Typography>
                                        {!!onEdit && (
                                            <IconButton onClick={(e) => onEdit(t)} disabled={!!disabled} size="small">
                                                <EditIcon />
                                            </IconButton>
                                        )}
                                        {!!onDelete && (
                                            <IconButton
                                                onClick={() =>
                                                    showAlert("Smazat položku", `Chcete smazat položku ${t.name || t.description} ?`, [
                                                        { text: "Ne" },
                                                        {
                                                            text: "Ano",
                                                            onPress: () => {
                                                                onDelete(t);
                                                            },
                                                        },
                                                    ])
                                                }
                                                disabled={!!disabled}
                                                size="small"
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        )}
                                    </Stack>
                                </DetailRowValue>
                            </DetailRow>
                        );
                    })}
            </div>
        </div>
    );
};

const createTransactionItemsListClasses = makeStyles((theme) => ({
    itemsRoot: {},
    itemRoot: {
        marginBottom: theme.spacing(2),
    },
    itemContent: {
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
    },
    buttons: {
        flex: "0 0 auto",
        display: "flex",
    },
}));

export const TransactionItemsList = ({ data, currencyCode, disabled, readOnly, onAdd, onEdit, onDelete }) => {
    const showAlert = useAlert();
    const classes = createTransactionItemsListClasses();
    const [openState, setOpenState] = useState({});

    useEffect(() => {
        setOpenState({});
    }, data);

    return (
        <div>
            <Box mb={2}>
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                    <Typography variant="subtitle1">Položky</Typography>
                    {!readOnly && !!onAdd && (
                        <div>
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <IconButton color="inherit" disabled={!!disabled} onClick={onAdd}>
                                    <AddIcon />
                                </IconButton>
                            </Stack>
                        </div>
                    )}
                </Stack>
            </Box>
            <div className={classes.itemsRoot}>
                {Array.isArray(data) &&
                    data.map((t) => {
                        return (
                            <DetailRow
                                open={openState[t.id]}
                                setOpen={(open) => setOpenState({ ...openState, [t.id]: open })}
                                renderDetail={() => <pre>{JSON.stringify(t, null, 4)}</pre>}
                            >
                                <DetailRowMain slim>
                                    <Typography variant="body1" component="div">
                                        {t.name}
                                    </Typography>
                                    <Typography variant="caption" color="textSecondary" component="div">
                                        {!!t.pieces && <NumberValue value={t.pieces} unit={t.unit} />}{" "}
                                        {!!t.pricePerUnit && <CurrencyValue currencyCode={currencyCode} value={t.pricePerUnit} unit={t.unit} />}
                                    </Typography>
                                </DetailRowMain>
                                <DetailRowValue right>
                                    <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={0}>
                                        <Typography variant="body1">
                                            <b>
                                                <CurrencyValue currencyCode={currencyCode} value={t.total} />
                                            </b>
                                        </Typography>
                                        <IconButton onClick={(e) => onEdit(t)} disabled={!!disabled}>
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton
                                            onClick={() =>
                                                showAlert("Smazat položku", `Chcete smazat položku ${t.name || t.description} ?`, [
                                                    { text: "Ne" },
                                                    {
                                                        text: "Ano",
                                                        onPress: () => {
                                                            onDelete(t);
                                                        },
                                                    },
                                                ])
                                            }
                                            disabled={!!disabled}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Stack>
                                </DetailRowValue>
                            </DetailRow>
                        );
                    })}
            </div>
        </div>
    );
};

export const TransactionItemFormFields = ({ form, disabled, readOnly, size = "normal", currencyCode }) => {
    const { values = {}, handleChange, setValues, errors } = form || {};
    return (
        <>
            <Box mb={4}>
                <TextField
                    fullWidth
                    label="Název"
                    margin="normal"
                    name="name"
                    value={values.name || ""}
                    onChange={handleChange}
                    disabled={disabled}
                    size={size}
                />
                <Stack direction="row" spacing={2} alignItems="center">
                    <div>
                        <TextField
                            fullWidth
                            label="Jednotka"
                            margin="normal"
                            name="unit"
                            value={values.unit || ""}
                            onChange={handleChange}
                            disabled={disabled}
                            size={size}
                        />
                    </div>
                    <div>
                        <NumberField
                            fullWidth
                            label="Počet"
                            margin="normal"
                            name="pieces"
                            value={values.pieces || ""}
                            onChange={handleChange}
                            disabled={disabled}
                            size={size}
                            unit={values.unit}
                        />
                    </div>
                    <div>
                        <CurrencyField
                            fullWidth
                            label={`Cena${values.unit ? " za " + values.unit : ""}`}
                            margin="normal"
                            name="pricePerUnit"
                            value={values.pricePerUnit || ""}
                            onChange={handleChange}
                            disabled={disabled}
                            size={size}
                            currencyCode={currencyCode}
                        />
                    </div>
                </Stack>
                <CurrencyField
                    fullWidth
                    label="Celkem"
                    margin="normal"
                    name="total"
                    value={values.total || ""}
                    onChange={handleChange}
                    disabled={disabled}
                    size={size}
                    currencyCode={currencyCode}
                    onCalculate={() => setValues({ ...values, total: numbers.multiply(values.pieces, values.pricePerUnit, 2) })}
                    calculateHint="Spočítat celkovou částku"
                    error={!!errors.total}
                    helperText={!!errors.total}
                />
            </Box>
            <Box mb={4}>
                <TaxItemsField
                    baseAmount={values.total}
                    value={values.taxes}
                    onChange={(taxes) => setValues({ ...values, taxes })}
                    disabled={disabled}
                    readOnly={readOnly}
                    currencyCode={currencyCode}
                />
            </Box>
            {/*            
            <Box mb={4}>
                <TextField
                    fullWidth
                    label="Popis"
                    margin="normal"
                    name="description"
                    value={values.description || ""}
                    onChange={handleChange}
                    disabled={disabled}
                    multiline
                    rows={3}
                />
            </Box>
        */}
        </>
    );
};

const TransactionItemForm = ({ value, onSubmit, onCancel, taxType, disabled, readOnly, size, currencyCode }) => {
    const form = useForm({ onSubmit });
    const { values, handleChange, errors, setErrors, handleSubmit, setValues, valuesRef } = form;

    useEffect(() => {
        setValues(value);
    }, [value]);

    return (
        <>
            <Box mb={2}>
                <TransactionItemFormFields form={form} disabled={disabled} readOnly={readOnly} size={size} currencyCode={currencyCode} />
            </Box>
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                <IconButton onClick={() => onCancel && onCancel()} size="small">
                    <CloseIcon />
                </IconButton>
                <IconButton onClick={handleSubmit} size="small">
                    <CheckIcon />
                </IconButton>
            </Stack>
        </>
    );
};

const TransactionItemDialogForm = ({ value, onSubmit, onCancel, taxType, disabled, readOnly, size, currencyCode }) => {
    const form = useForm({ onSubmit });
    const { values, handleChange, errors, setErrors, handleSubmit, setValues, valuesRef } = form;

    useEffect(() => {
        setValues(value);
    }, [value]);

    return (
        <>
            <DialogContent>
                <TransactionItemFormFields form={form} disabled={disabled} readOnly={readOnly} size={size} currencyCode={currencyCode} />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onCancel && onCancel()}>Storno</Button>
                <Button onClick={handleSubmit}>Ok</Button>
            </DialogActions>
        </>
    );
};

export const TransactionItemsField = ({ value, onChange, disabled, readOnly, currencyCode, hasTax }) => {
    const showAlert = useAlert();
    const { saveRow, deleteRow, selectedRow, selectedRowData, addLastRow, editRow, hideRowForm } = useEditableList(value, onChange);

    return (
        <Section
            title="Položky"
            items={value}
            subtitleFn={(item) => item.name}
            px={2}
            actions={
                readOnly
                    ? null
                    : [
                          {
                              title: "Přidat položku",
                              icon: <AddIcon />,
                              onClick: () => addLastRow({}),
                          },
                      ]
            }
        >
            <TableContainer>
                {Array.isArray(value) &&
                    value.map((item, key) => {
                        return (
                            <TableRowContainer
                                key={key}
                                disabled={disabled}
                                actions={
                                    readOnly
                                        ? null
                                        : [
                                              {
                                                  icon: <EditIcon />,
                                                  title: "Upravit položku",
                                                  onClick: () => editRow(key, item),
                                              },
                                              {
                                                  icon: <DeleteIcon />,
                                                  title: "Smazat",
                                                  onClick: () =>
                                                      showAlert("Smazat položku", `Chcete smazat položku ${item.name} ?`, [
                                                          { text: "Ne" },
                                                          {
                                                              text: "Ano",
                                                              onPress: () => {
                                                                  deleteRow(key);
                                                              },
                                                          },
                                                      ]),
                                              },
                                          ]
                                }
                            >
                                <TableRowMain>
                                    <Typography variant="body2" component="div">
                                        {item.name}
                                    </Typography>
                                    <Typography variant="caption" color="textSecondary">
                                        <NumberValue value={item.pieces} unit={item.unit} />
                                    </Typography>

                                    <Typography variant="caption" color="textSecondary">
                                        <CurrencyValue currencyCode={currencyCode} value={item.pricePerUnit} />
                                        {item.unit ? ` / ${item.unit}` : null}
                                    </Typography>
                                </TableRowMain>
                                <TableRowValue>
                                    <Typography variant="body2" align="right" component="div">
                                        <CurrencyValue currencyCode={currencyCode} value={item.total} />
                                    </Typography>
                                </TableRowValue>

                                {/*!!hasTax && Array.isArray(item.taxes) && !!item.taxes.length && (
                                        <Box mt={1.5}>
                                            {item.taxes.map((ti) => (
                                                <Grid container columnSpacing={1} key={ti.id}>
                                                    <Grid item xs={12}>
                                                        <Typography variant="caption">{ti.name}</Typography>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Typography variant="caption" color="textSecondary">
                                                            {ti.value} %
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Typography variant="caption" color="textSecondary">
                                                            <CurrencyValue currencyCode={currencyCode} value={ti.base} />
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Typography variant="caption" align="right" component="div">
                                                            <CurrencyValue currencyCode={currencyCode} value={ti.total} />
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            ))}
                                        </Box>
                                    )*/}
                            </TableRowContainer>
                        );
                    })}
            </TableContainer>
            <Dialog open={!!selectedRow || selectedRow === 0} onClose={hideRowForm}>
                <DialogTitle>Položka transakce</DialogTitle>
                <TransactionItemDialogForm
                    value={selectedRowData}
                    onSubmit={(val) => saveRow(val, selectedRow, true)}
                    onCancel={hideRowForm}
                    size="small"
                    currencyCode={currencyCode}
                    hasTax={hasTax}
                />
            </Dialog>
        </Section>
    );
};

export const TransactionItemModal = ({ transactionId, data, open, onClose, onDataSaved, currencyCode, ...props }) => {
    const { startLoading, stopLoading, isLoading } = useLoadingIndicator();
    const onSubmit = useMemo(
        () => async (values, submitHints) => {
            startLoading();
            try {
                if (transactionId) {
                    values = await saveTransactionItem(transactionId, values);
                }
                if (!!onDataSaved) {
                    await onDataSaved(values, submitHints);
                }

                if (!submitHints.keepOpen) {
                    onClose && onClose();
                }
            } catch (e) {
                alert(e);
                console.error(e);
            } finally {
                stopLoading();
            }
        },
        [onClose, onDataSaved]
    );

    const form = useForm({ onSubmit });
    const { values, handleChange, errors, setErrors, handleSubmit, setValues, valuesRef } = form;
    const handleFormSubmit = (keepOpen) => (e) => handleSubmit(e, { keepOpen });

    useEffect(() => setValues(data || {}), [data]);

    return (
        <Modal
            open={open}
            onClose={onClose}
            title="Položka transakce"
            buttons={[{ title: "Storno" }, { title: "Uložit", main: true, onClick: handleFormSubmit(false), disabled: isLoading }]}
            fullSizeButtons={[{ title: "Uložit a přidat další", main: true, onClick: handleFormSubmit(true), disabled: isLoading }]}
        >
            <Box px={2}>
                <TransactionItemFormFields form={form} disabled={isLoading} currencyCode={currencyCode} />
            </Box>
        </Modal>
    );
};

const TaxItemForm = ({ value, onSubmit, onCancel, taxType, disabled, size = "normal", currencyCode }) => {
    const form = useForm({ onSubmit });
    const { values, handleChange, errors, setErrors, handleSubmit, setValues, valuesRef } = form;

    useEffect(() => {
        setValues(value);
    }, [value]);

    return (
        <>
            <DialogContent>
                <TextField
                    fullWidth
                    label="Název"
                    margin="normal"
                    name="name"
                    value={values.name || ""}
                    onChange={handleChange}
                    disabled={disabled}
                    size={size}
                />
                <CurrencyField
                    fullWidth
                    label="Základ daně"
                    margin="normal"
                    name="base"
                    value={values.base || ""}
                    onChange={handleChange}
                    disabled={disabled}
                    size={size}
                    currencyCode={currencyCode}
                />
                <CurrencyField
                    fullWidth
                    label="Daň"
                    margin="normal"
                    name="total"
                    value={values.total || ""}
                    onChange={handleChange}
                    disabled={disabled}
                    size={size}
                    currencyCode={currencyCode}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onCancel && onCancel()}>Storno</Button>
                <Button onClick={handleSubmit}>Ok</Button>
            </DialogActions>
        </>
    );
};

const TaxItemFormFields = ({ value, onChange, taxType }) => {
    return <></>;
};

export const TaxItemsField = ({ baseAmount = 0, units, taxTypes, value, onChange, readOnly, disabled, currencyCode, ...props }) => {
    const showAlert = useAlert();
    const [state, setState] = useState({ types: [], loading: false });
    const { saveRow, deleteRow, selectedRow, selectedRowData, addLastRow, editRow, hideRowForm } = useEditableList(value, onChange);

    const fetchTypes = async () => {
        setState({ ...state, loading: true });
        try {
            let types = await fetchTaxTypes();
            setState({ ...state, loading: false, types: types || [] });
        } catch (e) {
            console.error(e);
            setState({ ...state, loading: false, types: [] });
        }
    };

    useEffect(() => {
        fetchTypes();
    }, []);

    return (
        <Box my={2}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                <Typography variant="body1">Daň</Typography>
                {!readOnly && (
                    <DropDown
                        title="Přidat daň"
                        items={(Array.isArray(state.types) && state.types) || []}
                        onSelect={(type) => {
                            let { id, ...t } = type || {};
                            saveRow({ taxTypeId: type.id, ...t, base: baseAmount, total: calculateTax(type, baseAmount, units) });
                        }}
                    />
                )}
            </Stack>
            <TableContainer>
                {Array.isArray(value) &&
                    value.map((item, key) => {
                        return (
                            <TableRowContainer
                                key={key}
                                disabled={disabled}
                                readOnly={readOnly}
                                actions={[
                                    {
                                        icon: <EditIcon />,
                                        title: "upravit",
                                        onClick: () => editRow(key, item),
                                    },
                                    {
                                        icon: <DeleteIcon />,
                                        title: "Smazat",
                                        onClick: () =>
                                            showAlert("Smazat daň", `Chcete smazat daň ${item.name} ?`, [
                                                { text: "Ne" },
                                                {
                                                    text: "Ano",
                                                    onPress: () => {
                                                        deleteRow(key);
                                                    },
                                                },
                                            ]),
                                    },
                                ]}
                            >
                                <Grid container columnSpacing={1}>
                                    <Grid item xs={12}>
                                        <Typography variant="caption">{item.name}</Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="caption" color="textSecondary">
                                            {item.value} %
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="caption" color="textSecondary">
                                            <CurrencyValue currencyCode={currencyCode} value={item.base} />
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="caption" align="right" component="div">
                                            <CurrencyValue currencyCode={currencyCode} value={item.total} />
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </TableRowContainer>
                        );
                    })}
            </TableContainer>

            <Dialog open={!!selectedRow || selectedRow === 0} onClose={hideRowForm} maxWidth="xs">
                <DialogTitle>Daň</DialogTitle>
                <TaxItemForm
                    value={selectedRowData || {}}
                    onSubmit={(val) => saveRow(val, selectedRow, true)}
                    onCancel={hideRowForm}
                    size="small"
                    currencyCode={currencyCode}
                />
            </Dialog>
        </Box>
    );
};

const TaxTypeFormFields = ({ form, disabled, currencyCode }) => {
    const { values, handleChange, errors, setErrors, handleSubmit, setValues, valuesRef } = form || {};

    let valueLabel = "Částka";
    if (values.calculationType === "PERCENTAGE") {
        valueLabel = "Procentuální sazba";
    } else if (values.calculationType === "PER_UNIT") {
        valueLabel = "Částka za " + (values.unit || "jednotku");
    }

    return (
        <>
            <Box mb={2} px={3}>
                <TextField fullWidth label="Název" margin="normal" name="name" value={values.name || ""} onChange={handleChange} disabled={disabled} required />
            </Box>
            <Box my={2} px={3}>
                <ToggleButtonGroup
                    color="secondary"
                    value={values.calculationType}
                    exclusive
                    onChange={(e, calculationType) => setValues({ ...values, calculationType })}
                    disabled={disabled}
                >
                    <ToggleButton value="PERCENTAGE" disabled={disabled}>
                        Procentuální
                    </ToggleButton>
                    <ToggleButton value="PER_UNIT" disabled={disabled}>
                        Za jednotku
                    </ToggleButton>
                    <ToggleButton value="FLAT" disabled={disabled}>
                        Částka
                    </ToggleButton>
                </ToggleButtonGroup>
            </Box>
            <Box mb={2} px={3}>
                {values.calculationType === "PER_UNIT" && (
                    <TextField fullWidth label="Jednotka" margin="normal" name="unit" value={values.unit || ""} onChange={handleChange} disabled={disabled} />
                )}

                {values.calculationType === "PERCENTAGE" ? (
                    <NumberField
                        fullWidth
                        label={valueLabel}
                        margin="normal"
                        name="value"
                        value={values.value || ""}
                        onChange={handleChange}
                        currencyCode={currencyCode}
                        disabled={disabled}
                        unit="%"
                    />
                ) : (
                    <CurrencyField
                        fullWidth
                        label={valueLabel}
                        margin="normal"
                        name="value"
                        value={values.value || ""}
                        onChange={handleChange}
                        currencyCode={currencyCode}
                        disabled={disabled}
                        unit={values.calculationType === "PER_UNIT" ? values.unit : undefined}
                    />
                )}
            </Box>
        </>
    );
};

export const TaxTypeModal = ({ data, open, onClose, onDataSaved, ...props }) => {
    const { startLoading, stopLoading, isLoading } = useLoadingIndicator();
    const settings = useSettings();
    const onSubmit = useMemo(
        () => async (values) => {
            values = await saveTaxType(values);
            onClose && onClose();
            onDataSaved && onDataSaved(values);
        },
        [onClose, onDataSaved]
    );

    const form = useForm({ onSubmit });

    const { values, handleChange, errors, setErrors, handleSubmit, setValues, valuesRef } = form;

    useEffect(() => setValues(data || {}), [data]);

    return (
        <Modal
            open={open}
            onClose={onClose}
            title="Sazba daně"
            size="big"
            buttons={[{ title: "Storno" }, { title: "Uložit", main: true, onClick: handleSubmit, disabled: isLoading }]}
        >
            <TaxTypeFormFields form={form} disabled={isLoading} currencyCode={settings.defaultCurrencyCode} />
        </Modal>
    );
};

//TODO payment methods
const PaymentMethodValueForm = ({ value, onSubmit, onCancel, taxType, disabled }) => {
    const form = useForm({ onSubmit });
    const { values, handleChange, errors, setErrors, handleSubmit, setValues, valuesRef } = form;

    useEffect(() => {
        setValues(value);
    }, [value]);

    return (
        <>
            <DialogContent>
                <TextField
                    fullWidth
                    label="Název"
                    margin="normal"
                    name="name"
                    value={values.name || ""}
                    onChange={handleChange}
                    disabled={disabled}
                    size="small"
                />
                {!!values.source ? (
                    <Typography>TODO resolve {values.source}</Typography>
                ) : (
                    <TextField
                        fullWidth
                        label="Hodnota"
                        margin="normal"
                        name="value"
                        value={values.value || ""}
                        onChange={handleChange}
                        disabled={disabled}
                        size="small"
                    />
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onCancel && onCancel()} size="small">
                    Storno
                </Button>
                <Button onClick={handleSubmit} size="small">
                    Ok
                </Button>
            </DialogActions>
        </>
    );
};

const PaymentMethodValuesField = ({ value, onChange, disabled, readOnly }) => {
    const showAlert = useAlert();
    const { saveRow, deleteRow, selectedRow, selectedRowData, addLastRow, editRow, hideRowForm } = useEditableList(value, onChange);

    const fieldTypes = [
        {
            source: "COMPANY_ID",
            name: "IČ společnosti",
        },
        {
            source: "COMPANY_TAX_ID",
            name: "DIČ společnosti",
        },
        {
            source: "COUNTERPARTY_ID",
            name: "IČ protistrany",
        },
        {
            source: "COUNTERPARTY_TAX_ID",
            name: "DIČ protistrany",
        },
        {
            source: "TRANSACTION_ID",
            name: "Identifikátor transakce",
        },
    ];

    return (
        <>
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                <Typography variant="body1">Datová pole</Typography>
                {!readOnly && (
                    <DropDown
                        title="Přidat pole"
                        items={fieldTypes}
                        onSelect={(type) => {
                            addLastRow({ source: type.source });
                        }}
                    />
                )}
            </Stack>

            <TableContainer>
                {Array.isArray(value) &&
                    value.map((pm, key) => {
                        return (
                            <TableRowContainer
                                key={key}
                                disabled={disabled}
                                actions={[
                                    {
                                        icon: <EditIcon />,
                                        title: "upravit",
                                        onClick: () => editRow(key, pm),
                                    },
                                    {
                                        icon: <DeleteIcon />,
                                        title: "Smazat",
                                        onClick: () =>
                                            showAlert("Smazat pole", `Chcete smazat pole ${pm.name} ?`, [
                                                { text: "Ne" },
                                                {
                                                    text: "Ano",
                                                    onPress: () => {
                                                        deleteRow(key);
                                                    },
                                                },
                                            ]),
                                    },
                                ]}
                            >
                                <ListItemText primary={pm.name} secondary={pm.source ? pm.source : pm.value} />
                            </TableRowContainer>
                        );
                    })}
            </TableContainer>
            <Dialog open={!!selectedRow || selectedRow === 0} onClose={hideRowForm} maxWidth="xs">
                <DialogTitle>Hodnota platební instrukce</DialogTitle>
                <PaymentMethodValueForm
                    value={selectedRowData || {}}
                    onSubmit={(val) => saveRow(val, (value && value.length) || 0, true)}
                    onCancel={hideRowForm}
                />
            </Dialog>
        </>
    );
};

const PaymentMethodForm = ({ value, onSubmit, onCancel, taxType, disabled }) => {
    const form = useForm({ onSubmit });
    const { values, handleChange, errors, setErrors, handleSubmit, setValues, valuesRef } = form;

    useEffect(() => {
        setValues(value);
    }, [value]);

    return (
        <>
            <DialogContent>
                <Box mb={2}>
                    <TextField
                        fullWidth
                        label="Název"
                        margin="normal"
                        name="name"
                        value={values.name || ""}
                        onChange={handleChange}
                        disabled={disabled}
                        size="small"
                    />
                </Box>
                <Box mb={2}>
                    <ToggleButtonGroup
                        color="secondary"
                        value={values.type}
                        exclusive
                        onChange={(e, type) => setValues({ ...values, type })}
                        disabled={disabled}
                        size="small"
                    >
                        <ToggleButton value="BANK_TRANSFER" disabled={disabled} size="small">
                            Bankovní převod
                        </ToggleButton>
                        <ToggleButton value="CUSTOM" disabled={disabled} size="small">
                            Ostatní
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Box>

                <Box mb={2}>
                    <PaymentMethodValuesField
                        value={values.values}
                        onChange={(vals) => {
                            setValues({ ...values, values: vals });
                        }}
                        disabled={disabled}
                    />
                </Box>
            </DialogContent>

            <DialogActions>
                <Button onClick={() => onCancel && onCancel()}>Storno</Button>
                <Button onClick={handleSubmit}>Ok</Button>
            </DialogActions>
        </>
    );
};

export const PaymentMethodsFormField = ({ value, onChange, disabled, readOnly, title }) => {
    const showAlert = useAlert();
    const { saveRow, deleteRow, selectedRow, selectedRowData, addLastRow, editRow, hideRowForm } = useEditableList(value, onChange);

    return (
        <Section
            title="Platební instrukce"
            items={value}
            subtitleFn={(pm) => pm.name}
            px={2}
            actions={
                readOnly
                    ? null
                    : [
                          {
                              title: "Přidat způsob úhrady",
                              icon: <AddIcon />,
                              onClick: () => addLastRow({}),
                          },
                      ]
            }
        >
            <TableContainer>
                {Array.isArray(value) &&
                    value.map((pm, key) => {
                        return (
                            <TableRowContainer
                                key={key}
                                disabled={disabled}
                                actions={[
                                    {
                                        icon: <EditIcon />,
                                        title: "upravit",
                                        onClick: () => editRow(key, pm),
                                    },
                                    {
                                        icon: <DeleteIcon />,
                                        title: "Smazat",
                                        onClick: () =>
                                            showAlert("Smazat způsob platby", `Chcete smazat způsob platby ${pm.name} ?`, [
                                                { text: "Ne" },
                                                {
                                                    text: "Ano",
                                                    onPress: () => {
                                                        deleteRow(key);
                                                    },
                                                },
                                            ]),
                                    },
                                ]}
                            >
                                <TableRowMain>
                                    <Typography component="div" variant="body1">
                                        {pm.name}
                                    </Typography>
                                    <Typography component="div" variant="caption">
                                        {pm.type == "BANK_TRANSFER" ? "Bankovní převod" : "Ostatní"}
                                    </Typography>
                                </TableRowMain>
                                {/*<QrCode2Icon />*/}
                            </TableRowContainer>
                        );
                    })}
            </TableContainer>
            <Dialog open={!!selectedRow || selectedRow === 0} onClose={hideRowForm} width="sm">
                <DialogTitle>Platební instrukce</DialogTitle>
                <PaymentMethodForm value={selectedRowData || {}} onSubmit={(val) => saveRow(val, (value && value.length) || 0, true)} onCancel={hideRowForm} />
            </Dialog>
        </Section>
    );
};

const QrPaymentCode = ({ value, total }) => {
    if (!value || !value.generateQrCode) {
        return null;
    }
    let fields = (value.values || []).reduce((map, obj) => {
        map[obj.name] = obj.value;
        return map;
    }, {});

    let otherFields = "";
    if (fields[value.qrXVSCodeField]) {
        otherFields += `X-VS:${fields[value.qrXVSCodeField]}*`;
    }
    if (fields[value.qrXSSCodeField]) {
        otherFields += `X-SS:${fields[value.qrXSSCodeField]}*`;
    }
    if (fields[value.qrXKSCodeField]) {
        otherFields += `X-KS:${fields[value.qrXKSCodeField]}*`;
    }

    let spd = `SPD*1.0*ACC:${fields[value.qrAccField]}*AM:${total || 0}*PT:IP*${otherFields}`;

    return <QRCodeSVG value={spd} level="Q" includeMargin />;
};

const usePaymentMethodsStyles = makeStyles((theme) => ({
    paymentMethodsRoot: {},
    paymentMethodsRow: {
        display: "flex",
    },
    paymentMethodsImage: {
        flex: "0 0 auto",
        padding: theme.spacing(2),
    },
    paymentMethodsData: {
        flex: "1 1 auto",
        padding: theme.spacing(2),
    },
}));

export const PaymentMethods = ({ total, value }) => {
    const classes = usePaymentMethodsStyles();
    return (
        <div className={classes.paymentMethodsRoot}>
            {Array.isArray(value) &&
                value.map((pm, key) => {
                    return (
                        <div key={key} className={classes.paymentMethodsRow}>
                            <div className={classes.paymentMethodsImage}>
                                {/*<pre>{JSON.stringify(pm, null, 4)}</pre>*/}
                                <QrPaymentCode value={pm} total={total} />
                            </div>
                            <div className={classes.paymentMethodsImage}>
                                <Divider variant="middle" orientation="vertical" />
                            </div>
                            <div className={classes.paymentMethodsData}>
                                <Typography variant="body1">
                                    <b>{pm.type == "BANK_TRANSFER" ? "Bankovní převod" : "Ostatní"}</b>
                                </Typography>
                                <Grid container rowSpacing={1} columnSpacing={2}>
                                    {Array.isArray(pm.values) &&
                                        pm.values
                                            .filter((pm) => !pm.hidden)
                                            .map((field) => (
                                                <Grid key={field.id} item xs>
                                                    <Value label={field.name} value={field.value} />
                                                </Grid>
                                            ))}
                                </Grid>
                            </div>
                        </div>
                    );
                })}
        </div>
    );
};

const BankTransferPaymentMethod = () => {};

//const TaxItemFormFields = ({typeId, typeName, value, onChange})

export const useTransactionTypes = (filter) => {
    const [types, setTypes] = useState([]);
    const { startLoading, stopLoading, isLoading } = useLoadingIndicator();

    const loadTypes = async () => {
        startLoading();
        try {
            let transactionTypes = await fetchTransactionTypes(filter);
            setTypes(transactionTypes);
        } catch (e) {
            setTypes([]);
            console.error(e);
            alert(e);
        }
        stopLoading();
    };

    useEffect(() => loadTypes(), []);

    return types || [];
};

export const TransactionTypeDropDown = ({ label, value, onChange, size, name, hasProjectReference, hasCounterparty, object }) => {
    const types = useTransactionTypes({ hasCounterparty, hasProjectReference });

    return (
        <FormControl fullWidth size={size}>
            <InputLabel id="transaction-type-id">{label}</InputLabel>
            <Select
                labelId="transaction-type-id"
                value={value || ""}
                label={label}
                onChange={(e) => {
                    if (!onChange) {
                        return;
                    }
                    if (!!object) {
                        for (let type of types) {
                            if (type.id === e.target.value) {
                                onChange(type);
                                return;
                            }
                        }
                        onChange();
                        return;
                    }
                    onChange(e);
                }}
                size={size}
                name={name}
            >
                {types.map((type) => (
                    <MenuItem value={type.id} key={type.id}>
                        {type.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export const AddTransactionButtonWithTypes = ({ onTypeSelected, disabled, types }) => {
    return (
        <DropDown
            disabled={disabled}
            items={types || []}
            onSelect={onTypeSelected}
            renderButton={(onClick, disabled) => (
                <IconButton onClick={onClick} disabled={disabled}>
                    <AddIcon color="inherit" />
                </IconButton>
            )}
        ></DropDown>
    );
};

export const AddTransactionButton = ({ onTypeSelected, disabled, hasProjectReference, hasCounterparty }) => {
    const types = useTransactionTypes({ hasCounterparty, hasProjectReference });
    const { isLoading } = useLoadingIndicator();

    return <AddTransactionButtonWithTypes onTypeSelected={onTypeSelected} disabled={disabled || isLoading} types={types} />;
};

export const TransactionTypeLink = ({ children, onTypeSelected, filter, disabled, types, variant }) => {
    return (
        <DropDown disabled={disabled} items={types} onSelect={onTypeSelected}>
            <Link onClick={() => {}} diabled={disabled} variant={variant}>
                {children}
            </Link>
        </DropDown>
    );
};

/*
"currencyCode": "CZK",
    "periodType": "month",
    "data": {
        "month;2023-01-01": {
            "income": 198000,
            "expenses": 13300,
            "total": 184700,
            "balance": 184700
        },
*/
export const CashflowChart = ({ data, disabled }) => {
    const settings = useSettings();
    const theme = useTheme();

    const values = useMemo(() => {
        const values = { labels: [], income: [], expenses: [], balance: [], barMin: 0, barMax: 0, lineMin: 0, lineMax: 0 };

        if (data && data.data) {
            for (let key in data.data) {
                values.labels.push(key);
                values.income.push(data.data[key].income);
                values.expenses.push(data.data[key].expenses * -1);
                values.balance.push(data.data[key].balance);
            }

            let minBar = Math.min(Math.min(...values.income), Math.min(...values.expenses)),
                maxBar = Math.max(Math.max(...values.income), Math.max(...values.expenses)),
                minLine = Math.min(...values.balance),
                maxLine = Math.max(...values.balance);

            values.barMax = Math.max(Math.abs(minBar), Math.abs(maxBar));
            values.barMin = -1 * values.barMax;

            values.lineMax = Math.max(Math.abs(minLine), Math.abs(maxLine));
            values.lineMin = -1 * values.lineMax;
        }

        return values;
    }, [data]);

    const valueFormatter = (value) => {
        return formatCurrency(value, data.currencyCode, settings.currencies, settings.decimalDigits, settings.decimalDelimiter, settings.thousandDelimiter);
    };

    return (
        <ReactECharts
            minHeight={400}
            option={{
                width: "auto",
                height: "auto",
                tooltip: {
                    trigger: "axis",
                },

                xAxis: [
                    {
                        type: "category",
                        data: values.labels,
                        axisLabel: {
                            formatter: (val) => formatPeriodValue(val),
                            interval: 1,
                        },
                        axisLine: { show: false },
                    },
                ],
                yAxis: [
                    {
                        axisLine: { show: true },
                        type: "value",
                        axisLabel: {
                            show: false,
                        },
                        axisTick: {
                            show: true,
                        },
                        alignTicks: false,
                        show: false,
                        min: values.barMin,
                        max: values.barMax,
                    },
                    {
                        axisLine: { show: true },
                        type: "value",
                        axisLabel: {
                            show: true,
                        },
                        axisTick: {
                            show: true,
                        },
                        alignTicks: false,
                        show: false,
                        min: values.lineMin,
                        max: values.lineMax,
                    },
                ],
                series: [
                    {
                        name: "Income",
                        type: "bar",
                        stack: "total",
                        tooltip: {
                            valueFormatter,
                        },
                        labelLine: { show: false },
                        data: values.income,
                        color: disabled ? "#CCCCCC" : theme.palette.secondary.light,
                    },
                    {
                        name: "Expenses",
                        type: "bar",
                        stack: "total",
                        tooltip: {
                            valueFormatter: (value) => {
                                return formatCurrency(
                                    value * -1,
                                    data.currencyCode,
                                    settings.currencies,
                                    settings.decimalDigits,
                                    settings.decimalDelimiter,
                                    settings.thousandDelimiter
                                );
                            },
                        },
                        labelLine: { show: false },
                        alignTicks: true,
                        data: values.expenses,
                        color: disabled ? "#CCCCCC" : theme.palette.primary.light,
                    },
                    {
                        name: "Balance",
                        type: "line",
                        yAxisIndex: 1,
                        tooltip: {
                            valueFormatter,
                        },
                        labelLine: { show: false },
                        alignTicks: true,
                        data: values.balance,
                        color: disabled ? "#666666" : "#f79239",
                    },
                ],
            }}
        />
    );
};

export const TransactionSummaryWidget = ({ disabled, data, period, setPeriod }) => {
    const theme = useTheme();
    if (!data) {
        data = {};
    }
    return (
        <Panel fullHeight>
            <WidgetRoot>
                <WidgetFlexible>
                    <Value label="Celkem" value={<CurrencyValue currencyCode={data.currencyCode} value={data.total} />} big />
                    <Value label="Příjmy" value={<CurrencyValue currencyCode={data.currencyCode} value={data.income} />} />
                    <Value label="Výdaje" value={<CurrencyValue currencyCode={data.currencyCode} value={data.expenses} />} />
                </WidgetFlexible>
                <WidgetFlexible>
                    <TrendsBarChart
                        data={data.periods}
                        period={period}
                        disabled={disabled}
                        orientation="vertical"
                        onClick={setPeriod ? updatePeriodOnClick(setPeriod) : null}
                    />
                </WidgetFlexible>
            </WidgetRoot>
            <div style={{ display: "flex", height: theme.spacing(4), backgroundColor: "#E0E0E0", marginTop: theme.spacing(2) }}>
                <div style={{ flex: 278064, backgroundColor: theme.palette.primary.light }} />
                <div style={{ flex: 114589, backgroundColor: theme.palette.primary.dark }} />
                <div style={{ flex: 412500, backgroundColor: theme.palette.secondary.light }} />
                <div style={{ flex: 212500, background: "none" }} />
            </div>
        </Panel>
    );
};

//TODO add widgets

export const TransactionTypesWidget = ({ data, disabled, onClick, variant = "row", size }) => {
    const theme = useTheme();
    return (
        <Panel fullHeight>
            <WidgetRoot>
                <WidgetFlexible>
                    <ChartLabels
                        values={data || []}
                        color={disabled ? "#6A6A6A" : theme.palette.primary.main}
                        renderValue={(nv) => <CurrencyValue value={nv.value} currencyCode={nv.unit} />}
                        onClick={(nv) => {
                            onClick && onClick(nv.id);
                        }}
                        variant={variant}
                        size={size}
                    />
                </WidgetFlexible>
                <WidgetFlexible>
                    <DonutChart data={[(data || []).map((nv) => nv.value)]} colors={disabled || ["#4433aa", "#2faf6e"]} size={140} />
                </WidgetFlexible>
            </WidgetRoot>
        </Panel>
    );
};

export const TransactionsCounterpartiesWidget = ({ data, disabled, onClick, variant = "row", size }) => {
    const theme = useTheme();
    return (
        <Panel fullHeight>
            <WidgetRoot>
                <WidgetFlexible>
                    <ChartLabels
                        values={data || []}
                        color={disabled ? "#6A6A6A" : theme.palette.primary.main}
                        renderValue={(nv) => <CurrencyValue value={nv.value} currencyCode={nv.unit} />}
                        onClick={(nv) => {
                            onClick && onClick(nv.id);
                        }}
                        variant={variant}
                        size={size}
                    />
                </WidgetFlexible>
                <WidgetFlexible>
                    <DonutChart data={[(data || []).map((nv) => nv.value)]} colors={disabled || ["#4433aa", "#2faf6e"]} size={140} />
                </WidgetFlexible>
            </WidgetRoot>
        </Panel>
    );
};

const useStatusesChartStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        height: theme.spacing(4),
        backgroundColor: "#E0E0E0",
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
}));

export const TransactionStatusesChart = ({ data, disabled }) => {
    const classes = useStatusesChartStyles();
    const theme = useTheme();
    const settings = useSettings();

    if (!data) {
        data = {};
    }

    let toBePaidColor, paidColor, overdueColor;
    if (disabled) {
        toBePaidColor = "#454545";
        paidColor = "#818181";
        overdueColor = "#a6a6a6";
    } else {
        toBePaidColor = theme.palette.primary.light;
        paidColor = theme.palette.secondary.light;
        overdueColor = "#f79239";
    }

    let currency = data.currencyCode || settings.defaultCurrencyCode,
        toBePaid = formatCurrency(
            data.toBePaid || 0,
            currency,
            settings.currencies,
            settings.decimalDigits,
            settings.decimalDelimiter,
            settings.thousandDelimiter
        ),
        paid = formatCurrency(data.paid || 0, currency, settings.currencies, settings.decimalDigits, settings.decimalDelimiter, settings.thousandDelimiter),
        overdue = formatCurrency(
            data.overdue || 0,
            currency,
            settings.currencies,
            settings.decimalDigits,
            settings.decimalDelimiter,
            settings.thousandDelimiter
        ),
        other = formatCurrency(data.other || 0, currency, settings.currencies, settings.decimalDigits, settings.decimalDelimiter, settings.thousandDelimiter);

    return (
        <>
            <div className={classes.root}>
                <div style={{ flex: data.toBePaid || 0, backgroundColor: toBePaidColor }} title={`Čeká na zaplacení: ${toBePaid}`} />
                <div style={{ flex: data.paid || 0, backgroundColor: paidColor }} title={`Zaplaceno: ${paid}`} />
                <div style={{ flex: data.overdue || 0, backgroundColor: overdueColor }} title={`Nezaplaceno po splatnosti: ${overdue}`} />
                <div style={{ flex: data.other || 0, background: "none" }} title={`Ostatní: ${other}`} />
            </div>
            <ChartLabels
                values={[
                    { name: "Čeká na zaplacení", value: toBePaid, color: toBePaidColor },
                    { name: "Zaplaceno", value: paid, color: paidColor },
                    { name: "Nezaplaceno po splatnosti", value: overdue, color: overdueColor },
                    { name: "Ostatní", value: other, color: "#E0E0E0" },
                ]}
                renderValue={(nv) => nv.value}
                variant="column"
                size="small"
                useDataColors
            />
        </>
    );
};

const useReportStyles = makeStyles((theme) => ({
    root: {
        display: "grid",
        gridTemplateColumns: "repeat(4, 1fr)",
        gap: theme.spacing(4),
    },
    hidden: {
        [theme.breakpoints.down("md")]: {
            display: "none",
        },
    },
    main: {
        gridColumn: "span 3/span 3",
        [theme.breakpoints.down("lg")]: {
            gridColumn: "span 4/span 4",
        },
    },
    secondary: {
        gridColumn: "span 1/span 1",
        [theme.breakpoints.down("lg")]: {
            gridColumn: "span 4/span 4",
        },
        textAlign: "center",
    },
}));

export const AccountingTransactionReportsContainer = ({ menuItems, reportId }) => {
    const { hideDetail, refreshMaster } = useMasterDetail();
    const settings = useSettings();
    const { startLoading, stopLoading, isLoading } = useLoadingIndicator();
    const [state, setState] = useState({});
    const classes = useReportStyles();

    const fetchData = async () => {
        startLoading();
        let result = await Promise.allSettled([
            fetchAccountingTransactionReport(reportId),
            fetchAccountingTransactionReportInstances(reportId, { page: 0, size: 10 }),
            fetchAccountingTransactionReportSummary(reportId),
        ]);

        let report = {},
            instances = {},
            summary = {};

        let dataResult = result[0];
        if ((dataResult.status = "fulfilled")) {
            report = dataResult.value;
        }

        dataResult = result[1];
        if ((dataResult.status = "fulfilled")) {
            instances = dataResult.value;
        }

        dataResult = result[2];
        if ((dataResult.status = "fulfilled")) {
            summary = dataResult.value;
        }

        setState({ report, instances, summary, open: {} });

        stopLoading();
    };

    useEffect(() => {
        fetchData();
    }, [reportId]);

    return (
        <Page>
            <LoadingIndicator hidden="smDown" />
            <PageTitle title={(state.report && state.report.name) || "Přehled transakcí"} menuItems={menuItems} onBack={hideDetail}></PageTitle>
            <LoadingIndicator hidden="smUp" />
            <MasterDetailContent>
                <Box mb={4}>
                    <Panel>
                        <Stack
                            direction={{ xs: "column", sm: "row" }}
                            spacing={4}
                            divider={<Divider orientation="vertical" flexItem={true} className={classes.hidden} />}
                        >
                            <div style={{ flex: "1 1 auto" }}>
                                <Value
                                    label="Celkem"
                                    big
                                    value={
                                        <CurrencyValue
                                            value={(state && state.summary && state.summary.total) || 0}
                                            currencyCode={state.summary && state.summary.currencyCode}
                                        />
                                    }
                                />
                            </div>
                            <div style={{ flex: "1 1 auto" }}>
                                <TrendsBarChart
                                    data={
                                        (state.instances &&
                                            state.instances.content &&
                                            state.instances.content.map((i) => ({ name: i.fiscalYear, value: i.total }))) ||
                                        []
                                    }
                                    disabled={isLoading}
                                    formatValue={(v) =>
                                        `${formatCurrency(
                                            v.value,
                                            settings.defaultCurrencyCode,
                                            settings.currencies,
                                            settings.decimalDigits,
                                            settings.decimalDelimiter,
                                            settings.thousandDelimiter
                                        )}`
                                    }
                                />
                            </div>
                        </Stack>
                    </Panel>
                </Box>

                <Box mb={4}>
                    {!!state.instances &&
                        !!state.instances.content &&
                        state.instances.content.map((instance) => (
                            <Box key={instance.id} mb={2}>
                                <DetailRow
                                    open={state.open && state.open[instance.id]}
                                    setOpen={(fo) => setState({ ...state, open: { ...state.open, [instance.id]: !!fo } })}
                                    renderDetail={() => (
                                        <div className={classes.root}>
                                            <div className={classes.main}>
                                                <TransactionsList data={instance.transactions || []} buttons={[]} withDetail />
                                            </div>
                                            <div className={classes.secondary}>
                                                <AttachmentChips
                                                    value={instance.attachments}
                                                    disabled={isLoading}
                                                    url={(a) => `/api/transactions/reports/${reportId}/instances/${instance.id}/attachments/${a.id}`}
                                                    center
                                                />
                                            </div>
                                        </div>
                                    )}
                                >
                                    <DetailRowMain>
                                        <Typography variant="body1">{instance.fiscalYear}</Typography>
                                    </DetailRowMain>
                                    <DetailRowValue>
                                        <Typography variant="body1">
                                            {formatCurrency(
                                                instance.total,
                                                instance.currencyCode,
                                                settings.currencies,
                                                settings.decimalDigits,
                                                settings.decimalDelimiter,
                                                settings.thousandDelimiter
                                            )}
                                        </Typography>
                                    </DetailRowValue>
                                </DetailRow>
                            </Box>
                        ))}

                    {!!state.instances && state.instances.totalPages > 1 && (
                        <Pagination
                            count={state.instances.totalPages}
                            siblingCount={1}
                            boundaryCount={1}
                            onChange={async (e, page) => {
                                startLoading();
                                try {
                                    let instances = await fetchAccountingTransactionReportInstances(reportId, { page: page - 1, size: 10 });
                                    setState({ ...state, instances });
                                } catch (e) {
                                    console.error(e);
                                    alert("error");
                                }
                                stopLoading();
                            }}
                        />
                    )}
                </Box>
            </MasterDetailContent>
        </Page>
    );
};

/* ******** utils ******** */
export const calculateTax = (tax, baseAmount, units) => {
    if (tax.calculationType === "PERCENTAGE") {
        //FIXME
        return (tax.value / 100) * baseAmount;
    } else if (tax.calculationType === "PER_UNIT") {
        //FIXME
        return tax.value * units;
    }
    return tax.value;
};

/* ******** API ******** */

export const fetchTransaction = async (id) => {
    let response = await backend(`/api/transactions/${id}`);
    return response.body;
};

export const saveTransaction = async (item) => {
    let formData = prepareMultipartRequest(item);
    let response = await backend(`/api/transactions${item.id ? "/" + item.id : ""}`, item.id ? "PUT" : "POST", {
        body: formData,
        upload: true,
    });
    return response.body;
};

export const fetchTransactions = async (filter, paging) => {
    let response = await backend("/api/transactions", "GET", { params: { ...(filter || {}), ...(paging || {}) } });
    return response.body; // { ...emptyPage, content: [...mockedProjects] };
};

export const fetchSummary = async (from, to) => {
    let response = await backend("/api/transactions/summary", "GET", { params: { from, to } });
    return response.body;
};

export const fetchSummaryPeriods = async (date, periodType, size) => {
    let response = await backend("/api/transactions/summary/periods", "GET", { params: { date, periodType, size } });
    return response.body;
};

export const fetchSummaryFiscal = async (date) => {
    let response = await backend("/api/transactions/summary/fiscal", "GET", { params: { date } });
    return response.body;
};

export const fetchSummaryTypes = async (from, to, size) => {
    let response = await backend("/api/transactions/summary/types", "GET", { params: { from, to, size } });
    return response.body;
};

export const fetchSummaryStatus = async (from, to, size) => {
    let response = await backend("/api/transactions/summary/status", "GET", { params: { from, to, size } });
    return response.body;
};

export const fetchSummaryCounterparties = async (from, to, size) => {
    let response = await backend("/api/transactions/summary/counterparties", "GET", { params: { from, to, size } });
    return response.body;
};

export const fetchCashflow = async (from, to, periodType) => {
    let response = await backend("/api/transactions/cashflow", "GET", { params: { from, to, periodType } });
    return response.body;
};

export const fetchCashflowFiscal = async (date) => {
    let response = await backend("/api/transactions/cashflow/fiscal", "GET", { params: { date } });
    return response.body;
};

export const deleteTransactionAttachment = async (transactionId, attachmentId) => {
    await backend(`/api/transactions/${transactionId}/attachments/${attachmentId}`, "DELETE");
};

export const saveTransactionItem = async (transactionId, item) => {
    let response = await backend(`/api/transactions/${transactionId}/items${item.id ? "/" + item.id : ""}`, item.id ? "PUT" : "POST", {
        body: item,
    });
    return response.body;
};

export const deleteTransactionItem = async (transactionId, itemId) => {
    await backend(`/api/transactions/${transactionId}/items/${itemId}`, "DELETE");
};

export const fetchTransactionPdf = async (id) => {
    await download(`/api/transactions/${id}/pdf`);
};

export const fetchTransactionTypes = async (filter) => {
    let params = {};
    if (filter) {
        Object.keys(filter).forEach((key) => {
            if (typeof filter[key] !== "undefined") {
                params[key] = filter[key];
            }
        });
    }
    let response = await backend(`/api/transactions/types`, "GET", { params });
    return response.body;
};

export const saveTransactionType = async (type) => {
    let response = await backend(`/api/transactions/types` + (type.id ? "/" + type.id : ""), type.id ? "PUT" : "POST", {
        body: type,
    });
    return response.body;
};

export const fetchTaxTypes = async () => {
    let response = await backend(`/api/transactions/tax/types`);
    return response.body;
};

export const saveTaxType = async (type) => {
    let response = await backend(`/api/transactions/tax/types` + (type.id ? "/" + type.id : ""), type.id ? "PUT" : "POST", {
        body: type,
    });
    return response.body;
};

export const fetchAccountingTransactionReports = async () => {
    let response = await backend(`/api/transactions/reports`);
    return response.body;
};

export const fetchAccountingTransactionReport = async (reportId) => {
    let response = await backend(`/api/transactions/reports/${reportId}`);
    return response.body;
};

export const fetchAccountingTransactionReportInstances = async (reportId, paging) => {
    let response = await backend(`/api/transactions/reports/${reportId}/instances`, "GET", { params: { ...(paging || {}) } });
    return response.body;
};

export const fetchAccountingTransactionReportSummary = async (reportId) => {
    let response = await backend(`/api/transactions/reports/${reportId}/summary`);
    return response.body;
};

export const fetchAccountingTransactionOverviewStatuses = async (from, to, date) => {
    let response = await backend(`/api/transactions/overview/statuses`, "GET", { params: { from, to, date } });
    return response.body;
};
