import React, {useEffect, useState} from "react";
import {useTheme} from "@mui/material/styles";
import {makeStyles} from "@mui/styles";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import classnames from "classnames";
import moment from "moment";

const createClasses = makeStyles((theme) => ({
    grid: {
        width: "100%",
        display: "grid",
        gridTemplateColumns: "repeat(7, 1fr)",
        gridAutoRows: "min() 1fr",
        gap: "4px 4px",
        "& > div": {
            width: "100%",
            position: "relative",
        },
    },
    dayName: {
        paddingTop: 0,
        paddingBottom: theme.spacing(1),
        textAlign: "center",
        color: "#888",
        fontWeight: 700,
    },
    padding: {
        background: "none",
    },
    day: {
        backgroundColor: "#F6F6F6",
        paddingTop: "100%",
        cursor: "default",
        "& > div": {
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            fontWeight: 700,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
        },
        "& > .MonthDayAction": {
            display: "none",
        },
        "&:hover > .MonthDayAction": {
            display: "block",
        },
    },
    weekDay: {
        overflow: "hidden",
        position: "relative",
    },
    weekDayHeader: {
        position: "relative",
        backgroundColor: "#F6F6F6",
        paddingTop: "100%",
        cursor: "pointer",
        "& > div": {
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
        },
        "& > .MonthDayAction": {
            display: "none",
        },
        "&:hover > .MonthDayAction": {
            display: "block",
        },
    },
    weekDayNumber: {
        fontWeight: 700,
        color: "#3B3B3B",
    },
    dayClickable: {
        cursor: "pointer",
    },
    dayContainer: {
        minHeight: 250,
    },
    dayAction: {
        paddingTop: 24,
        paddingBottom: 24,
        fontWeight: 700,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
    },
    monthDayAction: {
        position: "absolute",
        right: 0,
        bottom: 0,
    },
}));

//TODO
const dayNames = ["Po", "Út", "St", "Čt", "Pá", "So", "Ne"];

export const MonthDay = ({ children, onClick, color, day, dayNumber, onAction, actionIcon, actionTitle }) => {
    const classes = createClasses();
    const theme = useTheme();

    return (
        <div
            className={classnames(classes.day, !!onClick && classes.dayClickable)}
            onClick={() => onClick && onClick(day)}
            style={!!color ? { color: theme.palette.getContrastText(color), backgroundColor: color } : null}
        >
            <div>{dayNumber}</div>
            {children}
            {!!onAction && (
                <IconButton
                    className={classnames(classes.monthDayAction, "MonthDayAction")}
                    title={actionTitle || null}
                    onClick={(e) => {
                        e.stopPropagation();
                        onAction && onAction(day);
                    }}
                    size="small"
                    style={!!color ? { color: theme.palette.getContrastText(color) } : null}
                >
                    {actionIcon || null}
                </IconButton>
            )}
        </div>
    );
};

const _defaultRenderDay = (day, onClick) => {
    return <MonthDay key={day.date} day={day.date} dayNumber={day.dayNumber} onClick={onClick} />;
};

export const MonthCalendar = ({ month = 11, year = 2021, onClick, renderDay, ...props }) => {
    const classes = createClasses();
    const [days, setDays] = useState([]);

    //const baseColor = theme.palette.primary.main;

    //prepare grid
    useEffect(() => {
        let ds = [];
        let m = moment();
        m.year(year);
        m.month(month);
        m = m.startOf("month");

        if (!month) {
            month = m.month();
        }

        //TODO start of week
        if (m.isoWeekday() != 1) {
            m.isoWeekday(1);
            while (m.month() != month) {
                ds.push({ padding: true });
                m = m.add("day", 1);
            }
        }

        while (m.month() == month) {
            ds.push({ date: m.format("YYYY-MM-DD"), dayNumber: m.get("date") });
            m = m.add("day", 1);
        }

        setDays(ds);
    }, [month, year]);

    const render = renderDay || _defaultRenderDay;

    return (
        <div className={classes.grid}>
            {dayNames.map((name, key) => (
                <div className={classes.dayName} key={key}>
                    {name}
                </div>
            ))}
            {days.map((d, key) => {
                if (d.padding) {
                    return <div key={key} className={classes.padding} />;
                }

                return <React.Fragment key={key}>{render(d, onClick)}</React.Fragment>;
            })}
        </div>
    );
};

export const WeekCalendar = ({ week = 0, year = 2021, onClick, renderDay, onAction, actionIcon, actionTitle }) => {
    const classes = createClasses();

    const m = moment().utc();
    m.year(year);
    m.isoWeek(week);
    m.startOf("isoWeek");

    const render = renderDay || (() => null);

    return (
        <div className={classes.grid}>
            {dayNames.map((name, key) => {
                let md = moment(m).utc().add("days", key);
                const day = md.format("YYYY-MM-DD");
                return (
                    <div key={key} className={classes.weekDay}>
                        <div className={classes.weekDayHeader} onClick={() => !!onClick && onClick(day)}>
                            <div>
                                <div className={classes.weekDayNumber}>{name}</div>
                                <Typography component="div" variant="body2">
                                    {md.format("DD.MM.")}
                                </Typography>
                            </div>
                            {!!onAction && (
                                <IconButton
                                    className={classnames(classes.monthDayAction, "MonthDayAction")}
                                    title={actionTitle || null}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        onAction && onAction(day);
                                    }}
                                    size="small"
                                >
                                    {actionIcon || null}
                                </IconButton>
                            )}
                        </div>
                        <div>{renderDay(day)}</div>
                    </div>
                );
            })}
        </div>
    );
};

export const DayCalendar = ({ date, renderDay }) => {
    const classes = createClasses();
    const render = renderDay || (() => null);

    const md = moment(date);

    return <div className={classes.dayContainer}>{renderDay(md.format("YYYY-MM-DD"))}</div>;
};

export const DayCalendarAction = ({ icon, onClick, title }) => {
    const classes = createClasses();

    return (
        <div className={classes.dayAction}>
            <IconButton onClick={onClick} title={title}>
                {icon || null}
            </IconButton>
        </div>
    );
};
