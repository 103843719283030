import React, {useEffect, useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";

import {makeStyles} from "@mui/styles";
import Collapse from "@mui/material/Collapse";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import AddIcon from "@mui/icons-material/Add";
import DashboardIcon from "@mui/icons-material/Dashboard";
import FilterListIcon from "@mui/icons-material/FilterList";
import EditIcon from "@mui/icons-material/Edit";
import PrintIcon from "@mui/icons-material/Print";

import {useTheme} from "@mui/material/styles";

import {Page, PageTitle, Panel} from "../components/Layout";
import {
    MasterContent,
    MasterDetail,
    MasterDetailContent,
    useMasterDetail,
    useMasterDetailParams,
    useMasterRefresh,
} from "../components/MasterDetail";
import Period, {nextPeriodType, usePeriod} from "../components/period";
import {useLoadableList} from "../utils/lists";
import {
    CashflowChart,
    deleteTransactionAttachment,
    deleteTransactionItem,
    fetchAccountingTransactionOverviewStatuses,
    fetchCashflow,
    fetchSummary,
    fetchSummaryCounterparties,
    fetchSummaryPeriods,
    fetchSummaryTypes,
    fetchTransaction,
    fetchTransactionPdf,
    fetchTransactions,
    fetchTransactionTypes,
    PaymentMethods,
    TransactionItemModal,
    TransactionItemsList,
    TransactionList,
    TransactionListFilter,
    TransactionModal,
    TransactionStatusesChart,
} from "../components/transactions";

import {LoadingIndicator, LoadingIndicatorContainer, useLoadingIndicator} from "../utils/loading";
import {useForm} from "../utils/form";
import {useSettings} from "../utils/settings";

import {AttachmentChips} from "../components/Attachments";
import {CurrencyValue, formatCurrency, NumberValue} from "../components/NumberFields";
import Value from "../components/Value";
import {ChartLabels, DonutChart} from "../components/Charts";
import {formatDate} from "../components/DateFields";
import TrendsBarChart, {updatePeriodOnClick} from "../components/TrendsBarChart";
import {useModal} from "../components/Modal";

import moment from "moment";

const TransactionsMaster = ({ menuItems, showFilter, setShowFilter, periodRef }) => {
    const params = useMasterDetailParams();
    const { showDetail, showOverview, showAdd, mobile, detailId } = useMasterDetail();
    const filterForm = useForm();
    const loadableList = useLoadableList();
    const [period, setPeriod] = periodRef;

    const { startLoading, stopLoading, isLoading } = useLoadingIndicator();

    const handleFilterChange = (filter) => {
        fetchList(filter);
    };

    const fetchList = async (filter, paging) => {
        startLoading();
        try {
            const data = await fetchTransactions({ ...filter, from: period.start, to: period.end }, paging);
            loadableList.acceptData(data);
        } catch (e) {
            console.error(e);
        } finally {
            stopLoading();
        }
    };

    useEffect(() => {
        //fetch data
        fetchList(filterForm.values);
    }, []);

    useMasterRefresh(() => fetchList(filterForm.valuesRef.current));

    useEffect(() => {
        fetchList(filterForm.values);
    }, [period]);

    return (
        <>
            <Collapse in={showFilter}>
                <Box p={2} pb={0}>
                    <Period periodRef={periodRef} disabled={!!isLoading} fullWidth size="small" year quarter month week day />
                    <TransactionListFilter onFilterChange={handleFilterChange} form={filterForm} />
                </Box>
            </Collapse>
            <TransactionList
                disabled={isLoading}
                data={loadableList.data}
                hasMore={loadableList.size < loadableList.totalElements - 1}
                onShowMoreClick={() => fetchList(filterForm.values, loadableList.nextPageParams)}
                onItemClick={({ id }) => showDetail(id)}
                selectedId={params.tabName}
            />
        </>
    );
};

const TransactionMasterContainer = ({ menuItems, periodRef }) => {
    const { showDetail, showOverview, showAdd, mobile, refreshMaster } = useMasterDetail();
    const [state, setState] = useState({});
    const { startLoading, stopLoading, isLoading } = useLoadingIndicator();
    const settings = useSettings();

    const loadTypes = async () => {
        startLoading();
        try {
            let transactionTypes = await fetchTransactionTypes();
            setState({ ...state, transactionTypes });
        } catch (e) {
            console.error(e);
            alert(e);
        }
        stopLoading();
    };

    const toggleFilter = () => {
        setState({ ...state, showFilter: !state.showFilter });
    };

    useEffect(() => loadTypes(), []);

    const hasTypes = Array.isArray(state.transactionTypes) && state.transactionTypes.length;

    //remember context menu items
    const masterMenuItems = [
        {
            icon: <AddIcon />,
            title: "Přidat transakci",
            alwaysVisible: true,
            disabled: !!isLoading,
            onClick: hasTypes ? undefined : () => setState({ ...state, modalData: {} }),
            items: hasTypes
                ? state.transactionTypes.map((type) => ({
                      icon: <AddIcon />,
                      title: type.name,
                      onClick: () => {
                          let { id, name, ...t } = type;
                          let subject = {};
                          if (t.hasSubjectInfo) {
                              subject.subjectName = settings.name;
                              subject.subjectIdNumber = settings.idNumber;
                              subject.subjectTaxIdNumber = settings.taxIdNumber;
                              subject.subjectAddress = settings.address;
                          }

                          setState({
                              ...state,
                              modalData: {
                                  name,
                                  typeName: name,
                                  typeId: id,
                                  ...t,
                                  date: moment().format("YYYY-MM-DD"),
                                  fiscalYear: moment().format("YYYY"),
                                  defualCurrencyCode: settings.defaultCurrencyCode,
                                  currencyCode: settings.defaultCurrencyCode,
                                  ...subject,
                              },
                          });
                      },
                  }))
                : undefined,
        },
        {
            icon: <FilterListIcon />,
            title: "Filtrovat",
            alwaysVisible: true,
            onClick: toggleFilter,
            badgeVariant: "dot",
            badgeContent: !state.showFilter,
        },
        { icon: <DashboardIcon />, title: "Přehled", onClick: showOverview },
    ];

    return (
        <Page>
            <LoadingIndicatorContainer>
                <LoadingIndicator color="secondary" hidden="smDown" />
                <PageTitle title="Finance" menuItems={mobile ? [...masterMenuItems, ...menuItems] : masterMenuItems} />
                <LoadingIndicator color="secondary" hidden="smUp" />
                <MasterContent>
                    <TransactionsMaster menuItems={menuItems} showFilter={state.showFilter} setShowFilter={toggleFilter} periodRef={periodRef} />
                </MasterContent>

                <TransactionModal
                    data={state.modalData}
                    open={!!state.modalData}
                    onDataSaved={refreshMaster}
                    onClose={() => setState({ ...state, modalData: undefined })}
                />
            </LoadingIndicatorContainer>
        </Page>
    );
};

const TransactionDetailContainer = ({ menuItems, readOnly, disabled }) => {
    const settings = useSettings();
    const { hideDetail, refreshMaster } = useMasterDetail();
    const { detailId } = useMasterDetailParams();

    const [state, setState] = useState({});
    const { startLoading, stopLoading, isLoading } = useLoadingIndicator();
    const form = useForm({ initialValues: {} });
    const { values, handleChange, errors, setErrors, handleSubmit, setValues } = form;

    const loadTransaction = async (detailId) => {
        startLoading();
        try {
            let values = await fetchTransaction(detailId);
            setValues(values);
        } catch (e) {
            alert("error");
        }
        stopLoading();
    };
    /*
    const saveTransaction = async (values) => {
        startLoading();
        //await wait(5000);
        stopLoading();
    };
*/
    const deleteItem = async (item) => {
        startLoading();
        //await wait(5000);
        try {
            await deleteTransactionItem(detailId, item.id);
        } catch (e) {
            alert("error");
        }
        stopLoading();
        onDataSaved();
    };

    const onItemSaved = (values, submitHints) => {
        if (submitHints && submitHints.keepOpen) {
            setState({ ...state, itemModalData: {} });
        }
        if (detailId) {
            loadTransaction(detailId);
        }
        refreshMaster();
    };
    const onDataSaved = (values) => {
        refreshMaster();
        if (detailId) {
            loadTransaction(detailId);
        }
    };

    const detailMenuItems = useMemo(() => {
        let items = [
            {
                alwaysVisible: false,
                title: "Upravit",
                icon: <EditIcon />,
                onClick: () => {
                    setState({ ...state, modalData: values || {} });
                },
            },
        ];

        if (!!values.printable) {
            items.push({
                alwaysVisible: false,
                title: "Export",
                icon: <PrintIcon />,
                onClick: async () => {
                    try {
                        startLoading();
                        await fetchTransactionPdf(detailId);
                    } catch (e) {
                        alert("error");
                        console.error(e);
                    } finally {
                        stopLoading();
                    }
                },
            });
        }

        return [...items, ...menuItems];
    }, [detailId, values]);

    useEffect(() => {
        if (!!detailId) {
            loadTransaction(detailId);
        }
    }, [detailId]);

    const _disabled = !!disabled || !!readOnly || !!isLoading;

    const widgets = [];
    let paymentInstructionsRendered = false;
    if (values) {
        if (values.hasCpty || values.hasProjectReference) {
            widgets.push(
                <Grid item xs={12} lg={6} key="cpty">
                    <Panel fullHeight>
                        <Typography variant="body1" component="div">
                            <b>{values.counterpartyName}</b>
                        </Typography>
                        <Typography variant="body1" component="div" sx={{ whiteSpace: "pre" }}>
                            {values.counterpartyAddress}
                        </Typography>
                        {!!values.counterpartyIdNumber && (
                            <Typography variant="body1" component="div">
                                IČ: {values.counterpartyIdNumber}
                            </Typography>
                        )}
                        {!!values.counterpartyTaxIdNumber && (
                            <Typography variant="body1" component="div">
                                DIČ: {values.counterpartyTaxIdNumber}
                            </Typography>
                        )}
                        {!!values.projectId && <Value label="Projekt" value={values.projectName} />}
                    </Panel>
                </Grid>
            );
        } else if (values.hasPaymentInstructions) {
            widgets.push(
                <Grid item xs={12} lg={6}>
                    <Panel fullHeight>
                        <PaymentMethods value={values.paymentMethods} total={values.totalPayable || values.total || 0} />
                    </Panel>
                </Grid>
            );
            paymentInstructionsRendered = true;
        } else {
            widgets.push(<Grid item xs={12} lg={6} />);
        }

        widgets.push(
            <Grid item xs={12} lg={6}>
                <Panel fullHeight>
                    <Grid container columnSpacing={1}>
                        <Grid item xs={8}>
                            <Grid container rowSpacing={2} columnSpacing={4}>
                                <Grid item xs={6}>
                                    {!!values.hasIdentificator && <Value label="Identifikátor transacke" value={values.transactionId} />}
                                    {!!values.hasStatus && <Value label="Stav" value={values.status} />}
                                </Grid>
                                <Grid item xs={6}>
                                    <Value label="Datum" value={formatDate(values.date, settings.dateFormat)} />
                                    {!!values.hasDueDate && <Value label="Datum splatnosti" value={formatDate(values.due, settings.dateFormat)} />}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                            <Value label="Celkem" big value={<CurrencyValue currencyCode={values.currencyCode} value={values.total} />} align="right" />
                            {!isNaN(values.totalPayable) && values.total != values.totalPayable && (
                                <Value
                                    label="Celkem k zaplacení"
                                    value={<CurrencyValue currencyCode={values.currencyCode} value={values.totalPayable} />}
                                    align="right"
                                />
                            )}
                        </Grid>
                    </Grid>

                    {Array.isArray(values.attachments) && !!values.attachments.length && (
                        <Box mt={2}>
                            <AttachmentChips
                                value={values.attachments}
                                size="small"
                                url={(attachment) => `/api/transactions/${detailId}/attachments/${attachment.id}`}
                                onDelete={(item) => deleteTransactionAttachment(detailId, item.id)}
                            />
                        </Box>
                    )}
                </Panel>
            </Grid>
        );

        if (values.hasItems) {
            widgets.push(
                <Grid item xs={12}>
                    <Box my={4}>
                        <TransactionItemsList
                            data={values.items || undefined}
                            onAdd={() => setState({ ...state, itemModalData: {} })}
                            onEdit={(item) => setState({ ...state, itemModalData: item })}
                            onDelete={deleteItem}
                            readOnly={!!readOnly}
                            disabled={_disabled}
                            currencyCode={values.currencyCode}
                        />
                    </Box>
                </Grid>
            );
        }

        if (!paymentInstructionsRendered && values.hasPaymentInstructions) {
            widgets.push(
                <Grid item xs={12} lg={6}>
                    <Panel fullHeight>
                        <PaymentMethods value={values.paymentMethods} total={values.totalPayable || values.total || 0} />
                    </Panel>
                </Grid>
            );
        }
    }

    return (
        <Page>
            <MasterDetailContent
                showPageTitle
                showLoadingIndicator
                pageTitle={values.name || "Finance"}
                menuItems={detailMenuItems}
                onBack={hideDetail}
                disabled={isLoading}
            >
                <Box mb={3}>
                    <Grid rowSpacing={2} columnSpacing={4} container>
                        {widgets}

                        <Grid item xs={12} lg={6}>
                            {Array.isArray(values.taxSummary) && !!values.taxSummary.length && (
                                <Panel fullHeight>
                                    {Array.isArray(values.taxSummary) &&
                                        values.taxSummary.map((tax) => (
                                            <div key={tax.id}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12}>
                                                        <Typography variant="body1">{tax.name}</Typography>
                                                    </Grid>
                                                    <Grid item xs>
                                                        <Value
                                                            label="Sazba"
                                                            value={
                                                                tax.calculationType === "PERCENTAGE" ? (
                                                                    <NumberValue value={tax.value} unit="%" />
                                                                ) : (
                                                                    <CurrencyValue currencyCode={values.currencyCode} value={tax.value} />
                                                                )
                                                            }
                                                        />
                                                    </Grid>
                                                    <Grid item xs>
                                                        <Value
                                                            label="Základ daně"
                                                            value={<CurrencyValue currencyCode={values.currencyCode} value={tax.base} />}
                                                        />
                                                    </Grid>
                                                    <Grid item xs>
                                                        <Value
                                                            label="Daň"
                                                            value={<CurrencyValue currencyCode={values.currencyCode} value={tax.total} />}
                                                            align="right"
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        ))}
                                </Panel>
                            )}
                        </Grid>
                    </Grid>
                </Box>

                <TransactionItemModal
                    transactionId={values.id}
                    data={state.itemModalData}
                    open={!!state.itemModalData}
                    onDataSaved={onItemSaved}
                    onClose={() => setState({ ...state, itemModalData: undefined })}
                />

                <TransactionModal
                    transactionId={values.id}
                    data={state.modalData}
                    open={!!state.modalData}
                    onDataSaved={onDataSaved}
                    onClose={() => setState({ ...state, modalData: undefined })}
                />
            </MasterDetailContent>
        </Page>
    );
};

const createOverviewClasses = makeStyles((theme) => ({
    dashboardTopRow: {
        display: "grid",
        gridTemplateColumns: "2fr 3fr 3fr",
        gap: theme.spacing(4),
        [theme.breakpoints.down("lg")]: {
            gridTemplateColumns: "1fr",
        },
    },
    dashboardSecondRow: {
        display: "grid",
        gridTemplateColumns: "7fr 3fr",
        gap: theme.spacing(4),
        [theme.breakpoints.down("xl")]: {
            gridTemplateColumns: "1fr",
        },
    },
    dashboardSecondRowCharts: {
        display: "grid",
        gridTemplateColumns: "1fr",
        gap: theme.spacing(2),
        overflow: "hidden",
        [theme.breakpoints.down("xl")]: {
            gridTemplateColumns: "1fr 1fr",
        },
        [theme.breakpoints.down("lg")]: {
            gridTemplateColumns: "1fr",
        },
    },
    smallChart: {
        marginTop: theme.spacing(2),
        display: "grid",
        gridTemplateColumns: "2fr 3fr",
        gap: theme.spacing(1),
        alignItems: "stretch",
        overflow: "hidden",
        "& > *": {
            overflow: "hidden",
        },
    },
}));

const TransactionsOverview = ({ menuItems, periodRef }) => {
    const theme = useTheme();
    const classes = createOverviewClasses();
    const { startLoading, stopLoading, isLoading } = useLoadingIndicator();
    const { hideDetail, refreshMaster, mobile, showDetail } = useMasterDetail();
    const [period, setPeriod] = periodRef;
    const isDisabled = !!isLoading;
    const [data, setData] = useState({ cashflowType: "fiscal", summary: {}, types: [], periods: [], cashflow: {}, status: {} });
    const settings = useSettings();
    const transactionModal = useModal();
    const navigate = useNavigate();
    const [transactionTypes, setTransactionTypes] = useState([]);

    const loadTypes = async () => {
        startLoading();
        try {
            let transactionTypes = await fetchTransactionTypes();
            setTransactionTypes(transactionTypes);
        } catch (e) {
            console.error(e);
            alert(e);
        }
        stopLoading();
    };

    const fetchData = async () => {
        startLoading();
        let start = period.start,
            end = period.end;
        if (data.cashflowType == "fiscal") {
            //TODO
            let m = moment(period.date);
            start = m.startOf("year").format("YYYY-MM-DD");
            end = m.endOf("year").format("YYYY-MM-DD");
        }

        let result = await Promise.allSettled([
            fetchSummary(start, end),
            fetchSummaryTypes(start, end),
            fetchSummaryPeriods(period.start, period.type),
            data.cashflowType == "fiscal" ? fetchCashflow(start, end, period.type) : fetchCashflow(start, end, nextPeriodType(period.type)),
            fetchAccountingTransactionOverviewStatuses(start, end, moment().format("YYYY-MM-DD")),
            fetchSummaryCounterparties(start, end),
        ]);
        let summary = {},
            types = [],
            periods = [],
            cashflow = {},
            status = [],
            counterparties = [];

        let dataResult = result[0];
        if ((dataResult.status = "fulfilled")) {
            summary = dataResult.value;
        }
        dataResult = result[1];
        if ((dataResult.status = "fulfilled")) {
            types = dataResult.value;
        }
        dataResult = result[2];
        if ((dataResult.status = "fulfilled")) {
            periods = dataResult.value;
            if (summary) {
                summary.periods = periods;
            }
        }
        dataResult = result[3];
        if ((dataResult.status = "fulfilled")) {
            cashflow = dataResult.value;
        }
        dataResult = result[4];
        if ((dataResult.status = "fulfilled")) {
            status = dataResult.value;
        }
        dataResult = result[5];
        if ((dataResult.status = "fulfilled")) {
            counterparties = dataResult.value;
        }

        setData({ ...data, summary, types, periods, cashflow, status, counterparties });

        stopLoading();
    };

    useEffect(() => {
        fetchData();
    }, [period, data.cashflowType]);

    useEffect(() => loadTypes(), []);

    const hasTypes = Array.isArray(transactionTypes) && transactionTypes.length;

    const overviewMenuItems = [
        {
            icon: <AddIcon />,
            title: "Přidat transakci",
            alwaysVisible: true,
            disabled: !!isLoading,
            onClick: hasTypes ? undefined : () => transactionModal.showModal({}),
            items: hasTypes
                ? transactionTypes.map((type) => ({
                      icon: <AddIcon />,
                      title: type.name,
                      onClick: () => {
                          let { id, name, ...t } = type;
                          let subject = {};
                          if (t.hasSubjectInfo) {
                              subject.subjectName = settings.name;
                              subject.subjectIdNumber = settings.idNumber;
                              subject.subjectTaxIdNumber = settings.taxIdNumber;
                              subject.subjectAddress = settings.address;
                          }

                          transactionModal.showModal({
                              name,
                              typeName: name,
                              typeId: id,
                              ...t,
                              date: moment().format("YYYY-MM-DD"),
                              fiscalYear: moment().format("YYYY"),
                              defualCurrencyCode: settings.defaultCurrencyCode,
                              currencyCode: settings.defaultCurrencyCode,
                              ...subject,
                          });
                      },
                  }))
                : undefined,
        },
        ...(menuItems || []),
    ];

    return (
        <>
            <MasterDetailContent pageTitle="Přehled" menuItems={overviewMenuItems} onBack={hideDetail} disabled={isLoading} showPageTitle showLoadingIndicator>
                <Box mb={4}>
                    <Panel fullHeight>
                        <Box mb={2}>
                            <Period
                                periodRef={periodRef}
                                disabled={isDisabled}
                                year
                                quarter
                                month
                                week
                                day
                                actions={[
                                    {
                                        render: () => {
                                            return (
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            size="small"
                                                            color="secondary"
                                                            checked={data.cashflowType == "fiscal"}
                                                            onChange={(e) => {
                                                                setData({ ...data, cashflowType: e.target.checked ? "fiscal" : "period" });
                                                                e.stopPropagation();
                                                            }}
                                                            disabled={isLoading}
                                                        />
                                                    }
                                                    label={<Typography variant="caption">Souhrn za rok</Typography>}
                                                />
                                            );
                                        },
                                    },
                                ]}
                            />
                        </Box>
                        <div className={classes.dashboardTopRow}>
                            <div>
                                <Value
                                    label="Celkem"
                                    value={<CurrencyValue currencyCode={settings.defaultCurrencyCode} value={data.summary && data.summary.total} />}
                                    big
                                />
                                <Value
                                    label="Příjmy"
                                    value={<CurrencyValue currencyCode={settings.defaultCurrencyCode} value={data.summary && data.summary.income} />}
                                />
                                <Value
                                    label="Výdaje"
                                    value={<CurrencyValue currencyCode={settings.defaultCurrencyCode} value={data.summary && data.summary.expenses} />}
                                />
                            </div>
                            <div>
                                <Typography>Podle období</Typography>
                                <TrendsBarChart
                                    data={(data.summary && data.summary.periods) || []}
                                    period={period}
                                    disabled={isLoading}
                                    orientation="horizontal"
                                    onClick={setPeriod ? updatePeriodOnClick(setPeriod) : null}
                                    formatValue={(v) =>
                                        `${formatCurrency(
                                            v.value,
                                            settings.defaultCurrencyCode,
                                            settings.currencies,
                                            settings.decimalDigits,
                                            settings.decimalDelimiter,
                                            settings.thousandDelimiter
                                        )}`
                                    }
                                />
                            </div>
                            <div>
                                <Typography>Stav</Typography>
                                <TransactionStatusesChart data={data.status} disabled={isLoading} />
                            </div>
                        </div>
                    </Panel>
                </Box>
                <Box mb={4}>
                    <Panel fullHeight>
                        <div className={classes.dashboardSecondRow}>
                            <div>
                                <Typography>Peněžní tok</Typography>
                                <CashflowChart data={data.cashflow} disabled={!!isLoading} />
                            </div>
                            <div className={classes.dashboardSecondRowCharts}>
                                <div>
                                    <Typography gutterBottom>Protistrany</Typography>
                                    <div className={classes.smallChart}>
                                        <DonutChart
                                            data={[(data.counterparties || []).map((nv) => nv.value)]}
                                            colors={isLoading || [theme.palette.primary.dark]}
                                            size={110}
                                        />
                                        <ChartLabels
                                            values={data.counterparties || []}
                                            color={isLoading ? "#6A6A6A" : theme.palette.primary.dark}
                                            renderValue={(nv) => <CurrencyValue value={nv.value} currencyCode={nv.unit} />}
                                            variant="column"
                                            size="small"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <Typography>Typy transakcí</Typography>
                                    <div className={classes.smallChart}>
                                        <DonutChart
                                            data={[(data.types || []).map((nv) => nv.value)]}
                                            colors={isLoading || [theme.palette.primary.dark]}
                                            size={110}
                                        />
                                        <ChartLabels
                                            values={data.types || []}
                                            color={isLoading ? "#6A6A6A" : theme.palette.primary.main}
                                            renderValue={(nv) => <CurrencyValue value={nv.value} currencyCode={nv.unit} />}
                                            variant="column"
                                            size="small"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Panel>
                </Box>
            </MasterDetailContent>
            <TransactionModal
                data={transactionModal.data}
                open={transactionModal.open}
                onDataSaved={(data) => {
                    refreshMaster();
                    navigate(`/transactions/${data.id}`);
                }}
                onClose={transactionModal.closeModal}
            />
        </>
    );
};

const TasksMasterDetail = ({ menuItems }) => {
    const periodRef = usePeriod();

    return (
        <MasterDetail
            masterElement={<TransactionMasterContainer menuItems={menuItems} periodRef={periodRef} />}
            detailElement={<TransactionDetailContainer menuItems={menuItems} periodRef={periodRef} />}
            overviewElement={<TransactionsOverview menuItems={menuItems} periodRef={periodRef} />}
            titleMenuItems={menuItems}
            detailHasBackground={false}
        />
    );
};

export default TasksMasterDetail;
