import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Switch from "@mui/material/Switch";

import { makeStyles } from "@mui/styles";
import clsx from "clsx";

import ExpandButton from "./ExpandButton";
import Value from "../components/Value";

const useStyles = makeStyles((theme) => {
    const transition = {
        duration: theme.transitions.duration.shortest,
    };

    return {
        expandableHeader: {
            display: "flex",
            alignItems: "center",
        },
        expandableHeaderButton: {
            flex: "0 0 auto",
        },
        expandableHeaderContent: {
            flex: "1 1 auto",
        },
        iconButton: {
            padding: 10,
        },
    };
});

export const ExpandableContainer = ({ children }) => {
    return <div>{children}</div>;
};

export const ExpandableHeader = ({ children }) => {
    const classes = useStyles();
    return (
        <div className={classes.expandableHeader}>
            <div className={classes.expandableListHeaderButton}>
                <ExpandButton />
            </div>
            <div className={classes.expandableHeaderContent}>{children}</div>
        </div>
    );
};

const useSectionStyles = makeStyles((theme) => {
    const transition = {
        duration: theme.transitions.duration.shortest,
    };

    return {
        sectionRoot: {
            marginTop: -1,
            borderTop: "1px solid #EAEAEA",
            borderBottom: "1px solid #EAEAEA",
        },
        sectionHeader: {
            display: "flex",
            alignItems: "center",
            paddingTop: 8,
            paddingBottom: 8,
        },
        sectionHeaderButton: {
            flex: "0 0 auto",
        },

        sectionHeaderContent: {
            flex: "1 1 auto",
            overflow: "hidden",
        },
        sectionHeaderAction: {
            flex: "0 0 auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
        },
        sectionHeaderSubtitleRoot: {
            display: "flex",
            overflow: "hidden",
        },
        sectionHeaderSubtitle: {
            flex: "1 1",
            overflow: "hidden",
        },
        sectionHeaderSubtitleWrapper: {
            overflow: "hidden",
            textOverflow: "ellipsis",
        },
        sectionHeaderSubtitleCount: {
            flex: "0 0 auto",
        },

        sectionContent: {
            paddingLeft: 36,
            paddingBottom: 16,
        },
    };
});

const Actions = ({ actions, className, onClick, disabled, size }) => {
    if (!Array.isArray(actions)) {
        return null;
    }
    return (
        <div className={className}>
            {Array.isArray(actions) &&
                actions.map((action, key) =>
                    action.icon ? (
                        <IconButton
                            size={size || "medium"}
                            key={key}
                            onClick={(e) => {
                                e.stopPropagation();
                                onClick && onClick(e);
                                action.onClick && action.onClick(e);
                            }}
                            disabled={disabled || action.disabled}
                            title={action.title}
                        >
                            {action.icon}
                        </IconButton>
                    ) : (
                        <button
                            key={key}
                            onClick={(e) => {
                                e.stopPropagation();
                                onClick && onClick(e);
                                action.onClick && action.onClick(e);
                            }}
                        >
                            {action.title}
                        </button>
                    )
                )}
        </div>
    );
};

export const Section = ({ title, subtitle, items, subtitleFn, children, defaultOpen, actions, disabled, alwaysShowSubtitle, ...props }) => {
    const classes = useSectionStyles();
    const [open, setOpen] = useState(!!defaultOpen);

    useEffect(() => {
        setOpen(!!defaultOpen);
    }, [defaultOpen]);

    let subtitleMore = null;

    if (!subtitle && Array.isArray(items) && items.length && typeof subtitleFn === "function") {
        let item = items[0];
        if (!!item) {
            subtitle = subtitleFn(item);
            if (items.length > 1) {
                subtitleMore = items.length - 1;
            }
        }
    }

    return (
        <Box className={classes.sectionRoot} {...props}>
            <div className={classes.sectionHeader}>
                <div className={classes.sectionHeaderButton}>
                    <ExpandButton open={open} onOpenStateChange={setOpen} disabled={disabled} />
                </div>
                <div className={classes.sectionHeaderContent}>
                    {!!title && (
                        <Typography variant="body1" gutterBottom={false} component="div" sx={{ fontSize: "1.07rem" }}>
                            {title}
                        </Typography>
                    )}
                    {(!open || alwaysShowSubtitle) && (
                        <div className={classes.sectionHeaderSubtitleRoot}>
                            <div className={classes.sectionHeaderSubtitle}>
                                <div className={classes.sectionHeaderSubtitleWrapper}>
                                    <Typography variant="caption" component="span" gutterBottom={false} color="textSecondary" noWrap>
                                        {subtitle}
                                    </Typography>
                                </div>
                            </div>
                            {!!subtitleMore && !open && (
                                <div className={classes.sectionHeaderSubtitleCount}>
                                    <Typography variant="caption" color="textSecondary" component="i">{`+${subtitleMore} další`}</Typography>
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <Actions actions={actions} onClick={() => setOpen(true)} className={classes.sectionHeaderAction} />
            </div>
            <Collapse in={open}>
                <div className={classes.sectionContent}>{children}</div>
            </Collapse>
        </Box>
    );
};

export const SwitchSection = ({ title, subtitle, items, subtitleFn, children, open, setOpen, actions, disabled, alwaysShowSubtitle, ...props }) => {
    const classes = useSectionStyles();

    let subtitleMore = null;

    if (!subtitle && Array.isArray(items) && items.length && typeof subtitleFn === "function") {
        let item = items[0];
        if (!!item) {
            subtitle = subtitleFn(item);
            if (items.length > 1) {
                subtitleMore = items.length - 1;
            }
        }
    }

    return (
        <Box className={classes.sectionRoot} {...props}>
            <div className={classes.sectionHeader}>
                <div className={classes.sectionHeaderButton}>
                    <Switch
                        checked={!!open}
                        onChange={(e) => {
                            !!setOpen && setOpen(e.target.checked);
                        }}
                        size="small"
                    />
                </div>
                <div className={classes.sectionHeaderContent}>
                    {!!title && (
                        <Typography variant="body1" gutterBottom={false} component="div" sx={{ fontSize: "1.07rem" }}>
                            {title}
                        </Typography>
                    )}
                    {(!open || alwaysShowSubtitle) && (
                        <div className={classes.sectionHeaderSubtitleRoot}>
                            <div className={classes.sectionHeaderSubtitle}>
                                <div className={classes.sectionHeaderSubtitleWrapper}>
                                    <Typography variant="caption" component="span" gutterBottom={false} color="textSecondary" noWrap>
                                        {subtitle}
                                    </Typography>
                                </div>
                            </div>
                            {!!subtitleMore && !open && (
                                <div className={classes.sectionHeaderSubtitleCount}>
                                    <Typography variant="caption" color="textSecondary" component="i">{`+${subtitleMore} další`}</Typography>
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <Actions actions={actions} onClick={() => setOpen(true)} className={classes.sectionHeaderAction} />
            </div>
            <Collapse in={open}>
                <div className={classes.sectionContent}>{children}</div>
            </Collapse>
        </Box>
    );
};

/*
const createTableClasses = makeStyles((theme) => ({
    root: {
        "& > .MuiGrid-container:nth-child(2n+1)": {
            backgroundColor: "#FCFCFC",
            alignItems: "center",
            "& > .MuiGrid-item": {
                padding: theme.spacing(1),
                color: "#3B3B3B",
            },
            "& .MuiInputBase-root": {
                backgroundColor: "#FFFFFF",
            },
        },
        "& > .MuiGrid-container:nth-child(2n)": {
            backgroundColor: "#F6F6F6",
            alignItems: "center",
            "& > .MuiGrid-item": {
                padding: theme.spacing(1),
                color: "#3B3B3B",
            },
            "& .MuiInputBase-root": {
                backgroundColor: "#FFFFFF",
            },
        },
    },
}));
*/

const useTableStyles = makeStyles((theme) => {
    const transition = {
        duration: theme.transitions.duration.shortest,
    };

    return {
        tableRoot: {
            "& .TableRow": {
                borderTop: "1px solid #EAEAEA",
            },
            "& .TableRow:first-child": {
                borderTop: "none",
            },

            "& > .TableRow:nth-child(2n)": {
                //backgroundColor: emphasize(theme.palette.background.paper, 0.025),
            },
            "& > .TableRow:nth-child(2n+1)": {
                //backgroundColor: emphasize(theme.palette.background.paper, 0.05),
            },
        },
        tableRow: {
            display: "flex",
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
        },
        tableRowContent: {
            flex: "1 1 auto",
            overflow: "hidden",
            display: "flex",
            columnGap: theme.spacing(2),
        },
        tableRowMain: {
            flex: 1,
            minWidth: 0,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
        },
        tableRowValue: {
            flex: 0,
            whiteSpace: "nowrap",
        },
        tableRowContentPadding: {
            /*paddingLeft: theme.spacing(2),*/
            paddingRight: theme.spacing(2),
        },
        tableRowActions: {
            flex: "0 0 auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
        },
    };
});

export const TableContainer = ({ children }) => {
    const classes = useTableStyles();

    return <div className={classes.tableRoot}>{children}</div>;
};

export const TableRowContainer = ({ children, actions, selected, noPadding }) => {
    const classes = useTableStyles();

    return (
        <div className={clsx(classes.tableRow, "TableRow")}>
            <div className={clsx(classes.tableRowContent, { [classes.tableRowContentPadding]: !noPadding })}>{children}</div>
            <Actions actions={actions} className={classes.tableRowActions} size="small" />
        </div>
    );
};

export const TableRowMain = ({ children }) => {
    const classes = useTableStyles();
    return <div className={classes.tableRowMain}>{children}</div>;
};

export const TableRowValue = ({ children }) => {
    const classes = useTableStyles();
    return <div className={classes.tableRowValue}>{children}</div>;
};

const useFlexStyles = makeStyles((theme) => {
    const transition = {
        duration: theme.transitions.duration.shortest,
    };

    return {
        flexRow: {
            padding: theme.spacing(1),
            display: "flex",
            flex: "1 1 auto",
            alignItems: "stretch",
            justifyContent: "stretch",

            "& > .FlexRowItem": {
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2),
            },
            "& > .FlexRowItem:first-child": {
                paddingLeft: 0,
            },
            "& > .FlexRowItem:last-child": {
                paddingRight: 0,
            },
            "& > .FlexRowItem:not(:first-child)": {
                borderLeft: `1px solid ${theme.palette.divider}`,
            },
        },
        flexItem: {
            flex: 1,
            display: "flex",
            alignItems: "center",
            width: "100%",

            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
        },
        right: {
            justifyContent: "flex-end",
        },
        noBorder: {
            border: "none !important",
        },
    };
});

export const FlexRow = ({ className, children, dividers = true }) => {
    const classes = useFlexStyles();
    return <div className={clsx(classes.flexRow, className)}>{children}</div>;
};

export const FlexItem = ({ className, children, relativeWidth = 1, right }) => {
    const classes = useFlexStyles();
    return (
        <div
            className={clsx(classes.flexItem, "FlexRowItem", { [classes.right]: right, [classes.noBorder]: children.length == 0 }, className)}
            style={{ flex: relativeWidth }}
        >
            {children}
        </div>
    );
};

const useDetailStyles = makeStyles((theme) => ({
    detailRowRoot: {
        marginBottom: theme.spacing(2),
        border: "1px solid #EAEAEA",
        borderRadius: theme.spacing(1),
    },
    detailRowContent: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        //padding: theme.spacing(2),
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),

        backgroundColor: "#FFFFFF",
        overflow: "hidden",
    },
    detailRowContentLink: {
        cursor: "pointer",
    },
    detailExpandWrapper: {
        flex: "0 0 auto",
    },
    detailRowWrapper: {
        flex: "1 1 auto",
        display: "flex",
        columnGap: theme.spacing(2),
        flexWrap: "nowrap",
        overflow: "hidden",
    },
    detailRowDetail: {
        backgroundColor: "#F4F4F4",
        padding: theme.spacing(2),
        overflow: "hidden",
    },
    detailRowButtons: {
        flex: "0 0 auto",
        display: "flex",
    },
    detailRowMain: {
        flex: 1,
        minWidth: 0,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        padding: theme.spacing(2),
    },
    detailRowValue: {
        flex: 0,
        whiteSpace: "nowrap",
        padding: theme.spacing(2),
    },
    slimRow: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
}));

export const DetailContainer = ({ children }) => {
    return null;
};

export const DetailRow = ({ children, open, setOpen, renderDetail, noDetailPadding, slim, onClick }) => {
    const classes = useDetailStyles();
    return (
        <div className={classes.detailRowRoot}>
            <div className={clsx({ [classes.detailRowContent]: true, [classes.detailRowContentLink]: !!onClick })} onClick={!!onClick ? onClick : undefined}>
                {renderDetail && setOpen && <ExpandButton open={!!open} onOpenStateChange={setOpen} />}
                <div className={classes.detailRowWrapper}>{children}</div>
            </div>
            {renderDetail && (
                <Collapse in={!!open} unmountOnExit>
                    <div className={classes.detailRowDetail} style={!!noDetailPadding ? { padding: 0 } : null}>
                        {renderDetail()}
                    </div>
                </Collapse>
            )}
        </div>
    );
};

export const DetailRowMain = ({ children, slim }) => {
    const classes = useDetailStyles();
    return <div className={clsx(classes.detailRowMain, { [classes.slimRow]: !!slim })}>{children}</div>;
};

export const DetailRowValue = ({ children, slim }) => {
    const classes = useDetailStyles();
    return <div className={clsx(classes.detailRowValue, { [classes.slimRow]: !!slim })}>{children}</div>;
};

const useWidgetStyles = makeStyles((theme) => ({
    widgetRoot: {
        backgroundColor: "#FFFFFF",
    },
}));

export const SummaryWidget = ({ disabled, headerLabel, mainLabel, mainValue, leftLabel, leftValue, rightLabel, rightValue, color = "primary" }) => {
    const classes = useWidgetStyles();
    return (
        <div className={classes.widgetRoot}>
            <Box p={4}>
                <Grid container columnSpacing={2} rowSpacing={1}>
                    <Grid item xs={12}>
                        <Value label={mainLabel} big value={mainValue} disabled={disabled} />
                    </Grid>
                    <Grid item xs={6}>
                        <Value label={leftLabel} value={leftValue} disabled={disabled} />
                    </Grid>
                    <Grid item xs={6}>
                        <Value label={rightLabel} value={rightValue} disabled={disabled} />
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
};
