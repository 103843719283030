import React, {useContext, useEffect, useMemo, useState} from "react";

import {makeStyles} from "@mui/styles";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";

import {debounce} from "lodash";

import {AttachmentChips, AttachmentContainer, AttachmentContext, AttachmentSectionField} from "../Attachments";
import {TagChips, TagsSectionField} from "../Tags";
import backend, {prepareMultipartRequest} from "../../utils/backend";
import {useForm} from "../../utils/form";
import {useLoadingIndicator} from "../../utils/loading";
import Modal from "../Modal";
import {ListContainer, ListRow} from "../Layout";
import {CurrencyField, formatCurrency} from "../NumberFields";
import {formatDate} from "../DateFields";
import {useSettings} from "../../utils/settings";
import {DetailRow, DetailRowMain, DetailRowValue, Section} from "../Containers";
import {ProjectSelect} from "../projects";
import {TransactionFormFields, TransactionTypeDropDown} from "../transactions";
import Value from "../Value";
import Link from "../Link";
import SwitchField from "../SwitchField";
import {parseErrors} from "../../applicationErrors";

import {useAlert} from "../Alert";

const avatarString = (s) => {
    return s && s.length && s.substring(0, Math.min(2, s.length)).toUpperCase();
};

//components

export const DocumentListFilter = ({ disabled, onFilterChange, form, ...props }) => {
    const { values = {}, setValues } = form || {};

    const fetch = useMemo(
        () =>
            debounce(
                (filter) => {
                    try {
                        onFilterChange && onFilterChange(filter);
                    } catch (e) {
                        console.error(e);
                    }
                },
                1000,
                { leading: false, trailing: true }
            ),
        []
    );

    const handleChange = (values) => {
        setValues(values);
        fetch(values);
    };

    return (
        <div>
            <TextField
                value={values.filter || ""}
                onChange={(e) => handleChange({ ...values, filter: e.target.value })}
                size="small"
                fullWidth
                margin="normal"
            />
        </div>
    );
};

export const DocumentList = ({ disabled, data, onItemClick, hasMore, onShowMoreClick, selectedId }) => {
    const settings = useSettings();
    return (
        <>
            <ListContainer>
                {data.map((p) => (
                    <ListRow
                        key={p.id}
                        avatar={p.name}
                        mainTitle={p.name}
                        description={p.projectName}
                        onClick={() => onItemClick && onItemClick(p)}
                        selected={selectedId == p.id}
                        disabled={disabled}
                        value={formatCurrency(
                            p.total,
                            p.currencyCode,
                            settings.currencies,
                            settings.decimalDigits,
                            settings.decimalDelimiter,
                            settings.thousandDelimiter
                        )}
                    />
                ))}
            </ListContainer>
            {!!hasMore && (
                <Box sx={{ p: 2 }}>
                    <Button onClick={onShowMoreClick} disabled={disabled}>
                        načíst další...
                    </Button>
                </Box>
            )}
        </>
    );
};

export const DocumentForm = ({ task, editMode = true }) => {
    return <div></div>;
};

export const DocumentFormFields = ({ form, editMode = true, disabled, readOnly, currencyCode }) => {
    const onAddAttachment = useContext(AttachmentContext);
    const { values = {}, handleChange, setValues } = form || {};

    return (
        <>
            <Box mb={4} px={2}>
                <TextField fullWidth label="Název" margin="normal" value={values.name || ""} onChange={handleChange} name="name" disabled={disabled} />

                <TextField
                    fullWidth
                    label="Popis"
                    margin="normal"
                    name="description"
                    value={values.description || ""}
                    onChange={handleChange}
                    disabled={disabled}
                    multiline
                    rows={4}
                />

                <CurrencyField
                    fullWidth
                    label="Celkem"
                    margin="normal"
                    name="total"
                    value={values.total || ""}
                    onChange={handleChange}
                    disabled={disabled}
                    currencyCode={currencyCode || "CZK"}
                />
            </Box>
            <Box mb={4}>
                <Section title="Projekt" subtitle={values.projectName} px={2}>
                    <ProjectSelect
                        size="small"
                        value={!!values.projectId ? { id: values.projectId, name: values.projectName } : null}
                        onChange={(val) => setValues({ ...values, projectId: val ? val.id : null, projectName: val ? val.name : null })}
                        label="Projekt"
                        margin="normal"
                        disabled={disabled}
                    />
                </Section>
                <AttachmentSectionField
                    value={values.attachments}
                    onChange={(attachments) => setValues({ ...values, attachments })}
                    disabled={disabled}
                    readOnly={readOnly}
                    onAddClick={onAddAttachment}
                />

                <TagsSectionField value={values.tags} onChange={(tags) => setValues({ ...values, tags })} disabled={disabled} readOnly={readOnly} />
            </Box>
        </>
    );
};

export const DocumentModal = ({ title, data, open, onClose, onDataSaved, ...props }) => {
    const { startLoading, stopLoading, isLoading } = useLoadingIndicator();
    const onSubmit = useMemo(
        () => async (values) => {
            startLoading();
            try {
                values = await saveDocument(values);
                onClose && onClose();
                onDataSaved && onDataSaved(values);
            } catch (e) {
                alert(e);
                console.error(e);
            } finally {
                stopLoading();
            }
        },
        [onClose, onDataSaved]
    );

    const form = useForm({ onSubmit });

    const { values, handleChange, errors, setErrors, handleSubmit, setValues, valuesRef } = form;

    useEffect(() => setValues(data || {}), [data]);

    return (
        <Modal
            open={open}
            onClose={onClose}
            title={title}
            size="big"
            buttons={[{ title: "Storno" }, { title: "Uložit", main: true, onClick: handleSubmit, disabled: isLoading }]}
        >
            <AttachmentContainer value={values.attachments} onChange={(attachments) => setValues({ ...values, attachments })}>
                <DocumentFormFields form={form} disabled={isLoading} />
            </AttachmentContainer>
        </Modal>
    );
};

export const DocumentBasicInfo = ({ data, disabled, readOnly }) => {
    return (
        <div style={{ textAlign: "center" }}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Avatar sx={{ width: 160, height: 160, mb: 5, fontSize: 72 }} alt={data.name}>
                    {avatarString(data.name)}
                </Avatar>
            </div>
            <Typography variant="h6" component="div">
                {data.name}
            </Typography>

            {!!data.tags && !!data.tags.length && (
                <Box py={4}>
                    <TagChips value={data.tags} disabled={disabled} readOnly={readOnly} color="secondary" />
                </Box>
            )}

            {!!data.attachments && !!data.attachments.length && (
                <Box py={4}>
                    <AttachmentChips
                        value={data.attachments}
                        disabled={disabled}
                        readOnly={readOnly}
                        color="secondary"
                        url={(a) => `/api/documents/${data.id}/attachments/${a.id}`}
                        center
                    />
                </Box>
            )}
        </div>
    );
};

const ProcessDocumentForm = ({ form, disabled, doc }) => {
    const { values, handleChange, errors, setErrors, handleSubmit, setValues, valuesRef } = form;
    return (
        <>
            <Box p={2} mb={2}>
                <TransactionTypeDropDown
                    label="Typ transakce"
                    value={values.typeId}
                    onChange={(type) => {
                        let { id: typeId, name: typeName, ...otherFields } = type || {};
                        setValues({ ...values, ...(doc || {}), ...otherFields, typeId, typeName });
                    }}
                    name="accountingTransactionTypeId"
                    disabled={disabled}
                    object
                />
            </Box>
            <TransactionFormFields form={form} disabled={disabled} currencyCode={values.currencyCode} />
        </>
    );
};

export const ProcessDocumentModal = ({ data, open, onClose, onDataSaved, ...props }) => {
    const { startLoading, stopLoading, isLoading } = useLoadingIndicator();
    const onSubmit = useMemo(
        () =>
            async ({ docId, ...values }) => {
                startLoading();
                try {
                    values = await processDocument(docId, values);
                    onClose && onClose();
                    onDataSaved && onDataSaved(values);
                } catch (e) {
                    alert(e);
                    console.error(e);
                } finally {
                    stopLoading();
                }
            },
        [onClose, onDataSaved]
    );

    const form = useForm({ onSubmit });

    const { values, handleChange, errors, setErrors, handleSubmit, setValues, valuesRef } = form;

    useEffect(() => {
        let { id, ...values } = data || {};
        setValues(values);
    }, [data]);

    return (
        <Modal
            open={open}
            onClose={onClose}
            title="Zpracování dokumentu"
            size="big"
            buttons={[{ title: "Storno" }, { title: "Uložit", main: true, onClick: handleSubmit, disabled: isLoading }]}
        >
            <AttachmentContainer value={values.attachments} onChange={(attachments) => setValues({ ...values, attachments })}>
                <ProcessDocumentForm form={form} disabled={isLoading} doc={data} />
            </AttachmentContainer>
        </Modal>
    );
};

const useDocumentListStyles = makeStyles((theme) => ({
    detailRoot: {
        display: "grid",
        gridTemplateColumns: "repeat(5, 1fr)",
        gap: theme.spacing(4),
    },
    hidden: {
        [theme.breakpoints.down("md")]: {
            display: "none",
        },
    },
    detailMain: {
        gridColumn: "span 4/span 4",
        [theme.breakpoints.down("lg")]: {
            gridColumn: "span 5/span 5",
        },
    },
    detailButtons: {
        gridColumn: "span 1/span 1",
        [theme.breakpoints.down("lg")]: {
            gridColumn: "span 5/span 5",
        },
        textAlign: "center",
    },
}));

const DocumentDetailRow = ({ data, disabled, settings, onDiscardDocument, onProcessDocument, classes }) => {
    const { startLoading, stopLoading, isLoading } = useLoadingIndicator();
    const showAlert = useAlert();

    return (
        <div className={classes.detailRoot}>
            <div className={classes.detailMain}>
                <Grid container rowSpacing={3} columnSpacing={4}>
                    <Grid item sm={12} md={9}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} md={6}>
                                <Box mb={6}>
                                    <Value label="Název" value={data.name} />
                                    <Value label="Popis" value={data.description} />
                                </Box>
                                <Grid container columnSpacing={4}>
                                    <Grid item xs={6}>
                                        {!!data.employeeId && <Value label="Zadal" value={data.employeeName} />}
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Value label="Datum" value={formatDate(data.date, settings.dateFormat)} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                {!!data.accountingTransactionId && (
                                    <Value label="Transakce">
                                        <Link onClick={console.log}>
                                            {data.accountingTransactionName} ({data.typeName})
                                        </Link>
                                    </Value>
                                )}

                                <Grid container columnSpacing={4}>
                                    <Grid item xs={6}>
                                        <Value label="Zpracováno" value={data.processed ? "Ano" : "Ne"} />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Value label="Datum zpracování" value={formatDate(data.processedDate, settings.dateFormat)} />
                                    </Grid>
                                </Grid>

                                <Value
                                    label="Celkem"
                                    align="right"
                                    value={formatCurrency(
                                        data.total,
                                        data.currencyCode,
                                        settings.currencies,
                                        settings.decimalDigits,
                                        settings.decimalDelimiter,
                                        settings.thousandDelimiter
                                    )}
                                    big
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            <div className={classes.detailButtons}>
                {" "}
                {!data.processed && (
                    <Stack direction="column" spacing={4}>
                        <Button
                            disabled={disabled}
                            onClick={() =>
                                showAlert(
                                    "Zahodit dokument",
                                    'Doklad bude označen jako "zpracovaný", takže se přestane zobrazovat mezi dokumenty ke zpracování, ale nebude vytvořena žádná účetní transkace. Chcete pokračovat?',
                                    [
                                        {
                                            text: "Ano",
                                            onPress: async () => {
                                                onDiscardDocument(data);
                                            },
                                        },
                                        { text: "Ne" },
                                    ]
                                )
                            }
                        >
                            Zahodit dokument
                        </Button>
                        <Button
                            variant="contained"
                            disabled={disabled}
                            color="secondary"
                            onClick={() => {
                                onProcessDocument && onProcessDocument(data);
                            }}
                        >
                            Zpracovat dokument
                        </Button>
                    </Stack>
                )}
            </div>
        </div>
    );
};

export const DocumentsList = ({ data, projectId, onDiscardDocument, onProcessDocument }) => {
    const classes = useDocumentListStyles();
    const settings = useSettings();
    const [openState, setOpen] = useState({});
    useEffect(() => {
        setOpen({});
    }, [projectId]);

    return (
        <div>
            {Array.isArray(data) &&
                data.map((r) => (
                    <DetailRow
                        key={r.id}
                        open={openState[r.id]}
                        setOpen={(open) => setOpen({ ...openState, [r.id]: open })}
                        renderDetail={() => (
                            <DocumentDetailRow
                                data={r}
                                settings={settings}
                                onDiscardDocument={onDiscardDocument}
                                onProcessDocument={onProcessDocument}
                                classes={classes}
                            />
                        )}
                    >
                        <DetailRowMain>
                            <Typography variant="body1">{r.name}</Typography>
                        </DetailRowMain>
                        <DetailRowValue>
                            <Typography variant="body1">
                                {formatCurrency(
                                    r.total,
                                    r.currencyCode,
                                    settings.currencies,
                                    settings.decimalDigits,
                                    settings.decimalDelimiter,
                                    settings.thousandDelimiter
                                )}
                            </Typography>
                        </DetailRowValue>
                    </DetailRow>
                ))}
        </div>
    );
};

export const createDocumentTypeFeatures = () => {
    /*
        private Boolean hasAmount;

    private Boolean isApprovable;

    private Boolean isConvertibleToTransaction;
    */
    let features = {
        hasAmount: "Má částku",
        isApprovable: "Podléhá schválení",
        isConvertibleToTransaction: "Může být konvertována na účetní transakci",
    };
    return features;
};

const TransactionSwitch = ({ fieldKey, fieldLabel, handleChange, values, disabled }) => (
    <>
        <Divider />
        <Box px={3} my={1}>
            <SwitchField title={fieldLabel} value={values[fieldKey]} onChange={handleChange} name={fieldKey} disabled={disabled} />
        </Box>
    </>
);
export const DocumentTypeFormFields = ({ form, disabled, readOnly }) => {
    const { values = {}, handleChange, setValues, errors } = form || {};
    const settings = useSettings();
    let switchValues = useMemo(() => createDocumentTypeFeatures(settings.transactionsTaxSupport));
    return (
        <>
            <Box mb={4} px={3}>
                <TextField
                    fullWidth
                    label="Název"
                    margin="normal"
                    name="name"
                    value={values.name || ""}
                    onChange={handleChange}
                    disabled={disabled}
                    required
                    error={!!errors.name}
                    helperText={errors.name}
                />
                <TextField
                    fullWidth
                    label="Popis"
                    margin="normal"
                    name="description"
                    value={values.description || ""}
                    onChange={handleChange}
                    disabled={disabled}
                    error={!!errors.description}
                    helperText={errors.description}
                    multiline
                    rows={3}
                />
            </Box>
            <Box mb={4}>
                {Object.keys(switchValues).map((fieldKey) => (
                    <TransactionSwitch
                        key={fieldKey}
                        fieldKey={fieldKey}
                        fieldLabel={switchValues[fieldKey] || ""}
                        values={values}
                        handleChange={handleChange}
                        disabled={disabled}
                    />
                ))}
                <Divider />
            </Box>
        </>
    );
};

export const DocumentTypeModal = ({ data, open, onClose, onDataSaved, ...props }) => {
    const { startLoading, stopLoading, isLoading } = useLoadingIndicator();
    const onSubmit = async (values) => {
        values = await saveDocumentType(values);
        onClose && onClose();
        onDataSaved && onDataSaved(values);
    };

    const form = useForm({ onSubmit, parseErrors });

    const { values, handleChange, errors, setErrors, handleSubmit, setValues, valuesRef } = form;

    useEffect(() => setValues(data || {}, true), [data]);

    return (
        <Modal
            open={open}
            onClose={onClose}
            title="Typ dokumentu"
            size="big"
            buttons={[{ title: "Storno" }, { title: "Uložit", main: true, onClick: handleSubmit, disabled: isLoading }]}
        >
            <DocumentTypeFormFields form={form} disabled={isLoading} />
        </Modal>
    );
};

export const prepareDocumentTransactionData = (vals) => {
    let { id, ...data } = vals || {};
    return { docId: id, ...data };
};

const populateFilterParams = () => ({});

export const fetchDocuments = async (filter, paging) => {
    let response = await backend("/api/documents", "GET", { params: { ...(filter || {}), ...(paging || {}) } });
    return response.body; // { ...emptyPage, content: [...mockedProjects] };
};

export const fetchDocument = async (id) => {
    let response = await backend(`/api/documents/${id}`);
    return response.body;
};

export const saveDocument = async (item) => {
    let formData = prepareMultipartRequest(item);
    let response = await backend(`/api/documents${item.id ? "/" + item.id : ""}`, item.id ? "PUT" : "POST", {
        body: formData,
        upload: true,
    });
    return response.body;
};

export const fetchDocumentsSummary = async () => {
    let response = await backend(`/api/documents/summary`);
    return response.body;
};

export const processDocument = async (id, transaction) => {
    let formData = prepareMultipartRequest(transaction);
    let response = await backend(`/api/documents/${id}/transactions`, "PUT", {
        body: formData,
        upload: true,
    });
    return response.body;
};

export const discardDocument = async (id) => {
    let response = await backend(`/api/documents/${id}/discard`, "PUT");
    return response.body;
};

export const restoreDocument = async (id) => {
    let response = await backend(`/api/documents/${id}/discard`, "DELETE");
    return response.body;
};

export const fetchDocumentTypes = async (paging) => {
    let response = await backend("/api/documents/types", "GET", { params: { ...(paging || {}) } });
    return response.body;
};

export const fetchDocumentType = async (id) => {
    let response = await backend(`/api/documents/types/${id}`);
    return response.body;
};

export const saveDocumentType = async (item) => {
    let response = await backend(`/api/documents/types${item.id ? "/" + item.id : ""}`, item.id ? "PUT" : "POST", {
        body: item,
    });
    return response.body;
};
