import React, {useEffect, useMemo, useState} from "react";

import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import PersonIcon from "@mui/icons-material/Person";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import ExtensionIcon from "@mui/icons-material/Extension";
import GroupIcon from "@mui/icons-material/Group";
import PaidIcon from "@mui/icons-material/Paid";
import DescriptionIcon from "@mui/icons-material/Description";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import TextField from "@mui/material/TextField";

import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";

import Divider from "@mui/material/Divider";
import {useTheme} from "@mui/material/styles";
import {makeStyles} from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import {
    MasterContent,
    MasterDetail,
    MasterDetailContent,
    useMasterDetail,
    useMasterDetailParams
} from "../components/MasterDetail";
import {Page, PageTitle, Panel, PanelTitle} from "../components/Layout";
import {
    createTransactionTypeFeatures,
    fetchTaxTypes,
    fetchTransactionTypes,
    TaxTypeModal,
    TransactionTypeModal,
} from "../components/transactions";
import {useChangeSettings, useSettings} from "../utils/settings";
import {createDocumentTypeFeatures, DocumentTypeModal, fetchDocumentTypes} from "../components/documents";

import {useForm} from "../utils/form";
import {LoadingIndicator, LoadingIndicatorContainer, useLoadingIndicator} from "../utils/loading";
import Modal, {useModal} from "../components/Modal";
import Switch from "../components/SwitchField";
import Value from "../components/Value";
import moment from "moment";
import backend from "../utils/backend";
import {formatDate} from "../components/DateFields";
import {formatCurrency, formatNum} from "../components/NumberFields";
import {DetailRow, DetailRowMain, DetailRowValue} from "../components/Containers";
import {parseErrors} from "../applicationErrors";

const avatar = (name) => {
    return name && name.length && name.substring(0, Math.min(2, name.length)).toUpperCase();
};

const SettingsItem = ({ title, id, selectedId, showDetail, icon }) => {
    return (
        <ListItem button divider onClick={() => showDetail(id)} selected={selectedId == id}>
            <ListItemAvatar>
                <Avatar>{icon || avatar(title)}</Avatar>
            </ListItemAvatar>
            <ListItemText primary={title} />
        </ListItem>
    );
};

const SettingsPanel = ({ title, subtitle, children, actions }) => {
    return (
        <Panel fullHeight>
            <PanelTitle title={title} subtitle={subtitle} actions={actions} />
            {children}
        </Panel>
    );
};

const SettingsValue = ({ value }) => {
    return null;
};

const SettingsMaster = ({ settings }) => {
    const { showDetail, showOverview, showAdd, mobile, refreshMaster } = useMasterDetail();
    const params = useMasterDetailParams();
    const detailId = params.tabName;

    return (
        <List>
            <SettingsItem title="Účet" id="company" showDetail={showDetail} selectedId={detailId} icon={<PersonIcon />} />
            <SettingsItem title="Uživatelé" id="users" showDetail={showDetail} selectedId={detailId} icon={<GroupIcon />} />
            {!!settings.transactionsSupport && (
                <SettingsItem title="Typy transakcí" id="transactionTypes" showDetail={showDetail} selectedId={detailId} icon={<PaidIcon />} />
            )}
            {!!settings.transactionsSupport && !!settings.transactionsTaxSupport && (
                <SettingsItem title="Sazby daně" id="taxTypes" showDetail={showDetail} selectedId={detailId} />
            )}
            {!!settings.documentsSupport && (
                <SettingsItem title="Typy dokumentů" id="documents" showDetail={showDetail} selectedId={detailId} icon={<DescriptionIcon />} />
            )}
            <SettingsItem title="Rozšíření" id="features" showDetail={showDetail} selectedId={detailId} icon={<ExtensionIcon />} />
        </List>
    );
};

const SettingsMasterContainer = ({ menuItems }) => {
    const settings = useSettings();
    const { mobile } = useMasterDetail();

    const masterMenuItems = [];

    return (
        <Page>
            <PageTitle title="Nastavení" menuItems={mobile ? [...masterMenuItems, ...menuItems] : masterMenuItems} />
            <MasterContent>
                <SettingsMaster settings={settings} />
            </MasterContent>
        </Page>
    );
};

const SettingsPage = ({ menuItems, title, children }) => {
    const { hideDetail, refreshMaster } = useMasterDetail();
    return (
        <Page>
            <LoadingIndicator hidden="smDown" />
            <PageTitle title={title} menuItems={menuItems} onBack={hideDetail}></PageTitle>
            <LoadingIndicator hidden="smUp" />
            <MasterDetailContent>{children}</MasterDetailContent>
        </Page>
    );
};

/* ******** settings pages ******** */
const hasRequirementsMet = (component, values) => {
    if (!component || !component.requires || !component.requires.length || !values) {
        return true;
    }

    for (let i = 0; i < component.requires.length; i++) {
        if (!values[component.requires[i]]) {
            return false;
        }
    }
    return true;
};

const ModuleSettings = ({ values, onChange, disabled }) => {
    const components = [
        {
            name: "timesheetsSupport",
            title: "Výkazy",
            description: "Umožňuje vašim zaměstnancům vykazovat práci",
        },
        {
            name: "counterpartiesSupport",
            title: "Protistrany",
            description: "Organizujte transakce a projekty podle zákazníků / dodavatelů",
        },
        {
            name: "projectsSupport",
            title: "Projekty",
            description: "Organizujte výkazy, transakce a vyúčtování podle projektů",
        },
        {
            name: "transactionsSupport",
            title: "Finance",
            description: "Spravujte finanční toky - příjmy a výdaje",
        },
        {
            name: "transactionsTaxSupport",
            title: "Evidovat daň",
            description: "Evidujte daně (DPH, spotřební daň, atd.) na účetních transakcích",
            requires: ["transactionsSupport"],
        },
        {
            name: "billingsSupport",
            title: "Vyúčtování",
            description: "Počítejte zisk / ztrátu na projektech",
        },
        {
            name: "documentsSupport",
            title: "Dokumenty",
            description: "Sdílejte dokumenty mezi zaměstnanci",
        },
    ];
    return (
        <>
            {components.map((component, key) => (
                <div key={component.name}>
                    <Box my={2} px={2}>
                        <Switch
                            title={component.title}
                            name={component.name}
                            value={values[component.name]}
                            onChange={(e) => onChange && onChange({ ...values, [e.target.name]: e.target.checked })}
                            disabled={disabled || !hasRequirementsMet(component, values)}
                            variant="body1"
                        />
                        {!!component.description ? (
                            <Typography variant="caption" color="textSecondary" component="div">
                                {component.description}
                            </Typography>
                        ) : null}
                    </Box>
                    {key < components.length - 1 && <Divider />}
                </div>
            ))}
        </>
    );
};

const CompanyFormFields = ({ form, disabled }) => {
    const { values = {}, handleChange, setValues, errors = {} } = form || {};

    return (
        <>
            <Box mb={4} px={2}>
                <TextField
                    fullWidth
                    label="Název"
                    margin="normal"
                    value={values.name || ""}
                    onChange={handleChange}
                    name="name"
                    disabled={disabled}
                    required
                    error={!!errors.name}
                    helperText={errors.name}
                />

                <Stack direction="row" spacing={4}>
                    <div style={{ flex: "1 1 auto" }}>
                        <TextField
                            fullWidth
                            label="IČ"
                            margin="normal"
                            name="idNumber"
                            value={values.idNumber || ""}
                            onChange={handleChange}
                            disabled={disabled}
                        />
                    </div>
                    <div style={{ flex: "1 1 auto" }}>
                        <TextField
                            fullWidth
                            label="DIČ"
                            margin="normal"
                            name="taxIdNumber"
                            value={values.taxIdNumber || ""}
                            onChange={handleChange}
                            disabled={disabled}
                        />
                    </div>
                </Stack>
                <TextField
                    fullWidth
                    label="Adresa"
                    margin="normal"
                    name="address"
                    value={values.address || ""}
                    onChange={handleChange}
                    disabled={disabled}
                    multiline
                    rows={4}
                />
            </Box>
        </>
    );
};

const CompanyModal = ({ data, open, onClose, onDataSaved, ...props }) => {
    const { isLoading } = useLoadingIndicator();

    const onSubmit = async (values) => {
        await saveCompanyInfo(values);
        onClose && onClose();
        onDataSaved && onDataSaved(values);
    };

    const form = useForm({ onSubmit });

    const { values, handleChange, errors, setErrors, handleSubmit, setValues, valuesRef } = form;

    useEffect(() => setValues(data || {}, true), [data]);

    return (
        <Modal
            open={open}
            onClose={onClose}
            title="Společnost"
            size="big"
            buttons={[{ title: "Storno" }, { title: "Uložit", main: true, onClick: handleSubmit, disabled: isLoading }]}
        >
            <CompanyFormFields form={form} disabled={isLoading} />
        </Modal>
    );
};

const createCompanyClasses = makeStyles((theme) => ({
    flexContainer: {
        display: "flex",
        gap: theme.spacing(4),
        flexWrap: "wrap",
        justifyContent: "space-between",
        alignItems: "center",
    },
}));

const CompanyContainer = ({ menuItems }) => {
    const theme = useTheme();
    const settings = useSettings();
    const changeSettings = useChangeSettings();

    const modal = useModal();
    const { startLoading, stopLoading, isLoading } = useLoadingIndicator();
    const classes = createCompanyClasses();

    return (
        <SettingsPage title="Účet" menuItems={menuItems}>
            <Box px={2}>
                <SettingsPanel>
                    <Stack
                        direction={{ xs: "column", sm: "row" }}
                        spacing={4}
                        divider={<Divider orientation={useMediaQuery(theme.breakpoints.down("lg")) ? "horizontal" : "vertical"} flexItem={true} />}
                    >
                        <div style={{ flex: "1 1 50%" }}>
                            <Box mb={8}>
                                <Stack direction="row" spacing={4} justifyContent="space-between" alignItems="center">
                                    <Typography variant="h6" gutterBottom>
                                        {settings.name}
                                    </Typography>
                                    <IconButton onClick={(e) => modal.showModal({ ...settings })} disabled={!!isLoading}>
                                        <EditIcon />
                                    </IconButton>
                                </Stack>
                                <Typography variant="body1" component="div" sx={{ whiteSpace: "pre" }}>
                                    {settings.address}
                                </Typography>
                                {!!settings.idNumber && (
                                    <Typography variant="body1" component="div">
                                        IČ: {settings.idNumber}
                                    </Typography>
                                )}
                                {!!settings.taxIdNumber && (
                                    <Typography variant="body1" component="div">
                                        DIČ: {settings.taxIdNumber}
                                    </Typography>
                                )}
                            </Box>
                            <Box mb={4}>
                                <Box mb={2}>
                                    <Stack direction="row" spacing={4} justifyContent="space-between" alignItems="center">
                                        <Typography variant="body1" gutterBottom>
                                            <b>Formáty</b>
                                        </Typography>
                                        <IconButton onClick={(e) => modal.showModal({ ...settings })} disabled={!!isLoading} title="Změnit">
                                            <EditIcon />
                                        </IconButton>
                                    </Stack>
                                </Box>

                                <div className={classes.flexContainer}>
                                    <div>
                                        <Value
                                            label="Formát čísla"
                                            value={formatNum(1234567.8901234, settings.decimalDigits, settings.decimalDelimiter, settings.thousandDelimiter)}
                                        />
                                    </div>
                                    <div>
                                        <Value
                                            label="Měna"
                                            value={formatCurrency(
                                                1234567.89,
                                                "CZK",
                                                settings.currencies,
                                                settings.decimalDigits,
                                                settings.decimalDelimiter,
                                                settings.thousandDelimiter
                                            )}
                                        />
                                    </div>
                                    <div>
                                        <Value label="Formát datumu" value={formatDate(moment(), settings.dateFormat)} />
                                    </div>
                                </div>
                            </Box>
                        </div>
                        <div style={{ flex: "1 1 50%" }}>
                            <ModuleSettings
                                values={settings}
                                onChange={async (values) => {
                                    startLoading();
                                    try {
                                        let settings = await changeModuleSettings(values);
                                        changeSettings(settings);
                                    } catch (e) {
                                        console.error(e);
                                        alert("error");
                                    } finally {
                                        stopLoading();
                                    }
                                }}
                                disabled={isLoading}
                            />
                        </div>
                    </Stack>
                </SettingsPanel>
            </Box>
            <CompanyModal data={modal.data} open={modal.open} onClose={modal.closeModal} onDataSaved={changeSettings} />
        </SettingsPage>
    );
};

const TaxTypesWidgets = ({ data, disabled, onEdit, settings }) => (
    <Grid container spacing={8}>
        {Array.isArray(data) &&
            data.map((row) => {
                let valueLabel = "Částka";
                let value = row.value;
                if (row.calculationType === "PERCENTAGE") {
                    valueLabel = "Procentuální sazba";
                    value = `${formatNum(row.value, settings.decimalDigits, settings.decimalDelimiter, settings.thousandDelimiter)}%`;
                } else if (row.calculationType === "PER_UNIT") {
                    valueLabel = "Částka za " + (row.unit || "jednotku");
                    value = `${formatCurrency(
                        value,
                        settings.defaultCurrencyCode,
                        settings.currencies,
                        settings.decimalDigits,
                        settings.decimalDelimiter,
                        settings.thousandDelimiter
                    )}${row.unit ? "/" + row.unit : ""}`;
                } else {
                    value = formatCurrency(
                        value,
                        settings.defaultCurrencyCode,
                        settings.currencies,
                        settings.decimalDigits,
                        settings.decimalDelimiter,
                        settings.thousandDelimiter
                    );
                }

                return (
                    <Grid item xs={12} lg={6} key={row.id}>
                        <SettingsPanel
                            title={row.name}
                            actions={
                                <IconButton disabled={disabled} onClick={() => onEdit && onEdit(row)} title="Upravit">
                                    <EditIcon />
                                </IconButton>
                            }
                        >
                            <Box sx={{ textAlign: "center" }}>
                                <Typography variant="h4" gutterBottom>
                                    {value}
                                </Typography>
                                <Typography variant="body2" gutterBottom color="textSecondary">
                                    {valueLabel}
                                </Typography>
                            </Box>
                            {/* {"id":"9fefae43-f987-46a1-9e09-4ae9b59f4fd3","name":"Základní sazba DPH","value":21,"unit":null,"calculationType":"PERCENTAGE","position":0} */}
                        </SettingsPanel>
                    </Grid>
                );
            })}
    </Grid>
);

const TaxTypesContainer = ({ menuItems }) => {
    const settings = useSettings();
    const { detailId } = useMasterDetailParams();
    const [state, setState] = useState({ data: [] });
    const modal = useModal();

    const { startLoading, stopLoading, isLoading } = useLoadingIndicator();

    const fetchData = async () => {
        startLoading();
        try {
            let data = await fetchTaxTypes();
            setState({ ...state, data });
        } catch (e) {
            console.error(e);
            alert(e);
        } finally {
            stopLoading();
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <SettingsPage
            title="Sazby daně"
            menuItems={[
                {
                    alwaysVisible: false,
                    title: "Přidat",
                    icon: <AddIcon />,
                    onClick: () => modal.showModal({ name: "" }),
                },
                ...menuItems,
            ]}
        >
            <Box px={2}>
                <TaxTypesWidgets data={state.data || []} disabled={isLoading} onEdit={modal.showModal} settings={settings} />
            </Box>
            <TaxTypeModal data={modal.data} open={modal.open} onClose={modal.closeModal} onDataSaved={fetchData} />
        </SettingsPage>
    );
};

const TransactionTypesFeatures = ({ features, data, onEdit, disabled }) => (
    <Grid container spacing={4}>
        {Array.isArray(data) &&
            data.map((row) => (
                <Grid item xs={12} lg={6} key={row.id}>
                    <SettingsPanel
                        title={row.name}
                        subtitle={
                            <>
                                {row.direction == "DEBIT" ? "Příchozí platba" : "Odchozí platba"}
                                {!!row.description && (
                                    <Box my={2}>
                                        <Typography variant="caption" color="textSecondary">
                                            {row.description}
                                        </Typography>
                                    </Box>
                                )}
                            </>
                        }
                        actions={
                            <IconButton disabled={disabled} onClick={() => onEdit && onEdit(row)} title="Upravit">
                                <EditIcon />
                            </IconButton>
                        }
                    >
                        <Box my={3}>
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    {Object.keys(features)
                                        .filter((key) => !!row[key])
                                        .map((key) => (
                                            <div key={key}>
                                                <CheckCircleIcon fontSize="small" color="secondary" />{" "}
                                                <Typography key={key} variant="caption" color="textSecondary">
                                                    {features[key]}
                                                </Typography>
                                            </div>
                                        ))}
                                </Grid>
                                <Grid item xs={6}>
                                    {Object.keys(features)
                                        .filter((key) => !row[key])
                                        .map((key) => (
                                            <Typography key={key} variant="caption" color="textSecondary" component="div">
                                                <CancelIcon fontSize="small" color="error" /> {features[key]}
                                            </Typography>
                                        ))}
                                </Grid>
                            </Grid>
                        </Box>
                    </SettingsPanel>
                </Grid>
            ))}
    </Grid>
);

const TransactionTypesContainer = ({ menuItems }) => {
    const { detailId } = useMasterDetailParams();
    const [state, setState] = useState({ data: [] });
    const modal = useModal();
    const settings = useSettings();
    const features = useMemo(() => createTransactionTypeFeatures(settings.transactionsTaxSupport));

    const { startLoading, stopLoading, isLoading } = useLoadingIndicator();

    const fetchData = async () => {
        startLoading();
        try {
            let data = await fetchTransactionTypes();
            setState({ ...state, data });
        } catch (e) {
            console.error(e);
            alert(e);
        } finally {
            stopLoading();
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <SettingsPage
            title="Typy transakcí"
            menuItems={[
                {
                    alwaysVisible: false,
                    title: "Přidat",
                    icon: <AddIcon />,
                    onClick: () => modal.showModal({ name: "" }),
                },
                ...menuItems,
            ]}
        >
            <Box px={2} pb={2}>
                <TransactionTypesFeatures features={features} data={state.data || []} onEdit={modal.showModal} disabled={isLoading} />
            </Box>
            <TransactionTypeModal data={modal.data} open={modal.open} onClose={modal.closeModal} onDataSaved={fetchData} />
        </SettingsPage>
    );
};

const FeatureRow = ({ feature, title, subtitle, enabled, children, open, setOpen, changeFeature, settings, disabled }) => {
    return (
        <Box px={2} mb={2}>
            {/* reports */}
            <DetailRow
                open={open && open[feature]}
                setOpen={(fo) => setOpen && setOpen({ ...open, [feature]: !!fo })}
                renderDetail={() => <LoadingIndicatorContainer>{children}</LoadingIndicatorContainer>}
            >
                <DetailRowMain>
                    <Typography variant="body1">{title}</Typography>
                    <Typography variant="body2" color="textSecondary">
                        {subtitle}
                    </Typography>
                </DetailRowMain>

                <DetailRowValue>
                    <Switch
                        color="secondary"
                        value={!!(settings && settings.features && settings.features[feature])}
                        onChange={(e) => changeFeature && changeFeature(feature, !!e.target.checked)}
                        disabled={disabled}
                        size="small"
                    />
                </DetailRowValue>
            </DetailRow>
        </Box>
    );
};

const FeaturesContainer = ({ menuItems }) => {
    const { detailId } = useMasterDetailParams();
    const [state, setState] = useState({ data: [] });
    const [open, setOpen] = useState({});
    const modal = useModal();

    const settings = useSettings([]);
    const changeSettings = useChangeSettings();

    const { startLoading, stopLoading, isLoading } = useLoadingIndicator();

    const changeFeature = async (key, enable) => {
        startLoading();
        try {
            let data = await changeFeatureSettings(key, enable);
            console.warn("changing settings", key, enable);
            changeSettings(data);
        } catch (e) {
            console.error(e);
            alert(e);
        } finally {
            stopLoading();
        }
    };

    const features = [
        { key: "accountingTransactionReports", title: "Přehledy transakcí", subtitle: "Umožňuje vytvořit přehledy transakcí za období" },
        { key: "epoDph", title: "EPO - DPH", subtitle: "Elektronická podání pro Finanční správu ČR - daň z přidané hodnoty" },
        { key: "epoDp", title: "EPO - Daň", subtitle: "Elektronická podání pro Finanční správu ČR - daň" },
    ];

    return (
        <SettingsPage title="Rozšíření" menuItems={[...menuItems]}>
            {/* title, subtitle, enabled, children, open, setOpen, changeFeature, settings */}
            {features.map((feature) => (
                <FeatureRow
                    key={feature.key}
                    feature={feature.key}
                    title={feature.title}
                    subtitle={feature.subtitle}
                    open={open}
                    setOpen={setOpen}
                    changeFeature={changeFeature}
                    settings={settings}
                    disabled={!!isLoading}
                ></FeatureRow>
            ))}
        </SettingsPage>
    );
};

const UsersContainer = ({ menuItems }) => {
    const { detailId } = useMasterDetailParams();
    const [state, setState] = useState({ data: [] });
    const [open, setOpen] = useState({});
    const modal = useModal();

    const settings = useSettings([]);
    const changeSettings = useChangeSettings();

    const { startLoading, stopLoading, isLoading } = useLoadingIndicator();

    const changeFeature = async (key, enable) => {
        startLoading();
        try {
            let data = await changeFeatureSettings(key, enable);
            changeSettings(data);
        } catch (e) {
            console.error(e);
            alert(e);
        } finally {
            stopLoading();
        }
    };

    return (
        <SettingsPage title="Uživatelé" menuItems={[...menuItems]}>
            modul &gt; oprávnění (R/W) &gt; objekty
        </SettingsPage>
    );
};

const DocumentTypesFeatures = ({ features, data, disabled, onEdit }) => (
    <Grid container spacing={4}>
        {data &&
            Array.isArray(data.content) &&
            data.content.map((row) => (
                <Grid item xs={12} lg={6} key={row.id}>
                    <SettingsPanel
                        title={row.name}
                        subtitle={
                            !!row.description && (
                                <Box my={2}>
                                    <Typography variant="caption" color="textSecondary">
                                        {row.description}
                                    </Typography>
                                </Box>
                            )
                        }
                        actions={
                            <IconButton disabled={disabled} onClick={() => onEdit && onEdit(row)} title="Upravit">
                                <EditIcon />
                            </IconButton>
                        }
                    >
                        <Box my={3}>
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    {Object.keys(features)
                                        .filter((key) => !!row[key])
                                        .map((key) => (
                                            <div key={key}>
                                                <CheckCircleIcon fontSize="small" color="secondary" />{" "}
                                                <Typography key={key} variant="caption" color="textSecondary">
                                                    {features[key]}
                                                </Typography>
                                            </div>
                                        ))}
                                </Grid>
                                <Grid item xs={6}>
                                    {Object.keys(features)
                                        .filter((key) => !row[key])
                                        .map((key) => (
                                            <Typography key={key} variant="caption" color="textSecondary" component="div">
                                                <CancelIcon fontSize="small" color="error" /> {features[key]}
                                            </Typography>
                                        ))}
                                </Grid>
                            </Grid>
                        </Box>
                    </SettingsPanel>
                </Grid>
            ))}
    </Grid>
);

const DocumentTypesContainer = ({ menuItems }) => {
    const { detailId } = useMasterDetailParams();
    const [state, setState] = useState({ data: [] });
    const modal = useModal();
    const settings = useSettings();
    const features = useMemo(() => createDocumentTypeFeatures(settings.transactionsTaxSupport));

    const { startLoading, stopLoading, isLoading } = useLoadingIndicator();

    const fetchData = async () => {
        startLoading();
        try {
            let data = await fetchDocumentTypes();
            setState({ ...state, data });
        } catch (e) {
            console.error(e);
            alert(e);
        } finally {
            stopLoading();
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <SettingsPage
            title="Typy dokumentů"
            menuItems={[
                {
                    alwaysVisible: false,
                    title: "Přidat",
                    icon: <AddIcon />,
                    onClick: () => modal.showModal({ name: "" }),
                },
                ...menuItems,
            ]}
        >
            <Box px={2} pb={2}>
                <DocumentTypesFeatures features={features} data={state.data || []} onEdit={modal.showModal} disabled={isLoading} />
            </Box>
            <DocumentTypeModal data={modal.data} open={modal.open} onClose={modal.closeModal} onDataSaved={fetchData} />
        </SettingsPage>
    );
};

/* ******** routes ******** */

const SettingsDetailContainer = ({ menuItems }) => {
    const { detailId } = useMasterDetailParams();

    switch (detailId) {
        case "company":
            return <CompanyContainer menuItems={menuItems} />;
        case "taxTypes":
            return <TaxTypesContainer menuItems={menuItems} />;
        case "transactionTypes":
            return <TransactionTypesContainer menuItems={menuItems} />;
        case "features":
            return <FeaturesContainer menuItems={menuItems} />;
        case "users":
            return <UsersContainer menuItems={menuItems} />;
        case "documents":
            return <DocumentTypesContainer menuItems={menuItems} />;
        default:
        //nothing
    }

    return <></>;
};

export default ({ menuItems }) => {
    return (
        <MasterDetail
            masterElement={<SettingsMasterContainer menuItems={menuItems} />}
            detailElement={<SettingsDetailContainer menuItems={menuItems} />}
            titleMenuItems={menuItems}
            detailHasBackground={false}
        />
    );
};

const OnboardingWizardButtons = ({ onNextStep, onPrevStep }) => {
    const { startLoading, stopLoading, isLoading } = useLoadingIndicator();
    return (
        <Box sx={{ mb: 2 }}>
            <Stack spacing={2} direction="row" alignItems="center" justifyContent="flex-end">
                {!!onPrevStep && (
                    <Button onClick={onPrevStep} disabled={isLoading}>
                        Zpět
                    </Button>
                )}
                {!!onNextStep && (
                    <Button variant="contained" onClick={onNextStep} disabled={isLoading}>
                        Pokračovat
                    </Button>
                )}
            </Stack>
        </Box>
    );
};

const OnboardingCompanyStep = ({ settings, changeSettings, onNextStep, onPrevStep }) => {
    const { startLoading, stopLoading, isLoading } = useLoadingIndicator();

    const onSubmit = async (values) => {
        let data = await saveCompanyInfo(values);
        changeSettings && changeSettings(data);
        onNextStep && onNextStep();
    };

    const form = useForm({ onSubmit, parseErrors });
    const { values, handleChange, errors, setErrors, handleSubmit, setValues, valuesRef } = form;
    useEffect(() => setValues(settings), []);

    return (
        <>
            <StepLabel optional={settings.name ? <Typography variant="caption">{settings.name}</Typography> : null}>Informace o společnosti</StepLabel>
            <StepContent>
                <Box my={2}>
                    <CompanyFormFields form={form} disabled={isLoading} />
                </Box>
                <OnboardingWizardButtons onPrevStep={onPrevStep} onNextStep={handleSubmit} />
            </StepContent>
        </>
    );
};

const OnboardingModulesStep = ({ settings, changeSettings, onNextStep, onPrevStep }) => {
    const form = useForm();
    const { startLoading, stopLoading, isLoading } = useLoadingIndicator();

    return (
        <>
            <StepLabel>Funkčnosti</StepLabel>
            <StepContent>
                <Box my={2}>
                    <ModuleSettings
                        values={settings}
                        onChange={async (values) => {
                            startLoading();
                            try {
                                let settings = await changeModuleSettings(values);
                                changeSettings(settings);
                            } catch (e) {
                                console.error(e);
                                alert("error");
                            } finally {
                                stopLoading();
                            }
                        }}
                        disabled={isLoading}
                    />
                </Box>
                <OnboardingWizardButtons onPrevStep={onPrevStep} onNextStep={onNextStep} />
            </StepContent>
        </>
    );
};

const OnboardingTransactionsStep = ({ settings, changeSettings, onNextStep, onPrevStep }) => {
    const [state, setState] = useState({ data: [] });
    const modal = useModal();
    const features = useMemo(() => createTransactionTypeFeatures(settings.transactionsTaxSupport));

    const { startLoading, stopLoading, isLoading } = useLoadingIndicator();

    const fetchData = async () => {
        startLoading();
        try {
            let data = await fetchTransactionTypes();
            setState({ ...state, data });
        } catch (e) {
            console.error(e);
            alert(e);
        } finally {
            stopLoading();
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <StepLabel>Typy transakcí</StepLabel>
            <StepContent>
                <Box my={2}>
                    <TransactionTypesFeatures features={features} data={state.data || []} onEdit={modal.showModal} disabled={isLoading} />
                    <Box p={4}>
                        <IconButton onClick={() => modal.showModal({})}>
                            <AddIcon />
                        </IconButton>
                    </Box>
                </Box>
                <OnboardingWizardButtons onPrevStep={onPrevStep} onNextStep={onNextStep} />
            </StepContent>

            <TransactionTypeModal data={modal.data} open={modal.open} onClose={modal.closeModal} onDataSaved={fetchData} />
        </>
    );
};

const OnboardingTaxTypesStep = ({ settings, changeSettings, onNextStep, onPrevStep }) => {
    const [state, setState] = useState({ data: [] });
    const modal = useModal();

    const { startLoading, stopLoading, isLoading } = useLoadingIndicator();

    const fetchData = async () => {
        startLoading();
        try {
            let data = await fetchTaxTypes();
            setState({ ...state, data });
        } catch (e) {
            console.error(e);
            alert(e);
        } finally {
            stopLoading();
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <StepLabel>Sazby daně</StepLabel>
            <StepContent>
                <Box my={2}>
                    <TaxTypesWidgets data={state.data || []} disabled={isLoading} onEdit={modal.showModal} settings={settings} />{" "}
                </Box>
                <OnboardingWizardButtons onPrevStep={onPrevStep} onNextStep={onNextStep} />
            </StepContent>
            <TaxTypeModal data={modal.data} open={modal.open} onClose={modal.closeModal} onDataSaved={fetchData} size="small" />
        </>
    );
};

const OnboardingDocumentTypesStep = ({ settings, changeSettings, onNextStep, onPrevStep }) => {
    const [state, setState] = useState({ data: [] });
    const modal = useModal();
    const features = useMemo(() => createDocumentTypeFeatures(settings.transactionsTaxSupport));

    const { startLoading, stopLoading, isLoading } = useLoadingIndicator();

    const fetchData = async () => {
        startLoading();
        try {
            let data = await fetchDocumentTypes();
            setState({ ...state, data });
        } catch (e) {
            console.error(e);
            alert(e);
        } finally {
            stopLoading();
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <StepLabel>Typy dokumentů</StepLabel>
            <StepContent>
                <Box my={2}>
                    <DocumentTypesFeatures features={features} data={state.data || []} onEdit={modal.showModal} disabled={isLoading} />
                    <Box p={4}>
                        <IconButton onClick={() => modal.showModal({})}>
                            <AddIcon />
                        </IconButton>
                    </Box>
                </Box>
                <OnboardingWizardButtons onPrevStep={onPrevStep} onNextStep={onNextStep} />
            </StepContent>

            <DocumentTypeModal data={modal.data} open={modal.open} onClose={modal.closeModal} onDataSaved={fetchData} />
        </>
    );
};

const OnboardingFinishStep = ({ settings, changeSettings, onNextStep, onPrevStep }) => {
    return (
        <>
            <StepLabel>Dokončení</StepLabel>
            <StepContent>
                <Box my={2}>
                    <Typography variant="body2" color="textSecondary">
                        Vše máte nastaveno. Můžete pokračovat do aplikace
                    </Typography>
                </Box>
                <OnboardingWizardButtons onPrevStep={onPrevStep} onNextStep={onNextStep} />
            </StepContent>
        </>
    );
};

export const OnboardingContainer = () => {
    const settings = useSettings();
    const changeSettings = useChangeSettings();
    const { startLoading, stopLoading, isLoading } = useLoadingIndicator();

    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleFinish = async () => {
        setActiveStep(0);
        changeSettings({ ...settings, initialized: true });
    };

    return (
        <Box>
            <Box mb={4}>
                <Typography variant="h5" gutterBottom>
                    Děkujeme!
                </Typography>
                <Typography color="textSecondary" variant="body2" gutterBottom>
                    Již zbývá jen málo a můžete plně využívat svúj nový účet. Pojďme nastavit pár základních věcí. Všechna tato nastavení můžete později
                    změnit...
                </Typography>
            </Box>
            <Stepper activeStep={activeStep} orientation="vertical" color="secondary">
                <Step>
                    <OnboardingCompanyStep settings={settings} onNextStep={handleNext} />
                </Step>
                <Step>
                    <OnboardingModulesStep settings={settings} changeSettings={changeSettings} onNextStep={handleNext} onPrevStep={handleBack} />
                </Step>
                {settings.transactionsSupport && (
                    <Step>
                        <OnboardingTransactionsStep settings={settings} onNextStep={handleNext} onPrevStep={handleBack} />
                    </Step>
                )}
                {settings.transactionsTaxSupport && (
                    <Step>
                        <OnboardingTaxTypesStep settings={settings} onNextStep={handleNext} onPrevStep={handleBack} />
                    </Step>
                )}
                {settings.documentsSupport && (
                    <Step>
                        <OnboardingDocumentTypesStep settings={settings} onNextStep={handleNext} onPrevStep={handleBack} />
                    </Step>
                )}
                <Step>
                    <OnboardingFinishStep settings={settings} onNextStep={handleFinish} onPrevStep={handleBack} />
                </Step>
            </Stepper>
        </Box>
    );
};

/* ***** backend ***** */
//TODO move to profile?
export const fetchCurrencies = async () => {
    let response = await backend(`/api/settings/currencies`);
    return response.body;
};

const saveCompanyInfo = async (values) => {
    let response = await backend(`/api/settings/company`, "PUT", {
        body: values,
    });
    return response.body;
};

const changeFeatureSettings = async (key, enabled) => {
    let response = await backend(`/api/settings/features/${key}`, enabled ? "PUT" : "DELETE");
    return response.body;
};

const changeModuleSettings = async (values) => {
    let response = await backend(`/api/settings/modules`, "PUT", { body: values });
    return response.body;
};
