import React, {useEffect, useLayoutEffect, useRef} from "react";

import {makeStyles} from "@mui/styles";
import {emphasize} from "@mui/material/styles";
import * as echarts from "echarts";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import clsx from "clsx";

const emptyColor = "#DADADA";

export const chartColor = (baseColor, count, index) => {
    if (isNaN(count) || count < 1) {
        return baseColor;
    }
    return emphasize(baseColor, 0.25 + (0.55 / count) * index);
};

export const ReactECharts = ({ option, minWidth, minHeight, onClick }) => {
    const containerRef = useRef(),
        chartRef = useRef();
    // const [size, setSize] = useState({});
    useLayoutEffect(() => {
        var myChart = echarts.init(containerRef.current, null, { renderer: "svg" });
        /*
        grid: {
            top:    60,
            bottom: 60,
            left:   '10%',
            right:  '10%',
          },
          */
        myChart.setOption(option);

        myChart.on("click", function (event) {
            !!onClick && onClick(event);
        });

        chartRef.current = myChart;

        let observer = new ResizeObserver((a) => {
            let width = a[0].contentRect.width,
                height = a[0].contentRect.height;

            //chartRef.current.dispose(containerRef.current);

            //chartRef.current = echarts.init(containerRef.current, null, { renderer: "svg" });
            //chartRef.current.setOption({ width, height, ...option });

            myChart.resize({ width, height });

            //setSize({ width, height });
        });
        observer.observe(containerRef.current);
        return () => {
            myChart.dispose();
            observer.disconnect(containerRef.current);
        };
    }, []);

    useEffect(() => {
        if (chartRef.current) {
            chartRef.current.setOption(option);
        }
    }, [chartRef.current, option]);

    return (
        <div style={{ position: "relative", height: "100%", width: "100%", minHeight: minHeight || 140, minWidth: minWidth || 140, overflow: "hidden" }}>
            <div style={{ position: "absolute", width: "100%", height: "100%" }} ref={containerRef}></div>
        </div>
    );
};

const emptyDataPie = {
    label: "empty",
    data: [1],
    backgroundColor: [emptyColor],
    hoverBackgroundColor: [emptyColor],
};

export const DonutChart = ({
    data,
    children,
    colors,
    dataColors,
    centerTitle,
    centerValue,
    disabled,
    useChartColors = true,
    size = 300,
    onClick,
    radius = 95,
}) => {
    const series = [];
    const hasData = Array.isArray(data) && data.length;

    if (!Array.isArray(colors) || !colors.length) {
        //TODO populate chart colors
        colors = [];
        let max = Math.max((data || []).length, 1);
        for (let i = 0; i < max; i++) {
            colors.push(chartColor("#6A6A6A", max, i));
        }
    }

    if (hasData) {
        for (let i = 0; i < data.length; i++) {
            //check if data
            let val = 0;
            if (Array.isArray(data[i]) && data[i].length) {
                for (let j = 0; j < data[i].length; j++) {
                    val += (data[i][j] || 0) * 1;
                }
            }

            if (!val || isNaN(val) || val === 0) {
                series.push({ type: "pie", label: { show: false }, data: [{ value: 1, itemStyle: { color: emptyColor } }] });
                continue;
            }

            let s = { type: "pie", label: { show: false }, data: [] };
            for (let j = 0; j < data[i].length; j++) {
                s.data.push({
                    value: data[i][j] || 0,
                    itemStyle: { color: useChartColors ? chartColor(colors[i % colors.length], data[i].length, j) : colors[j % colors.length] },
                });
            }
            series.push(s);
        }
    } else {
        series.push({ type: "pie", label: { show: false }, data: [{ value: 1, itemStyle: { color: emptyColor } }] });
    }

    for (let i = 0; i < series.length; i++) {
        series[i].startAngle = 270;
        series[i].radius = [`${radius - 15 - i * 17}%`, `${radius - i * 17}%`];
    }

    let options = {
        series,
        width: "auto",
        height: "auto",
    };

    if (centerValue) {
        options.title = {
            text: `${centerValue}`,
            left: "center",
            top: "center",
        };
    }

    return <ReactECharts option={options} minHeight={size} onClick={onClick} />;
};

const useLabelClasses = makeStyles((theme) => ({
    clickable: {
        cursor: "pointer",
    },
    chartLabel: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        alignItems: "center",
        gap: theme.spacing(1),
    },
    dotContainer: {
        flex: 0,
    },
    labelContainer: {
        flex: 1,
        "& > *": {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
        },
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        minWidth: 0,
    },
    valueContainer: {
        flex: 0,
        textAlign: "right",
    },
    dot: {
        display: "inline-block",
        width: "0.75em",
        height: "0.75em",
        borderRadius: "0.75em",
    },
    labels: {
        display: "flex",
        flexWrap: "wrap",
    },
    variantRow: {
        flexDirection: "row",
        columnGap: theme.spacing(4),
        rowGap: theme.spacing(2),
        justifyContent: "space-between",
    },
    variantColumn: {
        flexDirection: "column",
        flexWrap: "nowrap",
        alignContent: "space-bwetween",
    },
    small: {
        rowGap: theme.spacing(1),
        columnGap: theme.spacing(2),
    },
}));

export const ChartLabelDot = ({ value, color }) => {
    const classes = useLabelClasses();
    return (
        <Typography variant="subtitle2" component="span">
            {value || undefined}
        </Typography>
    );
};

export const ChartLabel = ({ label, value, color, index, count, onClick, size, sx, useDataColors }) => {
    const classes = useLabelClasses();
    let c = !!useDataColors ? color : chartColor(color || emptyColor, count, index);

    return (
        <Box onClick={onClick} className={clsx(classes.chartLabel, { [classes.clickable]: !!onClick, [classes.small]: size == "small" })} sx={sx}>
            <div className={classes.dotContainer}>
                <div className={classes.dot} style={{ backgroundColor: c }} />
            </div>
            <div className={classes.labelContainer}>
                <Typography variant="caption" color="textSecondary">
                    {label}
                </Typography>
            </div>
            <Typography variant="caption" component="div" className={classes.valueContainer}>
                {value}
            </Typography>
        </Box>
    );
};

export const ChartLabels = ({ values, renderValue, color, onClick, emptyValueLabel, variant = "column", size, sx, useDataColors }) => {
    const classes = useLabelClasses();
    let array = values || [];
    return (
        <div className={clsx(classes.labels, variant == "row" ? classes.variantRow : classes.variantColumn)}>
            {array.map((nv, idx) => (
                <ChartLabel
                    key={`key-${nv.id}-${idx}`}
                    label={nv.name || emptyValueLabel || ""}
                    value={renderValue(nv)}
                    color={(useDataColors && nv.color) || (Array.isArray(color) && color.length ? color[idx % color.length] : color)}
                    useDataColors={useDataColors}
                    count={array.length}
                    index={idx}
                    onClick={() => {
                        onClick && onClick(nv);
                    }}
                    size={size}
                    sx={sx}
                />
            ))}
        </div>
    );
};

const useStackedHorizontalBarStyles = makeStyles((theme) => ({}));

export const StackedHorizontalBar = ({}) => {
    return <div></div>;
};
