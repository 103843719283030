import React, {useEffect} from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import {adaptV4Theme, createTheme, StyledEngineProvider, ThemeProvider} from "@mui/material/styles";
import theme from "./theme";
import {BrowserRouter as Router, useLocation} from "react-router-dom";
import {Security} from "./utils/security";
import {LoadingIndicatorContainer} from "./utils/loading";
import {SnackbarProvider} from "notistack";
import DateAdapter from "@mui/lab/AdapterMoment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import {AlertContainer} from "./components/Alert";

const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
};

ReactDOM.render(
    <StyledEngineProvider injectFirst>
        <ThemeProvider theme={createTheme(adaptV4Theme(theme))}>
            <LocalizationProvider dateAdapter={DateAdapter}>
                <SnackbarProvider>
                    <Security>
                        <LoadingIndicatorContainer>
                            <Router>
                                <ScrollToTop />
                                <AlertContainer>
                                    <App />
                                </AlertContainer>
                            </Router>
                        </LoadingIndicatorContainer>
                    </Security>
                </SnackbarProvider>
            </LocalizationProvider>
        </ThemeProvider>
    </StyledEngineProvider>,
    document.getElementById("root")
);
