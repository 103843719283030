import React from "react";

import {ToolPanel} from "./Layout";
import TagIcon from "@mui/icons-material/Tag";
import {makeStyles} from "@mui/styles";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import {Section} from "./Containers";
import clsx from "clsx";

export const TagsTool = ({ value, onChange, disabled, defaultExpanded, title = "Štítky" }) => {
    return (
        <ToolPanel title={title} defaultExpanded={defaultExpanded} icon={<TagIcon fontSize="small" />}>
            <TagsField value={value} onChange={onChange} disabled={disabled} variant="standard" placeholder="Přidejte štítek" />
            <FormHelperText>Po zadání štítku stikněte enter</FormHelperText>
        </ToolPanel>
    );
};

export const TagsField = ({ value, onChange, disabled, defaultExpanded, label, margin = "normal", variant, size = "normal", placeholder }) => {
    return (
        <Autocomplete
            multiple
            options={[]}
            value={value || []}
            onChange={(!!onChange && ((e, value, reason, detail) => onChange(value, reason, detail))) || undefined}
            size={size}
            freeSolo
            renderTags={(value, getTagProps) => value.map((option, index) => <Chip label={option} {...getTagProps({ index })} sx={{ mb: 1 }} />)}
            renderInput={(params) => <TextField {...params} label={label || undefined} variant={variant} placeholder={placeholder || null} margin={margin} />}
            disabled={!!disabled}
        />
    );
};

const useChipStyles = makeStyles((theme) => ({
    attachmentChips: {
        display: "flex",
        flexWrap: "wrap",
        columnGap: 8,
        rowGap: 8,

        "& > .MuiChip-root": {
            maxWidth: "100%",
        },
        attachmentChipsCentered: {
            justifyContent: "center",
        },
    },
}));

export const TagChips = ({ value, url, spacing = 2, disabled, readOnly, size, variant, center }) => {
    const classes = useChipStyles();
    //const showAlert = useAlert();

    /*
    const handleDelete =
        !readOnly && onDelete
            ? (attachment, key) => () => {
                  showAlert("Smazat přílohu", `Chcete smazat přílohu ${attachment.name || attachment.path} ?`, [
                      { text: "Ne" },
                      {
                          text: "Ano",
                          onPress: () => {
                              onDelete(attachment, key);
                          },
                      },
                  ]);
              }
            : () => null;
*/
    return (
        <div className={clsx(classes.attachmentChips, { [classes.attachmentChipsCentered]: !!center })}>
            {value &&
                Array.isArray(value) &&
                !!value.length &&
                value.map((a, key) => (
                    <Chip
                        key={`${key}-${a}`}
                        label={a}
                        icon={<TagIcon />}
                        disabled={disabled}
                        variant={variant}
                        color="primary"
                        onDelete={null /*handleDelete(a, key)*/}
                        size={size || "normal"}
                        title={a}
                    />
                ))}
        </div>
    );
};

export const TagsSectionField = ({ value, onChange, disabled, defaultExpanded, title, label, margin = "normal", variant, size = "small", placeholder }) => {
    return (
        <Section
            title={title || "Tagy"}
            items={value || []}
            subtitleFn={(pm) => pm}
            px={2}
            actions={
                /*
                readOnly
                    ? null
                    : [
                          {
                              title: hint || "Nahrát přílohu",
                              icon: <AddIcon />,
                              onClick: console.log,
                          },
                      ]
            */ undefined
            }
        >
            <Autocomplete
                multiple
                options={[]}
                value={value || []}
                onChange={(!!onChange && ((e, value, reason, detail) => onChange(value, reason, detail))) || undefined}
                size={size}
                freeSolo
                renderTags={(value, getTagProps) => value.map((option, index) => <Chip label={option} {...getTagProps({ index })} sx={{ mb: 1 }} />)}
                renderInput={(params) => (
                    <TextField {...params} label={label || undefined} variant={variant} placeholder={placeholder || null} margin={margin} />
                )}
                disabled={!!disabled}
            />
        </Section>
    );
};
