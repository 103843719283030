import React from "react";

import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";

import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    popper: {
        zIndex: 1,
    },
}));

export default ({
    fetchOptions,
    value,
    onChange,
    label,
    id,
    error,
    disabled,
    readOnly,
    helperText,
    loadingText,
    getOptionLabel = (option) => option.label,
    variant,
    margin,
    fullWidth,
    filterOptions,
    freeSolo,
    ...props
}) => {
    const [open, setOpen] = React.useState(false);
    const [inputValue, setInputValue] = React.useState("");
    const [options, setOptions] = React.useState([]);
    const [loading, setLoading] = React.useState(open && options.length === 0);
    const classes = useStyles();

    React.useEffect(() => {
        let active = true;

        if (open && active && fetchOptions) {
            (async () => {
                try {
                    setLoading(true);
                    let options = [];
                    if (typeof fetchOptions == "function") {
                        options = await fetchOptions(inputValue);
                    } else {
                        options = await fetchOptions;
                    }

                    if (active) {
                        setOptions(options || []);
                    }
                } catch (e) {
                    console.error(e);
                } finally {
                    setLoading(false);
                }
            })();
        } else {
            setOptions([]);
        }

        return () => {
            active = false;
        };
    }, [open, inputValue]);

    return (
        <Autocomplete
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            getOptionLabel={getOptionLabel}
            options={options}
            loading={loading}
            value={value}
            onChange={onChange}
            variant={variant || "standard"}
            disabled={disabled}
            readOnly={readOnly}
            loadingText={loadingText}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            filterOptions={filterOptions}
            freeSolo={freeSolo}
            renderInput={(params) => (
                <TextField
                    {...params}
                    disabled={disabled}
                    readOnly={readOnly}
                    label={label}
                    fullWidth={fullWidth}
                    margin={margin}
                    helperText={helperText}
                    error={!!error}
                    variant={variant}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {disabled || readOnly ? null : params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
            {...props}
        />
    );
};
