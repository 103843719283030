import React, { useEffect, useMemo, useState, useCallback } from "react";

import { useNavigate } from "react-router-dom";

import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DashboardIcon from "@mui/icons-material/Dashboard";
import FilterListIcon from "@mui/icons-material/FilterList";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
//import RefreshIcon from "@mui/icons-material/Refresh";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";

import { makeStyles } from "@mui/styles";

import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";

import MuiTable from "@mui/material/Table";
import MuiTableBody from "@mui/material/TableBody";
import MuiTableCell from "@mui/material/TableCell";
import MuiTableContainer from "@mui/material/TableContainer";
import MuiTableHead from "@mui/material/TableHead";
import MuiTableRow from "@mui/material/TableRow";

import {
    MasterContent,
    MasterDetail,
    MasterDetailContent,
    useDetailRefresh,
    useMasterDetail,
    useMasterDetailParams,
    useMasterDetailTabs,
    useMasterRefresh,
    useOverviewRefresh,
} from "../components/MasterDetail";
import { LayoutWithInfo, LayoutWithInfoInfo, LayoutWithInfoMain, PageTitle, Panel } from "../components/Layout";
import {
    EmployeeBasicInfo,
    EmployeeList,
    EmployeeListFilter,
    EmployeeModal,
    EmployeeSelect,
    fetchBillingEmployeesChart,
    fetchBillingEmployeesData,
    fetchBillingPeriodSummary,
    fetchEmployee,
    fetchEmployees,
    fetchEmployeeTimesheetItems,
    fetchTimesheetEmployeesChart,
    fetchTimesheetEmployeesData,
    fetchTimesheetPeriodSummary,
    lockEmployee,
} from "../components/employees";
import {
    EmployeeBillingModal,
    EmployeeBillingsList,
    fetchEmployeeBillings,
    fetchEmployeeBillingsSummary,
    fetchEmployeeBillingsTrends,
    prepareEmployeeBillingTransaction,
} from "../components/billing";

import {
    deleteEmployeeTimesheet,
    deleteEmployeeTimesheetAttachment,
    downloadEmployeeExcel,
    downloadEmployeesExcel,
    fetchEmployeeAggregation,
    fetchEmployeeTimesheetCalendar,
    fetchEmployeeTimesheetChart,
    projectIdAxis,
    saveEmployeeTimesheet,
    TimesheetCalendar,
    TimesheetChart,
    TimesheetFilter,
    TimesheetModal,
    useTimesheetModal,
} from "../components/timesheets";

import { useForm } from "../utils/form";
import { useLoadableList } from "../utils/lists";
import { LoadingIndicator, LoadingIndicatorContainer, useLoadingIndicator } from "../utils/loading";
import { useAlert } from "../components/Alert";

import Period, {
    formatPeriod,
    formatPeriodValue,
    getDefaultPeriodDate,
    getPeriodKey,
    nextPeriodType,
    periodAxes,
    periodInterval,
    usePeriod,
} from "../components/period";
import { useTheme } from "@mui/material/styles";

import { chartColor, ChartLabels, DonutChart } from "../components/Charts";
import { useModal } from "../components/Modal";
import Value from "../components/Value";

import { ProjectSelect } from "../components/projects";
import { TagsField } from "../components/Tags";
import { InlineFilter, useInlineFilter } from "../components/InlineFilter";
import Aggregations, { AxesSelector } from "../components/Aggregations";
import { DateValue } from "../components/DateFields";
import { CurrencyValue, formatCurrency, formatNum, NumberValue } from "../components/NumberFields";
import { DetailRow, DetailRowMain, DetailRowValue, FlexItem, FlexRow } from "../components/Containers";
import { useSettings } from "../utils/settings";
import TrendsBarChart, { updatePeriodOnClick } from "../components/TrendsBarChart";
import { TransactionModal } from "../components/transactions";
import { WidgetFixed, WidgetFlexible, WidgetRoot } from "../components/Widgets";

const EmployeesMaster = ({ menuItems, showFilter, setShowFilter }) => {
    const { showDetail, showOverview, showAdd, mobile } = useMasterDetail();
    const filterForm = useForm();
    const [filter, setFilter] = useState({});
    const loadableList = useLoadableList();
    const { startLoading, stopLoading, isLoading } = useLoadingIndicator();
    const params = useMasterDetailParams();
    const selectedId = params.tabName;

    const handleFilterChange = (filter) => {
        setFilter(filter);
    };
    const fetchList = async (filter, paging) => {
        startLoading();
        try {
            const data = await fetchEmployees(filter, paging);
            loadableList.acceptData(data);
        } catch (e) {
            console.error(e);
        } finally {
            stopLoading();
        }
    };

    useEffect(() => {
        fetchList(filter);
    }, [filter]);

    useMasterRefresh(() => fetchList(filterForm.valuesRef.current));

    return (
        <>
            <Collapse in={showFilter}>
                <Box p={2} pb={0}>
                    <EmployeeListFilter onFilterChange={handleFilterChange} form={filterForm} />
                </Box>
            </Collapse>
            <EmployeeList
                disabled={isLoading}
                data={loadableList.data}
                hasMore={loadableList.size < loadableList.totalElements - 1}
                onShowMoreClick={() => fetchList(filter, loadableList.nextPageParams)}
                onItemClick={({ id }) => showDetail(id)}
                selectedId={selectedId}
            />
        </>
    );
};

const EmployeeMasterContainer = ({ menuItems }) => {
    const { showDetail, showOverview, showAdd, mobile, refreshMaster } = useMasterDetail();
    const [showFilter, setShowFilter] = useState();
    const employeeModal = useModal();

    //remember context menu items
    const masterMenuItems = useMemo(
        () => [
            {
                icon: <AddIcon />,
                title: "Přidat zaměstnance",
                alwaysVisible: true,
                onClick: () => employeeModal.showModal({}),
            },
            {
                icon: <FilterListIcon />,
                title: "Filter",
                alwaysVisible: true,
                onClick: () => setShowFilter(!showFilter),
                badgeVariant: "dot",
                badgeContent: !showFilter,
            },
            { icon: <DashboardIcon />, title: "Přehled", onClick: showOverview },
        ],
        [showFilter]
    );

    const onDataSaved = useMemo(
        () => (values) => {
            showDetail(values.id);
            refreshMaster();
        },
        [refreshMaster, showDetail]
    );

    return (
        <>
            <LoadingIndicatorContainer>
                <LoadingIndicator hidden="smDown" />
                <PageTitle title="Zaměstnanci" menuItems={mobile ? [...masterMenuItems, ...menuItems] : masterMenuItems} />
                <LoadingIndicator hidden="smUp" />
                <MasterContent>
                    <EmployeesMaster menuItems={menuItems} showFilter={showFilter} setShowFilter={setShowFilter} />
                </MasterContent>
            </LoadingIndicatorContainer>
            <EmployeeModal
                open={employeeModal.open}
                onClose={employeeModal.closeModal}
                onDataSaved={onDataSaved}
                data={employeeModal.data}
                title="Nový zaměstnanec"
            />
        </>
    );
};

const timesheetsTabState = { timesheetCalendar: {}, timesheetChart: {} };
const TimesheetsTab = ({ detailId, axes, setAxes, ...props }) => {
    const theme = useTheme();
    const readOnly = false;
    const { hideDetail, refreshDetail } = useMasterDetail();
    const { startLoading, stopLoading, isLoading } = useLoadingIndicator();
    const { showTimesheetModal, closeTimesheetModal, modalState } = useTimesheetModal();
    const periodRef = usePeriod();
    const [period] = periodRef;

    const [state, setState] = useState(timesheetsTabState);
    const [showFilter, setShowFilter] = useState(false);
    const [filter, setFilter] = useState("");
    const [tab, setTab] = useState("timesheets");

    const showAlert = useAlert();

    const fetchData = useCallback(async () => {
        startLoading();
        let interval = periodInterval(period);
        let timesheetCalendar = {},
            timesheetChart = {};
        try {
            let result = await Promise.allSettled([
                fetchEmployeeTimesheetCalendar(
                    detailId,
                    interval.from,
                    interval.to,
                    period.type == "month" ? "day" : nextPeriodType(period.type),
                    false,
                    filter
                ),
                fetchEmployeeTimesheetChart(detailId, interval.from, interval.to, undefined, filter),
            ]);

            let dataResult = result[0];
            if (dataResult.status === "fulfilled") {
                timesheetCalendar = dataResult.value;
            }
            dataResult = result[1];
            if (dataResult.status === "fulfilled") {
                timesheetChart = dataResult.value;
            }
        } catch (e) {
            console.error(e);
            alert(e);
        } finally {
            setState({ timesheetCalendar, timesheetChart });
            stopLoading();
        }
    }, [detailId, period, filter]);

    const openTimesheetModal = useCallback(
        (data) => {
            if (!!data) {
                return showTimesheetModal(data);
            }

            //prepare default data
            data = {
                date: getDefaultPeriodDate(periodRef),
            };

            return showTimesheetModal(data);
        },
        [showTimesheetModal]
    );

    const saveItem = useCallback(
        async (data, submitHints) => {
            await saveEmployeeTimesheet(detailId, data);
            refreshDetail();
            if (submitHints && submitHints.keepOpen) {
                openTimesheetModal({ date: data.date });
            }
        },
        [detailId, showTimesheetModal, openTimesheetModal]
    );

    const deleteItem = useCallback(
        (item) => {
            const deleteTimesheet = async () => {
                await deleteEmployeeTimesheet(detailId, item.id);
                refreshDetail();
            };
            showAlert("Smazat položku", `Opravdu chcete smazat položku výkazu ${item.projectName} (${item.hours} h)?`, [
                { text: "Ne" },
                { text: "Ano", onPress: deleteTimesheet },
            ]);
        },
        [refreshDetail]
    );

    useEffect(() => {
        if (detailId) {
            fetchData();
        } else {
            setState(timesheetsTabState);
        }
    }, [detailId, period, filter]);

    useDetailRefresh(fetchData, [fetchData]);

    const tabs = (
        <Tabs value={tab} onChange={(e, tab) => setTab(tab)} disabled={isLoading} size="small">
            <Tab label="Položky výkazu" value="timesheets" disabled={isLoading} />
            <Tab label="Přehled" value="overview" disabled={isLoading} />
        </Tabs>
    );

    return (
        <>
            <Box mb={2}>
                <Period
                    disabled={isLoading}
                    year
                    quarter
                    month
                    week
                    day
                    actions={[
                        {
                            icon: <FileDownloadIcon />,
                            title: "Exportovat do excelu",
                            onClick: () => async () => {
                                startLoading();
                                try {
                                    let interval = periodInterval(period);
                                    await downloadEmployeeExcel(detailId, interval.from, interval.to, filter);
                                } catch (e) {
                                    alert("error");
                                }
                                stopLoading();
                            },
                        },
                        {
                            icon: <FilterListIcon />,
                            title: "Filtrovat...",
                            onClick: () => setShowFilter(!showFilter),
                        },
                        {
                            icon: <AddIcon />,
                            title: "Přidat položku výkazu",
                            onClick: () => openTimesheetModal(),
                            alwaysVisible: true,
                        },
                    ]}
                />
            </Box>
            <Collapse in={showFilter}>
                <Box mb={4}>
                    <TimesheetFilter showProject filter={filter || {}} onFilterChange={(filter) => setFilter(filter)} />
                </Box>
            </Collapse>

            <Panel>
                <Box mb={4}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} xl={7}>
                            <TimesheetCalendar
                                data={state.timesheetCalendar}
                                disabled={isLoading}
                                showProject
                                showTimesheetModal={showTimesheetModal}
                                onEdit={showTimesheetModal}
                                onDelete={deleteItem}
                                onDeleteAttachment={(item) => async (attachment) => {
                                    startLoading();
                                    try {
                                        await deleteEmployeeTimesheetAttachment(detailId, item.id, attachment.id);
                                        refreshDetail();
                                        stopLoading();
                                    } catch (e) {
                                        stopLoading();
                                        alert(e);
                                    }
                                }}
                                downloadAttachmentUrl={(item) => (attachment) =>
                                    `/api/employees/${detailId}/timesheets/${item.id}/attachments/${attachment.id}`}
                            />
                        </Grid>
                        <Grid item xs={12} xl={5}>
                            <Box px={8} mb={4}>
                                <TimesheetChart data={state.timesheetChart} disabled={isLoading} />

                                <ChartLabels
                                    values={
                                        (Array.isArray(state.timesheetChart && state.timesheetChart.data) &&
                                            state.timesheetChart.data.length &&
                                            state.timesheetChart.data[0]) ||
                                        []
                                    }
                                    color={isLoading ? "#6A6A6A" : theme.palette.primary.main}
                                    renderValue={(nv) => <NumberValue value={nv.value} unit="h" />}
                                    emptyValueLabel="Ostatní"
                                    variant="row"
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Panel>

            <Box my={3}>
                {tab === "timesheets" && <TimesheetItemsTab tabs={tabs} detailId={detailId} period={period} disabled={isLoading} deleteItem={deleteItem} />}
                {tab === "overview" && <AggregationsTab tabs={tabs} detailId={detailId} disabled={isLoading} axes={axes} setAxes={setAxes} />}
            </Box>

            <TimesheetModal {...(modalState || {})} onSaveItem={saveItem} onClose={closeTimesheetModal} showProject employeeId={detailId} />
        </>
    );
};

const timesheetItemsTabState = {};
const TimesheetItemsTab = ({ detailId, tabs, period, ...props }) => {
    const { hideDetail, refreshDetail, on } = useMasterDetail();
    const [data, setData] = useState(timesheetItemsTabState);
    const { startLoading, stopLoading, isLoading } = useLoadingIndicator();

    const fetchData = useCallback(async () => {
        startLoading();
        let data = {};
        try {
            data = await fetchEmployeeTimesheetItems(detailId, period.start, period.end);
        } catch (e) {
            alert("error");
            console.error(e);
        } finally {
            setData(data);
            stopLoading();
        }
    }, [detailId, period]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    useDetailRefresh(fetchData, [fetchData]);

    return (
        <>
            <Box mb={4}>
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                    {tabs}
                    <Stack direction="row" alignItems="center">
                        {/*!readOnly && (
                            <IconButton disabled={isLoading} onClick={() => openTimesheetModal()} title="Přidat položku výkazu">
                                <AddIcon />
                            </IconButton>
                        )*/}
                    </Stack>
                </Stack>
            </Box>
            <Box mb={4}>
                <MuiTableContainer component={Paper}>
                    <MuiTable size="small">
                        <MuiTableHead>
                            <MuiTableRow>
                                <MuiTableCell>Datum</MuiTableCell>
                                <MuiTableCell>Projekt</MuiTableCell>
                                <MuiTableCell align="right">Počet hodin</MuiTableCell>
                            </MuiTableRow>
                        </MuiTableHead>
                        <MuiTableBody>
                            {!!data &&
                                Array.isArray(data.content) &&
                                data.content.map((row) => (
                                    <MuiTableRow key={row.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                        <MuiTableCell>
                                            <DateValue value={row.date} />
                                        </MuiTableCell>
                                        <MuiTableCell>
                                            {row.projectName}
                                            {!!row.description && (
                                                <Typography component="div" variant="caption" color="textSecondary">
                                                    {row.description}
                                                </Typography>
                                            )}
                                        </MuiTableCell>
                                        <MuiTableCell align="right">
                                            <NumberValue value={row.hours} unit="h" />
                                        </MuiTableCell>
                                    </MuiTableRow>
                                ))}
                        </MuiTableBody>
                    </MuiTable>
                </MuiTableContainer>
                {!!data && data.totalPages > 1 && (
                    <Box my={1}>
                        <Pagination
                            count={data.totalPages}
                            page={1 + data.number}
                            siblingCount={1}
                            boundaryCount={1}
                            onChange={async (e, page) => {
                                startLoading();
                                try {
                                    let data = await fetchEmployeeTimesheetItems(detailId, period.start, period.end, {
                                        page: page - 1,
                                        size: 10,
                                    });
                                    setData(data);
                                } catch (e) {
                                    console.error(e);
                                    alert("error");
                                }
                                stopLoading();
                            }}
                        />
                    </Box>
                )}
            </Box>
        </>
    );
};

const AggregationsTab = ({ detailId, axes, setAxes, tabs, ...props }) => {
    const theme = useTheme();
    const readOnly = false;
    const { hideDetail, refreshMaster } = useMasterDetail();
    const { startLoading, stopLoading, isLoading } = useLoadingIndicator();
    const periodRef = usePeriod();
    const [period] = periodRef;

    const [aggregationData, setAggregationData] = useState({});

    const showAlert = useAlert();

    const loadAggregationData = useCallback(
        async (axes) => {
            if (!axes || !axes.length) {
                return;
            }

            let aggregationData = {};
            setAggregationData({});
            let interval = periodInterval(period);
            startLoading();
            try {
                aggregationData = await fetchEmployeeAggregation(
                    detailId,
                    interval.from,
                    interval.to,
                    null,
                    axes.filter((a) => !!a.selected).map((a) => a.id)
                );
                setAggregationData(aggregationData);
            } catch (e) {
                alert(e);
                console.error(e);
            }
            stopLoading();
        },
        [detailId, axes, period]
    );

    useEffect(() => {
        loadAggregationData(axes);
    }, [axes, period, detailId]);

    return (
        <>
            <Box mb={4}>
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                    {tabs}
                    <Stack direction="row" alignItems="center">
                        {/*!readOnly && (
                            <IconButton disabled={isLoading} onClick={() => openTimesheetModal()} title="Přidat položku výkazu">
                                <AddIcon />
                            </IconButton>
                        )*/}
                        <AxesSelector value={axes} onChange={(axes) => setAxes(axes)} disabled={isLoading} />
                    </Stack>
                </Stack>
            </Box>

            <Aggregations
                data={aggregationData}
                disabled={isLoading}
                renderChart={(val) => (
                    <DonutChart
                        data={[(val.children || []).map((ch) => ch.value)]}
                        colors={isLoading ? ["#6A6A6A"] : [theme.palette.primary.main, theme.palette.secondary.main]}
                        disabled={isLoading}
                        height={250}
                        thickness={20}
                    />
                )}
                formatHeader={(base, classes, idx, count, isLeaf) => (
                    <FlexRow>
                        <FlexItem relativeWidth={3}>
                            {!!isLeaf && (
                                <div
                                    style={{
                                        display: "inline-block",
                                        width: 16,
                                        height: 16,
                                        backgroundColor: chartColor(theme.palette.primary.main, count, idx),
                                        marginLeft: 4,
                                        marginRight: 8,
                                    }}
                                ></div>
                            )}
                            <Typography variant="body1">{base.type == "period" ? formatPeriodValue(base.key) : base.name || base.key || "N/A"}</Typography>
                        </FlexItem>
                        <FlexItem right>
                            <Typography variant="body1">
                                <b>
                                    <NumberValue value={base.value} unit={base.unit} />
                                </b>
                            </Typography>
                        </FlexItem>
                    </FlexRow>
                )}
            />
        </>
    );
};

const BillingsTab = ({ detailId, onNewBilling, onEditBilling, readOnly, ...props }) => {
    const theme = useTheme();
    const settings = useSettings();
    const navigate = useNavigate();
    const { refreshDetail } = useMasterDetail();
    const { startLoading, stopLoading, isLoading } = useLoadingIndicator();
    const periodRef = usePeriod();
    const [period, setPeriod] = periodRef;
    const transactionModal = useModal();
    const [state, setState] = useState({ billings: {}, summary: {}, trends: {} });

    //
    const fetchData = useCallback(async () => {
        let billings = {},
            summary = {},
            trends = {};
        startLoading();
        try {
            let result = await Promise.allSettled([
                fetchEmployeeBillings(detailId, period.start, period.end),
                fetchEmployeeBillingsSummary(detailId, period.start, period.end),
                fetchEmployeeBillingsTrends(detailId, period.day, period.type),
            ]);

            let dataResult = result[0];
            if (dataResult.status === "fulfilled") {
                billings = dataResult.value;
            }
            dataResult = result[1];
            if (dataResult.status === "fulfilled") {
                summary = dataResult.value;
            }
            dataResult = result[2];
            if (dataResult.status === "fulfilled") {
                trends = dataResult.value;
            }
        } catch (e) {
            console.error(e);
            alert("error");
        }
        stopLoading();
        setState({ billings, summary, trends });
    }, [detailId, period]);

    useEffect(() => {
        if (detailId) {
            fetchData();
        }
    }, [detailId, fetchData]);

    useDetailRefresh(fetchData, [fetchData]);

    return (
        <>
            <Box mb={2}>
                <Period
                    disabled={isLoading}
                    year
                    quarter
                    month
                    week
                    day
                    actions={[
                        {
                            icon: <AddIcon />,
                            title: "Přidat vyúčtování",
                            onClick: () => onNewBilling(),
                            alwaysVisible: true,
                        },
                    ]}
                />
            </Box>

            <Box mb={4}>
                <Grid container rowSpacing={2} columnSpacing={4}>
                    <Grid item xs={12} lg={6}>
                        <Panel fullHeight>
                            <WidgetRoot>
                                <WidgetFixed>
                                    <Value
                                        label="Celkem"
                                        value={
                                            <CurrencyValue value={(state.trends && state.trends.value) || 0} currencyCode={state.trends && state.trends.unit} />
                                        }
                                        big
                                    />
                                </WidgetFixed>
                                <WidgetFlexible style={{ height: 140 }}>
                                    <TrendsBarChart
                                        data={state.trends.data}
                                        period={period}
                                        disabled={isLoading}
                                        formatValue={(v) =>
                                            `${formatCurrency(
                                                v.value,
                                                settings.defaultCurrencyCode,
                                                settings.currencies,
                                                settings.decimalDigits,
                                                settings.decimalDelimiter,
                                                settings.thousandDelimiter
                                            )}`
                                        }
                                        onClick={updatePeriodOnClick(setPeriod)}
                                    />
                                </WidgetFlexible>
                            </WidgetRoot>
                        </Panel>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Panel fullHeight>
                            <WidgetRoot>
                                <WidgetFlexible style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                    <ChartLabels
                                        values={[{ name: "Nevyúčtované hodiny", value: (state.summary && state.summary.unbilledHours) || 0 }]}
                                        color={isLoading ? "#6A6A6A" : theme.palette.primary.main}
                                        renderValue={(nv) => <NumberValue value={nv.value} unit="h" />}
                                    />
                                    <ChartLabels
                                        values={[{ name: "Vyúčtované hodiny", value: (state.summary && state.summary.billedHours) || 0 }]}
                                        color={isLoading ? "#6A6A6A" : theme.palette.secondary.main}
                                        renderValue={(nv) => <NumberValue value={nv.value} unit="h" />}
                                    />
                                </WidgetFlexible>
                                <WidgetFixed>
                                    <DonutChart
                                        data={[[(state.summary && state.summary.unbilledHours) || 0, (state.summary && state.summary.billedHours) || 0]]}
                                        colors={isLoading ? ["#6A6A6A"] : [theme.palette.primary.main, theme.palette.secondary.main]}
                                        useChartColors={false}
                                        disabled={isLoading}
                                        size={140}
                                    />
                                </WidgetFixed>
                            </WidgetRoot>
                        </Panel>
                    </Grid>
                </Grid>
            </Box>

            <EmployeeBillingsList
                billings={state.billings}
                disabled={isLoading}
                onEdit={onEditBilling}
                onTransactionClick={(id) => navigate(`/transactions/${id}`)}
                onTransactionTypeSelected={async (type, billing) => {
                    startLoading();
                    try {
                        let data = await prepareEmployeeBillingTransaction(detailId, billing.id, type.id);
                        transactionModal.showModal(data);
                    } catch (e) {
                        alert("error");
                        console.error(e);
                    }
                    stopLoading();
                }}
            />

            {!!state.billings && state.billings.totalPages > 1 && (
                <Pagination
                    count={state.billings.totalPages}
                    siblingCount={1}
                    boundaryCount={1}
                    onChange={async (e, page) => {
                        startLoading();
                        try {
                            let billings = await fetchEmployeeBillings(detailId, period.start, period.end, { page: page - 1, size: 5 });
                            setState({ ...state, billings });
                        } catch (e) {
                            console.error(e);
                            alert("error");
                        }
                        stopLoading();
                    }}
                />
            )}

            <TransactionModal data={transactionModal.data} open={transactionModal.open} onDataSaved={refreshDetail} onClose={transactionModal.closeModal} />
        </>
    );
};

const EmployeeDetailContainer = ({ menuItems }) => {
    const settings = useSettings();
    const theme = useTheme();
    const readOnly = false;
    const { hideDetail, refreshMaster, refreshDetail } = useMasterDetail();
    const { detailId } = useMasterDetailParams();
    const { startLoading, stopLoading, isLoading } = useLoadingIndicator();
    const { showTimesheetModal, closeTimesheetModal, modalState } = useTimesheetModal();
    const employeeModal = useModal({});
    const billingModal = useModal({});

    const periodRef = usePeriod();
    const [period] = periodRef;

    const [data, setData] = useState({});
    const { tab, setTab, tabs } = useMasterDetailTabs({ timesheets: settings.timesheetsSupport, billings: settings.billingsSupport });
    const [axes, setAxes] = useState([]);

    const showAlert = useAlert();

    const loadPageData = useCallback(async () => {
        startLoading();
        let data = await fetchEmployee(detailId);
        setData(data);
        stopLoading();
    }, [detailId]);

    const onSaveEmployee = (data) => {
        setData(data);
        refreshMaster();
    };

    const detailMenuItems = useMemo(
        () => [
            {
                alwaysVisible: false,
                /*
            render: (props) => {
                return <SaveButton {...props} onClick={saveEmployee} loading={isLoading} disabled={isLoading} />;
            },
            */
                title: "Upravit",
                icon: <EditIcon />,
                onClick: () => employeeModal.showModal(data),
            },
            {
                icon: data.readOnly ? <LockIcon /> : <LockOpenIcon />,
                title: data.readOnly ? "Aktivovat zaměstnance" : "Archivovat zaměstnance",
                onClick: async () => {
                    startLoading();
                    try {
                        await lockEmployee(detailId, !data.readOnly);
                        setData({ ...data, readOnly: !data.readOnly });
                        refreshMaster();
                    } catch (e) {
                        alert("error");
                    }
                    stopLoading();
                },
            },

            /*
            {
                icon: <RefreshIcon />,
                title: "Obnovit data",
                onClick: () => loadEmployee(detailId),
            },
            */
        ],
        [detailId, data]
    );

    useEffect(() => {
        if (detailId) {
            loadPageData(detailId);
        } else {
            setData({});
        }
    }, [loadPageData]);

    useEffect(() => {
        setAxes(periodAxes(period, projectIdAxis));
        //loadAggregationData(axes);
    }, [period.type]);

    useDetailRefresh(loadPageData, [loadPageData]);

    return (
        <>
            <MasterDetailContent
                pageTitle={(data && data.name) || "Zaměstnanec"}
                menuItems={[...detailMenuItems, ...menuItems]}
                onBack={hideDetail}
                disabled={isLoading}
                pageTitleChildren={
                    !!tabs._ && (
                        <Tabs value={tab} onChange={(e, tab) => setTab(tab)} disabled={isLoading}>
                            {!!tabs.timesheets && <Tab label="Výkazy" value="timesheets" disabled={isLoading} />}
                            {!!tabs.billings && <Tab label="Vyúčtování" value="billings" disabled={isLoading} />}
                        </Tabs>
                    )
                }
                showPageTitle
                showLoadingIndicator
            >
                <LayoutWithInfo>
                    <LayoutWithInfoMain>
                        <Box mb={3}>
                            {tab === "timesheets" && <TimesheetsTab detailId={detailId} disabled={isLoading} axes={axes} setAxes={setAxes} />}
                            {tab == "billings" ? (
                                <BillingsTab
                                    detailId={detailId}
                                    disabled={isLoading}
                                    onNewBilling={() => {
                                        billingModal.showModal({
                                            name: `${data.lastName} ${data.firstName} - ${formatPeriod(period)}`,
                                            billingDate: getDefaultPeriodDate(periodRef, true, false),
                                            billingType: data.defaultBillingType || "PRICE_PER_HOUR",
                                            pricePerHour: data.pricePerHour,
                                            fixedPrice: data.fixedPrice,
                                            currencyCode: settings.defaultCurrencyCode,
                                        });
                                    }}
                                    onEditBilling={billingModal.showModal}
                                />
                            ) : null}
                        </Box>
                    </LayoutWithInfoMain>
                    <LayoutWithInfoInfo>
                        <Box mb={3}>
                            <EmployeeBasicInfo data={data} />
                        </Box>
                    </LayoutWithInfoInfo>
                </LayoutWithInfo>
            </MasterDetailContent>
            <EmployeeModal open={employeeModal.open} onClose={employeeModal.closeModal} onDataSaved={onSaveEmployee} data={data} title="Editace zaměstnance" />
            <EmployeeBillingModal
                open={billingModal.open}
                onClose={billingModal.closeModal}
                onDataSaved={refreshDetail}
                employeeId={detailId}
                employeeName={data ? `${data.lastName} ${data.firstName} - ${formatPeriod(period)}` : undefined}
                from={period.start}
                to={period.end}
                periodType={period.type}
                period={period}
                data={billingModal.data || {}}
            />
        </>
    );
};

export const EmployeesOverviewFilter = ({ disabled, filter, onFilterChange, showEmployee, showProject }) => {
    const { values, handleFilterChange } = useInlineFilter(filter, onFilterChange);

    return (
        <InlineFilter>
            {!!showProject && (
                <div>
                    <ProjectSelect
                        value={values.projects || []}
                        onChange={(projects) => handleFilterChange("projects", projects)}
                        name="tags"
                        fullWidth
                        label="Projekty"
                        margin="normal"
                        disabled={disabled}
                        multiple
                    />
                </div>
            )}
            {!!showEmployee && (
                <div>
                    <EmployeeSelect
                        value={values.employees || []}
                        onChange={(employees) => handleFilterChange("employees", employees)}
                        name="tags"
                        fullWidth
                        label="Zaměstnanci"
                        margin="normal"
                        disabled={disabled}
                        multiple
                    />
                </div>
            )}
            <div>
                <TagsField
                    value={values.tags}
                    onChange={(tags) => handleFilterChange("tags", tags)}
                    name="tags"
                    fullWidth
                    label="Tagy"
                    margin="normal"
                    disabled={disabled}
                />
            </div>
        </InlineFilter>
    );
};

//                fetchTimesheetProjectsChart(period.start, period.end),
//                fetchTimesheetProjectsData(period.start, period.end),
/*
[theme.breakpoints.down("md")]: {
                display: "none",
            },
  */
const createOverviewStyles = makeStyles((theme) => ({
    mainFlex: {
        display: "flex",
        gap: theme.spacing(4),
        alignContent: "stretch",
        flexWrap: "wrap",
        position: "relative",
    },
    childFlex: {
        flex: "1",
        //minWidth: "50%",
    },
    full: {
        flex: 1,
        minWidth: 1000,
    },
    half: {
        flex: 1,
        minWidth: 400,
    },
}));

const overviewTimesheetsTabState = { chart: [], data: {}, hoursPeriods: [] };
const OverviewTimesheetsTab = ({ filter, settings, showDetail, color }) => {
    const [period, setPeriod] = usePeriod();
    const { startLoading, stopLoading, isLoading } = useLoadingIndicator();
    const [state, setState] = useState(overviewState);

    const fetchState = useCallback(async () => {
        startLoading();
        try {
            let chart = [],
                data = {},
                hoursPeriods = {},
                dataResult = undefined;

            let paging = { page: (state.data && state.data.number) || 0, size: 10 };

            const result = await Promise.allSettled([
                fetchTimesheetEmployeesChart(period.start, period.end),
                fetchTimesheetEmployeesData(period.start, period.end, paging),
                fetchTimesheetPeriodSummary(period.day, period.type),
            ]);
            dataResult = result[0];
            if (dataResult.status === "fulfilled") {
                chart = dataResult.value;
            }
            dataResult = result[1];
            if (dataResult.status === "fulfilled") {
                data = dataResult.value;
            }
            dataResult = result[2];
            if (dataResult.status === "fulfilled") {
                hoursPeriods = dataResult.value;
            }

            setState({ ...state, chart, data, hoursPeriods });
        } catch (e) {
            alert(e);
            console.error(e);
        } finally {
            stopLoading();
        }
    }, [period]);

    useEffect(() => {
        fetchState();
    }, [fetchState]);

    useOverviewRefresh(fetchState, [fetchState]);

    return (
        <>
            <Box mb={4}>
                <Panel fullHeight>
                    <Box mb={2}>
                        <Period
                            disabled={isLoading}
                            year
                            quarter
                            month
                            week
                            day
                            actions={[
                                {
                                    icon: <FileDownloadIcon />,
                                    title: "Exportovat do excelu",
                                    onClick: async () => {
                                        startLoading();
                                        try {
                                            await downloadEmployeesExcel(period.start, period.end, state.filter);
                                        } catch (e) {
                                            alert("error");
                                        }
                                        stopLoading();
                                    },
                                },
                            ]}
                        />
                    </Box>
                    <Box mb={4}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} xl={6}>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12} lg={6}>
                                        <DonutChart
                                            data={[(state.chart || []).map((i) => i.value)]}
                                            colors={isLoading ? ["#6A6A6A"] : [color]}
                                            disabled={isLoading}
                                            size={270}
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                        <ChartLabels
                                            values={state.chart || []}
                                            color={isLoading ? "#6A6A6A" : color}
                                            emptyValueLabel="Ostatní"
                                            renderValue={(nv) => <NumberValue value={nv.value} unit="h" />}
                                            onClick={(nv) => {
                                                showDetail && showDetail(nv.id, { tab: "timesheets" });
                                            }}
                                            variant="row"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} xl={6}>
                                <TrendsBarChart
                                    data={state.hoursPeriods}
                                    period={period}
                                    disabled={isLoading}
                                    formatValue={(v) =>
                                        `${formatNum(v.value, settings.decimalDigits, settings.decimalDelimiter, settings.thousandDelimiter)} h`
                                    }
                                    onClick={updatePeriodOnClick(setPeriod)}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Panel>
            </Box>
            <Box mb={4}>
                {!!state.data &&
                    Array.isArray(state.data.content) &&
                    state.data.content.map((row) => (
                        <DetailRow key={row.id} onClick={() => !!showDetail && showDetail(row.id, { tab: "timesheets" })}>
                            <DetailRowMain slim>{row.name}</DetailRowMain>
                            <DetailRowValue slim>
                                <NumberValue value={row.value} unit="h" />
                            </DetailRowValue>
                        </DetailRow>
                    ))}
                {!!state.data && state.data.totalPages > 1 && (
                    <Box my={1}>
                        <Pagination
                            count={state.data.totalPages}
                            page={1 + state.data.number}
                            siblingCount={1}
                            boundaryCount={1}
                            onChange={async (e, page) => {
                                startLoading();
                                try {
                                    let data = await fetchTimesheetEmployeesData(period.start, period.end, {
                                        page: page - 1,
                                        size: 10,
                                    });
                                    setState({ ...state, data });
                                } catch (e) {
                                    console.error(e);
                                    alert("error");
                                }
                                stopLoading();
                            }}
                        />
                    </Box>
                )}
            </Box>
        </>
    );
};

const overviewBillingsTabState = { periods: [], chart: [] };
const OverviewBillingsTab = ({ settings, color, showDetail }) => {
    const [period, setPeriod] = usePeriod();
    const { startLoading, stopLoading, isLoading } = useLoadingIndicator();
    const [state, setState] = useState(overviewBillingsTabState);

    const fetchState = useCallback(async () => {
        startLoading();
        try {
            let chart = [],
                data = {},
                hoursPeriods = {},
                dataResult = undefined;

            let paging = { page: (state.data && state.data.number) || 0, size: 10 };

            const result = await Promise.allSettled([
                fetchBillingEmployeesChart(period.start, period.end),
                fetchBillingEmployeesData(period.start, period.end, paging),
                fetchBillingPeriodSummary(period.day, period.type),
            ]);
            dataResult = result[0];
            if (dataResult.status === "fulfilled") {
                chart = dataResult.value;
            }
            dataResult = result[1];
            if (dataResult.status === "fulfilled") {
                data = dataResult.value;
            }
            dataResult = result[2];
            if (dataResult.status === "fulfilled") {
                hoursPeriods = dataResult.value;
            }

            setState({ ...state, chart, data, hoursPeriods });
        } catch (e) {
            alert(e);
            console.error(e);
        } finally {
            stopLoading();
        }
    }, [period]);

    useEffect(() => {
        fetchState();
    }, [fetchState]);

    useOverviewRefresh(fetchState, [fetchState]);

    return (
        <>
            <Box mb={4}>
                <Panel fullHeight>
                    <Box mb={2}>
                        <Period
                            disabled={isLoading}
                            year
                            quarter
                            month
                            week
                            day
                            actions={
                                [
                                    /*
                                {
                                    icon: <FileDownloadIcon />,
                                    title: "Exportovat do excelu",
                                    onClick: async () => {
                                        startLoading();
                                        try {
                                            await downloadEmployeesExcel(period.start, period.end, state.filter);
                                        } catch (e) {
                                            alert("error");
                                        }
                                        stopLoading();
                                    },
                                },
                            */
                                ]
                            }
                        />
                    </Box>
                    <Box mb={4}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} xl={6}>
                                <TrendsBarChart
                                    data={state.hoursPeriods}
                                    period={period}
                                    disabled={isLoading}
                                    formatValue={(v) =>
                                        `${formatCurrency(
                                            v.value,
                                            settings.defaultCurrencyCode, //TODO fixme!!!
                                            settings.currencies,
                                            settings.decimalDigits,
                                            settings.decimalDelimiter,
                                            settings.thousandDelimiter
                                        )}`
                                    }
                                    onClick={updatePeriodOnClick(setPeriod)}
                                />
                            </Grid>
                            <Grid item xs={12} xl={6}>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12} lg={6}>
                                        <DonutChart
                                            data={[(state.chart || []).map((i) => i.value)]}
                                            colors={isLoading ? ["#6A6A6A"] : [color]}
                                            disabled={isLoading}
                                            size={270}
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                        <ChartLabels
                                            values={state.chart || []}
                                            color={isLoading ? "#6A6A6A" : color}
                                            emptyValueLabel="Ostatní"
                                            renderValue={(nv) => <NumberValue value={nv.value} unit="h" />}
                                            onClick={(nv) => {
                                                showDetail && showDetail(nv.id, { tab: "billings" });
                                            }}
                                            variant="row"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Panel>
            </Box>
            <Box mb={4}>
                {!!state.data &&
                    Array.isArray(state.data.content) &&
                    state.data.content.map((row) => (
                        <DetailRow key={row.id} onClick={() => !!showDetail && showDetail(row.id, { tab: "billings" })}>
                            <DetailRowMain slim>{row.name}</DetailRowMain>
                            <DetailRowValue slim>
                                <CurrencyValue value={row.value} currencyCode={row.unit} />
                                <NumberValue value={row.secondaryValue} unit="h" />
                            </DetailRowValue>
                        </DetailRow>
                    ))}
                {!!state.data && state.data.totalPages > 1 && (
                    <Box my={1}>
                        <Pagination
                            count={state.data.totalPages}
                            page={1 + state.data.number}
                            siblingCount={1}
                            boundaryCount={1}
                            onChange={async (e, page) => {
                                startLoading();
                                try {
                                    let data = await fetchBillingEmployeesData(period.start, period.end, {
                                        page: page - 1,
                                        size: 10,
                                    });
                                    setState({ ...state, data });
                                } catch (e) {
                                    console.error(e);
                                    alert("error");
                                }
                                stopLoading();
                            }}
                        />
                    </Box>
                )}
            </Box>
        </>
    );
};

const overviewState = { filter: {}, showFilter: false };
const EmployeesOverview = ({ menuItems }) => {
    const classes = createOverviewStyles();
    const theme = useTheme();
    const settings = useSettings();
    const { startLoading, stopLoading, isLoading } = useLoadingIndicator();
    const { hideDetail, refreshMaster, mobile, showDetail } = useMasterDetail();
    const [period, setPeriod] = usePeriod();
    const { tab, setTab, tabs } = useMasterDetailTabs({ timesheets: settings.timesheetsSupport, billings: settings.billingsSupport });
    const isDisabled = !!isLoading;

    const employeeModal = useModal();

    //remember context menu items
    const masterMenuItems = useMemo(
        () => [
            {
                icon: <AddIcon />,
                title: "Přidat zaměstnance",
                alwaysVisible: true,
                onClick: () => employeeModal.showModal({}),
            },
        ],
        []
    );

    const [state, setState] = useState(overviewState);
    //fetchTimesheetPeriodSummary(period.day, period.type),

    let periodKey = getPeriodKey(period);
    let color = theme.palette.primary.main;

    return (
        <>
            <MasterDetailContent
                showPageTitle
                pageTitle="Přehled"
                pageTitleSmall="Zaměstnanci"
                pageTitleChildren={
                    !!tabs._ && (
                        <Tabs value={tab} onChange={(e, tab) => setTab(tab)} disabled={isLoading} size="small">
                            {!!tabs.timesheets && <Tab label="Výkazy" value="timesheets" disabled={isLoading} />}
                            {!!tabs.billings && <Tab label="Vyúčtování" value="billings" disabled={isLoading} />}
                        </Tabs>
                    )
                }
                menuItems={[...masterMenuItems, ...menuItems]}
                onBack={(mobile && hideDetail) || null}
                disabled={isLoading}
                showLoadingIndicator
            >
                <Box px={2} pb={4}>
                    <Collapse in={state.showFilter}>
                        <Box mb={4}>
                            <EmployeesOverviewFilter
                                showProject
                                showEmployee
                                filter={state.filter || {}}
                                onFilterChange={(filter) => setState({ ...state, filter })}
                            />
                        </Box>
                    </Collapse>

                    {tab === "timesheets" && (
                        <OverviewTimesheetsTab filter={state.filter} color={theme.palette.primary.main} showDetail={showDetail} settings={settings} />
                    )}
                    {tab === "billings" && <OverviewBillingsTab color={theme.palette.secondary.main} showDetail={showDetail} settings={settings} />}
                </Box>

                <EmployeeModal
                    open={employeeModal.open}
                    onClose={employeeModal.closeModal}
                    onDataSaved={console.log}
                    data={employeeModal.data}
                    title="Nový zaměstnanec"
                />
            </MasterDetailContent>
        </>
    );
};

const EmployeesMasterDetail = ({ menuItems }) => {
    const fetchMasterData = async (filter) => {};

    return (
        <>
            <MasterDetail
                masterElement={<EmployeeMasterContainer menuItems={menuItems} />}
                detailElement={<EmployeeDetailContainer menuItems={menuItems} />}
                overviewElement={<EmployeesOverview menuItems={menuItems} />}
                titleMenuItems={menuItems}
                onRefreshMaster={fetchMasterData}
                detailHasBackground={false}
            />
        </>
    );
};

export default EmployeesMasterDetail;
