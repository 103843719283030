import React from "react";

import Typography from "@mui/material/Typography";
import FormHelperText from "@mui/material/FormHelperText";

export const notBlankError = "{javax.validation.constraints.NotBlank.message}";
export const sizeError = "{javax.validation.constraints.Size.message}";

export const hasErrors = (errors, field) => {
    if (!errors) {
        return false;
    }

    return errors[field] || false;
};

export const getError = (errors, field) => {
    if (!errors) {
        return undefined;
    }

    let e = errors[field];
    if (!e) {
        return undefined;
    }

    if (Array.isArray(e)) {
        return e.join("\n");
    }

    return e;
};

export const FormErrors = ({ errors }) => {
    if (!errors) {
        return null;
    }

    let e = errors[""];
    if (!e) {
        return null;
    }

    if (Array.isArray(e)) {
        return (
            <>
                {e.map((err, key) => (
                    <Typography variant="subtitle1" color="error" key={key}>
                        {err}
                    </Typography>
                ))}
            </>
        );
    }

    return (
        <Typography variant="subtitle1" color="error">
            {e}
        </Typography>
    );
};

export const FieldErrors = ({ errors, field }) => {
    if (!errors) {
        return null;
    }

    let e = errors[field];
    if (!e) {
        return null;
    }

    if (Array.isArray(e)) {
        return (
            <>
                {e.map((err, key) => (
                    <FormHelperText error key={key}>
                        {err}
                    </FormHelperText>
                ))}
            </>
        );
    }

    return <FormHelperText error>{e}</FormHelperText>;
};
