import React from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import IconButton from "@mui/material/IconButton";

import {makeStyles} from "@mui/styles";
import clsx from "clsx";

const useStyles = makeStyles((theme) => {
    const transition = {
        duration: theme.transitions.duration.shortest,
    };

    return {
        root: {
            display: "flex",
            alignItems: "center",
            marginBottom: theme.spacing(2),
        },
        iconButton: {
            padding: 10,
        },
        expandIcon: {
            transform: "rotate(-90deg)",
            transition: theme.transitions.create("transform", transition),
        },
        expanded: {
            transform: "rotate(0deg)",
        },
    };
});

export default ({ onOpenStateChange, open, disabled }) => {
    const classes = useStyles();

    const handleOpenChange = (e) => {
        e.preventDefault();
        !!onOpenStateChange && onOpenStateChange(!open);
    };

    return (
        <IconButton
            size="small"
            className={clsx(classes.expandIcon, {
                [classes.expanded]: !!open,
            })}
            onClick={handleOpenChange}
            disabled={disabled}
        >
            <ExpandMoreIcon />
        </IconButton>
    );
};
