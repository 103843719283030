import React, { useContext, useEffect } from "react";
import { Page, SidebarLayout } from "./components/Layout";

import DashboardIcon from "@mui/icons-material/Dashboard";
import PaidIcon from "@mui/icons-material/Paid";
import PeopleIcon from "@mui/icons-material/People";
import FolderIcon from "@mui/icons-material/Folder";
import BusinessIcon from "@mui/icons-material/Business";
import ExtensionIcon from "@mui/icons-material/Extension";
import SettingsIcon from "@mui/icons-material/Settings";
import DescriptionIcon from "@mui/icons-material/Description";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";

import { Outlet, Route, Routes, useNavigate } from "react-router-dom";

import PublicPage from "./pages/PublicPage";
import DashboardPage from "./pages/DashboardPage";
import CounterpartiesPage from "./pages/CounterpartiesPage";
import EmployeesPage from "./pages/EmployeesPage";
import TransactionsPage from "./pages/TransactionsPage";
import ProjectsPage from "./pages/ProjectsPage";
import SettingsPage, { OnboardingContainer } from "./pages/SettingsPage";
import FeaturesPage from "./pages/FeaturesPage";
import DocumentsPage from "./pages/DocumentsPage";

import { Login, NewPassword, ResetPassword, SecurityLayout, Signup, UserInfo } from "./components/Security";
import { SecurityContext } from "./utils/security";
import { WithPeriod } from "./components/period";
import { useSettings } from "./utils/settings";

import { Breakpoints } from "./components/devtools";

const SecurityRoutesLayout = () => {
    const navigate = useNavigate();
    return (
        <SecurityLayout onAction={() => navigate("/")} actionTitle="Domů">
            <Outlet />
        </SecurityLayout>
    );
};

const AppRoutesLayout = () => {
    const navigate = useNavigate();
    const { logout, user } = useContext(SecurityContext);
    const settings = useSettings();

    const menuItems = [
        {
            title: "Přehled",
            icon: <DashboardIcon />,
            path: "/",
        },
    ];

    if (settings.timesheetsSupport) {
        menuItems.push({
            title: "Výkazy",
            icon: <PeopleIcon />,
            path: "/employees",
        });
    }

    if (settings.counterpartiesSupport) {
        menuItems.push({
            title: "Protistrany",
            icon: <BusinessIcon />,
            path: "/counterparties",
        });
    }
    if (settings.projectsSupport) {
        menuItems.push({
            title: "Projekty",
            icon: <FolderIcon />,
            path: "/projects",
        });
    }
    if (settings.transactionsSupport) {
        menuItems.push({
            title: "Finance",
            icon: <PaidIcon />,
            path: "/financials",
        });
    }
    if (settings.documentsSupport) {
        menuItems.push({
            title: "Dokumenty",
            icon: <DescriptionIcon />,
            path: "/documents",
        });
    }
    menuItems.push({
        title: "Nastavení",
        icon: <SettingsIcon />,
        path: "/settings",
        bottom: true,
    });

    if (settings.hasFeatures) {
        menuItems.push({
            title: "Rozšíření",
            icon: <ExtensionIcon />,
            path: "/features",
        });
    }

    return (
        <SidebarLayout menuItems={menuItems}>
            <Page>
                <Outlet />
            </Page>
        </SidebarLayout>
    );
};

export default function App() {
    const { login, logout, signup, resetPassword, user } = useContext(SecurityContext);
    const navigate = useNavigate();
    const settings = useSettings();

    const signupSupported = false;

    const pageTitleMenuItems = [
        //{ icon: <NotificationsIcon />, badgeContent: 17, title: "Notifications" },
        {
            icon: <AccountCircleIcon />,
            title: "User",
            renderContent: () => (
                <UserInfo
                    user={user}
                    logout={async () => {
                        await logout();
                        navigate("/");
                    }}
                />
            ),
        },
    ];

    return (
        <WithPeriod>
            <Routes>
                {!!user ? (
                    settings.initialized ? (
                        <>
                            <Route element={<AppRoutesLayout />}>
                                <Route
                                    path="/"
                                    element={
                                        <>
                                            <DashboardPage menuItems={pageTitleMenuItems} />
                                        </>
                                    }
                                />

                                {!!settings.timesheetsSupport && <Route path="/employees/*" element={<EmployeesPage menuItems={pageTitleMenuItems} />} />}
                                {!!settings.transactionsSupport && <Route path="/financials/*" element={<TransactionsPage menuItems={pageTitleMenuItems} />} />}
                                {!!settings.projectsSupport && <Route path="/projects/*" element={<ProjectsPage menuItems={pageTitleMenuItems} />} />}
                                {!!settings.counterpartiesSupport && (
                                    <Route path="/counterparties/*" element={<CounterpartiesPage menuItems={pageTitleMenuItems} />} />
                                )}
                                <Route path="/settings/*" element={<SettingsPage menuItems={pageTitleMenuItems} />} />
                                {!!settings.hasFeatures && <Route path="/features/*" element={<FeaturesPage menuItems={pageTitleMenuItems} />} />}
                                {!!settings.documentsSupport && <Route path="/documents/*" element={<DocumentsPage menuItems={pageTitleMenuItems} />} />}
                                <Route path="*" element={<RedirectToRoot />} />
                            </Route>
                        </>
                    ) : (
                        <Route
                            element={
                                <SecurityLayout
                                    onAction={async () => {
                                        await logout();
                                        navigate("/");
                                    }}
                                    actionIcon={<LogoutIcon />}
                                    actionTitle="Odhlásit se"
                                    withLoadingIndicator
                                >
                                    <Outlet />
                                </SecurityLayout>
                            }
                        >
                            <Route path="*" element={<OnboardingContainer />} />
                        </Route>
                    )
                ) : (
                    <>
                        <Route path="/" element={<PublicPage />} />
                        <Route element={<SecurityRoutesLayout />}>
                            <Route
                                path="login"
                                element={
                                    <Login
                                        loginAction={async (user) => {
                                            await login(user);
                                            navigate("/");
                                        }}
                                        onSignup={signupSupported ? () => navigate("/signup") : null}
                                        onResetPassword={() => navigate("/resetPassword")}
                                    />
                                }
                            />
                            {signupSupported ? (
                                <Route
                                    path="signup"
                                    element={
                                        <Signup
                                            signupAction={async (registration) => {
                                                await signup(registration);
                                                navigate("/");
                                            }}
                                            onLogin={() => navigate("/login")}
                                        />
                                    }
                                />
                            ) : null}
                            <Route
                                path="resetPassword"
                                element={
                                    <ResetPassword
                                        resetPasswordAction={async (request) => {
                                            await resetPassword(request);
                                        }}
                                        onLogin={() => navigate("/login")}
                                    />
                                }
                            />
                            <Route
                                path="newPassword"
                                element={
                                    <NewPassword
                                        newPasswordAction={async (req) => {
                                            await resetPassword(req);
                                        }}
                                    />
                                }
                            />

                            {/* TODO add logout route */}

                            {/* try to log the user in */}
                            <Route
                                path="*"
                                element={
                                    <Login
                                        loginAction={async (user) => {
                                            await login(user);
                                        }}
                                        onSignup={signupSupported ? () => navigate("/signup") : null}
                                        onResetPassword={() => navigate("/resetPassword")}
                                    />
                                }
                            />
                        </Route>
                    </>
                )}

                {/*<Route path="*" element={<RedirectToRoot />} />*/}
            </Routes>
            {process.env.NODE_ENV != "production" ? <Breakpoints /> : null}
        </WithPeriod>
    );
}

const RedirectToRoot = () => {
    const navigate = useNavigate();

    useEffect(() => {
        navigate("/", { replace: true });
    });
    return null;
};
