import React, { useEffect, useMemo, useState, useCallback } from "react";

import { useNavigate } from "react-router-dom";

import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DashboardIcon from "@mui/icons-material/Dashboard";
import FilterListIcon from "@mui/icons-material/FilterList";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import PrintIcon from "@mui/icons-material/Print";
import DeleteIcon from "@mui/icons-material/Delete";

import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";
import FormControlLabel from "@mui/material/FormControlLabel";

import MuiTable from "@mui/material/Table";
import MuiTableBody from "@mui/material/TableBody";
import MuiTableCell from "@mui/material/TableCell";
import MuiTableContainer from "@mui/material/TableContainer";
import MuiTableHead from "@mui/material/TableHead";
import MuiTableRow from "@mui/material/TableRow";
import { makeStyles } from "@mui/styles";

import {
    MasterContent,
    MasterDetail,
    MasterDetailContent,
    useDetailRefresh,
    useMasterDetail,
    useMasterDetailParams,
    useMasterDetailTabs,
    useMasterRefresh,
} from "../components/MasterDetail";
import { LayoutWithInfo, LayoutWithInfoInfo, LayoutWithInfoMain, Page, PageTitle, Panel } from "../components/Layout";
import {
    fetchProject,
    fetchProjectDocumentsSummary,
    fetchProjectOverviewFinancialsChart,
    fetchProjectOverviewFinancialsData,
    fetchProjectOverviewTimesheetsChart,
    fetchProjectOverviewTimesheetsData,
    fetchProjects,
    fetchProjectTimesheetItems,
    fetchProjectTimesheetItemTemplatesPdf,
    fetchProjectTransactions,
    fetchProjectTransactionStatuses,
    fetchTransactionsSummary,
    fetchTransactionsTrends,
    lockProject,
    ProjectBasicInfo,
    ProjectList,
    ProjectListFilter,
    ProjectModal,
} from "../components/projects";
import { AddTransactionButton, TransactionModal, TransactionsList, TransactionStatusesChart } from "../components/transactions";
import {
    fetchProjectBillings,
    fetchProjectBillingsSummary,
    fetchProjectBillingsTrends,
    prepareProjectBillingTransaction,
    ProjectBillingModal,
    ProjectBillingsList,
} from "../components/billing";
import {
    deleteProjectTimesheet,
    deleteProjectTimesheetAttachment,
    deleteProjectTimesheetItemTemplate,
    downloadProjectExcel,
    employeeIdAxis,
    fetchProjectAggregation,
    fetchProjectTimesheetCalendar,
    fetchProjectTimesheetChart,
    fetchProjectTimesheetItemTemplates,
    saveProjectTimesheet,
    saveProjectTimesheetItemTemplate,
    TimesheetCalendar,
    TimesheetChart,
    TimesheetFilter,
    TimesheetModal,
    TimesheetTemplateModal,
    useTimesheetModal,
} from "../components/timesheets";
import { discardDocument, DocumentsList, fetchDocuments, prepareDocumentTransactionData, ProcessDocumentModal } from "../components/documents";

import { useForm } from "../utils/form";
import { useAlert } from "../components/Alert";
import { useLoadableList } from "../utils/lists";
import { LoadingIndicator, LoadingIndicatorContainer, useLoadingIndicator } from "../utils/loading";
import Period, { formatPeriod, formatPeriodValue, getDefaultPeriodDate, nextPeriodType, periodAxes, periodInterval, usePeriod } from "../components/period";
import { DetailRow, DetailRowMain, DetailRowValue, FlexItem, FlexRow } from "../components/Containers";
import { useTheme } from "@mui/material/styles";
import Aggregations, { AxesSelector } from "../components/Aggregations";
import { CurrencyValue, formatCurrency, NumberValue } from "../components/NumberFields";
import { DateValue } from "../components/DateFields";
import { chartColor, ChartLabelDot, ChartLabels, DonutChart } from "../components/Charts";
import { useModal } from "../components/Modal";
import { useSettings } from "../utils/settings";
import Value from "../components/Value";
import { WidgetFixed, WidgetFlexible, WidgetRoot } from "../components/Widgets";
import TrendsBarChart, { updatePeriodOnClick } from "../components/TrendsBarChart";

import { QRCodeSVG } from "qrcode.react";
import moment from "moment";

const ProjectsMaster = ({ menuItems, showFilter, setShowFilter, filterRef }) => {
    const params = useMasterDetailParams();
    const { showDetail, showOverview, showAdd, mobile, refreshMaster } = useMasterDetail();
    const filterForm = useForm();
    const loadableList = useLoadableList();

    const { startLoading, stopLoading, isLoading } = useLoadingIndicator();

    const handleFilterChange = (filter) => {
        fetchList(filter);
    };

    const fetchList = async (filter, paging) => {
        startLoading();
        try {
            const data = await fetchProjects(filter, paging);
            loadableList.acceptData(data);
        } catch (e) {
            console.error(e);
        } finally {
            stopLoading();
        }
    };

    useEffect(() => {
        //fetch data
        fetchList(filterForm.values);
    }, []);

    useMasterRefresh(() => fetchList(filterForm.valuesRef.current));

    return (
        <>
            <Collapse in={showFilter}>
                <Box p={2} pb={0}>
                    <ProjectListFilter onFilterChange={handleFilterChange} form={filterForm} />
                </Box>
            </Collapse>
            <ProjectList
                disabled={isLoading}
                data={loadableList.data}
                hasMore={loadableList.size < loadableList.totalElements - 1}
                onShowMoreClick={() => fetchList(filterForm.values, loadableList.nextPageParams)}
                onItemClick={({ id }) => showDetail(id)}
                selectedId={params.tabName}
            />
        </>
    );
};

const ProjectMasterContainer = ({ menuItems }) => {
    const { showDetail, showOverview, showAdd, mobile, refreshMaster } = useMasterDetail();
    const [showFilter, setShowFilter] = useState();
    const modal = useModal();

    //remember context menu items
    const masterMenuItems = useMemo(
        () => [
            {
                icon: <AddIcon />,
                title: "Přidat projekt",
                alwaysVisible: true,
                onClick: () => modal.showModal({}),
            },
            {
                icon: <FilterListIcon />,
                title: "Filtrovat",
                alwaysVisible: true,
                onClick: () => setShowFilter(!showFilter),
                badgeVariant: "dot",
                badgeContent: !showFilter,
            },
            { icon: <DashboardIcon />, title: "Přehled", onClick: showOverview },
        ],
        [showFilter]
    );

    const onDataSaved = useMemo(
        () => (values) => {
            showDetail(values.id);
            refreshMaster();
        },
        [refreshMaster, showDetail]
    );

    return (
        <Page>
            <LoadingIndicatorContainer>
                <LoadingIndicator hidden="smDown" />
                <PageTitle title="Projekty" menuItems={mobile ? [...masterMenuItems, ...menuItems] : masterMenuItems} />
                <LoadingIndicator hidden="smUp" />
                <MasterContent>
                    <ProjectsMaster menuItems={menuItems} showFilter={showFilter} setShowFilter={setShowFilter} />
                </MasterContent>
            </LoadingIndicatorContainer>
            <ProjectModal open={modal.open} onClose={modal.closeModal} onDataSaved={onDataSaved} title="Nový projekt" data={modal.data} />
        </Page>
    );
};

const ProjectInfo = ({ isLoading, data }) => {
    const navigate = useNavigate();
    return <ProjectBasicInfo data={data} disabled={isLoading} onClientClick={(clientId) => navigate(`/counterparties/${clientId}`)} />;
};

const ProjectDetailContainer = ({ menuItems }) => {
    const { hideDetail, refreshMaster, refreshDetail } = useMasterDetail();
    const { detailId, tabName } = useMasterDetailParams();
    const settings = useSettings();
    const { tab, setTab, tabs } = useMasterDetailTabs({
        financials: settings.transactionsSupport,
        billings: settings.billingsSupport,
        timesheets: settings.timesheetsSupport,
        documents: settings.documentsSupport,
    });

    const { startLoading, stopLoading, isLoading } = useLoadingIndicator();
    const form = useForm({ initialValues: {} });
    const modal = useModal();
    const periodRef = usePeriod();
    const [period, setPeriod] = periodRef;
    const [data, setData] = useState({});
    const transactionModal = useModal();

    const fetchData = useCallback(async () => {
        startLoading();
        let data = {},
            transactionsSummary = {};
        try {
            let result = await Promise.allSettled([fetchProject(detailId), fetchTransactionsSummary(detailId, period.start, period.end)]);
            // values = await fetchProject(detailId);

            let dataResult = result[0];
            if (dataResult.status === "fulfilled") {
                data = dataResult.value;
            }

            dataResult = result[1];
            if (dataResult.status === "fulfilled") {
                transactionsSummary = dataResult.value;
            }

            //setState({ ...state, data, transactionsSummary });
        } catch (e) {
            alert("error");
        }
        setData(data);
        stopLoading();
    }, [detailId, period]);

    const onDataSaved = useCallback(async (values) => {
        refreshDetail();
        refreshMaster();
    }, []);

    const detailMenuItems = useMemo(() => {
        const items = [];

        return [
            {
                alwaysVisible: false,
                title: "Upravit",
                icon: <EditIcon />,
                onClick: () => modal.showModal(data),
            },
            /*
            {
                icon: <RefreshIcon />,
                title: "Obnovit data",
                onClick: () => loadProject(detailId),
            },
            */
            {
                icon: data.readOnly ? <LockIcon /> : <LockOpenIcon />,
                title: data.readOnly ? "Otevřít projekt" : "Uzavřít projekt",
                onClick: async () => {
                    startLoading();
                    try {
                        await lockProject(detailId, !data.readOnly);
                        refreshMaster();
                        refreshDetail();
                    } catch (e) {
                        alert("error");
                    }
                    stopLoading();
                },
            },
            ...menuItems,
        ];
    }, [detailId, data]);

    useEffect(() => {
        if (!!detailId) {
            fetchData();
        }
    }, [fetchData]);

    useDetailRefresh(fetchData, [fetchData]);

    return (
        <Page>
            <MasterDetailContent
                showPageTitle
                showLoadingIndicator
                pageTitle={data.name || "Projekt"}
                menuItems={detailMenuItems}
                onBack={hideDetail}
                disabled={isLoading}
                pageTitleChildren={
                    !!tabs._ && (
                        <Tabs value={tab} onChange={(e, tab) => setTab(tab)} disabled={isLoading}>
                            {!!tabs.financials && <Tab label="Finance" value="financials" disabled={isLoading} />}
                            {!!tabs.billings && <Tab label="Vyúčtování" value="billings" disabled={isLoading} />}
                            {!!tabs.timesheets && <Tab label="Výkazy" value="timesheets" disabled={isLoading} />}
                            {!!tabs.documents && <Tab label="Dokumenty" value="documents" disabled={isLoading} />}
                        </Tabs>
                    )
                }
            >
                <LayoutWithInfo>
                    <LayoutWithInfoMain>
                        <Box mb={4}>
                            {tab === "financials" && <TransactionsTab data={data} transactionModal={transactionModal} />}
                            {tab === "billings" && <BalanceTab data={data} transactionModal={transactionModal} />}
                            {tab === "timesheets" && <TimesheetsTab data={data} />}
                            {tab === "documents" && <DocumentsTab data={data} />}
                        </Box>
                    </LayoutWithInfoMain>
                    <LayoutWithInfoInfo>
                        <ProjectInfo data={data} isLoading={isLoading} />
                    </LayoutWithInfoInfo>
                </LayoutWithInfo>
            </MasterDetailContent>
            <TransactionModal data={transactionModal.data} open={transactionModal.open} onDataSaved={refreshDetail} onClose={transactionModal.closeModal} />

            <ProjectModal open={modal.open} onClose={modal.closeModal} onDataSaved={onDataSaved} data={data} title="Editace projektu" />
        </Page>
    );
};

const timesheetsTabState = { timesheetCalendar: {}, timesheetChart: {} };
const TimesheetsTab = ({ data, showBillingModal, ...props }) => {
    const { detailId, refreshDetail } = useMasterDetailParams();
    const theme = useTheme();
    const readOnly = false;
    const { hideDetail, refreshMaster } = useMasterDetail();
    const { startLoading, stopLoading, isLoading } = useLoadingIndicator();
    const { showTimesheetModal, closeTimesheetModal, modalState } = useTimesheetModal();
    const periodRef = usePeriod();
    const [period] = periodRef;

    const [state, setState] = useState(timesheetsTabState);
    const [showFilter, setShowFilter] = useState(false);
    const [filter, setFilter] = useState("");
    const [tab, setTab] = useState("timesheets");
    const [axes, setAxes] = useState([]);

    const showAlert = useAlert();

    const fetchData = useCallback(async () => {
        startLoading();
        let timesheetCalendar = {},
            timesheetChart = {};
        let interval = periodInterval(period);
        try {
            let result = await Promise.allSettled([
                fetchProjectTimesheetCalendar(
                    detailId,
                    interval.from,
                    interval.to,
                    period.type == "month" ? "day" : nextPeriodType(period.type),
                    false,
                    filter
                ),
                fetchProjectTimesheetChart(detailId, interval.from, interval.to, undefined, filter),
            ]);

            let dataResult = result[0];
            if (dataResult.status === "fulfilled") {
                timesheetCalendar = dataResult.value;
            }
            dataResult = result[1];
            if (dataResult.status === "fulfilled") {
                timesheetChart = dataResult.value;
            }
        } catch (e) {
            console.error(e);
            alert(e);
        } finally {
            setState({ timesheetCalendar, timesheetChart });
            stopLoading();
        }
    }, [detailId, filter, period]);

    const openTimesheetModal = useCallback(
        (data) => {
            if (!!data) {
                return showTimesheetModal(data);
            }

            //prepare default data
            data = {
                date: getDefaultPeriodDate(periodRef),
            };

            return showTimesheetModal(data);
        },
        [showTimesheetModal]
    );

    const saveItem = useCallback(
        async (data, submitHints) => {
            await saveProjectTimesheet(detailId, data);
            refreshDetail();
            if (submitHints && submitHints.keepOpen) {
                openTimesheetModal({ date: data.date });
            }
        },
        [refreshDetail, openTimesheetModal]
    );

    const saveTemplateItem = useCallback(
        async (data, submitHints) => {
            await saveProjectTimesheet(detailId, data);
            refreshDetail();
        },
        [detailId, refreshDetail]
    );

    const deleteItem = useCallback(
        (item) => {
            const deleteTimesheet = async () => {
                await deleteProjectTimesheet(detailId, item.id);
                refreshDetail();
            };
            showAlert("Smazat položku", `Opravdu chcete smazat položku výkazu ${item.projectName} (${item.hours} h)?`, [
                { text: "Ne" },
                { text: "Ano", onPress: deleteTimesheet },
            ]);
        },
        [refreshDetail, detailId]
    );

    useEffect(() => {
        if (detailId) {
            fetchData();
        }
    }, [fetchData]);

    useEffect(() => {
        setAxes(periodAxes(period, employeeIdAxis));
    }, [period.type]);

    useDetailRefresh(fetchData, [fetchData]);

    const tabs = (
        <Tabs value={tab} onChange={(e, tab) => setTab(tab)} disabled={isLoading} size="small" color="secondary">
            <Tab label="Položky výkazu" value="timesheets" disabled={isLoading} />
            <Tab label="Přehled" value="overview" disabled={isLoading} />
            <Tab label="QR kódy" value="qrcodes" disabled={isLoading} />
        </Tabs>
    );

    return (
        <>
            <Box mb={2}>
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                    <Period disabled={isLoading} year quarter month week day />

                    <Stack direction="row" alignItems="center">
                        <IconButton
                            disabled={isLoading}
                            onClick={async () => {
                                startLoading();
                                try {
                                    let interval = periodInterval(period);
                                    await downloadProjectExcel(detailId, interval.from, interval.to, filter);
                                } catch (e) {
                                    alert("error");
                                }
                                stopLoading();
                            }}
                            title="Exportovat do excelu"
                        >
                            <FileDownloadIcon />
                        </IconButton>
                        {/*!readOnly && (
                            <IconButton disabled={isLoading} onClick={showBillingModal} title="Vyúčtovat...">
                                <MultiIcon MainIcon={<PersonIcon />} SmallIcon={<AttachMoneyIcon />} />
                            </IconButton>
                        )*/}
                        <IconButton disabled={isLoading} onClick={() => setShowFilter(!showFilter)} title="Filtrovat výkazy">
                            <FilterListIcon />
                        </IconButton>
                        {!readOnly && (
                            <IconButton disabled={isLoading} onClick={() => openTimesheetModal()} title="Přidat položku výkazu">
                                <AddIcon />
                            </IconButton>
                        )}
                    </Stack>
                </Stack>
            </Box>

            <Collapse in={showFilter}>
                <Box mb={4}>
                    <TimesheetFilter showProject filter={filter || {}} onFilterChange={(filter) => setFilter(filter)} />
                </Box>
            </Collapse>

            <Panel>
                <Box mb={4}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} xl={7}>
                            {/*
                                                    disabled,
                                                    readOnly,
                                                    showTimesheetModal,
                                                    showProject,
                                                    onEdit,
                                                    onDelete,
                                                    onDeleteAttachment,
                                                    downloadAttachmentUrl,
                                                    */}
                            <TimesheetCalendar
                                data={state.timesheetCalendar}
                                disabled={isLoading}
                                showEmployee
                                showTimesheetModal={showTimesheetModal}
                                onEdit={showTimesheetModal}
                                onDelete={deleteItem}
                                onDeleteAttachment={(item) => async (attachment) => {
                                    startLoading();
                                    try {
                                        await deleteProjectTimesheetAttachment(detailId, item.id, attachment.id);
                                        refreshDetail();
                                        stopLoading();
                                    } catch (e) {
                                        stopLoading();
                                        alert(e);
                                    }
                                }}
                                downloadAttachmentUrl={(item) => (attachment) =>
                                    `/api/employees/${detailId}/timesheets/${item.id}/attachments/${attachment.id}`}
                            />
                        </Grid>
                        <Grid item xs={12} xl={5}>
                            <Box px={8} mb={4}>
                                <TimesheetChart data={state.timesheetChart} disabled={isLoading} />
                                <ChartLabels
                                    values={
                                        (Array.isArray(state.timesheetChart && state.timesheetChart.data) &&
                                            state.timesheetChart.data.length &&
                                            state.timesheetChart.data[0]) ||
                                        []
                                    }
                                    color={isLoading ? "#6A6A6A" : theme.palette.primary.main}
                                    renderValue={(nv) => <NumberValue value={nv.value} unit="h" />}
                                    emptyValueLabel="Ostatní"
                                    variant="row"
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Panel>

            <Box my={3}>
                {tab === "timesheets" && <TimesheetItemsTab tabs={tabs} detailId={detailId} period={period} disabled={isLoading} />}
                {tab === "overview" && <AggregationsTab tabs={tabs} detailId={detailId} axes={axes} setAxes={setAxes} disabled={isLoading} />}
                {tab === "qrcodes" && <QrCodesTab tabs={tabs} detailId={detailId} disabled={isLoading} data={data} />}
            </Box>

            <TimesheetModal {...(modalState || {})} onSaveItem={saveItem} onClose={closeTimesheetModal} showEmployee projectId={detailId} />
        </>
    );
};

const TimesheetItemsTab = ({ detailId, tabs, period, ...props }) => {
    const [data, setData] = useState({});
    const { startLoading, stopLoading, isLoading } = useLoadingIndicator();

    const fetchData = useCallback(async () => {
        startLoading();
        let data = {};
        try {
            data = await fetchProjectTimesheetItems(detailId, period.start, period.end);
        } catch (e) {
            alert("error");
            console.error(e);
        } finally {
            setData(data);
            stopLoading();
        }
    }, [period, detailId]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    useDetailRefresh(fetchData, [fetchData]);

    return (
        <>
            <Box mb={4}>
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                    {tabs}
                    <Stack direction="row" alignItems="center">
                        {/*!readOnly && (
                            <IconButton disabled={isLoading} onClick={() => openTimesheetModal()} title="Přidat položku výkazu">
                                <AddIcon />
                            </IconButton>
                        )*/}
                    </Stack>
                </Stack>
            </Box>
            <Box mb={4}>
                <MuiTableContainer component={Paper}>
                    <MuiTable size="small">
                        <MuiTableHead>
                            <MuiTableRow>
                                <MuiTableCell>Datum</MuiTableCell>
                                <MuiTableCell>Zaměstnanec</MuiTableCell>
                                <MuiTableCell align="right">Počet hodin</MuiTableCell>
                            </MuiTableRow>
                        </MuiTableHead>
                        <MuiTableBody>
                            {!!data &&
                                Array.isArray(data.content) &&
                                data.content.map((row) => (
                                    <MuiTableRow key={row.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                        <MuiTableCell>
                                            <DateValue value={row.date} />
                                        </MuiTableCell>
                                        <MuiTableCell>
                                            {row.employeeName}
                                            {!!row.description && (
                                                <Typography component="div" variant="caption" color="textSecondary">
                                                    {row.description}
                                                </Typography>
                                            )}
                                        </MuiTableCell>
                                        <MuiTableCell align="right">
                                            <NumberValue value={row.hours} unit="h" />
                                        </MuiTableCell>
                                    </MuiTableRow>
                                ))}
                        </MuiTableBody>
                    </MuiTable>
                </MuiTableContainer>
                {!!data && data.totalPages > 1 && (
                    <Box my={1}>
                        <Pagination
                            count={data.totalPages}
                            page={1 + data.number}
                            siblingCount={1}
                            boundaryCount={1}
                            onChange={async (e, page) => {
                                startLoading();
                                try {
                                    let data = await fetchProjectTimesheetItems(detailId, period.start, period.end, {
                                        page: page - 1,
                                        size: 10,
                                    });
                                    setData(data);
                                } catch (e) {
                                    console.error(e);
                                    alert("error");
                                }
                                stopLoading();
                            }}
                        />
                    </Box>
                )}
            </Box>
        </>
    );
};

//TODO fix!!!
const AggregationsTab = ({ detailId, tabs, axes, setAxes, ...props }) => {
    const theme = useTheme();
    const readOnly = false;
    const { hideDetail, refreshMaster } = useMasterDetail();
    const { startLoading, stopLoading, isLoading } = useLoadingIndicator();
    const periodRef = usePeriod();
    const [period] = periodRef;

    const [aggregationData, setAggregationData] = useState({});

    const showAlert = useAlert();

    const loadAggregationData = async (axes) => {
        if (!axes || !axes.length) {
            return;
        }

        let aggregationData = {};
        setAggregationData({});
        let interval = periodInterval(period);
        startLoading();
        try {
            aggregationData = await fetchProjectAggregation(
                detailId,
                interval.from,
                interval.to,
                null,
                axes.filter((a) => !!a.selected).map((a) => a.id)
            );
            setAggregationData(aggregationData);
        } catch (e) {
            alert(e);
            console.error(e);
        }
        stopLoading();
    };

    useEffect(() => {
        loadAggregationData(axes);
    }, [axes, period, detailId]);

    return (
        <>
            <Box mb={4}>
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                    {tabs}
                    <Stack direction="row" alignItems="center">
                        {/*!readOnly && (
                            <IconButton disabled={isLoading} onClick={() => openTimesheetModal()} title="Přidat položku výkazu">
                                <AddIcon />
                            </IconButton>
                        )*/}
                        <AxesSelector value={axes} onChange={(axes) => setAxes(axes)} disabled={isLoading} />
                    </Stack>
                </Stack>
            </Box>

            <Aggregations
                data={aggregationData}
                disabled={isLoading}
                renderChart={(val) => (
                    <DonutChart
                        data={[(val.children || []).map((ch) => ch.value)]}
                        colors={isLoading ? ["#6A6A6A"] : [theme.palette.primary.main, theme.palette.secondary.main]}
                        disabled={isLoading}
                        height={250}
                        thickness={20}
                    />
                )}
                formatHeader={(base, classes, idx, count, isLeaf) => (
                    <FlexRow>
                        <FlexItem relativeWidth={3}>
                            {!!isLeaf && (
                                <div
                                    style={{
                                        display: "inline-block",
                                        width: 16,
                                        height: 16,
                                        backgroundColor: chartColor(theme.palette.primary.main, count, idx),
                                        marginLeft: 4,
                                        marginRight: 8,
                                    }}
                                ></div>
                            )}
                            <Typography variant="body1">{base.type == "period" ? formatPeriodValue(base.key) : base.name || base.key || "N/A"}</Typography>
                        </FlexItem>
                        <FlexItem right>
                            <Typography variant="body1">
                                <b>
                                    <NumberValue value={base.value} unit={base.unit} />
                                </b>
                            </Typography>
                        </FlexItem>
                    </FlexRow>
                )}
            />
        </>
    );
};

const useQrStyles = makeStyles((theme) => ({
    list: {
        display: "grid",
        gap: theme.spacing(4),
        gridTemplateColumns: "1fr 1fr",
        [theme.breakpoints.down("xl")]: {
            gridTemplateColumns: "1fr",
        },
    },
    qrCodeDiv: {
        margin: theme.spacing(3),
        display: "flex",
        gap: theme.spacing(4),
    },
    qrCodeText: {
        flex: 1,
    },
    qrTitleRow: {
        display: "flex",
        alignItems: "center",
    },
    qrTitle: {
        flex: 1,
        "& > *": {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
        },
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        minWidth: 0,
        gap: theme.spacing(2),
    },
}));

const QrCodesTab = ({ tabs, detailId, data }) => {
    const classes = useQrStyles();
    const [templates, setTemplates] = useState({});
    const { startLoading, stopLoading, isLoading } = useLoadingIndicator();
    const templateModal = useModal();
    const showAlert = useAlert();

    const fetchData = async (detailId) => {
        startLoading();
        let templates = {};
        try {
            templates = await fetchProjectTimesheetItemTemplates(detailId);
        } catch (e) {
            alert("error");
            console.error(e);
        } finally {
            stopLoading();
            setTemplates(templates);
        }
    };

    useEffect(() => {
        fetchData(detailId);
    }, [detailId]);

    return (
        <>
            <Box mb={4}>
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                    {tabs}

                    <Stack direction="row" alignItems="center">
                        <IconButton
                            disabled={isLoading}
                            onClick={() =>
                                templateModal.showModal({
                                    projectId: data.id,
                                    projectName: data.name,
                                    mode: "HOURS",
                                })
                            }
                            title="Přidat QR kód"
                        >
                            <AddIcon />
                        </IconButton>
                        <IconButton
                            disabled={isLoading}
                            onClick={async () => {
                                try {
                                    startLoading();
                                    await fetchProjectTimesheetItemTemplatesPdf(detailId);
                                } catch (e) {
                                    console.error(e);
                                    alert("error");
                                } finally {
                                    stopLoading();
                                }
                            }}
                            title="Vytisknout kódy"
                        >
                            <PrintIcon />
                        </IconButton>
                    </Stack>
                </Stack>
            </Box>

            <Box mb={4}>
                <div className={classes.list}>
                    {!!templates &&
                        !!templates.content &&
                        templates.content.map((qr) => {
                            const { id, name, ...qrCodeValues } = qr;
                            return (
                                <Paper key={qr.id}>
                                    <div className={classes.qrCodeDiv}>
                                        <div>
                                            <QRCodeSVG value={JSON.stringify({ type: "timesheetItem", ...(qrCodeValues || {}) })} level="L" />
                                        </div>
                                        <div className={classes.qrCodeText}>
                                            <div className={classes.qrTitleRow}>
                                                <Typography variant="body1" className={classes.qrTitle}>
                                                    {qr.name}
                                                </Typography>
                                                <div>
                                                    <IconButton disabled={isLoading} onClick={() => templateModal.showModal(qr)} title="Upravit QR kód">
                                                        <EditIcon />
                                                    </IconButton>
                                                    <IconButton
                                                        disabled={isLoading}
                                                        onClick={() => {
                                                            showAlert("Smazat QR kód", `Opravdu chcete smazat QR kód ${qr.name}?`, [
                                                                { text: "Ne" },
                                                                {
                                                                    text: "Ano",
                                                                    onPress: async () => {
                                                                        try {
                                                                            await deleteProjectTimesheetItemTemplate(detailId, qr.id);
                                                                            fetchData(detailId);
                                                                        } catch (e) {
                                                                            console.error(e);
                                                                            alert("error");
                                                                        } finally {
                                                                        }
                                                                    },
                                                                },
                                                            ]);
                                                        }}
                                                        title="Odstranit QR kód"
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </div>
                                            </div>
                                            {!!qr.description && (
                                                <Box my={1}>
                                                    <Typography variant="caption" gutterBottom>
                                                        {qr.description}
                                                    </Typography>
                                                </Box>
                                            )}
                                            {!!qr.instructions && (
                                                <Box my={1}>
                                                    <Typography variant="caption" gutterBottom color="textSecondary">
                                                        {qr.instructions}
                                                    </Typography>
                                                </Box>
                                            )}
                                        </div>
                                    </div>
                                </Paper>
                            );
                        })}
                </div>
                {!!templates && templates.totalPages > 1 && (
                    <Pagination
                        count={templates.totalPages}
                        siblingCount={1}
                        boundaryCount={1}
                        onChange={async (e, page) => {
                            startLoading();
                            try {
                                let templates = await fetchProjectTimesheetItemTemplates(detailId, { page: page - 1, size: 10 });
                                setTemplates(templates);
                            } catch (e) {
                                console.error(e);
                                alert("error");
                            }
                            stopLoading();
                        }}
                    />
                )}
            </Box>

            <TimesheetTemplateModal
                open={templateModal.open}
                onClose={templateModal.closeModal}
                data={templateModal.data}
                onSaveItem={async (data) => {
                    try {
                        startLoading();
                        await saveProjectTimesheetItemTemplate(detailId, data);
                        await fetchData(detailId);
                    } catch (e) {
                        console.error(e);
                        alert("error");
                    } finally {
                        stopLoading();
                    }
                }}
            />
        </>
    );
};

const BalanceTab = ({ data, readOnly, projectName, projectId, transactionModal }) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const settings = useSettings();
    const { hideDetail, refreshMaster, refreshDetail } = useMasterDetail();
    const { detailId } = useMasterDetailParams();
    const periodRef = usePeriod();
    const [period, setPeriod] = periodRef;
    const { startLoading, stopLoading, isLoading } = useLoadingIndicator();

    const [state, setState] = useState({ billings: {}, summary: {}, trends: {} });
    const billingModal = useModal();

    const loadData = async () => {
        startLoading();
        let summary = {},
            billings = {},
            trends = {};

        try {
            let result = await Promise.allSettled([
                fetchProjectBillings(detailId, period.start, period.end),
                fetchProjectBillingsSummary(detailId, period.start, period.end),
                fetchProjectBillingsTrends(detailId, period.day, period.type),
            ]);

            let dataResult = result[0];
            if (dataResult.status === "fulfilled") {
                billings = dataResult.value;
            }
            dataResult = result[1];
            if (dataResult.status === "fulfilled") {
                summary = dataResult.value;
            }
            dataResult = result[2];
            if (dataResult.status === "fulfilled") {
                trends = dataResult.value;
            }
        } catch (e) {
            console.error(e);
            alert(e);
        } finally {
            setState({ trends, billings, summary });
            stopLoading();
        }
    };

    useDetailRefresh(loadData, [period, detailId]);
    useEffect(() => {
        if (detailId) {
            loadData();
        }
    }, [period, detailId]);

    return (
        <>
            <Box mb={2}>
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                    <Period disabled={isLoading} year quarter month week day />

                    <Stack direction="row" alignItems="center">
                        {!readOnly && (
                            <IconButton
                                disabled={isLoading}
                                onClick={() =>
                                    billingModal.showModal({
                                        name: `${data.name} - ${formatPeriod(period)}`,
                                        billingDate: period.end,
                                    })
                                }
                                title="Přidat vyúčtování"
                            >
                                <AddIcon />
                            </IconButton>
                        )}
                    </Stack>
                </Stack>
            </Box>

            <Box mb={4}>
                <Grid container rowSpacing={2} columnSpacing={4}>
                    <Grid item xs={12} lg={6}>
                        <Panel fullHeight>
                            <WidgetRoot>
                                <WidgetFixed>
                                    <Value
                                        label="Zisk za období"
                                        value={
                                            <CurrencyValue value={(state.trends && state.trends.value) || 0} currencyCode={state.trends && state.trends.unit} />
                                        }
                                    />
                                    <Value
                                        label="Zisk celkem"
                                        value={
                                            <CurrencyValue value={(state.trends && state.trends.total) || 0} currencyCode={state.trends && state.trends.unit} />
                                        }
                                        big
                                    />
                                </WidgetFixed>
                                <WidgetFlexible style={{ height: 180 }}>
                                    <TrendsBarChart
                                        data={state.trends.data}
                                        period={period}
                                        disabled={isLoading}
                                        formatValue={(v) =>
                                            `${formatCurrency(
                                                v.value,
                                                settings.defaultCurrencyCode,
                                                settings.currencies,
                                                settings.decimalDigits,
                                                settings.decimalDelimiter,
                                                settings.thousandDelimiter
                                            )}`
                                        }
                                        onClick={updatePeriodOnClick(setPeriod)}
                                    />
                                </WidgetFlexible>
                            </WidgetRoot>
                        </Panel>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Panel fullHeight>
                            <WidgetRoot>
                                <WidgetFlexible style={{ flex: "1 1 40%" }}>
                                    <Box mb={2}>
                                        <Typography variant="caption" component="div" color="textSecondary">
                                            Počet hodin vyúčtováno/nevyúčtováno
                                        </Typography>
                                        <ChartLabelDot
                                            value={<NumberValue value={(state.summary && state.summary.billedHours) || 0} unit="h" />}
                                            color={chartColor(theme.palette.primary.main, 2, 0)}
                                        />
                                        {" / "}
                                        <ChartLabelDot
                                            value={<NumberValue value={(state.summary && state.summary.unbilledHours) || 0} unit="h" />}
                                            color={chartColor(theme.palette.primary.main, 2, 1)}
                                        />
                                    </Box>
                                    <Box mb={2}>
                                        <Typography variant="caption" component="div" color="textSecondary">
                                            Počet transakcí vyúčtovaných/nevyúčtovaných
                                        </Typography>

                                        <ChartLabelDot
                                            value={<NumberValue value={(state.summary && state.summary.billedTransactionsCount) || 0} />}
                                            color={chartColor(theme.palette.secondary.main, 2, 0)}
                                        />
                                        {" / "}
                                        <ChartLabelDot
                                            value={<NumberValue value={(state.summary && state.summary.unbilledTransactionsCount) || 0} />}
                                            color={chartColor(theme.palette.secondary.main, 2, 1)}
                                        />
                                    </Box>
                                </WidgetFlexible>
                                <WidgetFixed>
                                    <DonutChart
                                        data={[
                                            [state.summary.billedHours || 0, state.summary.unbilledHours || 0],
                                            [state.summary.billedTransactionsCount || 0, state.summary.unbilledTransactionsCount || 0],
                                        ]}
                                        colors={isLoading ? ["#6A6A6A"] : [theme.palette.primary.main, theme.palette.secondary.main]}
                                        disabled={isLoading}
                                        size={140}
                                    />
                                </WidgetFixed>
                            </WidgetRoot>
                        </Panel>
                    </Grid>
                </Grid>
            </Box>

            <Box mb={4}>
                <ProjectBillingsList
                    billings={state.billings}
                    disabled={isLoading}
                    onEdit={billingModal.showModal}
                    onTransactionCreated={refreshDetail}
                    onTransactionClick={(id) => navigate(`/transactions/${id}`)}
                    onTransactionTypeSelected={
                        transactionModal
                            ? async (type, billing) => {
                                  startLoading();
                                  try {
                                      let data = await prepareProjectBillingTransaction(detailId, billing.id, type.id);
                                      transactionModal.showModal(data);
                                  } catch (e) {
                                      alert("error");
                                      console.error(e);
                                  }
                                  stopLoading();
                              }
                            : null
                    }
                />

                {!!state.billings && state.billings.totalPages > 1 && (
                    <Pagination
                        count={state.billings.totalPages}
                        siblingCount={1}
                        boundaryCount={1}
                        onChange={async (e, page) => {
                            startLoading();
                            try {
                                let billings = await fetchProjectBillings(detailId, period.start, period.end, { page: page - 1, size: 5 });
                                setState({ ...state, billings });
                            } catch (e) {
                                console.error(e);
                                alert("error");
                            }
                            stopLoading();
                        }}
                    />
                )}
            </Box>

            <ProjectBillingModal
                open={billingModal.open}
                onClose={billingModal.closeModal}
                data={billingModal.data}
                onDataSaved={refreshDetail}
                projectId={detailId}
                projectName={data ? `${data.name} - ${formatPeriod(period)}` : undefined}
                from={period.start}
                to={period.end}
                periodType={period.type}
                period={period}
            />
        </>
    );
};

const createOverviewClasses = makeStyles((theme) => ({
    dashboardTopRow: {
        display: "grid",
        gridTemplateColumns: "2fr 3fr 3fr",
        gap: theme.spacing(4),
        [theme.breakpoints.down("lg")]: {
            gridTemplateColumns: "1fr",
        },
    },
    dashboardSecondRow: {
        display: "grid",
        gridTemplateColumns: "7fr 3fr",
        gap: theme.spacing(4),
        [theme.breakpoints.down("xl")]: {
            gridTemplateColumns: "1fr",
        },
    },
    dashboardSecondRowCharts: {
        display: "grid",
        gridTemplateColumns: "1fr",
        gap: theme.spacing(2),
        overflow: "hidden",
        [theme.breakpoints.down("xl")]: {
            gridTemplateColumns: "1fr 1fr",
        },
        [theme.breakpoints.down("lg")]: {
            gridTemplateColumns: "1fr",
        },
    },
    smallChart: {
        marginTop: theme.spacing(2),
        display: "grid",
        gridTemplateColumns: "2fr 3fr",
        gap: theme.spacing(1),
        alignItems: "stretch",
        overflow: "hidden",
        "& > *": {
            overflow: "hidden",
        },
    },
}));

const TransactionsTab = ({ data, readOnly, projectName, projectId, transactionModal }) => {
    const theme = useTheme();
    const classes = createOverviewClasses();
    const settings = useSettings();
    const { hideDetail, refreshMaster, refreshDetail } = useMasterDetail();
    const { detailId } = useMasterDetailParams();
    const periodRef = usePeriod();
    const [period, setPeriod] = periodRef;
    const { startLoading, stopLoading, isLoading } = useLoadingIndicator();

    const [state, setState] = useState({ transactions: {}, statuses: {}, trends: {}, summary: {}, showAll: true });

    const loadData = async (showAll) => {
        startLoading();
        let transactions = {},
            statuses = {},
            trends = {},
            summary = {};

        try {
            let result = await Promise.allSettled([
                fetchProjectTransactions(detailId, showAll ? null : period.start, showAll ? null : period.end),
                fetchProjectTransactionStatuses(detailId, showAll ? null : period.start, showAll ? null : period.end, moment().format("YYYY-MM-DD")),
                fetchTransactionsTrends(detailId, period.day, period.type),
                fetchTransactionsSummary(detailId, period.start, period.end),
            ]);

            let dataResult = result[0];
            if (dataResult.status === "fulfilled") {
                transactions = dataResult.value;
            }

            dataResult = result[1];
            if (dataResult.status === "fulfilled") {
                statuses = dataResult.value;
            }
            dataResult = result[2];
            if (dataResult.status === "fulfilled") {
                trends = dataResult.value;
            }
            dataResult = result[3];
            if (dataResult.status === "fulfilled") {
                summary = dataResult.value;
            }
        } catch (e) {
            console.error(e);
            alert(e);
        } finally {
            stopLoading();
            setState({ transactions, statuses, trends, summary, showAll });
        }
    };

    useDetailRefresh(loadData, [period, detailId]);
    useEffect(() => {
        if (detailId) {
            loadData(state.showAll);
        }
    }, [period, detailId, state.showAll]);

    return (
        <>
            <Box mb={4}>
                <Panel fullHeight>
                    <Box mb={2}>
                        <Period
                            disabled={isLoading}
                            year
                            quarter
                            month
                            week
                            day
                            actions={[
                                {
                                    render: () => {
                                        return (
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        size="small"
                                                        color="secondary"
                                                        checked={!!state.showAll}
                                                        onChange={(e) => {
                                                            setState({ ...state, showAll: e.target.checked });
                                                            e.stopPropagation();
                                                        }}
                                                        disabled={isLoading}
                                                    />
                                                }
                                                label={<Typography variant="caption">Zobrazit vše</Typography>}
                                            />
                                        );
                                    },
                                },
                                {
                                    alwaysVisible: true,
                                    render: () => (
                                        <AddTransactionButton
                                            disabled={!!isLoading}
                                            onTypeSelected={(type) => {
                                                let transaction = {
                                                    ...type,
                                                    typeId: type.id,
                                                    typeName: type.name,
                                                    name: "",
                                                    id: null,
                                                    currencyCode: settings.defaultCurrencyCode,
                                                };
                                                if (!!data) {
                                                    transaction.projectId = data.id || null;
                                                    transaction.projectName = data.name || null;

                                                    if (type.direction == "DEBIT") {
                                                        transaction.counterpartyId = data.clientId || null;
                                                        transaction.counterpartyName = data.clientName || null;
                                                        transaction.counterpartyIdNumber = data.clientIdNumber || null;
                                                        transaction.counterpartyTaxIdNumber = data.clientTaxIdNumber || null;
                                                    }
                                                }
                                                transactionModal.showModal(transaction);
                                            }}
                                            hasProjectReference={true}
                                        />
                                    ),
                                },
                            ]}
                        />
                    </Box>
                    <div className={classes.dashboardTopRow}>
                        <div>
                            <Value
                                label="Celkem za období"
                                value={
                                    <CurrencyValue
                                        value={(state.summary && state.summary.sum) || 0}
                                        currencyCode={state.summary && state.summary.currencyCode}
                                    />
                                }
                            />
                            <Value
                                label="Celkem"
                                value={
                                    <CurrencyValue
                                        value={(state.summary && state.summary.sumTotal) || 0}
                                        currencyCode={state.summary && state.summary.currencyCode}
                                    />
                                }
                                big
                            />
                        </div>
                        <div>
                            <TrendsBarChart
                                data={state.trends.data}
                                period={period}
                                disabled={isLoading}
                                formatValue={(v) =>
                                    `${formatCurrency(
                                        v.value,
                                        settings.defaultCurrencyCode,
                                        settings.currencies,
                                        settings.decimalDigits,
                                        settings.decimalDelimiter,
                                        settings.thousandDelimiter
                                    )}`
                                }
                                onClick={updatePeriodOnClick(setPeriod)}
                            />
                        </div>
                        <div>
                            <TransactionStatusesChart data={state.statuses} disabled={isLoading} />
                        </div>
                    </div>
                </Panel>
            </Box>

            <Box mb={4}>
                <TransactionsList
                    data={(state.transactions && state.transactions.content) || []}
                    onEdit={transactionModal.showModal}
                    buttons={[]}
                    withDetail
                    showCounterparty
                />

                {!!state.transactions && state.transactions.totalPages > 1 && (
                    <Pagination
                        count={state.transactions.totalPages}
                        siblingCount={1}
                        boundaryCount={1}
                        onChange={async (e, page) => {
                            startLoading();
                            try {
                                let transactions = await fetchProjectTransactions(
                                    detailId,
                                    state.showAll ? null : period.start,
                                    state.showAll ? null : period.end,
                                    { page: page - 1, size: 5 }
                                );
                                setState({ ...state, transactions });
                            } catch (e) {
                                console.error(e);
                                alert("error");
                            }
                            stopLoading();
                        }}
                    />
                )}
            </Box>
        </>
    );
};

const DocumentsTab = ({ data, readOnly, projectId }) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const settings = useSettings();
    const { hideDetail, refreshMaster, refreshDetail } = useMasterDetail();
    const { detailId } = useMasterDetailParams();
    const periodRef = usePeriod();
    const [period, setPeriod] = periodRef;
    const { startLoading, stopLoading, isLoading } = useLoadingIndicator();

    const [state, setState] = useState({ documents: {}, summary: {}, trends: {} });
    const processModal = useModal();

    const loadData = async () => {
        startLoading();
        let summary = {},
            documents = {},
            trends = {};

        try {
            let result = await Promise.allSettled([fetchDocuments({ projectId: detailId }), fetchProjectDocumentsSummary(detailId)]);

            let dataResult = result[0];
            if (dataResult.status === "fulfilled") {
                documents = dataResult.value;
            }
            dataResult = result[1];
            if (dataResult.status === "fulfilled") {
                summary = dataResult.value;
            }
        } catch (e) {
            console.error(e);
            alert(e);
        } finally {
            setState({ trends, documents, summary });
            stopLoading();
        }
    };

    useDetailRefresh(loadData, [detailId]);
    useEffect(() => {
        if (detailId) {
            loadData();
        }
    }, [detailId]);

    return (
        <>
            <Box mb={4}>
                <Grid container rowSpacing={2} columnSpacing={4}>
                    <Grid item xs={12} lg={6}>
                        <Panel fullHeight>
                            <Value label="Nezpracovaných dokumentů" value={(state.summary && state.summary.unprocessedCount) || "0"} big />
                        </Panel>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Panel fullHeight>
                            <Value
                                label="Objem nezpracovaných dokumentů"
                                value={
                                    <CurrencyValue
                                        value={(state.summary && state.summary.unprocessedAmount) || 0}
                                        currencyCode={state.summary && state.summary.currencyCode}
                                    />
                                }
                                big
                            />
                        </Panel>
                    </Grid>
                </Grid>
            </Box>

            <Box mb={4}>
                <DocumentsList
                    data={state.documents && state.documents.content}
                    disabled={isLoading}
                    onDiscardDocument={async ({ id }) => {
                        startLoading();
                        try {
                            await discardDocument(id);
                            loadData();
                        } catch (e) {
                            console.error(e);
                            alert("error");
                        } finally {
                            stopLoading();
                        }
                    }}
                    onProcessDocument={(data) => processModal.showModal(prepareDocumentTransactionData(data))}
                />
                {!!state.documents && state.documents.totalPages > 1 && (
                    <Pagination
                        count={state.documents.totalPages}
                        siblingCount={1}
                        boundaryCount={1}
                        onChange={async (e, page) => {
                            startLoading();
                            try {
                                let documents = await fetchDocuments({ projectId: detailId }, { page: page - 1, size: 5 });
                                setState({ ...state, documents });
                            } catch (e) {
                                console.error(e);
                                alert("error");
                            }
                            stopLoading();
                        }}
                    />
                )}
            </Box>
            <ProcessDocumentModal open={processModal.open} onClose={processModal.closeModal} onDataSaved={loadData} data={processModal.data || {}} />
        </>
    );
};

const createFinancialsStyles = makeStyles((theme) => ({
    root: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        columnGap: theme.spacing(4),
        rowGap: theme.spacing(2),

        alignItems: "stretch",
        justifyItems: "stretch",

        overflow: "hidden",
        [theme.breakpoints.down("xl")]: {
            gridTemplateColumns: "1fr",
        },

        "& > *": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
    },
    legend: {},
    chart: {},
}));

const financialsOverviewTabState = {};
const FinancialsOverviewTab = ({ settings, theme, showDetail }) => {
    const classes = createFinancialsStyles();
    const [period, setPeriod] = usePeriod();
    const { startLoading, stopLoading, isLoading } = useLoadingIndicator();
    const [state, setState] = useState(financialsOverviewTabState);
    const [showAll, setShowAll] = useState(true);

    useEffect(() => {
        const fetchState = async () => {
            startLoading();
            try {
                let data = {},
                    chart = [],
                    chartLabels = [],
                    profitChart = [],
                    profitChartLabels = [],
                    dataResult = undefined;
                let paging = { page: (state.data && state.data.number) || 0, size: 10 };
                let start = period.start,
                    end = period.end;
                if (showAll) {
                    let m = moment(period.date);
                    start = m.startOf("year").format("YYYY-MM-DD");
                    end = m.endOf("year").format("YYYY-MM-DD");
                }
                const result = await Promise.allSettled([
                    fetchProjectOverviewFinancialsData(start, end),
                    fetchProjectOverviewFinancialsChart(start, end),
                    //fetchProjectOverviewFinancialsChartProfit(period.start, period.end),
                ]);

                dataResult = result[0];
                if (dataResult.status === "fulfilled") {
                    data = dataResult.value;
                }

                dataResult = result[1];
                if (dataResult.status === "fulfilled") {
                    chartLabels = dataResult.value;
                    chart = (chartLabels || []).map((nv) => Math.abs(nv.value || 0));
                }
                /*
                dataResult = result[2];
                if (dataResult.status === "fulfilled") {
                    profitChartLabels = dataResult.value;
                    profitChart = (profitChartLabels || []).map((nv) => Math.abs(nv.value || 0));
                }
                */
                setState({ data, chart, profitChart, chartLabels, profitChartLabels });
            } catch (e) {
                console.error(e);
                //alert("error"); //FIXME //TODO
            } finally {
                stopLoading();
            }
        };

        fetchState();
    }, [period, showAll]);

    return (
        <>
            <Box mb={4}>
                <Panel fullHeight>
                    <Box mb={2}>
                        <Period
                            disabled={isLoading}
                            year
                            quarter
                            month
                            week
                            day
                            actions={[
                                {
                                    render: () => (
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    size="small"
                                                    color="secondary"
                                                    checked={!!showAll}
                                                    onChange={(e) => {
                                                        setShowAll(e.target.checked);
                                                        e.stopPropagation();
                                                    }}
                                                    disabled={isLoading}
                                                />
                                            }
                                            label={<Typography variant="caption">Souhrn za rok</Typography>}
                                        />
                                    ),
                                },
                            ]}
                        />
                    </Box>
                    <Box mb={4}>
                        <div className={classes.root}>
                            <div className={classes.legend}>
                                <ChartLabels
                                    values={state.chartLabels || []}
                                    color={isLoading ? "#6A6A6A" : theme.palette.primary.dark}
                                    emptyValueLabel="Ostatní"
                                    renderValue={(nv) => <CurrencyValue value={nv.value} currencyCode={settings.defaultCurrencyCode} />}
                                    onClick={(nv) => {
                                        showDetail && showDetail(nv.id, { tab: "financials" });
                                    }}
                                    sx={{ xs: { textAlign: "center" }, lg: { textAlign: "left" } }}
                                />
                            </div>
                            <div className={classes.chart}>
                                <DonutChart
                                    data={[state.chart || []]}
                                    colors={isLoading ? ["#6A6A6A"] : [theme.palette.primary.light]}
                                    disabled={isLoading}
                                    size={270}
                                />
                            </div>
                        </div>
                    </Box>
                </Panel>
            </Box>
            <Box mb={4}>
                {!!state.data && Array.isArray(state.data.content) ? (
                    state.data.content.map((row) => (
                        <DetailRow key={row.id} onClick={() => showDetail && showDetail(row.id, { tab: "financials" })}>
                            <DetailRowMain slim>
                                <Typography variant="body1" component="div">
                                    {row.name}
                                </Typography>
                                <Typography variant="caption" color="textSecondary" component="div">
                                    {row.clientName}
                                </Typography>
                            </DetailRowMain>
                            <DetailRowValue slim>
                                <Typography variant="body1" component="div" align="right">
                                    <CurrencyValue currencyCode={row.unit} value={row.value} />
                                </Typography>
                            </DetailRowValue>
                        </DetailRow>
                    ))
                ) : (
                    <div>no data</div>
                )}

                {!!state.data && state.data.totalPages > 1 && (
                    <Box my={1}>
                        <Pagination
                            count={state.data.totalPages}
                            page={1 + state.data.number}
                            siblingCount={1}
                            boundaryCount={1}
                            onChange={async (e, page) => {
                                startLoading();
                                try {
                                    let start = period.start,
                                        end = period.end;
                                    if (showAll) {
                                        let m = moment(period.date);
                                        start = m.startOf("year").format("YYYY-MM-DD");
                                        end = m.endOf("year").format("YYYY-MM-DD");
                                    }
                                    let data = await fetchProjectOverviewFinancialsData(start, end, {
                                        page: page - 1,
                                        size: 10,
                                    });
                                    setState({ ...state, data });
                                } catch (e) {
                                    console.error(e);
                                    alert("error");
                                }
                                stopLoading();
                            }}
                        />
                    </Box>
                )}
            </Box>
        </>
    );
};

const timesheetsOverviewTabState = {};
const TimesheetsOverviewTab = ({ settings, theme, showDetail }) => {
    const classes = createFinancialsStyles();
    const [period, setPeriod] = usePeriod();
    const { startLoading, stopLoading, isLoading } = useLoadingIndicator();
    const [state, setState] = useState(timesheetsOverviewTabState);

    useEffect(() => {
        const fetchState = async () => {
            startLoading();
            try {
                let data = {},
                    chart = [],
                    chartLabels = [],
                    dataResult = undefined;
                let paging = { page: (state.data && state.data.number) || 0, size: 10 };

                const result = await Promise.allSettled([
                    fetchProjectOverviewTimesheetsData(period.start, period.end),
                    fetchProjectOverviewTimesheetsChart(period.start, period.end),
                ]);

                dataResult = result[0];
                if (dataResult.status === "fulfilled") {
                    data = dataResult.value;
                }

                dataResult = result[1];
                if (dataResult.status === "fulfilled") {
                    chartLabels = dataResult.value;
                    chart = (chartLabels || []).map((nv) => Math.abs(nv.value || 0));
                }

                setState({ data, chart, chartLabels });
            } catch (e) {
                console.error(e);
                //alert("error"); //FIXME //TODO
            } finally {
                stopLoading();
            }
        };

        fetchState();
    }, [period]);

    return (
        <>
            <Box mb={4}>
                <Panel fullHeight>
                    <Box mb={2}>
                        <Period
                            disabled={isLoading}
                            year
                            quarter
                            month
                            week
                            day
                            actions={
                                [
                                    /*
                                {
                                    icon: <FileDownloadIcon />,
                                    title: "Exportovat do excelu",
                                    onClick: async () => {
                                        startLoading();
                                        try {
                                            await downloadEmployeesExcel(period.start, period.end, state.filter);
                                        } catch (e) {
                                            alert("error");
                                        }
                                        stopLoading();
                                    },
                                },
                            */
                                ]
                            }
                        />
                    </Box>

                    <Box mb={4}>
                        <div className={classes.root}>
                            <div className={classes.legend}>
                                <ChartLabels
                                    values={state.chartLabels || []}
                                    color={isLoading ? "#6A6A6A" : theme.palette.primary.dark}
                                    emptyValueLabel="Ostatní"
                                    renderValue={(nv) => <NumberValue value={nv.value} unit="h" />}
                                    onClick={(nv) => {
                                        showDetail && showDetail(nv.id, { tab: "timesheets" });
                                    }}
                                    sx={{ xs: { textAlign: "center" }, lg: { textAlign: "left" } }}
                                />
                            </div>
                            <div className={classes.chart}>
                                <DonutChart
                                    data={[state.chart || []]}
                                    colors={isLoading ? ["#6A6A6A"] : [theme.palette.primary.light, theme.palette.secondary.light]}
                                    disabled={isLoading}
                                    size={270}
                                />
                            </div>
                        </div>
                    </Box>
                </Panel>
            </Box>
            <Box mb={4}>
                {!!state.data && Array.isArray(state.data.content) ? (
                    state.data.content.map((row) => (
                        <DetailRow key={row.id} onClick={() => showDetail && showDetail(row.id, { tab: "timesheets" })}>
                            <DetailRowMain slim>
                                <Typography variant="body1" component="div">
                                    {row.name}
                                </Typography>
                                <Typography variant="caption" color="textSecondary" component="div">
                                    {row.clientName}
                                </Typography>
                            </DetailRowMain>
                            <DetailRowValue slim>
                                <Typography variant="body1" component="div" align="right">
                                    <NumberValue value={row.value} unit="h" />
                                </Typography>
                            </DetailRowValue>
                        </DetailRow>
                    ))
                ) : (
                    <div>no data</div>
                )}

                {!!state.data && state.data.totalPages > 1 && (
                    <Box my={1}>
                        <Pagination
                            count={state.data.totalPages}
                            page={1 + state.data.number}
                            siblingCount={1}
                            boundaryCount={1}
                            onChange={async (e, page) => {
                                startLoading();
                                try {
                                    let data = await fetchProjectOverviewTimesheetsData(period.start, period.end, {
                                        page: page - 1,
                                        size: 10,
                                    });
                                    setState({ ...state, data });
                                } catch (e) {
                                    console.error(e);
                                    alert("error");
                                }
                                stopLoading();
                            }}
                        />
                    </Box>
                )}
            </Box>
        </>
    );
};

const ProjectsOverview = ({ menuItems }) => {
    const settings = useSettings();
    const theme = useTheme();
    const { hideDetail, refreshMaster, mobile, showDetail } = useMasterDetail();
    const periodRef = usePeriod();
    const [period, setPeriod] = periodRef;
    const { startLoading, stopLoading, isLoading } = useLoadingIndicator();
    const modal = useModal();
    const navigate = useNavigate();
    const { tab, setTab, tabs } = useMasterDetailTabs({
        financials: settings.transactionsSupport,
        timesheets: settings.timesheetsSupport,
        billings: settings.billingsSupport,
    });

    const masterMenuItems = useMemo(
        () => [
            {
                icon: <AddIcon />,
                title: "Přidat projekt",
                alwaysVisible: true,
                onClick: () => modal.showModal({}),
            },
            ...menuItems,
        ],
        [menuItems]
    );

    return (
        <>
            <MasterDetailContent
                showPageTitle
                showLoadingIndicator
                pageTitle="Projekty"
                menuItems={masterMenuItems}
                onBack={(mobile && hideDetail) || null}
                pageTitleChildren={
                    !!tabs._ && (
                        <Tabs value={tab} onChange={(e, tab) => setTab(tab)} disabled={isLoading}>
                            {!!tabs.financials && <Tab label="Finance" value="financials" disabled={isLoading} />}
                            {!!tabs.timesheets && <Tab label="Výkazy" value="timesheets" disabled={isLoading} />}
                            {!!tabs.billings && <Tab label="Vyúčtování" value="billings" disabled={isLoading} />}
                        </Tabs>
                    )
                }
            >
                <Box px={2} pb={4}>
                    {tab === "financials" && <FinancialsOverviewTab settings={settings} theme={theme} showDetail={showDetail} />}
                    {tab === "timesheets" && <TimesheetsOverviewTab settings={settings} theme={theme} showDetail={showDetail} />}
                </Box>
                <ProjectModal
                    open={modal.open}
                    onClose={modal.closeModal}
                    onDataSaved={(data) => {
                        //fetchData(period);
                        refreshMaster();
                        navigate(`/projects/${data.id}`);
                    }}
                    data={{}}
                    title="Nový projekt"
                />
            </MasterDetailContent>
        </>
    );
};

const ProjectsMasterDetail = ({ menuItems }) => {
    return (
        <MasterDetail
            masterElement={<ProjectMasterContainer menuItems={menuItems} />}
            detailElement={<ProjectDetailContainer menuItems={menuItems} />}
            overviewElement={<ProjectsOverview menuItems={menuItems} />}
            titleMenuItems={menuItems}
            detailHasBackground={false}
        />
    );
};

export default ProjectsMasterDetail;
