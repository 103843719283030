import {useState} from "react";

//TODO optimize using memo
export const useEditableList = (value, onChange) => {
    const [state, setState] = useState({ selectedRow: undefined, selectedRowData: undefined });

    if (!Array.isArray(value)) {
        value = [];
    } else {
        value = [...value];
    }
    if (!onChange) {
        onChange = (value) => console.warn("No onChange handler provided to useEditableList");
    }

    const saveRow = (item, index, keepOriginal) => {
        if (!keepOriginal) {
            item = { ...item };
        }
        if (index < 0) {
            value.unshift(item);
        } else if (index < value.length) {
            value[index] = item;
        } else {
            value.push(item);
        }

        setState({ selectedRow: undefined, selectedRowData: undefined });

        onChange(value);
    };

    const deleteRow = (index) => {
        if (index > -1 && index < value.length) {
            value.splice(index, 1);
        }
        onChange(value);
    };

    const editRow = (index, data) => {
        if (index > -1 && index < value.length) {
            setState({ selectedRow: index, selectedRowData: data || {} });
        }
    };

    const addFirstRow = (data) => {
        setState({ selectedRow: -1, selectedRowData: data || {} });
    };

    const addLastRow = (data) => {
        setState({ selectedRow: value.length, selectedRowData: data || {} });
    };

    const hideRowForm = () => {
        setState({ selectedRow: undefined, selectedRowData: undefined });
    };

    return {
        saveRow,
        deleteRow,

        ...state,
        editRow,
        addFirstRow,
        addLastRow,
        hideRowForm,
    };
};

const getPagingParams = () => () => {};

const emptyLoadableList = {
    data: [],
    totalElements: 0,
    errors: null, //TODO?
};

export const useLoadableList = () => {
    const [state, setState] = useState({ ...emptyLoadableList });

    const acceptData = (response) => {
        let data = response.content || [];
        if (!!response.first) {
            setState({ ...state, data, size: data.length, totalElements: response.totalElements, nextPageParams: { size: 25, page: 1 } });
            return;
        }

        data = [...state.data, ...data];
        setState({ ...state, data, size: data.length, totalElements: response.totalElements, nextPageParams: { size: 25, page: response.number * 1 + 1 } });
    };

    return { ...state, acceptData };
};
