import React, {createRef, useEffect, useMemo, useState} from "react";

import backend from "../../utils/backend";

import {makeStyles} from "@mui/styles";

import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import Button from "@mui/material/Button";
import Link from "../Link";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import Checkbox from "@mui/material/Checkbox";

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import PrintIcon from "@mui/icons-material/Print";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Paper from "@mui/material/Paper";
import FormHelperText from "@mui/material/FormHelperText";

import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Modal from "../Modal";
import ExpandButton from "../ExpandButton";

import MuiTable from "@mui/material/Table";
import MuiTableBody from "@mui/material/TableBody";
import MuiTableCell from "@mui/material/TableCell";
import MuiTableContainer from "@mui/material/TableContainer";
import MuiTableHead from "@mui/material/TableHead";
import MuiTableRow from "@mui/material/TableRow";
import {useForm} from "../../utils/form";
import {useEditableList} from "../../utils/lists";
import {LoadingIndicator, LoadingIndicatorContainer, useLoadingIndicator} from "../../utils/loading";
import {fetchTransactionPdf, TransactionTypeDropDown, TransactionTypeLink, useTransactionTypes,} from "../transactions";

import {useAlert} from "../Alert";
import {DatePicker, DateValue} from "../DateFields";
import {DetailRow, FlexItem, FlexRow, Section, TableContainer, TableRowContainer} from "../Containers";
import {CurrencyField, CurrencyValue, formatCurrency, NumberField, NumberValue} from "../NumberFields";
import Value from "../Value";
import numbers from "../../utils/numbers";
import {useSettings} from "../../utils/settings";

import {formatPeriod, isInPeriod} from "../../components/period";
import FilterableMultiSelectField from "../FilterableMultiSelectField";
import {unaccent} from "../../utils/string";

const TimesheetItemRow = ({ showEmployee, showProject, data, dateFormat }) => (
    <Grid container columnSpacing={1}>
        <Grid item xs={12}>
            {!!showEmployee && <Typography variant="caption">{data.employeeName || ""}</Typography>}
            {!!showProject && <Typography variant="caption">{data.projectName || ""}</Typography>}
            <Typography variant="caption" component="div" color="textSecondary">
                {data.description}
            </Typography>
        </Grid>
        <Grid item xs={6}>
            <Typography variant="caption" color="textSecondary">
                <DateValue value={data.date} />
            </Typography>
        </Grid>
        <Grid item xs={6}>
            <Typography variant="caption" align="right" component="div">
                <NumberValue value={data.hours} unit="h" />
            </Typography>
        </Grid>
    </Grid>
);

const AccountingTransactionItemRow = ({ data }) => (
    <Grid container columnSpacing={1}>
        <Grid item xs={12}>
            <Typography variant="caption">{data.name}</Typography>
            <Typography variant="caption" component="div" color="textSecondary">
                {data.typeName}
            </Typography>
        </Grid>
        <Grid item xs={6}>
            <Typography variant="caption" color="textSecondary">
                <DateValue value={data.date} />
            </Typography>
        </Grid>
        <Grid item xs={6}>
            <Typography variant="caption" align="right" component="div">
                <CurrencyValue value={data.total} currencyCode={data.currencyCode} />
            </Typography>
        </Grid>
    </Grid>
);

const ProjectUnbilledTransactionsSelectorField = ({ period, ...props }) => {
    const [transactions, setTransactions] = useState([]);
    const { startLoading, stopLoading, isLoading } = useLoadingIndicator();

    useEffect(async () => {
        startLoading();
        let transactions = [];
        try {
            transactions = await fetchProjectUnbilledTransactions(props.projectId);
        } catch (e) {
            console.error(e);
        } finally {
            stopLoading();
            setTransactions(transactions || []);
        }

        return null;
    }, []);

    return (
        <FilterableMultiSelectField
            {...props}
            items={transactions}
            idFunction={(item) => item.accountingTransactionId}
            renderFilterFn={(filter, setFilter) => (
                <Box mb={2}>
                    <TextField
                        fullWidth
                        placeholder="Filtr"
                        margin="normal"
                        name="name"
                        value={filter.text || ""}
                        onChange={(e) => {
                            setFilter({ ...filter, text: e.target.value });
                        }}
                        disabled={props.disabled}
                        size="small"
                    />

                    {!!period && (
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Switch
                                        color="secondary"
                                        chacked={filter.periodOnly || false}
                                        onChange={(e, val) => setFilter({ ...filter, periodOnly: !!e.target.checked })}
                                        value={true}
                                    />
                                }
                                label={
                                    <Typography variant="body2" color="textSecondary">
                                        Jen za období {!!period && formatPeriod(period)}
                                    </Typography>
                                }
                            />
                        </FormGroup>
                    )}
                </Box>
            )}
            resolveItemFilterFn={(item, filter) => {
                if (filter) {
                    if (period && filter.periodOnly && !isInPeriod(item.date, period)) {
                        return false;
                    }
                    if (filter.text && !unaccent(item.name, true).includes(filter.text)) {
                        return false;
                    }
                }

                return true;
            }}
        />
    );
};

const UnbilledTimesheetsSelectorField = ({ period, fetchFn, resolveFilterInner, idRef, ...props }) => {
    const [timesheets, setTimesheets] = useState([]);
    const { startLoading, stopLoading, isLoading } = useLoadingIndicator();
    const [filter, setFilter] = useState({});

    useEffect(async () => {
        startLoading();
        let timesheets = [];
        try {
            timesheets = await fetchFn(idRef);
        } catch (e) {
            console.error(e);
        } finally {
            stopLoading();
            setTimesheets(timesheets || []);
        }

        return null;
    }, [idRef]);

    return (
        <FilterableMultiSelectField
            {...props}
            items={timesheets}
            idFunction={(item) => item.timesheetItemId}
            filter={filter}
            setFilter={setFilter}
            renderFilterFn={(filter, setFilter) => (
                <Box mb={2}>
                    <TextField
                        fullWidth
                        placeholder="Filtr"
                        margin="normal"
                        name="name"
                        value={filter.text || ""}
                        onChange={(e) => {
                            setFilter({ ...filter, text: e.target.value });
                        }}
                        disabled={props.disabled}
                        size="small"
                    />
                    {!!period && (
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Switch
                                        color="secondary"
                                        chacked={filter.periodOnly || false}
                                        onChange={(e, val) => setFilter({ ...filter, periodOnly: !!e.target.checked })}
                                        value={true}
                                    />
                                }
                                label={
                                    <Typography variant="body2" color="textSecondary">
                                        Jen za období {!!period && formatPeriod(period)}
                                    </Typography>
                                }
                            />
                        </FormGroup>
                    )}
                </Box>
            )}
            resolveItemFilterFn={(item, filter) => {
                if (filter) {
                    if (period && filter.periodOnly && !isInPeriod(item.date, period)) {
                        return false;
                    }

                    return resolveFilterInner(filter, item);
                }

                return true;
            }}
        />
    );
};

const ProjectUnbilledTimesheetsSelectorField = ({ projectId, ...props }) => {
    return (
        <UnbilledTimesheetsSelectorField
            {...props}
            fetchFn={async (id) => {
                return await fetchProjectUnbilledTimesheets(id);
            }}
            resolveFilterInner={(filter, item) => {
                if (filter.text && !(unaccent(item.employeeName, true).includes(filter.text) || unaccent(item.description, true).includes(filter.text))) {
                    return false;
                }
                return true;
            }}
            idRef={projectId}
        />
    );
};
const EmployeeUnbilledTimesheetsSelectorField = ({ employeeId, ...props }) => {
    return (
        <UnbilledTimesheetsSelectorField
            {...props}
            fetchFn={async (id) => await fetchEmployeeUnbilledTimesheets(id)}
            resolveFilterInner={(filter, item) => {
                if (filter.text && !(unaccent(item.projectName, true).includes(filter.text) || unaccent(item.description, true).includes(filter.text))) {
                    return false;
                }
                return true;
            }}
            renderItemFn={(t) => <TimesheetItemRow showProject data={t} />}
            idRef={employeeId}
        />
    );
};

const TransactionsSelector = ({ open, handleClose, onAdd, projectId, accountingTransactions, period }) => {
    const valueRef = createRef([]);

    return (
        <Dialog open={!!open} onClose={handleClose} size="sm" fullSize>
            <DialogTitle>Transakce</DialogTitle>
            <DialogContent>
                <DialogContentText>Vyberte transakce, které chcete přidat do vyúčtování</DialogContentText>
                <ProjectUnbilledTransactionsSelectorField
                    projectId={projectId}
                    renderItemFn={(t) => <AccountingTransactionItemRow data={t} />}
                    skipValues={accountingTransactions}
                    valueRef={valueRef}
                    period={period}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Storno</Button>
                <Button
                    onClick={() => {
                        onAdd && onAdd(valueRef.current || []);
                        handleClose && handleClose();
                    }}
                >
                    Přidat
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const TimesheetsSelector = ({ open, handleClose, onAdd, projectId, employeeId, timesheets, period, renderSelector }) => {
    const valueRef = createRef([]);

    const props = {
        projectId: projectId,
        employeeId: employeeId,
        renderItemFn: (t) => <TimesheetItemRow showEmployee={!!projectId} showProject={!!employeeId} data={t} />,
        skipValues: timesheets,
        valueRef: valueRef,
        period: period,
    };

    const selector = (renderSelector && renderSelector(props)) || <ProjectUnbilledTimesheetsSelectorField {...props} />;

    return (
        <Dialog open={!!open} onClose={handleClose} size="sm" fullSize>
            <LoadingIndicator />
            <DialogTitle>Položky výkazu</DialogTitle>
            <DialogContent>
                <DialogContentText>Vyberte položky výkazu, které chcete přidat do vyúčtování</DialogContentText>
                {selector}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Storno</Button>
                <Button
                    onClick={() => {
                        onAdd && onAdd(valueRef.current || []);
                        handleClose && handleClose();
                    }}
                >
                    Přidat
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const BillingItemReferencesField = ({
    timesheets,
    accountingTransactions,
    onChangeTimesheets,
    onChangeAccountingTransactions,
    projectId,
    employeeId,
    readOnly,
    currencyCode,
    period,
    ...props
}) => {
    const settings = useSettings();
    const showAlert = useAlert();
    //const { saveRow, deleteRow, selectedRow, selectedRowData, addLastRow } = useEditableList(value, onChange);
    const [addField, setAddField] = useState(false);

    //TODO modal

    const handleClose = () => setAddField(null);

    let hours = 0;
    if (Array.isArray(timesheets)) {
        timesheets.forEach((ti) => {
            hours = numbers.plus(hours, ti.hours, 2);
        });
    }
    let timesheetSummary = `Vybráno ${hours} hodin`;

    let transactionsTotal = 0;
    if (Array.isArray(accountingTransactions)) {
        accountingTransactions.forEach((at) => {
            transactionsTotal = numbers.plus(transactionsTotal, at.total, 2);
        });
    }
    let accountingTransactionSummary = `Vybrány transakce za ${formatCurrency(
        transactionsTotal,
        currencyCode,
        settings.currencies,
        settings.decimalDigits,
        settings.decimalDelimiter,
        settings.thousandDelimiter
    )}`;

    return (
        <>
            <Section
                title="Položky výkazu"
                subtitle={timesheetSummary}
                alwaysShowSubtitle
                actions={
                    readOnly
                        ? null
                        : [
                              {
                                  title: "Přidat položky výkazu",
                                  icon: <AddIcon />,
                                  onClick: () => setAddField("projectTimesheet"),
                              },
                          ]
                }
            >
                <TableContainer>
                    {/* timesheets -> timesheetItem */}
                    {Array.isArray(timesheets) &&
                        timesheets.map((t, key) => (
                            <ListItem
                                key={`${t && t.id}-${key}`}
                                divider
                                secondaryAction={
                                    <IconButton
                                        edge="end"
                                        onClick={() =>
                                            showAlert("Odebrat položku", `Chcete z vyúčtování odebrat položku výkazu?`, [
                                                { text: "Ne" },
                                                {
                                                    text: "Ano",
                                                    onPress: () => {
                                                        onChangeTimesheets([]);
                                                    },
                                                },
                                            ])
                                        }
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                }
                            >
                                <TimesheetItemRow showEmployee={!!projectId} showProject={!!employeeId} data={t} />
                            </ListItem>
                        ))}
                </TableContainer>
            </Section>
            <Section
                title="Transakce"
                subtitle={accountingTransactionSummary}
                alwaysShowSubtitle
                actions={
                    readOnly
                        ? null
                        : [
                              {
                                  title: "Přidat transakce",
                                  icon: <AddIcon />,
                                  onClick: () => setAddField("projectTransaction"),
                              },
                          ]
                }
            >
                <TableContainer>
                    {Array.isArray(accountingTransactions) &&
                        accountingTransactions.map((t, key) => (
                            <ListItem
                                key={`${t && t.id}-${key}`}
                                divider
                                secondaryAction={
                                    <IconButton
                                        edge="end"
                                        onClick={() =>
                                            showAlert("Odebrat transakci", `Chcete z vyúčtování odebrat transakci ${t.name}?`, [
                                                { text: "Ne" },
                                                {
                                                    text: "Ano",
                                                    onPress: () => {
                                                        onChangeAccountingTransactions(t);
                                                    },
                                                },
                                            ])
                                        }
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                }
                            >
                                <AccountingTransactionItemRow data={t} />
                            </ListItem>
                        ))}
                </TableContainer>
            </Section>
            <TransactionsSelector
                open={addField == "projectTransaction"}
                handleClose={handleClose}
                projectId={projectId}
                accountingTransactions={accountingTransactions}
                onAdd={onChangeAccountingTransactions}
                period={period}
            />
            <TimesheetsSelector
                open={addField == "projectTimesheet"}
                handleClose={handleClose}
                projectId={projectId}
                timesheets={timesheets}
                onAdd={onChangeTimesheets}
                period={period}
            />
        </>
    );
};

const BillingItemForm = ({ value, onSubmit, onCancel, disabled, currencyCode, projectId, employeeId, period, canBeCalculated, showReferences }) => {
    const form = useForm({ onSubmit });
    const { values, handleChange, errors, setErrors, handleSubmit, setValues, valuesRef } = form;

    useEffect(() => {
        setValues(value);
    }, [value]);

    return (
        <>
            <Box mb={2}>
                <TextField
                    fullWidth
                    label="Název"
                    margin="normal"
                    name="name"
                    value={values.name || ""}
                    onChange={handleChange}
                    disabled={disabled}
                    size="small"
                />

                {!!showReferences && (
                    <BillingItemReferencesField
                        projectId={projectId}
                        employeeId={employeeId}
                        timesheets={values.timesheets || []}
                        accountingTransactions={values.accountingTransactions || []}
                        onChangeTimesheets={(timesheets) => setValues({ ...values, timesheets: (values.timesheets || []).concat(timesheets) })}
                        onChangeAccountingTransactions={(accountingTransactions) =>
                            setValues({ ...values, accountingTransactions: (values.accountingTransactions || []).concat(accountingTransactions) })
                        }
                        currencyCode={currencyCode}
                        period={period}
                    />
                )}
                {!!canBeCalculated && (
                    <Stack direction="row" spacing={2} alignItems="center">
                        <div>
                            <TextField
                                fullWidth
                                label="Jednotka"
                                margin="normal"
                                name="unit"
                                value={values.unit || ""}
                                onChange={handleChange}
                                disabled={disabled}
                                size="small"
                            />
                        </div>
                        <div>
                            <NumberField
                                fullWidth
                                label="Počet"
                                margin="normal"
                                name="pieces"
                                value={values.pieces || ""}
                                onChange={handleChange}
                                disabled={disabled}
                                size="small"
                                unit={values.unit}
                            />
                        </div>
                        <div>
                            <CurrencyField
                                fullWidth
                                label={`Cena${values.unit ? " za " + values.unit : ""}`}
                                margin="normal"
                                name="pricePerUnit"
                                value={values.pricePerUnit || ""}
                                onChange={handleChange}
                                disabled={disabled}
                                size="small"
                                currencyCode={currencyCode}
                            />
                        </div>
                    </Stack>
                )}
                <CurrencyField
                    fullWidth
                    label="Celkem"
                    margin="normal"
                    name="total"
                    value={values.total || ""}
                    onChange={handleChange}
                    disabled={disabled}
                    size="small"
                    currencyCode={currencyCode}
                    onCalculate={!!canBeCalculated && (() => setValues({ ...values, total: numbers.multiply(values.pieces, values.pricePerUnit, 2) }))}
                    calculateHint="Spočítat celkovou částku"
                />

                <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                    <IconButton onClick={() => onCancel && onCancel()} size="small">
                        <CloseIcon />
                    </IconButton>
                    <IconButton onClick={handleSubmit} size="small">
                        <CheckIcon />
                    </IconButton>
                </Stack>
            </Box>
        </>
    );
};

export const BillingItemsField = ({
    title,
    value,
    onChange,
    disabled,
    readOnly,
    currencyCode,
    projectId,
    employeeId,
    period,
    showReferences,
    canBeCalculated,
}) => {
    const showAlert = useAlert();
    const { saveRow, deleteRow, selectedRow, selectedRowData, addLastRow, editRow, hideRowForm } = useEditableList(value, onChange);

    return (
        <Section
            title={title || "Položky"}
            items={value}
            subtitleFn={(item) => item.name}
            px={2}
            actions={
                readOnly
                    ? null
                    : [
                          {
                              title: "Přidat položku",
                              icon: <AddIcon />,
                              onClick: () => addLastRow({}),
                          },
                      ]
            }
        >
            <TableContainer>
                {Array.isArray(value) &&
                    value.map((pm, key) => {
                        if (key === selectedRow) {
                            return (
                                <TableRowContainer key="rowForm" selected alignItems="flex-start">
                                    <BillingItemForm
                                        value={selectedRowData}
                                        onSubmit={(val) => saveRow(val, key, true)}
                                        onCancel={hideRowForm}
                                        projectId={projectId}
                                        employeeId={employeeId}
                                        currencyCode={currencyCode}
                                        period={period}
                                        canBeCalculated={canBeCalculated}
                                        showReferences={showReferences}
                                    />
                                </TableRowContainer>
                            );
                        }

                        return (
                            <TableRowContainer
                                key={key}
                                disabled={disabled}
                                actions={[
                                    {
                                        icon: <EditIcon />,
                                        title: "upravit",
                                        onClick: () => editRow(key, pm),
                                    },
                                    {
                                        icon: <DeleteIcon />,
                                        title: "Smazat",
                                        onClick: () =>
                                            showAlert("Smazat položku", `Chcete smazat položku ${pm.name} ?`, [
                                                { text: "Ne" },
                                                {
                                                    text: "Ano",
                                                    onPress: () => {
                                                        deleteRow(key);
                                                    },
                                                },
                                            ]),
                                    },
                                ]}
                            >
                                <Grid container columnSpacing={1}>
                                    <Grid item xs={12}>
                                        <Typography variant="body2">{pm.name}</Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="caption" color="textSecondary">
                                            <NumberValue value={pm.pieces} unit={pm.unit} />
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="caption" color="textSecondary">
                                            <CurrencyValue currencyCode={currencyCode} value={pm.pricePerUnit} />
                                            {pm.unit ? ` / ${pm.unit}` : null}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="body1" align="right" component="div">
                                            <CurrencyValue currencyCode={currencyCode} value={pm.total} />
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </TableRowContainer>
                        );
                    })}
                {selectedRow !== false && selectedRow >= ((value && value.length) || 0) && (
                    <TableRowContainer key="rowForm">
                        <BillingItemForm
                            value={selectedRowData}
                            onSubmit={(val) => saveRow(val, (value && value.length) || 0, true)}
                            onCancel={hideRowForm}
                            currencyCode={currencyCode}
                            projectId={projectId}
                            employeeId={employeeId}
                            period={period}
                            canBeCalculated={canBeCalculated}
                            showReferences={showReferences}
                        />
                    </TableRowContainer>
                )}
            </TableContainer>
        </Section>
    );
};

export const EmployeeBillingTypeFields = ({
    form,
    currencyCode,
    showHints,
    itemsBillingTypeKey = "itemsBillingType",
    pricePerHourKey = "pricePerHour",
    fixedPriceKey = "fixedPrice",
    disabled,
    hours,
}) => {
    const { values, setValues, handleChange } = form;
    return (
        <Box mb={2}>
            <RadioGroup value={values[itemsBillingTypeKey]} name={itemsBillingTypeKey} onChange={handleChange} disabled={disabled}>
                <FormControlLabel value="PRICE_PER_HOUR" control={<Radio />} label="Hodinová sazba" disabled={disabled} />
                <Collapse in={values[itemsBillingTypeKey] == "PRICE_PER_HOUR"}>
                    <Box mb={2}>
                        <CurrencyField
                            currencyCode={currencyCode}
                            fullWidth
                            unit="h"
                            size="small"
                            name={pricePerHourKey}
                            value={values[pricePerHourKey] || ""}
                            onChange={handleChange}
                            disabled={disabled}
                        />
                        {!!showHints && (
                            <FormHelperText>
                                Celková částka je <CurrencyValue currencyCode={currencyCode} value={numbers.multiply(values[pricePerHourKey], hours, 2)} />
                            </FormHelperText>
                        )}
                    </Box>
                </Collapse>
                <FormControlLabel value="FIXED_PRICE" control={<Radio />} label="Fixní částka" disabled={disabled} />
                <Collapse in={values[itemsBillingTypeKey] == "FIXED_PRICE"}>
                    <Box mb={2}>
                        <CurrencyField
                            currencyCode={currencyCode}
                            fullWidth
                            size="small"
                            name={fixedPriceKey}
                            value={values[fixedPriceKey] || ""}
                            onChange={handleChange}
                            disabled={disabled}
                        />
                        {!!showHints && (
                            <FormHelperText>
                                Průměrná sazba za <NumberValue value={hours || 0} unit="h" /> je{" "}
                                <CurrencyValue currencyCode={currencyCode} value={numbers.divide(values[fixedPriceKey], hours, 2) || 0} unit="h" />.
                            </FormHelperText>
                        )}
                    </Box>
                </Collapse>
            </RadioGroup>
        </Box>
    );
};

const calculateEmployeeBillingTotal = (values) => {
    let total = 0;

    return total;
};

export const EmployeeBillingTransactionFields = ({ form, currencyCode, showHints, size, disabled }) => {
    const { values, setValues, handleChange } = form;
    return (
        <Box mb={2}>
            <FormControl margin="normal">
                <FormControlLabel
                    control={
                        <Checkbox defaultChecked name="createAccountingTransaction" checked={!!values.createAccountingTransaction} onChange={handleChange} />
                    }
                    label="Vytvořit transakci"
                    disabled={disabled}
                />
            </FormControl>
            <Collapse in={!!values.createAccountingTransaction}>
                <Box mb={2}>
                    <TransactionTypeDropDown
                        label="Typ transakce"
                        size={size}
                        value={values.transactionTypeId}
                        onChange={handleChange}
                        name="transactionTypeId"
                        disabled={disabled}
                    />
                </Box>
            </Collapse>
            {/*<pre>{JSON.stringify({ ...values, selectedItems: Object.keys(values.selectedItems || []) }, null, 2)}</pre>*/}
        </Box>
    );
};

export const EmployeeBillingModal = ({ data, employeeId, from, to, periodType, open, onClose, onDataSaved, employeeName, period, ...props }) => {
    const { startLoading, stopLoading, isLoading } = useLoadingIndicator();
    const onSubmit = useMemo(
        () => async (values) => {
            startLoading();
            try {
                let selectedItems = values.selectedItems || {};
                values = await saveEmployeeTimesheetBilling(employeeId, { ...values, selectedItems: Object.keys(selectedItems) });
                onClose && onClose();
                onDataSaved && onDataSaved(values);
            } catch (e) {
                alert(e);
                console.error(e);
            } finally {
                stopLoading();
            }
        },
        [onClose, onDataSaved]
    );

    const showAlert = useAlert();
    const valueRef = createRef([]);
    const [timesheetModalOpen, setTimesheetModalOpen] = useState(false);
    const form = useForm({ onSubmit });
    const { values, setValues, handleChange, handleSubmit } = form;
    useEffect(() => setValues(data || {}), [data]);

    let hours = useMemo(() => {
        let hours = 0;
        if (values && Array.isArray(values.timesheets)) {
            values.timesheets.forEach((ti) => {
                hours = numbers.plus(hours, ti.hours, 2);
            });
        }
        console.log("calculating hours", hours, values && values.timesheets);
        return hours;
    }, [values && values.timesheets]);
    let timesheetSummary = `Vybráno ${hours} hodin`;

    return (
        <LoadingIndicatorContainer>
            <Modal
                open={open}
                onClose={onClose}
                title="Vyúčtování"
                size="big"
                buttons={[{ title: "Storno" }, { title: "Uložit", main: true, onClick: handleSubmit, disabled: isLoading }]}
            >
                <Box mb={4} px={2}>
                    <TextField
                        fullWidth
                        label="Název vyúčtování"
                        margin="normal"
                        name="name"
                        disabled={isLoading}
                        value={values.name || ""}
                        onChange={handleChange}
                    />

                    <DatePicker
                        fullWidth
                        label="Datum"
                        margin="normal"
                        name="billingDate"
                        disabled={isLoading}
                        value={values.billingDate || ""}
                        onChange={(val) => setValues({ ...values, billingDate: val })}
                    />
                </Box>

                <Box mb={4}>
                    <Section
                        title="Položky výkazu"
                        subtitle={timesheetSummary}
                        alwaysShowSubtitle
                        px={2}
                        actions={[
                            {
                                title: "Přidat položky výkazu",
                                icon: <AddIcon />,
                                onClick: () => setTimesheetModalOpen(true),
                            },
                        ]}
                    >
                        <TableContainer>
                            {/* timesheets -> timesheetItem */}
                            {Array.isArray(values.timesheets) &&
                                values.timesheets.map((t, key) => (
                                    <ListItem
                                        key={`${t && t.id}-${key}`}
                                        divider
                                        secondaryAction={
                                            <IconButton
                                                edge="end"
                                                onClick={() =>
                                                    showAlert("Odebrat položku", `Chcete z vyúčtování odebrat položku výkazu?`, [
                                                        { text: "Ne" },
                                                        {
                                                            text: "Ano",
                                                            onPress: () => {
                                                                setValues({ ...values, timesheets: values.timesheets.filter((x, idx) => idx !== key) });
                                                            },
                                                        },
                                                    ])
                                                }
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        }
                                    >
                                        <TimesheetItemRow showProject data={t} />
                                    </ListItem>
                                ))}
                        </TableContainer>
                    </Section>
                    <Box my={2} px={3}>
                        <EmployeeBillingTypeFields
                            form={form}
                            currencyCode={values.currencyCode}
                            itemsBillingTypeKey="billingType"
                            disabled={isLoading}
                            showHints
                            hours={hours}
                        />
                    </Box>
                    <BillingItemsField
                        title="Ostatní"
                        value={values.items}
                        onChange={(items) => setValues({ ...values, items })}
                        disabled={isLoading}
                        currencyCode={values.currencyCode}
                        period={period}
                    />
                </Box>

                <TimesheetsSelector
                    open={timesheetModalOpen}
                    handleClose={() => setTimesheetModalOpen(false)}
                    employeeId={employeeId}
                    timesheets={values.timesheets || []}
                    onAdd={(items) => {
                        setValues({ ...values, timesheets: [...(values.timesheets || []), ...(items || [])] });
                    }}
                    period={period}
                    renderSelector={(props) => <EmployeeUnbilledTimesheetsSelectorField {...props} />}
                />
            </Modal>
        </LoadingIndicatorContainer>
    );
};

const BillingForm = ({ value, disabled, projectId, employeeId, form, period, ...props }) => {
    const { setValues, values, handleChange } = form;

    return (
        <>
            <Box mb={4} px={2}>
                <TextField
                    fullWidth
                    label="Název vyúčtování"
                    margin="normal"
                    name="name"
                    value={values.name || ""}
                    onChange={handleChange}
                    disabled={disabled}
                />

                <DatePicker
                    fullWidth
                    label="Datum"
                    margin="normal"
                    name="billingDate"
                    value={values.billingDate || ""}
                    onChange={(val) => setValues({ ...values, billingDate: val })}
                    disabled={disabled}
                />
            </Box>
            <BillingItemsField
                value={values.items}
                onChange={(items) => setValues({ ...values, items })}
                disabled={disabled}
                projectId={projectId}
                employeeId={employeeId}
                currencyCode={values.currencyCode}
                period={period}
                showReferences
                canBeCalculated
            />
        </>
    );
};

export const ProjectBillingModal = ({ data, projectId, from, to, periodType, open, onClose, onDataSaved, projectName, period, ...props }) => {
    const { startLoading, stopLoading, isLoading } = useLoadingIndicator();
    const onSubmit = useMemo(
        () => async (values) => {
            startLoading();
            try {
                let billing = { ...values, projectId };
                values = await saveBilling(billing);
                onClose && onClose();
                onDataSaved && onDataSaved(values);
            } catch (e) {
                alert(e);
                console.error(e);
            } finally {
                stopLoading();
            }
        },
        [onClose, onDataSaved, projectId]
    );
    const form = useForm({ onSubmit });
    const { values, handleChange, errors, setErrors, handleSubmit, setValues, valuesRef } = form;

    useEffect(() => setValues(data || {}), [data]);
    const [state, setState] = useState({ values: {}, items: [] });

    return (
        <LoadingIndicatorContainer>
            <Modal
                open={open}
                onClose={onClose}
                title="Vyúčtování"
                size="big"
                buttons={[{ title: "Storno" }, { title: "Uložit", main: true, onClick: handleSubmit, disabled: isLoading }]}
            >
                <BillingForm form={form} disabled={isLoading} onSubmit={onSubmit} projectId={projectId} period={period} />
            </Modal>
        </LoadingIndicatorContainer>
    );
};

const BillingRowDetail = ({ data, disabled, readOnly, renderTransactionButton, ...props }) => {
    let pieces = 0,
        pricePerUnit = 0,
        unit = "";

    return (
        <div>
            <Box mb={2}>{renderTransactionButton ? renderTransactionButton(data) : null}</Box>
            <Box mb={2}>
                <MuiTableContainer component={Paper}>
                    <MuiTable size="small">
                        <MuiTableHead>
                            <MuiTableRow>
                                <MuiTableCell>Položka</MuiTableCell>
                                <MuiTableCell align="right">Jednotková cena</MuiTableCell>
                                <MuiTableCell align="right">Počet</MuiTableCell>
                                <MuiTableCell align="right">Celkem</MuiTableCell>
                                <MuiTableCell align="right">Zisk</MuiTableCell>
                            </MuiTableRow>
                        </MuiTableHead>
                        <MuiTableBody>
                            {data.items.map((row) => (
                                <MuiTableRow key={row.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                    <MuiTableCell>
                                        <ExpandButton />
                                        {row.name}
                                    </MuiTableCell>
                                    <MuiTableCell align="right">
                                        <CurrencyValue value={row.pricePerUnit} currencyCode={data.currencyCode} />
                                    </MuiTableCell>
                                    <MuiTableCell align="right">
                                        <NumberValue value={row.pieces} unit={row.unit} />
                                    </MuiTableCell>
                                    <MuiTableCell align="right">
                                        <CurrencyValue value={row.total} currencyCode={data.currencyCode} />
                                    </MuiTableCell>
                                    <MuiTableCell align="right">
                                        <CurrencyValue value={row.profit} currencyCode={data.currencyCode} />
                                    </MuiTableCell>
                                </MuiTableRow>
                            ))}
                        </MuiTableBody>
                    </MuiTable>
                </MuiTableContainer>
            </Box>
        </div>
    );
};

const EmployeeBillingRowDetail = ({ data, disabled, onTransactionClick, transactionTypes, onTransactionTypeSelected }) => {
    const [state, setState] = useState({});
    useEffect(() => {
        setState({});
    }, [data]);

    if (!data) {
        data = {};
    }

    return (
        <Box mt={2}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={4}>
                {!!data.accountingTransactionId ? (
                    <>
                        <Typography variant="body">
                            Transakce:{" "}
                            <Link onClick={onTransactionClick ? () => onTransactionClick(data.accountingTransactionId) : null}>
                                {data.accountingTransactionName} ({data.accountingTransactionTypeName})
                            </Link>
                        </Typography>
                    </>
                ) : (
                    <span>
                        <Typography variant="body" color="textSecondary">
                            Není přiřazena žádná transakce.
                        </Typography>{" "}
                        <TransactionTypeLink
                            types={transactionTypes}
                            onTypeSelected={onTransactionTypeSelected ? (type) => onTransactionTypeSelected(type, data) : null}
                            variant="body"
                        >
                            Přidat transakci...
                        </TransactionTypeLink>
                    </span>
                )}
                <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
                    <IconButton onClick={console.log} disabled={true || !!disabled} size="small" title="Stáhnout excel">
                        <FileDownloadIcon />
                    </IconButton>
                </Stack>
            </Stack>

            <Box my={2}>
                <Grid container spacing={4}>
                    <Grid item md>
                        <Value label="Počet hodin" value={<NumberValue value={data.hours || 0} unit="h" />} />
                    </Grid>
                    <Grid item md>
                        <Value label="Způsob účtování" value={data.billingType == "PRICE_PER_HOUR" ? "Hodinová sazba" : "Fixní částka"} />
                    </Grid>
                    {data.billingType == "PRICE_PER_HOUR" ? (
                        <Grid item md>
                            <Value
                                label="Hodinová sazba"
                                value={<CurrencyValue value={data.pricePerHour || 0} currencyCode={data.currencyCode} />}
                                align="right"
                            />
                        </Grid>
                    ) : (
                        <Grid item md>
                            <Value label="Fixní částka" value={<CurrencyValue value={data.fixedPrice || 0} currencyCode={data.currencyCode} />} align="right" />
                        </Grid>
                    )}
                </Grid>
            </Box>

            {Array.isArray(data.timesheets) && data.timesheets.length ? (
                <Box my={4}>
                    <Typography variant="subtitle2" gutterBottom>
                        <ExpandButton open={state.timesheetsOpen} onOpenStateChange={() => setState({ ...state, timesheetsOpen: !state.timesheetsOpen })} />
                        Položky výkazu
                    </Typography>
                    <Collapse in={!!state.timesheetsOpen}>
                        <Box my={2}>
                            <MuiTableContainer component={Paper}>
                                <MuiTable size="small">
                                    <MuiTableHead>
                                        <MuiTableRow>
                                            <MuiTableCell align="center">Datum</MuiTableCell>
                                            <MuiTableCell>Projekt</MuiTableCell>
                                            <MuiTableCell align="right">Počet hodin</MuiTableCell>
                                        </MuiTableRow>
                                    </MuiTableHead>
                                    <MuiTableBody>
                                        {data.timesheets.map((row) => (
                                            <MuiTableRow key={row.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                                <MuiTableCell align="center">
                                                    <DateValue value={row.date} />
                                                </MuiTableCell>
                                                <MuiTableCell>{row.projectName}</MuiTableCell>
                                                <MuiTableCell align="right">
                                                    <NumberValue value={row.hours} unit="h" />
                                                </MuiTableCell>
                                            </MuiTableRow>
                                        ))}
                                    </MuiTableBody>
                                </MuiTable>
                            </MuiTableContainer>
                        </Box>
                    </Collapse>
                </Box>
            ) : null}

            {Array.isArray(data.items) && data.items.length ? (
                <Box my={4}>
                    <Typography variant="subtitle2" gutterBottom>
                        <ExpandButton open={state.itemsOpen} onOpenStateChange={() => setState({ ...state, itemsOpen: !state.itemsOpen })} /> Ostatní položky
                    </Typography>
                    <Collapse in={!!state.itemsOpen}>
                        <Box my={2}>
                            <MuiTableContainer component={Paper}>
                                <MuiTable size="small">
                                    <MuiTableHead>
                                        <MuiTableRow>
                                            <MuiTableCell>Název</MuiTableCell>
                                            <MuiTableCell align="right">Celkem</MuiTableCell>
                                        </MuiTableRow>
                                    </MuiTableHead>
                                    <MuiTableBody>
                                        {data.items.map((row) => (
                                            <MuiTableRow key={row.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                                <MuiTableCell>{row.name}</MuiTableCell>
                                                <MuiTableCell align="right">
                                                    <CurrencyValue value={row.total} currencyCode={data.currencyCode} />
                                                </MuiTableCell>
                                            </MuiTableRow>
                                        ))}
                                    </MuiTableBody>
                                </MuiTable>
                            </MuiTableContainer>
                        </Box>
                    </Collapse>
                </Box>
            ) : null}
        </Box>
    );
};

export const EmployeeBillingsList = ({ billings, disabled, readOnly, onEdit, onTransactionClick, onTransactionTypeSelected }) => {
    const transactionTypes = useTransactionTypes({ isExpense: true });
    const [openState, setOpen] = useState({});
    useEffect(() => setOpen({}), [billings]);

    return (
        <>
            {billings &&
                Array.isArray(billings.content) &&
                billings.content.map((d) => (
                    <DetailRow
                        key={d.id}
                        open={openState[d.id]}
                        setOpen={(open) => setOpen({ ...openState, [d.id]: open })}
                        renderDetail={() => (
                            <EmployeeBillingRowDetail
                                data={d}
                                disabled={disabled}
                                onTransactionClick={onTransactionClick}
                                transactionTypes={transactionTypes}
                                onTransactionTypeSelected={onTransactionTypeSelected}
                            />
                        )}
                    >
                        <FlexRow>
                            <FlexItem relativeWidth={3}>
                                <div>
                                    <Typography variant="body1" component="div">
                                        {d.name}
                                    </Typography>
                                    <Typography variant="caption" component="div" color="textSecondary">
                                        <DateValue value={d.billingDate} />
                                    </Typography>
                                </div>
                            </FlexItem>
                            <FlexItem right>
                                <Typography variant="body1">
                                    <b>
                                        <CurrencyValue currencyCode={d.currencyCode} value={d.total || 0} />
                                    </b>
                                </Typography>

                                <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
                                    {!readOnly && !!onEdit && (
                                        <IconButton onClick={(e) => onEdit(d)} disabled={!!disabled} size="small">
                                            <EditIcon />
                                        </IconButton>
                                    )}
                                </Stack>
                            </FlexItem>
                        </FlexRow>
                    </DetailRow>
                ))}
        </>
    );
};

const ProjectBillingItemRow = ({ row, currencyCode, open, setOpen }) => {
    return (
        <>
            <MuiTableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <MuiTableCell>
                    <ExpandButton open={open} onOpenStateChange={setOpen} />
                    {row.name}
                </MuiTableCell>
                <MuiTableCell align="right">
                    <CurrencyValue value={row.pricePerUnit} currencyCode={currencyCode} />
                </MuiTableCell>
                <MuiTableCell align="right">
                    <NumberValue value={row.pieces} unit={row.unit} />
                </MuiTableCell>
                <MuiTableCell align="right">
                    <CurrencyValue value={row.total} currencyCode={currencyCode} />
                </MuiTableCell>
                <MuiTableCell align="right">
                    <CurrencyValue value={row.profit} currencyCode={currencyCode} />
                </MuiTableCell>
            </MuiTableRow>
            <MuiTableRow sx={{ border: 0 }}>
                <MuiTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
                    <Collapse in={!!open} unmountOnExit>
                        <Box p={2}>
                            {Array.isArray(row.timesheets) && !!row.timesheets.length && (
                                <Box my={2}>
                                    <MuiTableContainer component={Paper}>
                                        <MuiTable size="small">
                                            <MuiTableHead>
                                                <MuiTableRow>
                                                    <MuiTableCell align="center">Datum</MuiTableCell>
                                                    <MuiTableCell>Zaměstnanec</MuiTableCell>
                                                    <MuiTableCell align="right">Počet hodin</MuiTableCell>
                                                </MuiTableRow>
                                            </MuiTableHead>
                                            <MuiTableBody>
                                                {row.timesheets.map((row) => (
                                                    <MuiTableRow key={row.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                                        <MuiTableCell align="center">
                                                            <DateValue value={row.date} />
                                                        </MuiTableCell>
                                                        <MuiTableCell>{row.employeeName}</MuiTableCell>
                                                        <MuiTableCell align="right">
                                                            <NumberValue value={row.hours} unit="h" />
                                                        </MuiTableCell>
                                                    </MuiTableRow>
                                                ))}
                                            </MuiTableBody>
                                        </MuiTable>
                                    </MuiTableContainer>
                                </Box>
                            )}
                            {Array.isArray(row.accountingTransactions) && !!row.accountingTransactions.length && (
                                <Box my={2}>
                                    <MuiTableContainer component={Paper}>
                                        <MuiTable size="small">
                                            <MuiTableHead>
                                                <MuiTableRow>
                                                    <MuiTableCell align="center">Datum</MuiTableCell>
                                                    <MuiTableCell>Název</MuiTableCell>
                                                    <MuiTableCell align="right">Částka</MuiTableCell>
                                                </MuiTableRow>
                                            </MuiTableHead>
                                            <MuiTableBody>
                                                {row.accountingTransactions.map((row) => (
                                                    <MuiTableRow key={row.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                                        <MuiTableCell align="center">
                                                            <DateValue value={row.date} />
                                                        </MuiTableCell>
                                                        <MuiTableCell>{row.name}</MuiTableCell>
                                                        <MuiTableCell align="right">
                                                            <CurrencyValue value={row.total} currencyCode={currencyCode} />
                                                        </MuiTableCell>
                                                    </MuiTableRow>
                                                ))}
                                            </MuiTableBody>
                                        </MuiTable>
                                    </MuiTableContainer>
                                </Box>
                            )}
                        </Box>
                    </Collapse>
                </MuiTableCell>
            </MuiTableRow>
        </>
    );
};

const ProjectBillingRowDetail = ({ data, disabled, onTransactionClick, onTransactionExport, transactionTypes, onTransactionTypeSelected }) => {
    const [state, setState] = useState({});
    useEffect(() => {
        setState({});
    }, [data]);

    if (!data) {
        data = {};
    }

    return (
        <Box mt={2}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={4}>
                {!!data.accountingTransactionId ? (
                    <>
                        <Typography variant="body">
                            Transakce:{" "}
                            <Link onClick={onTransactionClick ? () => onTransactionClick(data.accountingTransactionId) : null}>
                                {data.accountingTransactionName} ({data.accountingTransactionTypeName})
                            </Link>
                        </Typography>
                    </>
                ) : (
                    <span>
                        <Typography variant="body" color="textSecondary">
                            Není přiřazena žádná transakce.
                        </Typography>{" "}
                        <TransactionTypeLink types={transactionTypes} onTypeSelected={onTransactionTypeSelected} variant="body">
                            Přidat transakci...
                        </TransactionTypeLink>
                    </span>
                )}
                <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
                    {!!data.accountingTransactionPrintable && !!onTransactionExport && (
                        <IconButton
                            onClick={() => onTransactionExport(data.accountingTransactionId)}
                            disabled={!!disabled}
                            size="small"
                            title="Exportovat transakci"
                        >
                            <PrintIcon />
                        </IconButton>
                    )}
                    <IconButton onClick={console.log} disabled={true || !!disabled} size="small" title="Stáhnout excel">
                        <FileDownloadIcon />
                    </IconButton>
                </Stack>
            </Stack>

            <Box my={2}>
                <Grid container spacing={4}>
                    <Grid item md>
                        <Value label="Datum" value={<DateValue value={data.billingDate} />} />
                    </Grid>
                    <Grid item md>
                        <Value label="Zisk" value={<CurrencyValue value={data.profit || 0} currencyCode={data.currencyCode} />} align="right" />
                    </Grid>
                </Grid>
            </Box>

            {Array.isArray(data.items) && data.items.length ? (
                <Box my={4}>
                    <MuiTableContainer component={Paper}>
                        <MuiTable size="small">
                            <MuiTableHead>
                                <MuiTableRow>
                                    <MuiTableCell>Položka</MuiTableCell>
                                    <MuiTableCell align="right">Jednotková cena</MuiTableCell>
                                    <MuiTableCell align="right">Počet</MuiTableCell>
                                    <MuiTableCell align="right">Celkem</MuiTableCell>
                                    <MuiTableCell align="right">Zisk</MuiTableCell>{" "}
                                </MuiTableRow>
                            </MuiTableHead>
                            <MuiTableBody>
                                {data.items.map((row) => (
                                    <ProjectBillingItemRow
                                        key={row.id}
                                        row={row}
                                        currencyCode={data.currencyCode}
                                        open={state[row.id]}
                                        setOpen={() => setState({ ...state, [row.id]: !state[row.id] })}
                                    />
                                ))}
                            </MuiTableBody>
                        </MuiTable>
                    </MuiTableContainer>
                </Box>
            ) : null}
        </Box>
    );
};

export const ProjectBillingsList = ({ billings, disabled, readOnly, onEdit, onTransactionTypeSelected, onTransactionClick, onTransactionExport }) => {
    const { startLoading, stopLoading } = useLoadingIndicator();
    const transactionTypes = useTransactionTypes({ hasProjectReference: true, isIncome: true });
    const [openState, setOpen] = useState({});
    useEffect(() => setOpen({}), [billings]);

    return (
        <>
            {billings &&
                Array.isArray(billings.content) &&
                billings.content.map((d) => (
                    <DetailRow
                        key={d.id}
                        open={openState[d.id]}
                        setOpen={(open) => setOpen({ ...openState, [d.id]: open })}
                        renderDetail={() => (
                            <ProjectBillingRowDetail
                                data={d}
                                disabled={disabled}
                                readOnly={readOnly}
                                onTransactionClick={onTransactionClick}
                                transactionTypes={transactionTypes}
                                onTransactionTypeSelected={onTransactionTypeSelected ? (type) => onTransactionTypeSelected(type, d) : null}
                                onTransactionExport={async (id) => {
                                    try {
                                        startLoading();
                                        await fetchTransactionPdf(id);
                                    } catch (e) {
                                        alert("error");
                                        console.error(e);
                                    } finally {
                                        stopLoading();
                                    }
                                }}
                            />
                        )}
                    >
                        <FlexRow>
                            <FlexItem relativeWidth={3}>
                                <div>
                                    <Typography variant="body1" component="div">
                                        {d.name}
                                    </Typography>
                                    <Typography variant="caption" component="div" color="textSecondary">
                                        <DateValue value={d.billingDate} />
                                    </Typography>
                                </div>
                            </FlexItem>
                            <FlexItem right>
                                <Typography variant="body1">
                                    <b>
                                        <CurrencyValue currencyCode={d.currencyCode} value={d.total || 0} />
                                    </b>
                                </Typography>

                                <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
                                    {!readOnly && !!onEdit && (
                                        <IconButton onClick={(e) => onEdit(d)} disabled={!!disabled} size="small">
                                            <EditIcon />
                                        </IconButton>
                                    )}
                                </Stack>
                            </FlexItem>
                        </FlexRow>
                    </DetailRow>
                ))}
        </>
    );
};

const useBillingIndicatorStyles = makeStyles((theme) => ({}));
export const BillingIndicatorSmall = ({}) => {
    const classes = useBillingIndicatorStyles();
    return <div></div>;
};

/* ******** API ******** */

export const saveEmployeeTimesheetBilling = async (employeeId, data) => {
    let response = await backend(`/api/employees/${employeeId}/billings${data.id ? "/" + data.id : ""}`, data.id ? "PUT" : "POST", { body: data });
    return response.body;
};

export const saveProjectBilling = async (projectId, data) => {
    let response = await backend(`/api/projects/${projectId}/billings`, "POST", { body: data });
    return response.body;
};

export const fetchEmployeeBillings = async (employeeId, from, to, paging) => {
    let response = await backend(`/api/employees/${employeeId}/billings`, "GET", { params: { from, to, ...(paging || {}) } });
    return response.body;
};

export const fetchProjectBillings = async (projectId, from, to, paging) => {
    let response = await backend(`/api/billings/project/${projectId}`, "GET", { params: { from, to, ...(paging || {}) } });
    return response.body;
};

export const fetchEmployeeUnbilledTimesheets = async (employeeId) => {
    let response = await backend(`/api/employees/${employeeId}/timesheets/unbilled`);
    return response.body;
};

export const fetchProjectUnbilledTransactions = async (projectId) => {
    let response = await backend(`/api/projects/${projectId}/transactions/unbilled`);
    return response.body;
};

export const fetchProjectUnbilledTimesheets = async (projectId) => {
    let response = await backend(`/api/projects/${projectId}/timesheets/unbilled`);
    return response.body;
};

export const saveBilling = async (billing) => {
    let response = await backend(`/api/billings` + (billing.id ? "/" + billing.id : ""), billing.id ? "PUT" : "POST", {
        body: billing,
    });
    return response.body;
};

export const createProjectBillingTransaction = async (projectId, billingId, accountingTransactionTypeId) => {
    let response = await backend(`/api/projects/${projectId}/billings/${billingId}/transactions/billing`, "PUT", { params: { accountingTransactionTypeId } });
    return response.body;
};

export const prepareProjectBillingTransaction = async (projectId, billingId, typeId) => {
    let response = await backend(`/api/projects/${projectId}/billings/${billingId}/transactions/type/${typeId}`);
    return response.body;
};

export const prepareEmployeeBillingTransaction = async (employeeId, billingId, typeId) => {
    let response = await backend(`/api/employees/${employeeId}/billings/${billingId}/transactions/type/${typeId}`);
    return response.body;
};

export const fetchEmployeeBillingsSummary = async (employeeId, from, to) => {
    let response = await backend(`/api/employees/${employeeId}/billings/summary`, "GET", { params: { from, to } });
    return response.body;
};

export const fetchEmployeeBillingsTrends = async (employeeId, date, periodType) => {
    let response = await backend(`/api/employees/${employeeId}/billings/trends`, "GET", { params: { date, periodType } });
    return response.body;
};

export const fetchProjectBillingsSummary = async (projectId, from, to) => {
    let response = await backend(`/api/projects/${projectId}/billings/summary`, "GET", { params: { from, to } });
    return response.body;
};

export const fetchProjectBillingsTrends = async (projectId, date, periodType) => {
    let response = await backend(`/api/projects/${projectId}/billings/trends`, "GET", { params: { date, periodType } });
    return response.body;
};
