import React, {useEffect, useMemo} from "react";

import {makeStyles} from "@mui/styles";
import Stack from "@mui/material/Stack";

import {useForm} from "../utils/form";
import {debounce} from "lodash";

export const useInlineFilter = (filter, onFilterChange) => {
    const form = useForm();
    const { values, setValues } = form;
    useEffect(() => setValues({ ...(filter || {}) }), [filter]);

    const fetch = useMemo(
        () =>
            debounce(
                (filter) => {
                    try {
                        onFilterChange && onFilterChange(filter);
                    } catch (e) {
                        console.error(e);
                    }
                },
                1000,
                { leading: false, trailing: true }
            ),
        [onFilterChange]
    );

    const handleFilterChange = (key, value) => {
        let vals = { ...values, [key]: value };
        setValues(vals);
        fetch(vals);
    };

    return { values, handleFilterChange };
};

const useInlineFilterClasses = makeStyles((theme) => ({
    inlineFilterRoot: {
        flexWrap: "wrap",
        "& > *": { flex: "1 1", minWidth: "25%" },
    },
}));

export const InlineFilter = ({ children }) => {
    const classes = useInlineFilterClasses();
    return (
        <Stack direction="row" spacing={2} alignItems="center" className={classes.inlineFilterRoot}>
            {children}
        </Stack>
    );
};
