import {useCallback, useRef, useState} from "react";
import {useLoadingIndicator} from "./loading";

const enrichErrors = (errors) => {
    if (!errors || !Object.keys(errors).length) {
        return {
            __: { isEmpty: () => true },
        };
    }

    errors.__ = { isEmpty: () => false };
    //TODO construct error tree
    return errors;
};

export const useForm = ({ validate, onSubmit, initialValues, parseErrors } = {}) => {
    const [values, setValues] = useState({});
    const [errors, setErrors] = useState({});
    const [isSubmitting, setSubmitting] = useState(false);
    const { startLoading, stopLoading } = useLoadingIndicator();
    const valuesRef = useRef({});

    /*
    useEffect(() => {
        setErrors({});
        setValues(initialValues || {});
    }, [initialValues]);
    */

    const handleSubmit = useCallback(
        async (values, submitHints) => {
            setSubmitting(true);
            let errors = {};

            typeof validate === "function" && validate(values, errors);

            errors = enrichErrors(errors);
            if (!errors.__.isEmpty()) {
                setSubmitting(false);
                return;
            }

            if (onSubmit) {
                try {
                    startLoading();
                    await onSubmit(values, submitHints || {}, { setErrors });
                } catch (e) {
                    if (!!parseErrors) {
                        errors = parseErrors(e);
                    } else if (!!e.payload) {
                        errors = e.payload;
                    } else {
                        errors = { "": e.message };
                    }
                    setErrors(enrichErrors(errors));
                } finally {
                    stopLoading();
                    setSubmitting(false);
                }
            }
        },
        [setErrors, onSubmit, startLoading, stopLoading, setSubmitting, parseErrors, validate]
    );

    const handleChange = useCallback(
        (e) => {
            let isCheckbox = e.target.type === "checkbox";
            let vals = { ...values, [e.target.name]: isCheckbox ? e.target.checked : e.target.value };
            valuesRef.current = vals;
            setValues(vals);
        },
        [setValues, valuesRef, values]
    );

    return {
        values,
        setValues: (values, reset) => {
            valuesRef.current = values;
            !!reset && setErrors({});
            setValues(values);
        },
        handleChange,
        errors,
        setErrors,
        handleSubmit: (e, submitHints) => {
            e.preventDefault();
            e.stopPropagation();
            handleSubmit(values, submitHints || {});
        },
        isSubmitting,
        valuesRef,
    };
};
