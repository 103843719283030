//TODO implement properly
export const parseErrors = (e) => {
    if (!!e.offline) {
        return {
            "": "Nelze se připojit na server",
        };
    }

    if (e.statusCode === 400 && e.payload) {
        //TODO
        return e.payload;
    }

    return {
        "": "Nastala neočekávaná chyba",
    };
};
