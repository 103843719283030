import React, {useEffect, useState} from "react";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";

import {
    MasterContent,
    MasterDetail,
    MasterDetailContent,
    useMasterDetail,
    useMasterDetailParams
} from "../components/MasterDetail";
import {Page, PageTitle} from "../components/Layout";

import {useSettings} from "../utils/settings";

import {AccountingTransactionReportsContainer, fetchAccountingTransactionReports,} from "../components/transactions";

import {LoadingIndicator, useLoadingIndicator} from "../utils/loading";

import {useModal} from "../components/Modal";

import backend from "../utils/backend";

const avatar = (name) => {
    return name && name.length && name.substring(0, Math.min(2, name.length)).toUpperCase();
};

const FeaturesItem = ({ title, id, selectedId, showDetail }) => {
    return (
        <ListItem button divider onClick={() => showDetail(id)} selected={selectedId == id}>
            <ListItemAvatar>
                <Avatar>{avatar(title)}</Avatar>
            </ListItemAvatar>
            <ListItemText primary={title} />
        </ListItem>
    );
};

const FeaturesMasterContainer = ({ menuItems }) => {
    const { showDetail, showOverview, showAdd, mobile, refreshMaster } = useMasterDetail();
    const params = useMasterDetailParams();
    const selectedId = params.tabName;
    const [state, setState] = useState({});
    const settings = useSettings();
    const modal = useModal();
    const { startLoading, stopLoading, isLoading } = useLoadingIndicator();

    //remember context menu items
    const masterMenuItems = []; //useMemo(() => [{ icon: <DashboardIcon />, title: "Přehled", onClick: showOverview }], [showFilter]);

    const fetchData = async () => {
        startLoading();
        let result = await Promise.allSettled([
            settings.features && !!settings.features.accountingTransactionReports ? fetchAccountingTransactionReports() : Promise.resolve([]),
        ]);
        let accountingTransactionReports = [];
        let dataResult = result[0];
        if ((dataResult.status = "fulfilled")) {
            accountingTransactionReports = dataResult.value;
        }
        stopLoading();
        setState({ ...state, accountingTransactionReports });
    };

    useEffect(() => {
        fetchData();
    }, [settings]);

    return (
        <Page>
            <LoadingIndicator hidden="smDown" />
            <PageTitle title="Rozšíření" menuItems={mobile ? [...masterMenuItems, ...menuItems] : masterMenuItems} />
            <LoadingIndicator hidden="smUp" />
            <MasterContent>
                <List>
                    {settings.features &&
                        !!settings.features.accountingTransactionReports &&
                        Array.isArray(state.accountingTransactionReports) &&
                        state.accountingTransactionReports.map((report) => (
                            <FeaturesItem key={report.id} title={report.name} id={`atreport/${report.id}`} showDetail={showDetail} selectedId={selectedId} />
                        ))}

                    {!!settings.features && !!settings.features.epoDph && (
                        <FeaturesItem title="EPO - DPH" id="epodph" showDetail={showDetail} selectedId={selectedId} />
                    )}
                    {!!settings.features && !!settings.features.epoDp && (
                        <FeaturesItem title="EPO - Daň" id="epodp" showDetail={showDetail} selectedId={selectedId} />
                    )}
                </List>
            </MasterContent>
        </Page>
    );
};

const FeaturesPage = ({ menuItems, title, children }) => {
    const { hideDetail, refreshMaster } = useMasterDetail();
    return (
        <Page>
            <LoadingIndicator hidden="smDown" />
            <PageTitle title={title} menuItems={menuItems} onBack={hideDetail}></PageTitle>
            <LoadingIndicator hidden="smUp" />
            <MasterDetailContent>{children}</MasterDetailContent>
        </Page>
    );
};

/*
const CompanyFormFields = ({ form, disabled }) => {
    const { values = {}, handleChange, setValues } = form || {};
    return (
        <>
            <Box mb={4} px={2}>
                <TextField fullWidth label="Název" margin="normal" value={values.name || ""} onChange={handleChange} name="name" disabled={disabled} />

                <Stack direction="row" spacing={4}>
                    <div style={{ flex: "1 1 auto" }}>
                        <TextField
                            fullWidth
                            label="IČ"
                            margin="normal"
                            name="idNumber"
                            value={values.idNumber || ""}
                            onChange={handleChange}
                            disabled={disabled}
                        />
                    </div>
                    <div style={{ flex: "1 1 auto" }}>
                        <TextField
                            fullWidth
                            label="DIČ"
                            margin="normal"
                            name="taxIdNumber"
                            value={values.taxIdNumber || ""}
                            onChange={handleChange}
                            disabled={disabled}
                        />
                    </div>
                </Stack>
                <TextField
                    fullWidth
                    label="Adresa"
                    margin="normal"
                    name="address"
                    value={values.address || ""}
                    onChange={handleChange}
                    disabled={disabled}
                    multiline
                    rows={4}
                />
            </Box>
            <Box mb={4} px={2}>
                <Switch
                    title="Evidovat daň na transakcích"
                    name="transactionsTaxSupport"
                    value={values.transactionsTaxSupport}
                    onChange={handleChange}
                    disabled={disabled}
                    variant="body1"
                />
            </Box>
        </>
    );
};

const CompanyModal = ({ data, open, onClose, onDataSaved, ...props }) => {
    const { startLoading, stopLoading, isLoading } = useLoadingIndicator();

    const onSubmit = async (values) => {
        startLoading();
        try {
            await saveCompanyInfo(values);
            onClose && onClose();
            onDataSaved && onDataSaved(values);
        } catch (e) {
            alert(e);
            console.error(e);
        } finally {
            stopLoading();
        }
    };

    const form = useForm({ onSubmit });

    const { values, handleChange, errors, setErrors, handleSubmit, setValues, valuesRef } = form;

    useEffect(() => setValues(data || {}), [data]);

    return (
        <Modal
            open={open}
            onClose={onClose}
            title="Společnost"
            size="big"
            buttons={[{ title: "Storno" }, { title: "Uložit", main: true, onClick: handleSubmit, disabled: isLoading }]}
        >
            <CompanyFormFields form={form} disabled={isLoading} />
        </Modal>
    );
};

const CompanyContainer = ({ menuItems }) => {
    const theme = useTheme();
    const settings = useSettings();
    const changeSettings = useChangeSettings();

    const modal = useModal();
    const { startLoading, stopLoading, isLoading } = useLoadingIndicator();

    return (
        <SettingsPage
            title="Společnost"
            menuItems={[
                {
                    alwaysVisible: true,
                    title: "Upravit",
                    icon: <EditIcon />,
                    onClick: () => modal.showModal({ ...settings }),
                },
                ,
                ...menuItems,
            ]}
        >
            <Box px={2}>
                <SettingsPanel title={settings.name}>
                    <Stack
                        direction={{ xs: "column", sm: "row" }}
                        spacing={4}
                        divider={<Divider orientation={useMediaQuery(theme.breakpoints.down("md")) ? "horizontal" : "vertical"} flexItem={true} />}
                    >
                        <div style={{ flex: "1 1 auto" }}>
                            <Typography variant="body1" component="div" sx={{ whiteSpace: "pre" }}>
                                {settings.address}
                            </Typography>
                            {!!settings.idNumber && (
                                <Typography variant="body1" component="div">
                                    IČ: {settings.idNumber}
                                </Typography>
                            )}
                            {!!settings.taxIdNumber && (
                                <Typography variant="body1" component="div">
                                    DIČ: {settings.taxIdNumber}
                                </Typography>
                            )}
                        </div>
                        <div style={{ flex: "1 1 auto" }}>
                            <pre>{JSON.stringify(settings, null, 4)}</pre>
                        </div>
                    </Stack>
                </SettingsPanel>
            </Box>
            <CompanyModal data={modal.data} open={modal.open} onClose={modal.closeModal} onDataSaved={changeSettings} />
        </SettingsPage>
    );
};
*/
/* ******** routes ******** */

const FeaturesDetailContainer = ({ menuItems }) => {
    const { detailId, tabName } = useMasterDetailParams();

    switch (detailId) {
        case "atreport":
            return <AccountingTransactionReportsContainer menuItems={menuItems} reportId={tabName} />;
        default:
        //nothing
    }

    return <></>;
};

export default ({ menuItems }) => {
    return (
        <MasterDetail
            masterElement={<FeaturesMasterContainer menuItems={menuItems} />}
            detailElement={<FeaturesDetailContainer menuItems={menuItems} />}
            titleMenuItems={menuItems}
            detailHasBackground={false}
        />
    );
};

/* ***** backend ***** */
//TODO move to profile?
export const fetchCurrencies = async () => {
    let response = await backend(`/api/settings/currencies`);
    return response.body;
};

const saveCompanyInfo = async (values) => {
    let response = await backend(`/api/settings/company`, "PUT", {
        body: values,
    });
    return response.body;
};
