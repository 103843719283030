import React, {createContext, useContext, useEffect, useState} from "react";

import {makeStyles} from "@mui/styles";
import {alpha, emphasize} from "@mui/material/styles";
import {useLocation, useNavigate} from "react-router-dom";

import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Hidden from "@mui/material/Hidden";
import Drawer from "@mui/material/Drawer";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Link from "@mui/material/Link";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import classnames from "classnames";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Avatar from "@mui/material/Avatar";

import Actions from "./Actions";

import clsx from "clsx";

import {avatarString} from "../utils/string";
import {LoadingIndicator} from "../utils/loading";
import Grid from "@mui/material/Grid";

const drawerWidth = 200;

const MenuToggleButton = ({ open, setOpen }) => {
    return (
        <IconButton color="inherit" onClick={() => setOpen(!open)} sx={{ mx: 1 }}>
            {!!open ? <MenuOpenIcon /> : <MenuIcon />}
        </IconButton>
    );
};

const useMenuItemsStyles = makeStyles((theme) => ({
    menuItemsRoot: {
        height: "100%",
        position: "relative",
        display: "flex",
        flexDirection: "column",
    },
    mainMenuItems: {
        flex: "1 1 auto",
    },
    bottomMenuItems: {
        flex: "0 0 auto",
    },
    menuItemRow: {
        color: theme.palette.getContrastText(theme.palette.primary.main),
        maxHeight: theme.spacing(6),
        overflow: "hidden",
        "&:hover": {
            backgroundColor: emphasize(theme.palette.primary.main, 0.3),
            color: theme.palette.getContrastText(emphasize(theme.palette.primary.main, 0.3)),
            "& .MuiListItemIcon-root": {
                color: theme.palette.getContrastText(emphasize(theme.palette.primary.main, 0.3)),
            },
        },
        "& .MuiListItemIcon-root": {
            color: theme.palette.getContrastText(theme.palette.primary.main),
        },
    },
    menuText: {
        whiteSpace: "nowrap",
    },
    activeMenu: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        "&:hover": {
            backgroundColor: () => emphasize(theme.palette.secondary.main, 0.3),
        },
        "& .MuiListItemIcon-root": {
            color: theme.palette.secondary.contrastText,
        },
    },
}));

const MenuItems = ({ items }) => {
    const classes = useMenuItemsStyles();
    const location = useLocation();
    const navigate = useNavigate();

    const onMenuItemClick = (item) => () => {
        if (!!item.path) {
            navigate(item.path);
            return;
        }

        if (typeof item.onClick === "function") {
            item.onClick();
        }
    };

    const mainItems = [];
    const bottomItems = [];

    items.forEach((item) => {
        if (item.bottom) {
            bottomItems.push(item);
        } else {
            mainItems.push(item);
        }
    });

    const renderItems = (items, bottom) => (
        <div className={bottom ? classes.bottomMenuItems : classes.mainMenuItems}>
            <Stack spacing={1}>
                {Array.isArray(items) &&
                    items.map((menu, idx) => (
                        <React.Fragment key={idx}>
                            <ListItem
                                button
                                onClick={onMenuItemClick(menu)}
                                title={menu.title}
                                className={classnames(classes.menuItemRow, {
                                    [classes.activeMenu]:
                                        menu.path && (menu.path === "/" ? location.pathname === menu.path : location.pathname.startsWith(menu.path || "")),
                                })}
                                disabled={!!menu.disabled}
                                divider={!!menu.divider}
                            >
                                {menu.icon ? <ListItemIcon>{menu.icon}</ListItemIcon> : null}
                                <ListItemText className={classes.menuText}>{menu.title}</ListItemText>
                            </ListItem>
                        </React.Fragment>
                    ))}
            </Stack>
        </div>
    );

    return (
        <div className={classes.menuItemsRoot}>
            {renderItems(mainItems, false)}
            {renderItems(bottomItems, true)}
        </div>
    );
};

const noop = () => {};

const SidebarContext = createContext({
    open: false,
    setOpen: noop,
});

const useSidebarStyles = makeStyles((theme) => ({
    drawer: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.getContrastText(theme.palette.primary.main),
        borderRight: "none",
        zIndex: 1,
    },
    menuButton: {
        color: theme.palette.getContrastText(theme.palette.primary.main),
    },

    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: "hidden",
        width: theme.spacing(7),
        [theme.breakpoints.up("sm")]: {
            width: theme.spacing(7),
        },
    },
    menuButtonContainer: {
        ...theme.mixins.toolbar,
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
    },
}));

export const Sidebar = ({ children }) => {
    const classes = useSidebarStyles();
    const { open, setOpen } = useContext(SidebarContext) || {};
    return (
        <>
            <Hidden smUp>
                <SwipeableDrawer
                    open={!!open}
                    onClose={() => setOpen(false)}
                    onOpen={() => setOpen(true)}
                    style={{ zIndex: 1400 }}
                    classes={{ paper: classes.drawer }}
                    pt={8}
                >
                    <Box className={classes.menuButtonContainer}>
                        <MenuToggleButton open={open} setOpen={setOpen} />
                    </Box>
                    {children}
                </SwipeableDrawer>
            </Hidden>

            {/* TODO */}
            <Hidden smDown>
                <Drawer
                    variant="permanent"
                    className={classnames(classes.drawer, {
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    })}
                    classes={{
                        paper: classnames(classes.drawer, {
                            [classes.drawerOpen]: open,
                            [classes.drawerClose]: !open,
                        }),
                    }}
                    PaperProps={{ elevation: 16 }}
                >
                    <Box className={classnames(classes.menuButtonContainer, { [classes.menuButtonContainerOpen]: open })}>
                        <MenuToggleButton open={open} setOpen={setOpen} />
                    </Box>
                    {children}
                </Drawer>
            </Hidden>
        </>
    );
};

const useLayoutStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "row-reverse",
        width: "100%",
        height: "100%",
        position: "relative",
    },
    content: {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: "auto",
        overflowY: "auto",
    },
    drawer: {
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: "auto",
    },
}));

export const SidebarLayout = ({ children, SidebarContent, menuItems }) => {
    const classes = useLayoutStyles();
    const [open, setOpen] = useState(false);

    return (
        <SidebarContext.Provider value={{ open, setOpen }}>
            <div className={classes.root}>
                <div className={classes.content}>{children}</div>
                <div className={classes.drawer}>
                    <Sidebar>
                        <MenuItems items={menuItems} />
                    </Sidebar>
                </div>
            </div>
        </SidebarContext.Provider>
    );
};

const usePageStyles = makeStyles((theme) => ({
    page: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        overflow: "hidden",
    },
}));

export const Page = ({ children, px, py, p }) => {
    const classes = usePageStyles();
    return (
        <Box sx={{ p, px, py }} className={classes.page}>
            {children}
        </Box>
    );
};

const usePageTitleStyles = makeStyles((theme) => ({
    placeholder: {
        ...theme.mixins.toolbar,
    },
    menuItems: {
        display: "flex",
    },
    appBarRoot: {
        "& button.MuiTab-root": {
            color: theme.palette.getContrastText(theme.palette.primary.main),
        },
        "& .MuiTabs-root .MuiTabs-scroller span.MuiTabs-indicator": {
            height: 4,
            backgroundColor: theme.palette.secondary.light,
        },
    },
    ellipsis: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
}));

export const PageTitleSmall = ({ title, titleSmall, children, menuItems, onBack, disabled }) => {
    const classes = usePageTitleStyles();
    const sidebarContext = useContext(SidebarContext);
    return (
        <AppBar position="static" className={classes.appBarRoot}>
            <Toolbar sx={{ flex: 1, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <IconButton size="large" edge="start" color="inherit" sx={{ mr: !!onBack ? 0 : 2 }} onClick={() => sidebarContext.setOpen(true)}>
                    <MenuIcon />
                </IconButton>
                <Typography variant="h6" component="div" sx={{ flex: 1 }} className={classes.ellipsis}>
                    {!!onBack && (
                        <IconButton color="inherit" edge="start" onClick={onBack}>
                            <ArrowBackIcon />
                        </IconButton>
                    )}
                    {titleSmall || title || ""}
                </Typography>
                {Array.isArray(menuItems) && <Actions items={menuItems} disabled={disabled} />}
            </Toolbar>
            {children}
        </AppBar>
    );
};

export const PageTitleLarge = ({ title, children, menuItems, onBack, disabled }) => {
    const classes = usePageTitleStyles();
    return (
        <Toolbar sx={{ flex: 0, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Stack spacing={2} direction="row" alignItems="center" sx={{ overflow: "hidden" }}>
                <Typography variant="h6" color={!disabled ? "text.primary" : "text.secondary"} className={classes.ellipsis}>
                    {!!onBack && (
                        <IconButton color="inherit" edge="start" onClick={onBack}>
                            <ArrowBackIcon />
                        </IconButton>
                    )}
                    {title || ""}
                </Typography>
                {children}
            </Stack>
            {Array.isArray(menuItems) && <Actions items={menuItems} disabled={disabled} />}
        </Toolbar>
    );
};

export const PageTitle = ({ title, titleSmall, children, menuItems, onBack, disabled }) => {
    const classes = usePageTitleStyles();

    return (
        <>
            <Hidden smDown>
                <PageTitleLarge title={title} menuItems={menuItems} onBack={onBack} disabled={disabled}>
                    {children}
                </PageTitleLarge>
            </Hidden>
            <Hidden smUp>
                <PageTitleSmall title={title} titleSmall={titleSmall} menuItems={menuItems} onBack={onBack} disabled={disabled}>
                    {children}
                </PageTitleSmall>
            </Hidden>
        </>
    );
};

const usePageContentStyles = makeStyles((theme) => ({
    pageContent: {
        flex: "1 1 auto",
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
    },
    pageContentInner: {
        flex: 1,
    },
}));

export const PageContent = ({
    children,
    px,
    py,
    p = 2,
    showPageTitle,
    showLoadingIndicator,
    disabled,
    pageTitle,
    pageTitleSmall,
    pageTitleChildren,
    menuItems,
    onBack,
}) => {
    const classes = usePageContentStyles();
    const pageTitleClasses = usePageTitleStyles();
    const sidebarContext = useContext(SidebarContext);

    return (
        <>
            {!!showLoadingIndicator && <LoadingIndicator hidden="smDown" />}
            {!!showPageTitle && (
                <Hidden smUp>
                    <PageTitleSmall title={pageTitle} titleSmall={pageTitleSmall} disabled={disabled} menuItems={menuItems} onBack={onBack}>
                        {pageTitleChildren}
                    </PageTitleSmall>
                    {!!showLoadingIndicator && <LoadingIndicator />}
                </Hidden>
            )}
            <Box className={classes.pageContent}>
                {!!showPageTitle && (
                    <Hidden smDown>
                        <PageTitleLarge title={pageTitle} disabled={disabled} menuItems={menuItems} onBack={onBack}>
                            {pageTitleChildren}
                        </PageTitleLarge>
                    </Hidden>
                )}
                <Box sx={{ p, px, py }} className={classes.pageContentInner}>
                    {children}
                </Box>
            </Box>
        </>
    );
};

export const PageFooter = ({
    children,
    p = 1,
    px,
    py,
    copyright,
    copyrightOwner = "Jan Hrabal",
    copyrightOwnerUrl = "https://www.janhrabal.com",
    copyrightSince = 2021,
    links,
}) => {
    return (
        <Box
            sx={{
                flexGrow: 0,
                flexShrink: 0,
                flexWrap: "wrap",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                p,
                px,
                py,
            }}
        >
            {links && !!links.length && (
                <Box
                    sx={{
                        textAlign: "center",
                        margin: -1,
                        "& > *": {
                            margin: 1,
                        },
                    }}
                >
                    {links.map((link, idx) => (
                        <Button variant="text" key={idx} onClick={link.onClick}>
                            {link.text}
                        </Button>
                    ))}
                </Box>
            )}
            {children}
            {!!copyright && <Copyright owner={copyrightOwner} ownerUrl={copyrightOwnerUrl} since={copyrightSince} />}
        </Box>
    );
};

export const Copyright = ({ since, owner, ownerUrl }) => {
    let ownerElement = <span>{owner}</span>;
    if (ownerUrl) {
        ownerElement = (
            <Link href={ownerUrl} target="_blank" rel="noopener noreferrer" style={{ color: "inherit", textDecoration: "none" }}>
                {ownerElement}
            </Link>
        );
    }

    const year = new Date().getFullYear();
    return (
        <Typography component="div" color="textSecondary" variant="body2">
            Copyright {ownerElement} © {`${!!since && since !== year ? since + " - " : ""}`}
            {year}
        </Typography>
    );
};

const usePanelStyles = makeStyles(() => ({
    root: {},
    fullHeight: {
        height: "100%",
    },
}));

export const Panel = ({ p = 4, children, fullHeight }) => {
    const classes = usePanelStyles();
    return (
        <Paper className={clsx(fullHeight ? classes.fullHeight : classes.root)}>
            <Box p={p}>{children}</Box>
        </Paper>
    );
};

const usePanelTitleClasses = makeStyles((theme) => ({
    titleRoot: {
        marginBottom: theme.spacing(4),
    },
    title: {
        fontSize: "1.1em",
    },
}));

export const PanelTitle = ({ title, subtitle, children, actions }) => {
    const classes = usePanelTitleClasses();
    return (
        <div className={classes.titleRoot}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                <Typography variant="h6" className={classes.title}>
                    {title || children}
                </Typography>
                {!!actions && (
                    <Stack direction="row" alignItems="center">
                        {actions}
                    </Stack>
                )}
            </Stack>
            {!!subtitle && (
                <Typography variant="body2" color="textSecondary">
                    {subtitle}
                </Typography>
            )}
        </div>
    );
};

export const ToolPanel = ({ defaultExpanded, title, disabled, children, icon }) => {
    const [expanded, setExpanded] = useState(!!defaultExpanded);

    useEffect(() => {
        setExpanded(!!defaultExpanded);
    }, [defaultExpanded]);

    return (
        <Box mb={1}>
            <Accordion TransitionProps={{ unmountOnExit: true }} expanded={expanded} onChange={(e, expanded) => setExpanded(expanded)} disableGutters>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    {!!icon && <Avatar variant="square">{icon}</Avatar>}
                    <Box flexGrow={1} pl={2} alignSelf={"center"}>
                        <Typography variant="h6" color={disabled ? "textSecondary" : "textPrimary"}>
                            {title}
                        </Typography>
                    </Box>
                </AccordionSummary>
                <AccordionDetails>{children}</AccordionDetails>
            </Accordion>
        </Box>
    );
};

//TODO style properly
export const GroupPanel = ({ defaultExpanded, title, disabled, children, icon }) => {
    const [expanded, setExpanded] = useState(!!defaultExpanded);

    useEffect(() => {
        setExpanded(!!defaultExpanded);
    }, [defaultExpanded]);

    return (
        <Box>
            <Accordion
                TransitionProps={{ unmountOnExit: true }}
                expanded={expanded}
                onChange={(e, expanded) => setExpanded(expanded)}
                disableGutters
                sx={{ background: "none", border: "none" }}
            >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    {icon || null}
                    <Box flexGrow={1} pl={2} alignSelf={"center"}>
                        <Typography variant="body1" color={disabled ? "textSecondary" : "textPrimary"}>
                            {title}
                        </Typography>
                    </Box>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: 0 }}>{children}</AccordionDetails>
            </Accordion>
        </Box>
    );
};

//const useListContainer

const useListRowStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(1),
        borderBottom: "1px solid #CECECE",
        cursor: "pointer",
        overflow: "hidden",
        "&:hover":
            theme.palette.type === "light"
                ? {
                      //color: theme.palette.primary.main,
                      backgroundColor: alpha("#000000", theme.palette.action.selectedOpacity),
                  }
                : {
                      color: theme.palette.text.primary,
                      backgroundColor: theme.palette.primary.dark,
                  },
    },
    selected:
        theme.palette.type === "light"
            ? {
                  //color: theme.palette.primary.main,
                  backgroundColor: alpha(theme.palette.secondary.main, theme.palette.action.selectedOpacity),
              }
            : {
                  color: theme.palette.text.primary,
                  backgroundColor: theme.palette.primary.dark,
              },
    flex: {
        display: "flex",
        flexWrap: "nowrap",
        alignItems: "center",
        alignContent: "center",
        columnGap: theme.spacing(2),
    },
    fixed: {
        flex: 0,
    },
    main: {
        flex: 1,
        overflow: "hidden",
    },
    flexEllipsis: {
        display: "flex",
        flexWrap: "nowrap",
        alignItems: "center",
        alignContent: "center",
        columnGap: theme.spacing(4),
    },
    ellipsis: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    flexEllipsis: {
        flex: 1,
        minWidth: 0,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
}));

export const ListRow = ({ avatar, icon, mainTitle, upperTitle, description, date, value, onClick, disabled, selected }) => {
    const classes = useListRowStyles();
    return (
        <div className={clsx(classes.root, classes.flex, { [classes.selected]: !!selected })} role="button" onClick={onClick}>
            {!!avatar || !!icon ? (
                <div className={classes.fixed}>
                    <Avatar alt={avatar}>{!!icon ? icon : avatarString(avatar)}</Avatar>
                </div>
            ) : null}
            <div className={classes.main}>
                {!!upperTitle && (
                    <div className={classes.ellipsis}>
                        <Typography variant="caption" color="textSecondary" component="span">
                            {upperTitle}
                        </Typography>
                    </div>
                )}
                <div className={classes.flex}>
                    <div className={classes.flexEllipsis}>
                        <Typography variant="body1" component="span">
                            {mainTitle}
                        </Typography>
                    </div>
                    {!!value || !isNaN(value) ? (
                        <div className={classes.fixed}>
                            <Typography variant="body1" component="span">
                                {value}
                            </Typography>
                        </div>
                    ) : null}
                </div>

                {/* */}
                {!!date || !!description ? (
                    <div className={classes.flex}>
                        <div className={classes.flexEllipsis}>
                            <Typography variant="caption" color="textSecondary" component="span">
                                {description}
                            </Typography>
                        </div>
                        <div className={classes.fixed}>
                            <Typography variant="caption" color="textSecondary" component="span">
                                {date}
                            </Typography>
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export const ListContainer = ({ children }) => {
    return <div>{children}</div>;
};

const useLayoutWiothInfoStyles = makeStyles((theme) => ({}));

export const LayoutWithInfo = ({children}) => {
    return <Grid container rowSpacing={3} columnSpacing={4}>{children}</Grid>
};

export const LayoutWithInfoMain = ({children}) => {
    return <Grid item xs={12} xl={9}>{children}</Grid>;
};

export const LayoutWithInfoInfo = ({children}) => {
    return <Grid item xs={12} xl={3}>{children}</Grid>;
};
