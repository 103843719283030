import React, {createContext, useEffect, useState} from "react";

import backend from "./backend";

export const LOGIN_INFO_KEY = "_login_info_";

export const SecurityContext = createContext({
    login: null,
    loginInProgress: false,
    logout: null,
    resetPassword: null,
    resetPasswordInProgress: false,
    signup: null,
    signupInProgress: false,
});

//TODO backend
export async function loginRequest(username, password) {
    try {
        let response = await backend("/api/auth/login", "POST", {
            body: {
                username,
                password,
            },
        });
        let info = response.body;
        localStorage.setItem(LOGIN_INFO_KEY, JSON.stringify(info));
        return info;
    } catch (e) {
        await logout();
        throw e;
    }
}

export async function logout() {
    localStorage.removeItem(LOGIN_INFO_KEY);
    //TODO
}

export function loginInfo() {
    let s = localStorage.getItem(LOGIN_INFO_KEY);
    if (s == null) {
        return null;
    }

    return JSON.parse(s);
}

export async function userInfo() {
    try {
        let response = await backend("/api/auth/user", "GET", { logoutOn401: false });
        return response.body;
    } catch (e) {
        console.error(e);
        logout();
    }
    return undefined;
}

//TODO
export async function signupRequest(email, password, terms, privacy) {
    try {
        let response = await backend("/api/auth/signup", "POST", {
            body: {
                email,
                password,
                terms,
                privacy,
            },
        });
        let info = response.body;
        return info;
    } catch (e) {
        console.error(e);
        logout();
        throw e;
    }
}

//TODO
export async function resetPasswordRequest(email) {
    try {
        let response = await backend("/api/auth/resetRequest", "POST", {
            body: {
                login: email,
                email,
            },
        });
        let info = response.body;
        return info;
    } catch (e) {
        console.error(e);
        logout();
        throw e;
    }
}

export async function deleteAccountRequest() {
    await backend("/auth/user", "DELETE");
}

export const Security = ({ children }, securityProps) => {
    if (!securityProps) {
        securityProps = {};
    }
    const [state, setState] = useState(false);
    useEffect(() => {
        const user = localStorage.getItem("FE_USER");
        if (user) {
            setState({ ...state, user: JSON.parse(user) });
        }
    }, []);

    const login = async (user) => {
        const { username, password } = user || {};
        if (securityProps && securityProps.frontendOnly) {
            const user = { ...(securityProps.user || {}), username };
            setState({ user, userInfoLoading: false });
            localStorage.setItem("FE_USER", JSON.stringify(user));
            return;
        }

        let { auth } = state;
        setState({ loginInProgress: true });
        try {
            let loginResponse = await loginRequest(username, password);
            if (loginResponse) {
                localStorage.setItem(LOGIN_INFO_KEY, JSON.stringify(loginResponse));
            }
            let user = await userInfo();
            setState({ ...state, user, loginInProgress: false });
            localStorage.setItem("FE_USER", JSON.stringify(user));
        } catch (e) {
            setState({ ...state, user: null, loginInProgress: false });
            throw e;
        }
    };

    const logout = async () => {
        localStorage.removeItem("FE_USER");
        setState({ user: undefined });
    };

    const resetPassword = async () => {
        setState({ user: undefined, resetPasswordInProgress: true });
        await wait(2500);
        setState({ user: undefined, resetPasswordInProgress: false });
    };

    const signup = async () => {
        setState({ user: undefined, signupInProgress: true });
        await wait(2500);
        setState({ user: { email: "jh", requiresInit: true }, signupInProgress: false });
    };

    const updatePayload = (payload) => {
        let user = state.user || {};
        user = { ...user, payload: payload || {} };
        if (payload) {
            user.name = payload.name; //TODO?
        }

        setState({ ...state, user });
        localStorage.setItem("FE_USER", JSON.stringify(user));
    };

    return (
        <SecurityContext.Provider
            value={{
                login,
                loginInProgress: state.loginInProgress,
                logout,
                resetPassword,
                resetPasswordInProgress: state.resetPasswordInProgress,
                signup,
                signupInProgress: state.signupInProgress,
                user: state.user,
                updatePayload,
            }}
        >
            {children}
        </SecurityContext.Provider>
    );
};

const wait = (ms) => {
    return new Promise((resolve) => {
        setTimeout(() => resolve(1), ms);
    });
};
