import React from "react";

import Switch from "@mui/material/Switch";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";

export default ({ title, subtitle, value, onChange, disabled, name, variant = "h6" }) => {
    return (
        <Box display="flex" alignItems="center">
            <Box flexGrow={1} color={!!value && !disabled ? "text.primary" : "text.secondary"}>
                <Typography variant={variant || "h6"}>{title}</Typography>
                {!!subtitle && (
                    <Typography variant="caption" color="text.secondary" component="div">
                        {subtitle}
                    </Typography>
                )}
            </Box>
            <Switch color="secondary" checked={value || false} onChange={onChange} name={name} value={true} disabled={disabled} />
        </Box>
    );
};

export const TextSwitch = ({ value, onChange, options, variant, spacing = 1 }) => {
    return (
        <Stack direction="row" spacing={spacing} divider={<Divider orientation="vertical" flexItem />}>
            {Array.isArray(options) &&
                options.map((val, key) => {
                    return (
                        <Typography
                            variant={variant || "body2"}
                            key={`${val.value}-${key}`}
                            component={value === val.value ? "b" : "a"}
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                !!onChange && onChange(val.value);
                            }}
                        >
                            {val.title}
                        </Typography>
                    );
                })}
        </Stack>
    );
};
