import React, {useEffect, useMemo, useState} from "react";
import Stack from "@mui/material/Stack";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

import {makeStyles} from "@mui/styles";
import clsx from "clsx";
import {debounce} from "lodash";
import {unaccent} from "../utils/string";

import {useLoadingIndicator} from "../utils/loading";

const makeTransactionsSelectorStyles = makeStyles((theme) => ({
    transactionsRoot: {
        display: "flex",
        flexDirection: "column",
    },
    filterContainer: {
        flex: "0 0",
    },
    listContainer: {
        flex: "1 1 auto",
        border: "1px solid #CECECE",
        height: "100%",
        maxHeight: 500,
        minHeight: 250,
        overflowX: "hidden",
        overflowY: "auto",
        marginBottom: theme.spacing(2),
    },
    listItem: {
        display: "flex",
        rowGap: theme.spacing(1),
        borderBottom: "1px solid #CECECE",
    },
    listItemCheckbox: {
        flex: "0 0 auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    listItemContent: {
        flex: "1 1 auto",
        paddingRight: theme.spacing(1),
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
}));

const FilterableMultiSelectField = ({
    disabled,
    className,
    style,
    skipValues,
    renderFilterFn,
    resolveItemFilterFn,
    normalizeFilter = true,
    items,
    renderItemFn = (t) => <pre>{JSON.stringify(t, null, 4)}</pre>,
    idFunction = (x) => (x ? x.id : undefined),
    selectAllEnabled = true,
    valueRef,
    ...props
}) => {
    const classes = makeTransactionsSelectorStyles();
    const [state, setState] = useState({
        selectAll: false,
        _filter: false,
        selectedItems: {},
        items: [],
    });
    const [filter, setFilter] = useState({});

    const refreshFilter = useMemo(() => {
        return debounce((f) => setFilter(f || {}), 500, { leading: false, trailing: true });
    }, []);
    const { startLoading, stopLoading } = useLoadingIndicator();

    useEffect(async () => {
        startLoading();
        console.log("starting");
        let alreadySelected = {};
        if (Array.isArray(skipValues) && skipValues.length) {
            alreadySelected = skipValues.reduce(function (map, obj) {
                map[idFunction(obj)] = true;
                return map;
            }, {});
        }

        let filteredItems = (items || []).filter((i) => !alreadySelected[idFunction(i)]);
        if (resolveItemFilterFn && filter && Object.keys(filter).length) {
            //TODO unaccent filter's text
            let f = filter || {};
            if (!!normalizeFilter) {
                f = {};
                for (let key in filter) {
                    let val = filter[key];
                    if (typeof val === "string") {
                        f[key] = unaccent(val, true, true);
                        continue;
                    }
                    f[key] = val;
                }
            }

            filteredItems = filteredItems.filter((item) => resolveItemFilterFn(item, f));
        }

        stopLoading();
        setState({ ...state, items: filteredItems, selectedItems: {} });
        console.log("done");
    }, [items, filter, skipValues]);

    useEffect(() => {
        if (valueRef) {
            valueRef.current = (state.items || []).filter((i) => state.selectedItems[idFunction(i)]);
        }
    }, [state.selectedItems]);

    return (
        <div className={clsx(classes.transactionsRoot, className)}>
            <div className={classes.filterContainer}>
                {!!renderFilterFn &&
                    renderFilterFn(state._filter || {}, (filter) => {
                        setState({ ...state, _filter: filter || {} });
                        refreshFilter(filter);
                    })}
            </div>
            <div className={classes.listContainer}>
                {Array.isArray(state.items) &&
                    state.items.map((t) => {
                        let id = idFunction(t);
                        return (
                            <div
                                className={clsx(classes.listItem, { [classes.selectedRow]: !!state.selectedItems[id] })}
                                key={id}
                                onClick={(e) => {
                                    if (state.selectedItems[id]) {
                                        let s = { ...state.selectedItems };
                                        delete s[id];
                                        setState({ ...state, selectedItems: s, selectAll: false });
                                    } else {
                                        setState({ ...state, selectedItems: { ...state.selectedItems, [id]: true }, selectAll: false });
                                    }
                                }}
                            >
                                <div className={classes.listItemCheckbox}>
                                    <Checkbox checked={state.selectedItems[id] || false} disabled={props.disabled} value={true} />
                                </div>
                                <div className={classes.listItemContent}>{renderItemFn(t)}</div>
                            </div>
                        );
                    })}
            </div>
            <div className={classes.filterContainer}>
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                size="small"
                                checked={!!state.selectAll}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        let totalHours = 0;

                                        let selectedItems = (state.items || []).reduce(function (map, obj) {
                                            map[idFunction(obj)] = true;
                                            //totalHours = numbers.plus(obj.hours, totalHours, 2);
                                            return map;
                                        }, {});
                                        setState({ ...state, selectedItems, selectAll: true });
                                    } else {
                                        setState({ ...state, selectedItems: {}, selectAll: false });
                                    }
                                }}
                                disabled={props.disabled}
                            />
                        }
                        label="Vybrat vše"
                        size="small"
                    />
                </Stack>
            </div>
        </div>
    );
};

export default FilterableMultiSelectField;
