import React from "react";

import {makeStyles} from "@mui/styles";
import Typography from "@mui/material/Typography";

const useWidgetClasses = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap-reverse",
        flexDirection: "row",
        //alignItems: "center",
        //alignContent: "stretch",
        rowGap: theme.spacing(2),
        columnGap: theme.spacing(4),
        position: "relative",
        overflow: "hidden",
    },
    fixed: {
        flex: "0 0 auto",
        position: "relative",
        overflow: "hidden",
        height: "100%",
    },
    flexible: {
        flex: "1 1 auto",
        position: "relative",
        overflow: "hidden",
        height: "100%",
    },
    title: {
        marginBottom: theme.spacing(4),
    },
}));

export const WidgetRoot = ({ children, style }) => {
    const classes = useWidgetClasses();
    return (
        <div className={classes.root} style={style}>
            {children}
        </div>
    );
};

export const WidgetFixed = ({ children, style }) => {
    const classes = useWidgetClasses();
    return (
        <div className={classes.fixed} style={style}>
            {children}
        </div>
    );
};

export const WidgetFlexible = ({ children, style }) => {
    const classes = useWidgetClasses();
    return (
        <div className={classes.flexible} style={style}>
            {children}
        </div>
    );
};

export const WidgetTitle = ({ children }) => {
    const classes = useWidgetClasses();
    return (
        <Typography variant="h6" gutterBottom className={classes.title}>
            {children}
        </Typography>
    );
};
