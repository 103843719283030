import React from "react";

import {ReactECharts} from "./Charts";
import {useTheme} from "@mui/material/styles";
import {formatPeriodValue, getPeriodKey, parsePeriod} from "./period";
import {formatNum} from "./NumberFields";
import {useSettings} from "../utils/settings";

//TODO format values properly

export const updatePeriodOnClick = (periodRef) => {
    if (Array.isArray(periodRef) && periodRef.length > 1) {
        periodRef = periodRef[1];
    }

    if (!periodRef) {
        return undefined;
    }

    return (event) => {
        if (event.data && event.data.payload) {
            let period = parsePeriod(event.data.payload.name);
            periodRef(period);
        }
    };
};

const TrendsBarChart = ({ data, period, disabled, orientation = "horizontal", formatValue, onClick }) => {
    const theme = useTheme();
    const settings = useSettings();
    const periodKey = getPeriodKey(period);

    if (!Array.isArray(data)) {
        data = [];
    }

    return (
        <ReactECharts
            option={{
                width: "auto",
                height: "auto",
                maintainAspectRatio: false,
                [orientation == "horizontal" ? "yAxis" : "xAxis"]: {
                    data: data.map((nv) => nv.name),
                    axisLine: { show: false },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        inerval: 1,
                        formatter: formatPeriodValue,
                        rotate: orientation == "horizontal" ? 0 : 90,
                    },
                },
                [orientation == "horizontal" ? "xAxis" : "yAxis"]: {
                    show: false,
                },
                grid: {
                    containLabel: true,
                    left: "5%",
                    top: orientation == "horizontal" ? "5%" : "10%",
                    right: orientation == "horizontal" ? "25%" : "5%",
                    bottom: "5%",
                },
                series: [
                    {
                        type: "bar",
                        label: {
                            show: true,
                            position: orientation == "horizontal" ? "right" : "top",
                            formatter:
                                formatValue || ((v) => formatNum(v.value, settings.decimalDigits || 2, settings.decimalDelimiter, settings.thousandDelimiter)),
                        },
                        labelLine: { show: false },
                        //labelLayout: { hideOverlap: true },
                        avoidLabelOverlap: true,
                        containLabel: true,
                        data: data.map((nv) => ({
                            value: nv.value,
                            payload: nv,
                            currencyCode: nv.unit,
                            itemStyle: {
                                color:
                                    nv.name == periodKey
                                        ? disabled
                                            ? "#9A9A9A"
                                            : theme.palette.secondary.main
                                        : disabled
                                        ? "#6A6A6A"
                                        : theme.palette.primary.main,
                            },
                        })),
                    },
                ],
            }}
            onClick={onClick}
        />
    );
};

export default TrendsBarChart;
