import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import {useTheme} from "@mui/material/styles";
import {makeStyles} from "@mui/styles";

import {PageContent, Panel} from "../components/Layout";
import {LoadingIndicatorContainer, useLoadingIndicator} from "../utils/loading";

//import { ResponsiveContainer, PieChart, Pie, Legend, BarChart, Bar, Cell, XAxis, YAxis } from "recharts";
import {ChartLabels, DonutChart} from "../components/Charts";

import Period, {usePeriod} from "../components/period";
import TrendsBarChart, {updatePeriodOnClick} from "../components/TrendsBarChart";
import Value from "../components/Value";
import {CurrencyValue, formatCurrency, formatNum, NumberValue} from "../components/NumberFields";
import {useSettings} from "../utils/settings";

import moment from "moment";

import {
    fetchAccountingTransactionOverviewStatuses,
    fetchSummary as fetchTransactionSummary,
    fetchSummaryPeriods as fetchTransactionPeriods,
    TransactionStatusesChart,
} from "../components/transactions";

import {fetchTimesheetEmployeesChart, fetchTimesheetPeriodSummary,} from "../components/employees";

const createStyles = makeStyles((theme) => ({
    dashboard3Row: {
        display: "grid",
        gap: theme.spacing(4),
        gridTemplateColumns: "1fr 1fr 1fr",
    },
}));

const FinancialsWidget = ({ periodRef, settings, theme, classes, loadingIndicator, showAll }) => {
    const [period, setPeriod] = periodRef;
    const { startLoading, stopLoading, isLoading } = loadingIndicator || {};
    const [data, setData] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            startLoading();

            let start = period.start,
                end = period.end;

            if (showAll) {
                let m = moment(period.date);
                start = m.startOf("year").format("YYYY-MM-DD");
                end = m.endOf("year").format("YYYY-MM-DD");
            }

            let result = await Promise.allSettled([
                fetchTransactionSummary(start, end),
                fetchTransactionPeriods(period.day, period.type),
                fetchAccountingTransactionOverviewStatuses(start, end, moment().format("YYYY-MM-DD")),
            ]);

            let statuses = {},
                trends = {},
                summary = {};

            let dataResult = result[0];
            if (dataResult.status === "fulfilled") {
                summary = dataResult.value;
            }

            dataResult = result[1];
            if (dataResult.status === "fulfilled") {
                trends = dataResult.value;
            }
            dataResult = result[2];
            if (dataResult.status === "fulfilled") {
                statuses = dataResult.value;
            }

            setData({ statuses, summary, trends });

            stopLoading();
        };

        fetchData();
    }, [period, settings, showAll]);

    return (
        <>
            <div className={classes.dashboard3Row}>
                <div>
                    <Value
                        label="Celkem"
                        value={<CurrencyValue currencyCode={settings.defaultCurrencyCode} value={data.summary && data.summary.total} />}
                        big
                    />
                    <Value label="Příjmy" value={<CurrencyValue currencyCode={settings.defaultCurrencyCode} value={data.summary && data.summary.income} />} />
                    <Value label="Výdaje" value={<CurrencyValue currencyCode={settings.defaultCurrencyCode} value={data.summary && data.summary.expenses} />} />
                </div>
                <div>
                    <TrendsBarChart
                        data={data.trends || []}
                        period={period}
                        disabled={isLoading}
                        orientation="horizontal"
                        onClick={setPeriod ? updatePeriodOnClick(setPeriod) : null}
                        formatValue={(v) =>
                            `${formatCurrency(
                                v.value,
                                settings.defaultCurrencyCode,
                                settings.currencies,
                                settings.decimalDigits,
                                settings.decimalDelimiter,
                                settings.thousandDelimiter
                            )}`
                        }
                    />
                </div>
                <div>
                    <Typography>Stav</Typography>
                    <TransactionStatusesChart data={data.statuses} disabled={isLoading} />
                </div>
            </div>
        </>
    );
};

const TimesheetsWidget = ({ periodRef, settings, theme, classes, loadingIndicator, showAll }) => {
    const [period, setPeriod] = periodRef;
    const { startLoading, stopLoading, isLoading } = loadingIndicator || {};
    const [data, setData] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            startLoading();

            let start = period.start,
                end = period.end;

            if (showAll) {
                let m = moment(period.date);
                start = m.startOf("year").format("YYYY-MM-DD");
                end = m.endOf("year").format("YYYY-MM-DD");
            }

            let result = await Promise.allSettled([
                fetchTimesheetEmployeesChart(period.start, period.end),
                fetchTimesheetPeriodSummary(period.day, period.type),
            ]);

            let hoursChart = {},
                trends = {};

            let dataResult = result[0];
            if (dataResult.status === "fulfilled") {
                hoursChart = dataResult.value;
            }

            dataResult = result[1];
            if (dataResult.status === "fulfilled") {
                trends = dataResult.value;
            }

            setData({ hoursChart, trends });

            stopLoading();
        };

        fetchData();
    }, [period, settings, showAll]);

    return (
        <>
            <div className={classes.dashboard3Row}>
                <div>
                    <DonutChart
                        data={[(data.hoursChart || []).map((i) => i.value)]}
                        colors={isLoading ? ["#6A6A6A"] : [theme.palette.primary.main]}
                        disabled={isLoading}
                        size={270}
                    />{" "}
                </div>
                <div>
                    <ChartLabels
                        values={data.hoursChart || []}
                        color={isLoading ? "#6A6A6A" : theme.palette.primary.main}
                        emptyValueLabel="Ostatní"
                        renderValue={(nv) => <NumberValue value={nv.value} unit="h" />}
                        variant="row"
                    />
                </div>
                <div>
                    <TrendsBarChart
                        data={data.trends}
                        period={period}
                        disabled={isLoading}
                        formatValue={(v) => `${formatNum(v.value, settings.decimalDigits, settings.decimalDelimiter, settings.thousandDelimiter)} h`}
                        onClick={updatePeriodOnClick(setPeriod)}
                    />
                </div>
                <div></div>
            </div>
        </>
    );
};

const DashboardContent = ({ menuItems }) => {
    const classes = createStyles();
    const settings = useSettings();
    const theme = useTheme();
    const loadingIndicator = useLoadingIndicator();
    const { isLoading } = loadingIndicator;
    const periodRef = usePeriod();
    const [period, setPeriod] = periodRef;
    const [showAll, setShowAll] = useState(true);

    const widgets = [];
    const actions = [
        {
            render: () => {
                return (
                    <FormControlLabel
                        control={
                            <Switch
                                size="small"
                                color="secondary"
                                checked={!!showAll}
                                onChange={(e) => {
                                    setShowAll(e.target.checked);
                                    e.stopPropagation();
                                }}
                                disabled={isLoading}
                            />
                        }
                        label={<Typography variant="caption">Souhrn za rok</Typography>}
                    />
                );
            },
        },
    ];

    if (settings.transactionsSupport) {
        widgets.push(
            <FinancialsWidget periodRef={periodRef} settings={settings} theme={theme} loadingIndicator={loadingIndicator} classes={classes} showAll={showAll} />
        );
    }

    if (settings.timesheetsSupport) {
        widgets.push(
            <TimesheetsWidget periodRef={periodRef} settings={settings} theme={theme} loadingIndicator={loadingIndicator} classes={classes} showAll={showAll} />
        );
    }
    /*
    if (settings.counterpartiesSupport) {
        widgets.push(
            <FinancialsWidget periodRef={periodRef} settings={settings} theme={theme} loadingIndicator={loadingIndicator} classes={classes} showAll={showAll} />
        );
    }

    if (settings.projectsSupport) {
        widgets.push(
            <FinancialsWidget periodRef={periodRef} settings={settings} theme={theme} loadingIndicator={loadingIndicator} classes={classes} showAll={showAll} />
        );
    }

    if (settings.billingsSupport) {
        widgets.push(
            <FinancialsWidget periodRef={periodRef} settings={settings} theme={theme} loadingIndicator={loadingIndicator} classes={classes} showAll={showAll} />
        );
    }
*/
    //TODO add clients, projects, timesheets etc.

    return (
        <PageContent pageTitle="Přehled" menuItems={menuItems} showPageTitle showLoadingIndicator>
            <Box mb={4}>
                <Panel>
                    <Period periodRef={periodRef} year quarter month actions={actions} />

                    {widgets.map((wdg, idx) => (
                        <Box key={idx} my={4}>
                            {wdg}
                        </Box>
                    ))}
                </Panel>
            </Box>
        </PageContent>
    );
};

export default ({ menuItems }) => {
    return (
        <LoadingIndicatorContainer>
            <DashboardContent menuItems={menuItems} />
        </LoadingIndicatorContainer>
    );
};
