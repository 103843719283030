import React, {createContext, useContext, useEffect, useState} from "react";

import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import {useTheme} from "@mui/material/styles";
import {makeStyles} from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Menu from "@mui/material/Menu";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";

import Typography from "@mui/material/Typography";
import clsx from "clsx";
import moment from "moment";
import Hidden from "@mui/material/Hidden";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CalendarViewMonthIcon from "@mui/icons-material/CalendarViewMonth";
import CalendarViewWeekIcon from "@mui/icons-material/CalendarViewWeek";
import CalendarViewDayIcon from "@mui/icons-material/CalendarViewDay";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import CircleIcon from "@mui/icons-material/Circle";
import TripOriginIcon from "@mui/icons-material/TripOrigin";

import Actions from "../Actions";

const monthNames = ["Leden", "Únor", "Březen", "Duben", "Květen", "Červen", "Červenec", "Srpen", "Září", "Říjen", "Listopad", "Prosinec"];

const now = moment().format("YYYY-MM-DD");

export const periodTypes = {
    all: { title: "Vše", icon: <AllInclusiveIcon fontSize="inherit" /> },
    year: { title: "Rok", icon: <CircleIcon fontSize="inherit" /> },
    quarter: { title: "Čtvrtletí", icon: <TripOriginIcon fontSize="inherit" /> },
    month: { title: "Měsíc", icon: <CalendarViewMonthIcon fontSize="inherit" /> },
    week: { title: "Týden", icon: <CalendarViewWeekIcon fontSize="inherit" /> },
    day: { title: "Den", icon: <CalendarViewDayIcon fontSize="inherit" /> },
};

const typesLine = Object.keys(periodTypes);

export const nextPeriodType = (type, minType) => {
    for (let i = 0; i < typesLine.length - 1; i++) {
        let t = typesLine[i];
        if (minType && t === t) {
            return minType;
        }
        if (t == type) {
            return typesLine[i + 1];
        }
    }
    return minType || "day";
};

export const calculatePeriod = (type = "month", date = now) => {
    let m = moment.utc(date);
    let ptype = type == "week" ? "isoWeek" : type;

    let day = m.utc().format("YYYY-MM-DD"),
        end = m.utc().endOf(ptype).format("YYYY-MM-DD"),
        start = m.utc().startOf(ptype).format("YYYY-MM-DD"),
        year = m.utc().year(),
        month = m.utc().month(),
        quarter = m.utc().quarter(),
        week = m.utc().isoWeek();

    const period = {
        start,
        end,
        type,
        year,
        month,
        quarter,
        week,
        day,
        date,
    };

    return period;
};

export const previousPeriod = ([period, setPeriod] = []) => {
    let type = period.type || "month",
        ptype = type == "week" ? "isoWeek" : type,
        m = moment.utc(period.start),
        oldVal = m.get(type),
        idx = 0;

    while (oldVal == m.startOf(ptype).get(type) && idx < 12) {
        m = m.add(type, -1).startOf(ptype);
        idx++;
    }

    const p = calculatePeriod(type, m);
    setPeriod(p);
};

export const nextPeriod = ([period, setPeriod] = []) => {
    let type = period.type || "month",
        ptype = type == "week" ? "isoWeek" : type,
        m = moment.utc(period.start),
        oldVal = m.get(type),
        idx = 0;

    while (oldVal == m.startOf(ptype).get(type) && idx < 12) {
        m = m.add(type, 1).startOf(ptype);
        idx++;
    }

    const p = calculatePeriod(type, m);
    setPeriod(p);
};

export const resetPeriod = ([period, setPeriod] = [], stepType) => {
    let m = now;
    setPeriod(calculatePeriod(period.type || "month", m));
};

export const getDefaultPeriodDate = (periodRef, shiftPrev = true, shiftNext = true) => {
    const today = moment().format("YYYY-MM-DD");

    if (Array.isArray(periodRef) && periodRef.length) {
        const [period] = periodRef;
        if (shiftPrev && period.end < today) {
            return period.end;
        }
        if (shiftNext && period.start > today) {
            return period.start;
        }
    }

    return today;
};

export const isInPeriod = (date, period) => {
    if (!date || !period) {
        return false;
    }

    return !(date < period.start || date > period.end);
};

export const usePeriod = () => {
    return useContext(PeriodContext);
};

export const formatPeriod = (period) => {
    let desc = "";
    let _showType = period.type;
    if (_showType == "year") {
        desc = `${period.year}`;
    } else if (_showType == "quarter") {
        desc = `Q${period.quarter}/${period.year}`;
    } else if (_showType == "week") {
        let s = moment(period.start),
            e = moment(period.end);

        desc = `${s.format(e.year() != s.year() ? "DD.MM.YYYY" : "DD.MM.")} - ${e.format("DD.MM.YYYY")}`;
    } else if (_showType == "day") {
        desc = `${moment(period.start).format("DD.MM.YYYY")}`;
    } else {
        desc = `${monthNames[period.month]} ${period.year}`;
    }

    return desc;
};

export const formatPeriodValue = (date) => {
    return formatPeriod(parsePeriod(date));
};

export const getPeriodKey = (p) => {
    if (Array.isArray(p) && p.length) {
        p = p[0];
    }
    if (!p) {
        return null;
    }

    return `${p.type};${p.start}`;
};

export const parsePeriod = (date) => {
    if (!date) {
        return "";
    }

    let keys = ("" + date).split(";");
    let type = null,
        d = null;

    if (keys.length > 1) {
        type = keys[0];
        d = keys[1];
    } else {
        type = "month";
        d = keys[0];
    }

    return calculatePeriod(type, d);
};

export const periodInterval = (period) => {
    let result = { from: null, to: null };
    if (period) {
        result.from = period.start;
        result.to = period.end;
    }
    return result;
};

const createClasses = makeStyles((theme) => ({
    inline: {
        textAlign: "center",
        display: "flex",
        //justifyContent: "space-between",
        alignItems: "center",
    },
    title: {
        display: "flex",
        textAlign: "center",
        justifyContent: "space-between",
        alignItems: "center",
    },
    titleInner: {
        overflow: "hidden",
    },
    fullWidth: {},
}));

//TODO new period component
const createPeriodActionBarClasses = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexWrap: "nowrap",
        alignItems: "center",
    },
    content: {
        flex: 1,
        alignItems: "center",
    },
    root: {
        display: "flex",
        alignItems: "center",
    },
    rootFullScreen: {
        justifyContent: "space-between",
    },
    title: {
        overflow: "hidden",
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        display: "flex",
        alignItems: "center",
    },
    titleButton: {
        flex: 0,
    },
    titlePeriod: {
        minWidth: 0,
        overflow: "hidden",
        flex: 1,
        "& > *": {
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            cursor: "pointer",
        },
    },
    fullWidthNext: {
        order: 1,
    },
    actions: {
        order: 2,
        display: "flex",
        alignItems: "center",
    },
}));

export const PeriodActionBar = ({ actions, fullWidth, disabled, size, breakpoint, ...props }) => {
    const classes = createPeriodActionBarClasses();
    const periodRef = useContext(PeriodContext);
    const [period, setPeriod] = periodRef || [null, null];

    const theme = useTheme();
    const isDown = useMediaQuery(theme.breakpoints.down(breakpoint || "lg"));

    fullWidth = fullWidth || isDown;

    let buttonSize = "medium";
    /*
    if (isDown || size == "small") {
        buttonSize = "small";
    }
    */

    let desc = formatPeriod(period);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const showMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const closeMenu = (type) => {
        setAnchorEl(null);
        if (type == "all") {
            setPeriod({ ...period, start: null, end: null, type });
            return;
        }
        !!type && setPeriod && setPeriod(calculatePeriod(type, period.date));
    };

    let supportedTypes = Object.keys(periodTypes).filter((type) => !!props[type]);

    return (
        <>
            <div className={classes.container}>
                <div className={classes.content}>
                    <div className={clsx(classes.root, { [classes.rootFullScreen]: !!fullWidth })}>
                        <IconButton onClick={() => previousPeriod(periodRef)} disabled={disabled} size={buttonSize} title="Předchozí období">
                            <ArrowLeftIcon />
                        </IconButton>
                        <IconButton
                            onClick={() => nextPeriod(periodRef)}
                            disabled={disabled}
                            size={buttonSize}
                            title="Následující období"
                            className={clsx({ [classes.fullWidthNext]: !!fullWidth })}
                        >
                            <ArrowRightIcon />
                        </IconButton>

                        <div className={classes.title}>
                            <Button onClick={showMenu} size={buttonSize} className={classes.titleButton} disabled={disabled}>
                                {periodTypes[(period && period.type) || "month"].icon}
                                <ArrowDropDownIcon fontSize="inherit" />
                            </Button>
                            <div className={classes.titlePeriod} onClick={disabled ? null : () => resetPeriod(periodRef)}>
                                <div>{desc}</div>
                            </div>
                        </div>
                    </div>
                </div>
                {Array.isArray(actions) && (
                    <div className={classes.actions}>
                        <Actions items={actions} disabled={disabled} />
                    </div>
                )}
            </div>
            <Menu anchorEl={anchorEl} open={open} onClose={() => closeMenu()}>
                <MenuList>
                    {supportedTypes.map((key) => {
                        const typeInfo = periodTypes[key];
                        return (
                            <MenuItem key={key} onClick={() => closeMenu(key)}>
                                <ListItemIcon>{typeInfo.icon}</ListItemIcon>
                                <ListItemText>{typeInfo.title}</ListItemText>
                            </MenuItem>
                        );
                    })}
                </MenuList>
            </Menu>
        </>
    );
};

export default PeriodActionBar;

export const PeriodContext = createContext({});

export const WithPeriod = ({ children, type, date, periodId }) => {
    const [period, setPeriodState] = useState(calculatePeriod(type, date));

    //TODO use memo?
    const setPeriod = (period) => {
        localStorage.setItem(`_period_id_${periodId || "global"}`, period.type);
        setPeriodState(period);
    };

    useEffect(() => {
        let s = localStorage.getItem(`_period_id_${periodId || "global"}`);
        let period = calculatePeriod(s || type, date);
        setPeriod(period);
    }, []);

    return <PeriodContext.Provider value={[period, setPeriod]}>{children}</PeriodContext.Provider>;
};

const _periodAxis = [
    { id: "year", name: "Rok", fixed: true, toggle: true, icon: <CircleIcon /> },
    { id: "quarter", name: "Čtvrtletí", fixed: true, toggle: true, icon: <TripOriginIcon /> },
    { id: "month", name: "Měsíc", fixed: true, toggle: true, icon: <CalendarViewMonthIcon /> },
    { id: "week", name: "Týden", fixed: true, toggle: true, icon: <CalendarViewWeekIcon /> },
    { id: "day", name: "Den", fixed: true, toggle: true, icon: <CalendarViewDayIcon /> },
];

export const periodAxes = (period, axis) => {
    const dateAxes = [];

    let p = period.type;
    let idx = 0;
    while (p !== "day") {
        p = nextPeriodType(p);
        for (let i = idx; i < _periodAxis.length; i++) {
            if (_periodAxis[i].id === p) {
                dateAxes.push({ ..._periodAxis[i], selected: true });
                idx = i;
            }
        }
    }

    if (!!axis) {
        dateAxes.push({ ...axis, selected: true });
    }

    return dateAxes;
};

/** TODO ** */
export const PeriodToolbar = ({ className, disabled, size = "small", withTypeSelector, fullWidth, ...props }) => {
    const classes = createClasses();
    const periodRef = useContext(PeriodContext);
    const [period, setPeriod] = periodRef || [null, null];

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (type) => {
        setAnchorEl(null);
        if (type == "all") {
            setPeriod({ ...period, start: null, end: null, type });
            return;
        }
        !!type && setPeriod && setPeriod(calculatePeriod(type, period.date));
    };

    let desc = formatPeriod(period);
    let padding = size == "small" ? 8 : 32;
    let title = null;

    //TODO periodTypes
    let supportedTypes = Object.keys(periodTypes).filter((type) => !!props[type]);

    return (
        <>
            <Box className={clsx({ [classes.inline]: !fullWidth }, className)} {...props}>
                <Hidden mdUp>
                    <IconButton onClick={handleClick}>{periodTypes[(period && period.type) || "month"].icon}</IconButton>
                </Hidden>
                <Hidden mdDown>
                    <ToggleButtonGroup value={period && period.type} onChange={(e, val) => handleClose(val)} size="small" exclusive disabled={disabled}>
                        {supportedTypes.map((key) => {
                            const typeInfo = periodTypes[key];
                            const Icon = typeInfo.icon;
                            return (
                                <ToggleButton key={key} value={key} title={typeInfo.title}>
                                    {Icon || null}
                                </ToggleButton>
                            );
                        })}
                    </ToggleButtonGroup>
                </Hidden>
                {period && period.type != "all" && (
                    <Box className={clsx({ [classes.title]: !!fullWidth }, className)}>
                        <IconButton onClick={() => previousPeriod(periodRef)} disabled={disabled} size={size}>
                            <ArrowLeftIcon fontSize={size == "small" ? "small" : "default"} disabled={disabled} />
                        </IconButton>
                        {!fullWidth && (
                            <IconButton onClick={() => nextPeriod(periodRef)} disabled={disabled}>
                                <ArrowRightIcon fontSize={size == "small" ? "small" : "default"} disabled={disabled} />
                            </IconButton>
                        )}
                        <Typography
                            variant={size == "small" ? "subtitle2" : "h6"}
                            display="inline"
                            onClick={() => resetPeriod(periodRef)}
                            style={{ cursor: "pointer", paddingLeft: padding, paddingRight: padding }}
                            className={classes.titleInner}
                        >
                            {desc}
                        </Typography>
                        {!!fullWidth && (
                            <IconButton onClick={() => nextPeriod(periodRef)} disabled={disabled}>
                                <ArrowRightIcon fontSize={size == "small" ? "small" : "default"} disabled={disabled} />
                            </IconButton>
                        )}
                    </Box>
                )}
            </Box>
            <Menu anchorEl={anchorEl} open={open} onClose={() => handleClose()}>
                <MenuList>
                    {supportedTypes.map((key) => {
                        const typeInfo = periodTypes[key];
                        return (
                            <MenuItem key={key} onClick={() => handleClose(key)}>
                                <ListItemIcon>{typeInfo.icon}</ListItemIcon>
                                <ListItemText>{typeInfo.title}</ListItemText>
                            </MenuItem>
                        );
                    })}
                </MenuList>
            </Menu>
        </>
    );
};
