import {useCallback, useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";

const useTabs = (tabs, tabsName = "tab") => {
    if (!tabs) {
        tabs = {};
    }
    if (!tabsName) {
        tabsName = "tab";
    }
    const availableTabs = Object.keys(tabs).filter((k) => !!tabs[k]);
    const allTabs = availableTabs.join(":");

    const [state, setState] = useState({});
    const [searchParams, setSearchParams] = useSearchParams();
    const queryTab = searchParams.get(tabsName);

    const setTab = useCallback(
        (tab) => {
            searchParams.set(tabsName, tab);
            setSearchParams(searchParams);
        },
        [state]
    );

    useEffect(() => {
        let tab = state.tab;
        if (queryTab) {
            tab = queryTab;
        }

        if (!tab || availableTabs.indexOf(tab) < 0) {
            if (availableTabs.length) {
                tab = availableTabs[0];
            }
        }

        setState({ ...state, tab, tabs: { ...tabs, _: availableTabs.length > 1 } });
        console.warn("trying to calculate tabs", tabs.length == 0 ? {} : tabs);
    }, [allTabs, queryTab]);

    return { tab: state.tab, setTab, tabs: state.tabs || {} };
};

export default useTabs;
